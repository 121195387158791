import { Col, Empty, Row, Typography } from "antd";
import { Moment } from "moment";
import { Suspense } from "react";
import { EnergyMeterCategory, EnergyMeterRole, Site } from "src/lib/api";
import {
  getDotCloudData,
  getDotCloudLineCoordinates,
  loadBoxConsumptionData,
} from "src/lib/dashboard-utils";
import { useEnergyConsumptions, useSiteEnergyMeters, useWeatherData } from "src/lib/hooks/api";
import { unitCM, unitMWh } from "../Chart/Chart";
import ErrorBoundaryWithFallback from "../ErrorBoundaryWithFallback";
import Loader from "../Loader";
import { DashDiv } from "./DashDiv";
import { DashboardConsumptionPerfo } from "./DashboardConsumptionPerfo";
import { DashboardConsumptionRepartition } from "./DashboardConsumptionRepartition";
import { DashboardDotCloud } from "./DashboardDotCloud";
import { DashboardGeneralInfosCells } from "./DashboardGeneralInfos";
import { DashboardMonthlyConsumptions } from "./DashboardMonthlyConsumption";
import moment from "moment";

function formatUnitOfMeasure(uom: string) {
  switch (uom) {
    case "megawatt_hour":
      return unitMWh;
    case "cubic_meter":
      return unitCM;
    default:
      return "undefined";
  }
}

export const DashboardConsumptionBox: React.FC<{ site: Site; from: Moment; to: Moment }> = ({
  site,
  from,
  to,
}) => {
  const energyMeterRes = useSiteEnergyMeters(site.slug);
  const energyMeter = energyMeterRes.status === "success" ? energyMeterRes.data : [];
  const energyMeterRef = energyMeter.filter((e) => e.category === EnergyMeterCategory.Reference);

  const unitOfMeasure = formatUnitOfMeasure(
    energyMeterRef.length > 0 ? energyMeterRef[0].unitOfMeasure : "megawatt_hour",
  );

  const energyMeterIds = energyMeterRef.map((e) => e.id.toString());
  const consumptionsRes = useEnergyConsumptions(
    energyMeterIds,
    from.startOf("month").add(1, "day").unix(),
    to.unix(),
  );
  const consumptions = consumptionsRes.status === "success" ? consumptionsRes.data : [];
  // timelapse between "from" and "to" represent 25 months
  const consumptionData25Months = loadBoxConsumptionData(consumptions);
  const firstMonth = moment.unix(consumptionData25Months?.[0]?.timestamp).endOf("month");
  // remove the first month to get exactly 24 months
  // used to remove history consumption when creating a site
  const consumptionData =
    consumptionData25Months?.filter((c) => moment.unix(c.timestamp).isAfter(firstMonth)) ?? [];

  const energyMeterIdsHeating = energyMeter
    .filter((e) => e.category === EnergyMeterCategory.Reference)
    .filter((e) => e.role === EnergyMeterRole.Heating)
    .map((e) => e.id.toString());
  const energyMeterIdsHeatingHdw = energyMeter
    .filter((e) => e.category === EnergyMeterCategory.Reference)
    .filter((e) => e.role === EnergyMeterRole.HeatingHdw)
    .map((e) => e.id.toString());

  const consumptionsPerfoRes = useEnergyConsumptions(
    energyMeterIdsHeating,
    from.startOf("month").add(1, "day").unix(),
    to.unix(),
  );
  const consumptionsPerfo =
    consumptionsPerfoRes.status === "success" ? consumptionsPerfoRes.data : [];

  // only case where there's no need to substract ECS to consumption data :
  const hasHeatingOnlyMeter =
    energyMeterIdsHeating.length > 0 && energyMeterIdsHeatingHdw.length === 0;
  const consumptionPerfoData = hasHeatingOnlyMeter
    ? loadBoxConsumptionData(consumptionsPerfo)
    : consumptionData;

  const weatherDataRes = useWeatherData(
    site.id,
    from.startOf("month").add(1, "day").unix(),
    to.unix(),
  );
  const weatherData = weatherDataRes.status === "success" ? weatherDataRes.data : undefined;
  const hdd = weatherData?.dailyHDD ?? [];

  const dotCloudData = getDotCloudData(consumptionData, hdd);
  const [, meanHdw] = getDotCloudLineCoordinates(dotCloudData);

  return (
    <>
      <Row style={{ paddingRight: 10 }}>
        <Col span={12} style={{ padding: 5 }}>
          <DashDiv>
            <Typography.Text style={{ paddingLeft: 20, paddingRight: 5, fontSize: 16 }} strong>
              CONSOMMATIONS MENSUELLES
            </Typography.Text>
            <Typography.Text style={{ fontStyle: "italic" }}>(12 mois glissants)</Typography.Text>
            <ErrorBoundaryWithFallback fallback={<Empty />}>
              <DashboardMonthlyConsumptions
                consumptions={consumptionData}
                hdd={hdd}
                unitOfMeasure={unitOfMeasure}
              />
            </ErrorBoundaryWithFallback>
          </DashDiv>
        </Col>

        <Col span={12} style={{ padding: 5 }}>
          <DashDiv>
            <Typography.Text style={{ paddingLeft: 20, paddingRight: 5, fontSize: 16 }} strong>
              CONSO vs DJU
            </Typography.Text>
            <Typography.Text style={{ fontStyle: "italic" }}>(12 mois glissants)</Typography.Text>
            <ErrorBoundaryWithFallback fallback={<Empty />}>
              <DashboardDotCloud data={dotCloudData} unitOfMeasure={unitOfMeasure} />
            </ErrorBoundaryWithFallback>
          </DashDiv>
        </Col>
      </Row>

      <Row style={{ paddingRight: 10 }}>
        <Col span={24} style={{ padding: 5 }}>
          <DashDiv>
            <Typography.Text style={{ paddingLeft: 20, paddingRight: 5, fontSize: 16 }} strong>
              {`PERFORMANCE ENERGETIQUE (${unitOfMeasure}/DJU)`}
            </Typography.Text>
            <Typography.Text style={{ fontStyle: "italic" }}>(30 derniers jours)</Typography.Text>
            <ErrorBoundaryWithFallback fallback={<Empty />}>
              <DashboardConsumptionPerfo
                consumptions={consumptionPerfoData}
                hdd={hdd}
                meanHdw={
                  hasHeatingOnlyMeter ? undefined : isNaN(meanHdw) ? "0" : meanHdw.toFixed(2)
                }
                unitOfMeasure={unitOfMeasure}
              />
            </ErrorBoundaryWithFallback>
          </DashDiv>
        </Col>
      </Row>

      <Row style={{ paddingRight: 10 }}>
        <Col span={6}>
          <ErrorBoundaryWithFallback fallback={<></>}>
            <DashboardGeneralInfosCells site={site} consumptions={consumptionData} hdd={hdd} />
          </ErrorBoundaryWithFallback>
        </Col>

        <Col span={18} style={{ padding: 5 }}>
          <DashDiv style={{ height: "100%" }}>
            <Typography.Text style={{ paddingLeft: 20, paddingRight: 5, fontSize: 16 }} strong>
              REPARTITION DES CONSOMMATIONS
            </Typography.Text>
            <Typography.Text style={{ fontStyle: "italic" }}>
              (Saison de chauffe en cours)
            </Typography.Text>
            <Suspense fallback={<Loader />}>
              <ErrorBoundaryWithFallback fallback={<Empty />}>
                <DashboardConsumptionRepartition
                  site={site}
                  consumptions={consumptionData}
                  unitOfMeasure={unitOfMeasure}
                />
              </ErrorBoundaryWithFallback>
            </Suspense>
          </DashDiv>
        </Col>
      </Row>
    </>
  );
};
