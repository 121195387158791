import { Card, Image, Typography } from "antd";
import moment from "moment";
import circuitChart from "src/assets/circuit-supervision.png";
import ecsChart from "src/assets/ecs-supervision.png";
import { unitDegreeCelsius } from "src/constants";
import { HeatingDistributionCircuit, HeatingProductionUnit, InstrumentGroup } from "src/lib/api";
import {
  useInstrumentGroupMeasurements,
  useSiteHeatingDistributionCircuitMeasurements,
  useSiteHeatingProductionUnitMeasurements,
  useSiteHeatingProductionUnits,
  useSiteInstrumentGroups,
} from "src/lib/hooks/api";
import { latestMeasurement, sorterMeasurement } from "src/lib/utils";

const fontSizeFactor = 10 / 1200;

const SynopticImageOverlay: React.FC<{
  top: number;
  left: number;
  value?: number;
  unit: string;
  isFixed?: boolean;
}> = ({ top, left, value, unit, isFixed }) => (
  <div
    style={{
      position: "absolute",
      width: "6.89%",
      height: "6.92%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      top: `${top}%`,
      left: `${left}%`,
    }}
  >
    <Typography.Text style={{ fontSize: fontSizeFactor * window.innerWidth }}>
      {!isFixed
        ? `${value ? value.toFixed(1) : "-"} ${unit}`
        : `[${value ? value.toFixed(1) : "-"}] ${unit}`}
    </Typography.Text>
  </div>
);

const Synoptic: React.FC<{
  slug: string;
  heatingProductionUnitId: number;
  heatingDistributionCircuit: HeatingDistributionCircuit;
}> = ({ slug, heatingProductionUnitId, heatingDistributionCircuit }) => {
  const { data: productionUnits } = useSiteHeatingProductionUnits(slug);
  const relevantHpu = productionUnits.find(
    (hpu: HeatingProductionUnit) => hpu.id === heatingProductionUnitId,
  );
  const relevantHpuMetadatas = relevantHpu
    ? relevantHpu.metadata
    : { water_flow_set_point_temperature: "" };

  const { data: productionUnitMeasurements } = useSiteHeatingProductionUnitMeasurements(
    slug,
    heatingProductionUnitId,
    moment().startOf("day").unix(),
  );

  const latestHeatingProductionUnitMeasurement =
    productionUnitMeasurements?.sort(sorterMeasurement)?.[0];

  const isProductionWaterflowSetPointTemperature =
    latestHeatingProductionUnitMeasurement?.waterFlowSetPointTemperature !== null;

  const instrumentGroupsRes = useSiteInstrumentGroups(slug);
  const instrumentGroups = instrumentGroupsRes.status === "success" ? instrumentGroupsRes.data : [];
  const referenceGroups = instrumentGroups.filter(
    (ig: InstrumentGroup) => ig?.category === "reference",
  );

  const circuitGroupId = heatingDistributionCircuit.instrumentGroupId ?? referenceGroups?.[0]?.id;

  const igMeasurementsRes = useInstrumentGroupMeasurements(
    [circuitGroupId?.toString() ?? 0],
    moment(Date.now()).subtract({ hours: 1 }).unix(),
    moment(Date.now()).unix(),
  );

  const igMeasurements = igMeasurementsRes.status === "success" ? igMeasurementsRes.data : [];

  const latestReferenceGroupMeasurement = igMeasurements?.sort(sorterMeasurement)?.[0];

  const { data: measurements } = useSiteHeatingDistributionCircuitMeasurements(
    slug,
    heatingDistributionCircuit.id,
    moment().startOf("day").unix(),
  );
  const latestHeatingDistributionCircuitMeasurement = latestMeasurement(measurements);

  const isDistributionWaterFlowSetPointTemperature =
    latestHeatingDistributionCircuitMeasurement?.waterFlowSetPointTemperature !== null;

  const isEcs = heatingDistributionCircuit.role === "domestic_hot_water";
  return (
    <Card
      title={`Synoptique circuit à ${moment(latestHeatingDistributionCircuitMeasurement?.timestamp)
        .format("LT")
        .replace(":", "h")}`}
      bodyStyle={{ padding: 0 }}
      style={{ borderRadius: 10 }}
      headStyle={{ borderBottomColor: "white" }}
    >
      <div style={{ position: "relative" }}>
        <Image
          src={isEcs ? ecsChart : circuitChart}
          preview={false}
          title={"Synoptique"}
          style={{
            width: "100%",
            height: "100%",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        />
        <SynopticImageOverlay
          top={25.6}
          left={9.77}
          value={latestHeatingProductionUnitMeasurement?.waterFlowTemperature}
          unit={unitDegreeCelsius}
        />
        <SynopticImageOverlay
          top={52.4}
          left={9.77}
          value={
            isProductionWaterflowSetPointTemperature
              ? latestHeatingProductionUnitMeasurement?.waterFlowSetPointTemperature
              : parseFloat(relevantHpuMetadatas.water_flow_set_point_temperature)
          }
          unit={unitDegreeCelsius}
          isFixed={!isProductionWaterflowSetPointTemperature}
        />
        <SynopticImageOverlay
          top={79.2}
          left={9.77}
          value={latestHeatingProductionUnitMeasurement?.waterReturnTemperature}
          unit={unitDegreeCelsius}
        />
        <SynopticImageOverlay top={25.6} left={23.66} value={undefined} unit={unitDegreeCelsius} />

        <SynopticImageOverlay
          top={8.35}
          left={34.37}
          value={latestHeatingDistributionCircuitMeasurement?.threeWayValveTravelPercent}
          unit={"%"}
        />
        <SynopticImageOverlay
          top={isEcs ? 25.5 : 16.8}
          left={isEcs ? 72.2 : 55.1}
          value={latestHeatingDistributionCircuitMeasurement?.waterFlowTemperatureApplied}
          unit={unitDegreeCelsius}
        />
        <SynopticImageOverlay
          top={isEcs ? 39.2 : 31.9}
          left={isEcs ? 72.2 : 55.1}
          value={
            isDistributionWaterFlowSetPointTemperature
              ? latestHeatingDistributionCircuitMeasurement?.waterFlowSetPointTemperature
              : parseFloat(
                  heatingDistributionCircuit.metadata?.water_flow_set_point_temperature ?? "",
                )
          }
          unit={unitDegreeCelsius}
          isFixed={!isDistributionWaterFlowSetPointTemperature}
        />
        <SynopticImageOverlay
          top={isEcs ? 79.2 : 79.2}
          left={isEcs ? 72.2 : 55.1}
          value={latestHeatingDistributionCircuitMeasurement?.waterReturnTemperature}
          unit={unitDegreeCelsius}
        />
        {!isEcs && (
          <SynopticImageOverlay
            top={45.5}
            left={79.65}
            value={latestReferenceGroupMeasurement?.temperatureMeasurement}
            unit={unitDegreeCelsius}
          />
        )}
        {!isEcs && (
          <SynopticImageOverlay
            top={16.8}
            left={79.65}
            value={latestHeatingDistributionCircuitMeasurement?.compositeOutsideTemperature}
            unit={unitDegreeCelsius}
          />
        )}
      </div>
    </Card>
  );
};

export default Synoptic;
