import { JobAlertCategories, JobAlerts } from "./api";

export function countJobAlerts(jobAlerts: JobAlerts, filter?: JobAlertCategories) {
  if (filter) {
    return jobAlerts[filter]?.length;
  }
  return (
    jobAlerts.heatingDistributionCircuits?.length +
      jobAlerts.plcs?.length +
      jobAlerts.heatingProductionUnits?.length +
      jobAlerts.energyMeters?.length ?? undefined
  );
}
