import { Divider, Form, Input, Modal } from "antd";
import * as React from "react";
import { useState } from "react";
import { Instrument, InstrumentDisposition, InstrumentRow } from "src/lib/api";

export type InstrumentUpdateFormValues = Omit<
  Instrument,
  "id" | "battery" | "type" | "serialNumber" | "dataSource" | "supplierId"
>;

export interface InstrumentDispositionModalProps {
  visible: boolean;
  onSave: (iid: string, disposition: InstrumentDisposition) => void;
  instrument?: InstrumentRow;
  onCancel: () => void;
}

const InstrumentDispositionModal: React.FC<InstrumentDispositionModalProps> = ({
  visible,
  onSave,
  instrument,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  React.useEffect(() => {
    form.setFieldsValue(instrument?.disposition);
  }, [instrument, form]);
  return (
    <Modal
      visible={visible}
      title={`Emplacement de l'instrument ${instrument?.name}`}
      okText="Enregistrer"
      cancelText="Annuler"
      destroyOnClose={true}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      confirmLoading={confirmLoading}
      onOk={() => {
        form
          .validateFields()
          .then(async (data: InstrumentDisposition) => {
            setConfirmLoading(true);
            await onSave(instrument?.id.toString() ?? "", data);
            setConfirmLoading(false);
            form.resetFields();
          })
          .catch((err: any) => {
            console.error("InstrumentGroupModal error:", err);
          });
      }}
    >
      <Form
        layout="vertical"
        form={form}
        name="instrument-modal"
        initialValues={instrument?.disposition}
      >
        <Form.Item
          label="Bâtiment"
          name="building"
          hasFeedback={true}
          initialValue={instrument?.disposition?.building}
        >
          <Input placeholder={"Bâtiment A"} />
        </Form.Item>
        <Form.Item
          label="Circuit"
          name="circuit"
          hasFeedback={true}
          initialValue={instrument?.disposition?.circuit}
        >
          <Input placeholder={"Circuit 1"} />
        </Form.Item>
        <Form.Item
          label="Verticale"
          name="vertical"
          hasFeedback={true}
          initialValue={instrument?.disposition?.vertical}
        >
          <Input placeholder={"Cage escalier B"} />
        </Form.Item>
        <Form.Item
          label="Etage"
          name="floor"
          hasFeedback={true}
          initialValue={instrument?.disposition?.floor}
        >
          <Input placeholder={"5ème étage"} />
        </Form.Item>
        <Form.Item
          label="Numéro d'appartement"
          name="flatNumber"
          hasFeedback={true}
          initialValue={instrument?.disposition?.flatNumber}
        >
          <Input placeholder={"506"} />
        </Form.Item>
        <Divider>Informations supplémentaires</Divider>
        <Form.Item
          label="Orientation"
          name={["flatDetails", "orientation"]}
          hasFeedback={true}
          initialValue={instrument?.disposition?.flatDetails.orientation}
        >
          <Input placeholder={"Nord"} />
        </Form.Item>
        <Form.Item
          label="Vitrage"
          name={["flatDetails", "glazing"]}
          hasFeedback={true}
          initialValue={instrument?.disposition?.flatDetails.glazing}
        >
          <Input placeholder={"Simple"} />
        </Form.Item>
        <Form.Item
          label="Type d'émetteur"
          name={["flatDetails", "transmitterType"]}
          hasFeedback={true}
          initialValue={instrument?.disposition?.flatDetails.transmitterType}
        >
          <Input placeholder={"Radiateurs"} />
        </Form.Item>
        <Form.Item
          label="Commentaires"
          name={["flatDetails", "comments"]}
          hasFeedback={true}
          initialValue={instrument?.disposition?.flatDetails.comments}
        >
          <Input placeholder={"VMC installée dans le salon"} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InstrumentDispositionModal;
