import { useAuth0 } from "@auth0/auth0-react";
import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  useQueryErrorResetBoundary,
  UseQueryResult,
} from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { hasPermission, PermissionGroup, Permissions, User } from "src/lib/access-control";
import {
  AdminBanner,
  AdminBannerCreate,
  AggregatedMeasurement,
  AlertApiService,
  APIError,
  APIResult,
  APISuccess,
  AuthAPIService,
  BuildingImageAPIService,
  ComfortIndicator,
  ComfortMonitoring,
  DistributionDataPoint,
  DJUSaving,
  EnergyConsumption,
  EnergyMeasurement,
  EnergyMeasurementAPIService,
  EnergyMeter,
  EnergyMeterAPIService,
  EnergyMeterJobMonitoring,
  EnergyMeterWithJobConfiguration,
  EnergyRegressionParams,
  EnergySaving,
  Errors,
  HdcWithJobConfiguration,
  HealthZAPIService,
  HeatingCurveHistory,
  HeatingDistributionCircuit,
  HeatingDistributionCircuitCurve,
  HeatingDistributionCircuitHeatingCurveState,
  HeatingDistributionCircuitJobMonitoring,
  HeatingDistributionCircuitMeasurement,
  HeatingDistributionCircuitPredictiveSystem,
  HeatingDistributionCircuitWatchdogState,
  HeatingProductionUnit,
  HeatingProductionUnitAlert,
  HeatingProductionUnitMeasurement,
  HeatingSeason,
  HpuJobMonitoring,
  HpuWithJobConfiguration,
  ImageInstrumentGroupPosition,
  ImageInstrumentPosition,
  ImagePosition,
  Instrument,
  InstrumentAPIService,
  InstrumentCategory,
  InstrumentDeploymentSession,
  InstrumentDisposition,
  InstrumentGroup,
  InstrumentGroupAlert,
  InstrumentGroupAlertAPIService,
  InstrumentGroupAlertCreate,
  InstrumentGroupAPIService,
  InstrumentGroupCreate,
  InstrumentGroupMeasurement,
  InstrumentGroupMeasurementsAPIService,
  InstrumentStatsResponse,
  InstrumentStock,
  InstrumentStockCreate,
  JobAlerts,
  JobConfiguration,
  MappingSession,
  Measurement,
  MeasurementAPIService,
  MonitoringApiService,
  MonthlyIndicator,
  PLC,
  PlcHealthStatus,
  PlcJobMonitoring,
  PlcWithJobConfiguration,
  PredictiveMonitoring,
  ProductionDataPoint,
  ProvidersAPIService,
  RegulationAPIService,
  RegulationMeasurement,
  RegulationParameters,
  RequeaGateway,
  Role,
  SchedulingEvent,
  SchedulingMeasurement,
  SchedulingMode,
  SchedulingParameters,
  SchedulingParametersInput,
  Site,
  SiteAPIService,
  SiteCreate,
  SiteImage,
  SitePhysics,
  SitesAlerts,
  UserManagementAPIService,
  UserManagementUser,
  UserManagementUserCreate,
  UserManagementUserUpdate,
  UserRequiredActions,
  WeatherAPIService,
  WeatherData,
  WeatherLocation,
} from "src/lib/api";

const unmountedMsg = "component unmounting, cancelling request";

export type UseAPIResult<T> = UseAPIResultLoading | UseAPIResultError | UseAPIResultSuccess<T>;

export type UseDeferrableAPIResult<T> = UseAPIResultSuccess<T> | UseAPIResultPending;

export interface UseAPIResultLoading {
  status: "loading";
}

export interface UseAPIResultError {
  status: "error";
  message: string;
  error: Errors;
}

export interface UseAPIResultSuccess<T> {
  status: "success";
  data: T;
}

export interface UseAPIResultPending {
  status: "pending";
}

export function adaptUseQueryFetcher<T>(fetcher: Promise<APIResult<T>>): Promise<APISuccess<T>> {
  return fetcher.then((r) => {
    switch (r.status) {
      case "success":
        return r;
      case "error":
        return Promise.reject(r);
    }
  });
}

// https://stackoverflow.com/a/52913382/7573460
export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${JSON.stringify(val)}`);
  }
}

function adaptUseQuerySuspenseResult<T, S extends boolean>(
  res: UseQueryResult<APISuccess<T>, APIError>,
): S extends false ? UseAPIResultSuccess<T> : UseDeferrableAPIResult<T> {
  switch (res.status) {
    case "loading":
      return { status: "pending" } as S extends false
        ? UseAPIResultSuccess<T>
        : UseDeferrableAPIResult<T>;
    case "error":
      throw res.error;
    case "success":
      return { status: "success", data: res.data!.data } as S extends false
        ? UseAPIResultSuccess<T>
        : UseDeferrableAPIResult<T>;
    default:
      throw new UnreachableCaseError(res);
  }
}

function adaptUseQueryResult<T, S extends boolean>(
  res: UseQueryResult<APISuccess<T>, APIError>,
): S extends false ? UseAPIResult<T> : UseDeferrableAPIResult<T> {
  switch (res.status) {
    case "loading":
      return { status: "loading" } as UseAPIResultLoading as S extends false
        ? UseAPIResult<T>
        : UseDeferrableAPIResult<T>;
    case "error":
      return {
        status: "error",
        message: res?.error?.message,
        error: res?.error?.error,
      } as S extends false ? UseAPIResult<T> : UseDeferrableAPIResult<T>;
    case "success":
      // https://github.com/tannerlinsley/react-query/issues/280
      // @ts-expect-error
      return res.data === undefined && res.query.queryHash === undefined
        ? ({ status: "pending" } as S extends false ? UseAPIResult<T> : UseDeferrableAPIResult<T>)
        : ({ status: "success", data: res.data!.data } as S extends false
            ? UseAPIResult<T>
            : UseDeferrableAPIResult<T>);
    default:
      throw new UnreachableCaseError(res);
  }
}

export function useFirebaseCustomToken(): UseAPIResultSuccess<{ token: string }> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new AuthAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<{ token: string }>, APIError>(
    [`/auth/firebase/token`],
    () => adaptUseQueryFetcher(service.createFirebaseCustomToken()),
    {
      // Refetch the token every 50 minutes since it expires in 1h.
      refetchInterval: 50 * 60 * 1000,
    },
  );

  return adaptUseQuerySuspenseResult<{ token: string }, false>(res);
}

function queryKeyEnergyMeter(emId: string | number): QueryKey {
  return ["/energy-meters", emId.toString()];
}

export function useEnergyMeter(emId: string): UseAPIResultSuccess<EnergyMeter> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new EnergyMeterAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<EnergyMeter>, APIError>(queryKeyEnergyMeter(emId), () =>
    adaptUseQueryFetcher(service.fetchOne({ emId })),
  );

  return adaptUseQuerySuspenseResult<EnergyMeter, false>(res);
}

export function useGrdfConsentDemand() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (params: { idPce: string; demand: any }) =>
      adaptUseQueryFetcher(
        new EnergyMeterAPIService(getAccessTokenSilently).makeGrdfConsentDemand(params),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeyEnergyMetersJobMonitoring(), {
          refetchType: "all",
        }),
    },
  );
}

function queryKeyEnergyMetersJobMonitoring(): QueryKey {
  return [`/energy-meters/jobs`];
}

export function useEnergyMeterJobConfiguration(): UseAPIResultSuccess<
  EnergyMeterWithJobConfiguration[]
> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<EnergyMeterWithJobConfiguration[]>, APIError>(
    queryKeyEnergyMetersJobMonitoring(),
    () => adaptUseQueryFetcher(service.fetchEnergyMetersWithJobsConfiguration()),
  );

  return adaptUseQuerySuspenseResult<EnergyMeterWithJobConfiguration[], false>(res);
}

function queryKeyEnergyMetersWithJobConfiguration(): QueryKey {
  return [`/energy-meters/jobs/configuration`];
}

export function useEnergyMeterJobsMonitoring(): UseAPIResultSuccess<EnergyMeterJobMonitoring[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<EnergyMeterJobMonitoring[]>, APIError>(
    queryKeyEnergyMetersWithJobConfiguration(),
    () => adaptUseQueryFetcher(service.fetchEnergyMetersJobsMonitoring()),
  );

  return adaptUseQuerySuspenseResult<EnergyMeterJobMonitoring[], false>(res);
}

export function useUpdateEnergyMetersJobsConfiguration() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (jobConfiguration: JobConfiguration) =>
      adaptUseQueryFetcher(
        new MonitoringApiService(getAccessTokenSilently).updateEnergyMetersJobConfiguration({
          jobConfiguration,
        }),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyEnergyMetersWithJobConfiguration(), {
          refetchType: "all",
        });
        await queryClient.invalidateQueries(queryKeyMonitoringJobAlerts(), {
          refetchType: "all",
        });
        await queryClient.invalidateQueries(queryKeyEnergyMetersJobMonitoring(), {
          refetchType: "all",
        });
      },
    },
  );
}

export function useWeatherLocations(): UseAPIResultSuccess<WeatherLocation[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new WeatherAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<WeatherLocation[]>, APIError>(["/weather/locations"], () =>
    adaptUseQueryFetcher(service.fetchWeatherLocations()),
  );

  return adaptUseQuerySuspenseResult<WeatherLocation[], false>(res);
}

export function useMonthlyIndicator(slug: string, from: number, to: number) {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<MonthlyIndicator[]>, APIError>(
    [`/sites/comfort-indicators`, slug, from, to],
    () => adaptUseQueryFetcher(service.fetchMonthlyIndicator(slug, from, to)),
  );

  return adaptUseQuerySuspenseResult<MonthlyIndicator[], false>(res);
}

export function useQueryClientSetEnergyMeter() {
  const queryClient = useQueryClient();

  return (energyMeter: EnergyMeter) => {
    queryClient.setQueryData(queryKeyEnergyMeter(energyMeter.id), { data: energyMeter });
  };
}

function queryKeySiteEnergyMeters(sId: string | number): QueryKey {
  return [`/sites/energy-meters`, sId];
}

export function useSiteEnergyMeters(sId: string): UseAPIResultSuccess<EnergyMeter[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<EnergyMeter[]>, APIError>(queryKeySiteEnergyMeters(sId), () =>
    adaptUseQueryFetcher(service.fetchEnergyMeters({ sId })),
  );

  return adaptUseQuerySuspenseResult<EnergyMeter[], false>(res);
}

function queryKeyBuildingImages(slug: string, msId: number | null): QueryKey {
  return [`/sites/building-images`, slug, msId];
}

export function useSiteBuildingImages(slug: string) {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<SiteImage[]>, APIError>(queryKeyBuildingImages(slug, null), () =>
    adaptUseQueryFetcher(service.getSiteBuildingImages({ slug })),
  );

  return adaptUseQuerySuspenseResult<SiteImage[], false>(res);
}

export function useMappingSessionBuildingImages(slug: string, msId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<SiteImage[]>, APIError>(queryKeyBuildingImages(slug, msId), () =>
    adaptUseQueryFetcher(service.getMappingSessionImages({ msId, slug })),
  );

  return adaptUseQuerySuspenseResult<SiteImage[], false>(res);
}

export function useUploadBuildingImage(slug: string, msId: number | null) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (params: { file: File; fileUID: string; fileName: string }) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).uploadImage({
          msId,
          slug,
          file: params.file,
          fileUID: params.fileUID,
          fileName: params.fileName,
        }),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyBuildingImages(slug, msId), {
          refetchType: "all",
        });
      },
    },
  );
}

export function useDeleteBuildingImage(slug: string, msId: number | null) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (fileUID: string) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).deleteImage({
          fileUID,
          slug,
        }),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyBuildingImages(slug, msId), {
          refetchType: "all",
        });
      },
    },
  );
}

function queryKeyImagesPositions(msId: number, imageIds?: number[]): QueryKey {
  return imageIds ? [`/images/position`, msId, ...imageIds] : [`/images/position`, msId];
}

export function useImagesPositions(msId: number, imageIds: number[]) {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new BuildingImageAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<ImagePosition[]>, APIError>(
    queryKeyImagesPositions(msId, imageIds),
    () => adaptUseQueryFetcher(service.getImagesPositions({ imageIds })),
  );

  return adaptUseQuerySuspenseResult<ImagePosition[], false>(res);
}

export function useUploadImagePositions(msId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (ips: ImagePosition[]) =>
      adaptUseQueryFetcher(
        new BuildingImageAPIService(getAccessTokenSilently).saveImagePositions({ ips }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeyImagesPositions(msId), {
          refetchType: "all",
        }),
    },
  );
}

export function useDeleteImagePositions(msId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (ips: ImagePosition[]) =>
      adaptUseQueryFetcher(
        new BuildingImageAPIService(getAccessTokenSilently).deleteImagePositions({ ips }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeyImagesPositions(msId), {
          refetchType: "all",
        }),
    },
  );
}

function queryKeyImagesInstrumentPositions(sId: string | number, imageIds?: number[]): QueryKey {
  return imageIds
    ? [`/images/instrument-position`, sId, imageIds]
    : [`/images/instrument-position`, sId];
}

export function useImagesInstrumentPositions(sId: string, imageIds: number[]) {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<ImageInstrumentPosition[]>, APIError>(
    queryKeyImagesInstrumentPositions(sId, imageIds),
    () => adaptUseQueryFetcher(service.getImageInstrumentPositions({ sId, imageIds })),
  );

  return adaptUseQuerySuspenseResult<ImageInstrumentPosition[], false>(res);
}

export function useUploadImageInstrumentPositions(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (iips: ImageInstrumentPosition[]) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).saveImageInstrumentPositions({
          sId,
          iips,
        }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeyImagesInstrumentPositions(sId), {
          refetchType: "all",
        }),
    },
  );
}

export function useDeleteImageInstrumentPositions(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (iips: ImageInstrumentPosition[]) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).deleteImageInstrumentPositions({
          sId,
          iips,
        }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeyImagesInstrumentPositions(sId), {
          refetchType: "all",
        }),
    },
  );
}

function queryKeyImagesInstrumentGroupPositions(
  sId: string | number,
  imageIds?: number[],
): QueryKey {
  return imageIds
    ? [`/images/instrument-group-position`, sId, imageIds]
    : [`/images/instrument-group-position`, sId];
}

export function useImagesInstrumentGroupPositions(sId: string, imageIds: number[]) {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<ImageInstrumentGroupPosition[]>, APIError>(
    queryKeyImagesInstrumentGroupPositions(sId, imageIds),
    () => adaptUseQueryFetcher(service.getImageInstrumentGroupPositions({ sId, imageIds })),
  );

  return adaptUseQuerySuspenseResult<ImageInstrumentGroupPosition[], false>(res);
}

export function useUploadImageInstrumentGroupPositions(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (iigps: ImageInstrumentGroupPosition[]) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).saveImageInstrumentGroupPositions({
          sId,
          iigps,
        }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeyImagesInstrumentGroupPositions(sId), {
          refetchType: "all",
        }),
    },
  );
}

export function useDeleteImageInstrumentGroupPositions(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (iigps: ImageInstrumentGroupPosition[]) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).deleteImageInstrumentGroupPositions({
          sId,
          iigps,
        }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeyImagesInstrumentGroupPositions(sId), {
          refetchType: "all",
        }),
    },
  );
}

export function useSaveSiteEnergyMeter(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (em: EnergyMeter) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).saveSiteEnergyMeter({ sId, em }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteEnergyMeters(sId), {
          refetchType: "all",
        }),
    },
  );
}

export function useUpdateSiteEnergyMeter(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (em: EnergyMeter) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).updateSiteEnergyMeter({ sId, em }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteEnergyMeters(sId), {
          refetchType: "all",
        }),
    },
  );
}

export function useEnergyMeters(emIds: string[]): UseDeferrableAPIResult<EnergyMeter[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new EnergyMeterAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<EnergyMeter[]>, APIError>(
    [`/energy-meters`, emIds],
    () => adaptUseQueryFetcher(service.fetchMany({ emIds })),
    { enabled: emIds.length > 0 },
  );

  return adaptUseQuerySuspenseResult<EnergyMeter[], true>(res);
}

export function useEnergyMeasurements(
  emIds: string[],
  from: number,
  to: number,
): UseDeferrableAPIResult<EnergyMeasurement[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new EnergyMeasurementAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<EnergyMeasurement[]>, APIError>(
    [`/energy-meters/measurements`, emIds, from, to],
    () => adaptUseQueryFetcher(service.fetchMeasurements({ emIds, from, to })),
    { enabled: emIds.length > 0 },
  );

  return adaptUseQuerySuspenseResult<EnergyMeasurement[], true>(res);
}

export function useEnergyConsumptions(
  emIds: string[],
  from: number,
  to: number,
): UseDeferrableAPIResult<EnergyConsumption[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new EnergyMeasurementAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<EnergyConsumption[]>, APIError>(
    [`/energy-meters/consumptions`, emIds, from, to],
    () => adaptUseQueryFetcher(service.fetchConsumptions({ emIds, from, to })),
    { enabled: emIds.length > 0 },
  );

  return adaptUseQuerySuspenseResult<EnergyConsumption[], true>(res);
}

function queryKeySite(slug: string): QueryKey {
  return ["/sites", slug];
}

export function useSite(sId: string): UseAPIResultSuccess<Site> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<Site>, APIError>(queryKeySite(sId), () =>
    adaptUseQueryFetcher(service.fetchOne({ sId })),
  );

  return adaptUseQuerySuspenseResult<Site, false>(res);
}

function queryKeySiteHeatingSeasons(sId: string): QueryKey {
  return ["/sites/heating-seasons", sId];
}

export function useSiteHeatingSeasons(sId: string): UseAPIResultSuccess<HeatingSeason[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HeatingSeason[]>, APIError>(queryKeySiteHeatingSeasons(sId), () =>
    adaptUseQueryFetcher(service.fetchHeatingSeasons({ sId })),
  );

  return adaptUseQuerySuspenseResult<HeatingSeason[], false>(res);
}

export function useSetHeatingPeriodReference(slug: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (heatingSeasonId: number) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).setHeatingSeasonReference({
          slug,
          hsid: heatingSeasonId,
        }),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeySiteHeatingSeasons(slug), {
          refetchType: "all",
        });
      },
    },
  );
}

export function useQueryClientSetSite() {
  const queryClient = useQueryClient();

  return (site: Site) => queryClient.setQueryData(queryKeySite(site.slug), { data: site });
}

function queryKeySites(): QueryKey {
  return ["/sites"];
}

export function useSites(): UseAPIResultSuccess<Site[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<Site[]>, APIError>(queryKeySites(), () =>
    adaptUseQueryFetcher(service.fetch()),
  );

  return adaptUseQuerySuspenseResult<Site[], false>(res);
}

export function useSitesCreate() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (s: SiteCreate) => adaptUseQueryFetcher(new SiteAPIService(getAccessTokenSilently).create(s)),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeySites(), { refetchType: "all" }),
    },
  );
}

export function useSitesUpdate(slug: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (s: Partial<Site>) =>
      adaptUseQueryFetcher(new SiteAPIService(getAccessTokenSilently).update(s, slug)),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeySites(), { refetchType: "all" }),
    },
  );
}

export function useUpdateHeatingPeriod(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (heatingSeason: Partial<HeatingSeason>) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).updateHeatingSeason({ sId, heatingSeason }),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeySiteHeatingSeasons(sId), {
          refetchType: "all",
        });
        await queryClient.invalidateQueries(queryKeySites(), {
          refetchType: "all",
        });
        await queryClient.invalidateQueries(queryKeyUserRequiredActions(), {
          refetchType: "all",
        });
      },
    },
  );
}

function queryKeySiteInstruments(sId: string): QueryKey {
  return ["/instruments", sId];
}

export function useSiteInstruments(
  sId: string,
  supplierIds?: number[],
): UseAPIResultSuccess<Instrument[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<Instrument[]>, APIError>(queryKeySiteInstruments(sId), () =>
    adaptUseQueryFetcher(service.fetchInstruments({ sId, supplierIds })),
  );

  return adaptUseQuerySuspenseResult<Instrument[], false>(res);
}

export function useInstrumentUpdate(siteSlug: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (i: Instrument) =>
      adaptUseQueryFetcher(new InstrumentAPIService(getAccessTokenSilently).update(i)),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeySiteInstrumentGroups(siteSlug));
        await queryClient.invalidateQueries(queryKeySiteInstruments(siteSlug));
      },
    },
  );
}

export function useInstrumentUpdateComment(siteSlug: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (i: Instrument) =>
      adaptUseQueryFetcher(new InstrumentAPIService(getAccessTokenSilently).updateComment(i)),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeySiteInstrumentGroups(siteSlug));
        await queryClient.invalidateQueries(queryKeySiteInstruments(siteSlug));
      },
    },
  );
}

export function useInstrumentUpdateDisposition(siteSlug: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (params: { d: InstrumentDisposition; iid: string }) =>
      adaptUseQueryFetcher(
        new InstrumentAPIService(getAccessTokenSilently).updateDisposition(params.d, params.iid),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeySiteInstruments(siteSlug));
      },
    },
  );
}

export function useInstrumentSave(siteSlug: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (i: Omit<Instrument, "id" | "battery">) =>
      adaptUseQueryFetcher(new InstrumentAPIService(getAccessTokenSilently).save(i)),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeySiteInstrumentGroups(siteSlug));
        await queryClient.invalidateQueries(queryKeySiteInstruments(siteSlug));
      },
    },
  );
}

export function useInstrumentsUpdateCategory() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    ({ iids, category }: { iids: number[]; category: InstrumentCategory }) =>
      adaptUseQueryFetcher(
        new InstrumentAPIService(getAccessTokenSilently).updateCategory(iids, category),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["/instruments"], { refetchType: "all" });
      },
    },
  );
}

export function useInstrumentDelete(siteSlug: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (iId: string) =>
      adaptUseQueryFetcher(new InstrumentAPIService(getAccessTokenSilently).delete({ iId })),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeySiteInstruments(siteSlug));
        await queryClient.invalidateQueries(queryKeySiteInstrumentGroups(siteSlug));
      },
    },
  );
}

export function useInstrumentDeleteMeasurement(sId: string, iId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (deletePeriod: { from: number; to: number }) =>
      adaptUseQueryFetcher(
        new InstrumentAPIService(getAccessTokenSilently).deleteMeasurements({
          iId,
          from: deletePeriod.from,
          to: deletePeriod.to,
        }),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([`/sites/instruments/measurements/latest`, sId]);
        // could not invalidate measurement fetch queries because we don't have access to from and to.
      },
    },
  );
}

function queryKeySiteReferenceInstrumentGroups(sId: string): QueryKey {
  return [`/sites/instrument-reference-groups`, sId];
}

export function useSiteReferenceInstrumentGroups(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<InstrumentGroup[]>, APIError>(
    queryKeySiteReferenceInstrumentGroups(sId),
    () => adaptUseQueryFetcher(service.fetchReferenceInstrumentGroups({ sId })),
  );

  return adaptUseQuerySuspenseResult<InstrumentGroup[], false>(res);
}

export function queryKeySiteEnergyRegressionParams(
  sId: string,
  from: number,
  to: number,
): QueryKey {
  return [`/sites/energy-regression-params`, sId, from, to];
}

export function useSiteEnergyRegressionParams(slug: string, from: number, to: number) {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<EnergyRegressionParams>, APIError>(
    queryKeySiteEnergyRegressionParams(slug, from, to),
    () => adaptUseQueryFetcher(service.fetchEnergyRegressionParams({ slug, from, to })),
  );

  return adaptUseQuerySuspenseResult<EnergyRegressionParams, false>(res);
}

function queryKeySiteInstrumentGroups(siteSlug: string): QueryKey {
  return [`/sites/instrument-groups`, siteSlug];
}

export function useSiteInstrumentGroups(sId: string): UseAPIResultSuccess<InstrumentGroup[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  useEffect(() => () => service.cancelRequests(unmountedMsg), [service]);

  const res = useQuery<APISuccess<InstrumentGroup[]>, APIError>(
    queryKeySiteInstrumentGroups(sId),
    () => adaptUseQueryFetcher(service.fetchInstrumentGroups({ sId })),
  );

  return adaptUseQuerySuspenseResult<InstrumentGroup[], false>(res);
}

export function useSiteEnergySaving(
  slug: string,
  from: number,
  to: number,
): UseAPIResultSuccess<EnergySaving> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<EnergySaving>, APIError>(
    [`/sites/energy-saving`, slug, from, to],
    () => adaptUseQueryFetcher(service.fetchEnergySaving({ slug, from, to })),
  );

  return adaptUseQuerySuspenseResult<EnergySaving, false>(res);
}

export function queryKeySitePhysics(slug: string): QueryKey {
  return [`/sites/physics`, slug];
}

export function useSitePhysics(slug: string): UseAPIResultSuccess<SitePhysics> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<SitePhysics>, APIError>(queryKeySitePhysics(slug), () =>
    adaptUseQueryFetcher(service.fetchSitePhysics({ slug })),
  );

  return adaptUseQuerySuspenseResult<SitePhysics, false>(res);
}

export function useSaveSitePhysics(slug: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (sp: SitePhysics) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).saveSitePhysics({ slug, sp }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySitePhysics(slug), {
          refetchType: "all",
        }),
    },
  );
}

export function useSitePlcs(sId: string): UseAPIResultSuccess<PLC[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<PLC[]>, APIError>(
    [`/sites/heating-installations/plcs`, sId],
    () => adaptUseQueryFetcher(service.fetchSitePlcs({ sId })),
  );

  return adaptUseQuerySuspenseResult<PLC[], false>(res);
}

export function useSaveSitePlc(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (plc: PLC) =>
      adaptUseQueryFetcher(new SiteAPIService(getAccessTokenSilently).saveSitePlc({ sId, plc })),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`/sites/heating-installations/plcs`, sId], {
          refetchType: "all",
        }),
    },
  );
}

export function useUpdateSitePlc(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (plc: PLC) =>
      adaptUseQueryFetcher(new SiteAPIService(getAccessTokenSilently).updateSitePlc({ sId, plc })),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([`/sites/heating-installations/plcs`, sId], {
          refetchType: "all",
        }),
    },
  );
}

export function queryKeySiteHeatingProductionUnits(sId: string): QueryKey {
  return [`/sites/heating-installations/production-units`, sId];
}

export function useSiteHeatingProductionUnits(
  sId: string,
): UseAPIResultSuccess<HeatingProductionUnit[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HeatingProductionUnit[]>, APIError>(
    queryKeySiteHeatingProductionUnits(sId),
    () => adaptUseQueryFetcher(service.fetchHeatingProductionUnit({ sId })),
  );

  return adaptUseQuerySuspenseResult<HeatingProductionUnit[], false>(res);
}

export function useSaveSiteHeatingProductionUnit(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (hpu: HeatingProductionUnit) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).saveSiteHeatingProductionUnit({
          sId,
          heatingProductionUnit: hpu,
        }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteHeatingProductionUnits(sId), {
          refetchType: "all",
        }),
    },
  );
}

export function useUpdateSiteHeatingProductionUnit(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (hpu: HeatingProductionUnit) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).updateSiteHeatingProductionUnit({
          sId,
          heatingProductionUnit: hpu,
        }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteHeatingProductionUnits(sId), {
          refetchType: "all",
        }),
    },
  );
}

export function useSiteHeatingProductionUnitMeasurements(
  sId: string,
  hpuId: number,
  from: number,
  to?: number,
): UseAPIResultSuccess<HeatingProductionUnitMeasurement[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HeatingProductionUnitMeasurement[]>, APIError>(
    [`/sites/heating-installations/production-unit/measurements`, sId, hpuId, from, to],
    () =>
      adaptUseQueryFetcher(
        service.fetchHeatingProductionUnitMeasurements({ sId, hpuId, from, to }),
      ),
  );

  return adaptUseQuerySuspenseResult<HeatingProductionUnitMeasurement[], false>(res);
}

export function queryKeySiteProductionDataPoints(sId: string, hpuId: number): QueryKey {
  return [`/sites/heating-installations/production-unit/data-points`, sId, hpuId];
}

export function useProductionDataPoints(
  sId: string,
  hpuId: number,
): UseAPIResultSuccess<ProductionDataPoint[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<ProductionDataPoint[]>, APIError>(
    queryKeySiteProductionDataPoints(sId, hpuId),
    () => adaptUseQueryFetcher(service.fetchProductionDataPoints({ sId, hpuId })),
  );

  return adaptUseQuerySuspenseResult<ProductionDataPoint[], false>(res);
}

export function useSaveProductionDataPoint(sId: string, hpuId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (pdp: ProductionDataPoint) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).saveProductionDataPoint({ sId, hpuId, pdp }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteProductionDataPoints(sId, hpuId), {
          refetchType: "all",
        }),
    },
  );
}

export function useUpdateProductionDataPoint(sId: string, hpuId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (pdp: Partial<ProductionDataPoint & { id: number }>) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).updateProductionDataPoint({ sId, hpuId, pdp }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteProductionDataPoints(sId, hpuId), {
          refetchType: "all",
        }),
    },
  );
}

export function useDeleteProductionDataPoint(sId: string, hpuId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (pdpId: number) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).deleteProductionDataPoint({ sId, hpuId, pdpId }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteProductionDataPoints(sId, hpuId), {
          refetchType: "all",
        }),
    },
  );
}

export function queryKeySiteDistributionDataPoints(sId: string, hdcId: number): QueryKey {
  return [
    `/sites/heating-installations/production-unit/distribution-circuits/data-points`,
    sId,
    hdcId,
  ];
}

export function useDistributionDataPoints(
  sId: string,
  hdcId: number,
): UseAPIResultSuccess<DistributionDataPoint[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<DistributionDataPoint[]>, APIError>(
    queryKeySiteDistributionDataPoints(sId, hdcId),
    () => adaptUseQueryFetcher(service.fetchDistributionDataPoints({ sId, hdcId })),
  );

  return adaptUseQuerySuspenseResult<DistributionDataPoint[], false>(res);
}

export function useSaveDistributionDataPoint(sId: string, hdcId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (ddp: DistributionDataPoint) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).saveDistributionDataPoint({ sId, hdcId, ddp }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteDistributionDataPoints(sId, hdcId), {
          refetchType: "all",
        }),
    },
  );
}

export function useUpdateDistributionDataPoint(sId: string, hdcId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (ddp: Partial<DistributionDataPoint & { id: number }>) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).updateDistributionDataPoint({ sId, hdcId, ddp }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteDistributionDataPoints(sId, hdcId), {
          refetchType: "all",
        }),
    },
  );
}

export function useDeleteDistributionDataPoint(sId: string, hdcId: number) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (ddpId: number) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).deleteDistributionDataPoint({
          sId,
          hdcId,
          ddpId,
        }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteDistributionDataPoints(sId, hdcId), {
          refetchType: "all",
        }),
    },
  );
}

export function queryKeySiteHeatingDistributionCircuits(sId: string): QueryKey {
  return [`/sites/heating-installations/production-units/distribution-circuits`, sId];
}

export function useSiteHeatingDistributionCircuits(
  sId: string,
): UseAPIResultSuccess<HeatingDistributionCircuit[]> {
  const { user, getAccessTokenSilently } = useAuth0();
  const enabled = hasPermission(user, Permissions.HeatingInstallationsRead);

  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HeatingDistributionCircuit[]>, APIError>(
    queryKeySiteHeatingDistributionCircuits(sId),
    () => adaptUseQueryFetcher(service.fetchHeatingDistributionCircuits({ sId })),
    { enabled: !!enabled },
  );

  return adaptUseQuerySuspenseResult<HeatingDistributionCircuit[], false>(res);
}

export function useSaveSiteHeatingDistributionCircuit(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (hdc: HeatingDistributionCircuit) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).saveSiteHeatingDistributionCircuit({
          sId,
          heatingDistributionCircuit: hdc,
        }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteHeatingDistributionCircuits(sId), {
          refetchType: "all",
        }),
    },
  );
}

export function useUpdateSiteHeatingDistributionCircuit(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (hdc: HeatingDistributionCircuit) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).updateSiteHeatingDistributionCircuit({
          sId,
          heatingDistributionCircuit: hdc,
        }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteHeatingDistributionCircuits(sId), {
          refetchType: "all",
        }),
    },
  );
}

export function useSiteHeatingDistributionCircuitMeasurements(
  sId: string,
  hdcId: number,
  from: number,
  to?: number,
): UseAPIResultSuccess<HeatingDistributionCircuitMeasurement[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HeatingDistributionCircuitMeasurement[]>, APIError>(
    [`/sites/heating-installations/circuits/measurements`, sId, hdcId, from, to],
    () =>
      adaptUseQueryFetcher(service.fetchHeatingDistributionMeasurements({ sId, hdcId, from, to })),
  );

  return adaptUseQuerySuspenseResult<HeatingDistributionCircuitMeasurement[], false>(res);
}

export function queryKeySiteHeatingInstallationCircuitPredictiveSystem(
  sId: string,
  hicId: string,
): QueryKey {
  return [`/sites/heating-installations/circuits/predictive-system`, sId, hicId];
}

export function queryKeySiteHeatingInstallationCircuitUpdateSchedulingMode(
  sId: string,
  hicId: string,
): QueryKey {
  return [`/sites/heating-installations/circuits/update/scheduling/mode`, sId, hicId];
}

export function queryKeySiteHeatingInstallationCircuitUpdateSchedulingParameters(
  sId: string,
  hicId: string,
): QueryKey {
  return [`/sites/heating-installations/circuits/update/scheduling/parameters`, sId, hicId];
}

export function queryKeySiteDistributionCircuitLatestRegulationMeasurement(
  sId: string,
  hdcId: number,
): QueryKey {
  return [`/sites/heating-installations/circuits/regulation-measurement`, sId, hdcId];
}

export function queryKeySiteDistributionCircuitLatestSchedulingMeasurement(
  sId: string,
  hdcId: string,
): QueryKey {
  return [`/sites/heating-installations/circuits/scheduling-measurement`, sId, hdcId];
}

export function queryKeySiteDistributionCircuitMode(sId: string, hdcId: string): QueryKey {
  return [`/sites/heating-installations/circuits/scheduling/mode`, sId, hdcId];
}

export function queryKeySiteDistributionCircuitSchedulingParameters(
  sId: string,
  hdcId: string,
): QueryKey {
  return [`/sites/heating-installations/circuits/scheduling/parameters`, sId, hdcId];
}

export function queryKeySiteDistributionCircuitRegulationParameters(
  sId: string,
  hdcId: string,
): QueryKey {
  return [`/sites/heating-installations/circuits/regulation/parameters`, sId, hdcId];
}

export function useSiteHeatingInstallationCircuitPredictiveSystem(
  sId: string,
  hicId: string,
): UseAPIResultSuccess<HeatingDistributionCircuitPredictiveSystem> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HeatingDistributionCircuitPredictiveSystem>, APIError>(
    queryKeySiteHeatingInstallationCircuitPredictiveSystem(sId, hicId),
    () =>
      adaptUseQueryFetcher(service.fetchHeatingDistributionCircuitPredictiveSystem({ sId, hicId })),
  );

  return adaptUseQuerySuspenseResult<HeatingDistributionCircuitPredictiveSystem, false>(res);
}

export function useSiteHeatingDistributionRegulationMeasurements(
  sId: string,
  hdcId: number,
  from: number,
  to: number,
): UseAPIResultSuccess<RegulationMeasurement[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new RegulationAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<RegulationMeasurement[]>, APIError>(
    [`/sites/heating-installations/circuits/regulation-measurements`, sId, hdcId, from, to],
    () => adaptUseQueryFetcher(service.fetchRegulationMeasurements({ sId, hdcId, from, to })),
  );

  return adaptUseQuerySuspenseResult<RegulationMeasurement[], false>(res);
}

export function useSiteHeatingDistributionLatestRegulationMeasurement(
  sId: string,
  hdcId: number,
): UseAPIResultSuccess<RegulationMeasurement> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new RegulationAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<RegulationMeasurement>, APIError>(
    queryKeySiteDistributionCircuitLatestRegulationMeasurement(sId, hdcId),
    () => adaptUseQueryFetcher(service.fetchLatestRegulationMeasurement({ sId, hdcId })),
  );

  return adaptUseQuerySuspenseResult<RegulationMeasurement, false>(res);
}

export function useSiteHeatingDistributionLatestSchedulingMeasurement(
  sId: string,
  hdcId: string,
): UseAPIResultSuccess<SchedulingMeasurement> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new RegulationAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<SchedulingMeasurement>, APIError>(
    queryKeySiteDistributionCircuitLatestSchedulingMeasurement(sId, hdcId),
    () => adaptUseQueryFetcher(service.fetchLatestSchedulingMeasurement({ sId, hdcId })),
  );

  return adaptUseQuerySuspenseResult<SchedulingMeasurement, false>(res);
}

export function useSiteHeatingDistributionMode(
  sId: string,
  hdcId: string,
): UseAPIResultSuccess<{ mode: string }> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new RegulationAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<{ mode: string }>, APIError>(
    queryKeySiteDistributionCircuitMode(sId, hdcId),
    () => adaptUseQueryFetcher(service.fetchSchedulingMode({ sId, hdcId })),
  );

  return adaptUseQuerySuspenseResult<{ mode: string }, false>(res);
}

export function useSiteHeatingInstallationCircuitPredictiveSystemToggle(
  sId: string,
  hicId: string,
) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).toggleHeatingDistributionCircuitPredictiveSystem(
          { sId, hicId },
        ),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          queryKeySiteHeatingInstallationCircuitPredictiveSystem(sId, hicId),
        );
      },
    },
  );
}

export function useSiteHeatingInstallationCircuitUpdateSchedulingMode(slug: string, hicId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (mode: SchedulingMode) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).updateHeatingDistributionCircuitSchedulingMode({
          slug,
          hicId,
          mode,
        }),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          queryKeySiteHeatingInstallationCircuitUpdateSchedulingMode(slug, hicId),
        );
      },
    },
  );
}

export function useSiteHeatingInstallationCircuitUpdateSchedulingParameters(
  slug: string,
  hicId: string,
) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (schedulingParams: SchedulingParametersInput) =>
      adaptUseQueryFetcher(
        new SiteAPIService(
          getAccessTokenSilently,
        ).updateHeatingDistributionCircuitSchedulingParameters({
          slug,
          hicId,
          schedulingParams,
        }),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          queryKeySiteHeatingInstallationCircuitUpdateSchedulingParameters(slug, hicId),
        );
      },
    },
  );
}

export function useSiteHeatingInstallationCircuitUpdateRegulationParameters(
  slug: string,
  hicId: string,
) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const queryReset = useQueryErrorResetBoundary();

  return useMutation(
    (regulationParams: RegulationParameters) =>
      adaptUseQueryFetcher(
        new RegulationAPIService(
          getAccessTokenSilently,
        ).updateHeatingDistributionCircuitRegulationParameters({
          slug,
          hicId,
          regulationParams,
        }),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          queryKeySiteDistributionCircuitRegulationParameters(slug, hicId),
        );
        queryReset.reset();
      },
    },
  );
}
export function useSiteHeatingInstallationCircuitSaveRegulationParameters(
  slug: string,
  hicId: string,
) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const queryReset = useQueryErrorResetBoundary();

  return useMutation(
    (regulationParams: RegulationParameters) =>
      adaptUseQueryFetcher(
        new RegulationAPIService(
          getAccessTokenSilently,
        ).saveHeatingDistributionCircuitRegulationParameters({
          slug,
          hicId,
          regulationParams,
        }),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          queryKeySiteDistributionCircuitRegulationParameters(slug, hicId),
        );
        await queryClient.invalidateQueries(queryKeySiteHeatingDistributionCircuits(slug));
        queryReset.reset();
      },
    },
  );
}

export function queryKeySiteHeatingInstallationHeatingCurveState(
  sId: string,
  hdcId?: string,
): QueryKey {
  if (hdcId === undefined) return [`/sites/heating-installations/heating-curve-state`, sId];
  return [`/sites/heating-installations/heating-curve-state`, sId, hdcId];
}

export function useSiteHeatingDistributionCircuitHeatingCurveState(
  sId: string,
  hdcId: string,
): UseAPIResultSuccess<HeatingDistributionCircuitHeatingCurveState> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HeatingDistributionCircuitHeatingCurveState>, APIError>(
    queryKeySiteHeatingInstallationHeatingCurveState(sId, hdcId),
    () =>
      adaptUseQueryFetcher(
        service.fetchHeatingDistributionCircuitHeatingCurveState({ sId, hdcId }),
      ),
  );

  return adaptUseQuerySuspenseResult<HeatingDistributionCircuitHeatingCurveState, false>(res);
}

export function useUpdateCircuitHeatingCurveParameters(sId: string, hdcId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (hcs: HeatingDistributionCircuitHeatingCurveState) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).updateCircuitHeatingCurveParameters({
          sId,
          hdcId,
          hcs,
        }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteHeatingInstallationHeatingCurveState(sId), {
          refetchType: "all",
        }),
    },
  );
}

export function queryKeySiteHeatingInstallationWatchdogState(
  sId: string,
  hdcId?: string,
): QueryKey {
  if (hdcId === undefined) return [`/sites/heating-installations/watchdog-state`, sId];
  return [`/sites/heating-installations/watchdog-state`, sId, hdcId];
}

export function useSiteHeatingDistributionCircuitWatchdogState(
  sId: string,
  hdcId: string,
): UseAPIResultSuccess<HeatingDistributionCircuitWatchdogState> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HeatingDistributionCircuitWatchdogState>, APIError>(
    queryKeySiteHeatingInstallationWatchdogState(sId, hdcId),
    () =>
      adaptUseQueryFetcher(service.fetchHeatingDistributionCircuitWatchdogState({ sId, hdcId })),
  );

  return adaptUseQuerySuspenseResult<HeatingDistributionCircuitWatchdogState, false>(res);
}

export function queryKeySiteHeatingInstallationV3VRegulation(sId: string): QueryKey {
  return [`/sites/heating-installations/v3v-regulation`, sId];
}

export function useSiteHeatingDistributionCircuitV3VRegulation(
  sId: string,
  hdcId: string,
): UseAPIResultSuccess<boolean> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<boolean>, APIError>(
    queryKeySiteHeatingInstallationV3VRegulation(sId),
    () =>
      adaptUseQueryFetcher(service.fetchHeatingDistributionCircuitV3VRegulation({ sId, hdcId })),
  );

  return adaptUseQuerySuspenseResult<boolean, false>(res);
}

export function useSitePlcWatchdogToggle(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (plcId: string) =>
      adaptUseQueryFetcher(
        new SiteAPIService(getAccessTokenSilently).togglePlcWatchdogState({ sId, plcId }),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteHeatingInstallationWatchdogState(sId), {
          refetchType: "all",
        }),
    },
  );
}

export function useSiteHeatingInstallationCircuitHeatingCurve(
  sId: string,
  hdcId: number,
): UseAPIResultSuccess<HeatingDistributionCircuitCurve[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HeatingDistributionCircuitCurve[]>, APIError>(
    [`/sites/heating-installations/circuits/heating-curve`, sId, hdcId],
    () => adaptUseQueryFetcher(service.fetchHeatingDistributionCurve({ sId, hdcId })),
  );

  return adaptUseQuerySuspenseResult<HeatingDistributionCircuitCurve[], false>(res);
}

export function useSiteDistributionCircuitInstrumentGroupUpdate(sId: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (params: { hdcId: number; instrumentGroupId: number | null }) =>
      adaptUseQueryFetcher(
        new SiteAPIService(
          getAccessTokenSilently,
        ).updateHeatingDistributionCircuitInstrumentGroupId({ sId, ...params }),
      ),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeySiteHeatingDistributionCircuits(sId)),
    },
  );
}

export function useSiteHeatingInstallationCircuitHeatingCurveHistory(
  sId: string,
  hicId: string,
): UseAPIResultSuccess<HeatingCurveHistory[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HeatingCurveHistory[]>, APIError>(
    [`/sites/heating-installations/circuits/heating-curve-history`, sId, hicId],
    () => adaptUseQueryFetcher(service.fetchHeatingDistributionCurveHistory({ sId, hicId })),
  );

  return adaptUseQuerySuspenseResult<HeatingCurveHistory[], false>(res);
}

function queryKeyInstrument(iId: number | string): QueryKey {
  return ["/instrument", iId.toString()];
}

export function useInstrument(iId: string): UseAPIResultSuccess<Instrument> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new InstrumentAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  useEffect(() => () => service.cancelRequests(unmountedMsg), [service]);

  const res = useQuery<APISuccess<Instrument>, APIError>(queryKeyInstrument(iId), () =>
    adaptUseQueryFetcher(service.fetchOne({ iId })),
  );

  return adaptUseQuerySuspenseResult<Instrument, false>(res);
}

export function useQueryClientSetInstrument() {
  const queryClient = useQueryClient();

  return (i: Instrument) => queryClient.setQueryData(queryKeyInstrument(i.id), { data: i });
}

function queryKeyInstruments(iIds: string[]): QueryKey {
  return ["/instruments", iIds];
}

export function useInstruments(iIds: string[]): UseDeferrableAPIResult<Instrument[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new InstrumentAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  useEffect(() => () => service.cancelRequests(unmountedMsg), [service]);

  const res = useQuery<APISuccess<Instrument[]>, APIError>(
    queryKeyInstruments(iIds),
    () => adaptUseQueryFetcher(service.fetchMany({ iIds })),
    { enabled: iIds.length > 0 },
  );

  return adaptUseQuerySuspenseResult<Instrument[], true>(res);
}

function queryKeyInstrumentGroup(igId: number | string): QueryKey {
  return [`/instrument-groups`, igId.toString()];
}

export function useInstrumentGroup(igId: string): UseAPIResultSuccess<InstrumentGroup> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new InstrumentGroupAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  useEffect(() => () => service.cancelRequests(unmountedMsg), [service]);

  const res = useQuery<APISuccess<InstrumentGroup>, APIError>(queryKeyInstrumentGroup(igId), () =>
    adaptUseQueryFetcher(service.fetchOne({ igId })),
  );

  return adaptUseQuerySuspenseResult<InstrumentGroup, false>(res);
}

export function useQueryClientSetInstrumentGroup() {
  const queryClient = useQueryClient();

  return (ig: InstrumentGroup) => queryClient.setQueryData(queryKeyInstrument(ig.id), { data: ig });
}

export function useInstrumentGroups(igIds: string[]): UseDeferrableAPIResult<InstrumentGroup[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new InstrumentGroupAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  useEffect(() => () => service.cancelRequests(unmountedMsg), [service]);

  const res = useQuery<APISuccess<InstrumentGroup[]>, APIError>(
    [`/instrument-groups`, igIds],
    () => adaptUseQueryFetcher(service.fetchMany({ igIds })),
    { enabled: igIds.length > 0 },
  );

  return adaptUseQuerySuspenseResult<InstrumentGroup[], true>(res);
}

export function useInstrumentGroupsSave(siteSlug: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (ig: InstrumentGroupCreate) =>
      adaptUseQueryFetcher(new InstrumentGroupAPIService(getAccessTokenSilently).save(ig)),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeySiteInstrumentGroups(siteSlug));
      },
    },
  );
}

export function useInstrumentGroupsDelete(siteSlug: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (igId: string) =>
      adaptUseQueryFetcher(new InstrumentGroupAPIService(getAccessTokenSilently).delete({ igId })),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeySiteInstrumentGroups(siteSlug));
      },
    },
  );
}

export function useInstrumentGroupMeasurements(
  igIds: string[],
  from: number,
  to: number,
): UseDeferrableAPIResult<Measurement[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new InstrumentGroupMeasurementsAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<Measurement[]>, APIError>(
    [`/instrument-groups/measurements`, igIds, from, to],
    () => adaptUseQueryFetcher(service.fetch({ igIds, from, to })),
    { enabled: igIds.length > 0 },
  );

  return adaptUseQuerySuspenseResult<Measurement[], true>(res);
}

export function useReferenceInstrumentGroupMeasurements(
  igIds: string[],
  from: number,
  to: number,
): UseDeferrableAPIResult<InstrumentGroupMeasurement[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new InstrumentGroupMeasurementsAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<InstrumentGroupMeasurement[]>, APIError>(
    [`/reference-instrument-groups/measurements`, igIds, from, to],
    () => adaptUseQueryFetcher(service.fetchReference({ igIds, from, to })),
    { enabled: igIds.length > 0 },
  );

  return adaptUseQuerySuspenseResult<InstrumentGroupMeasurement[], true>(res);
}

export function useInstrumentGroupAlerts(
  user: User,
): UseDeferrableAPIResult<InstrumentGroupAlert[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new InstrumentGroupAlertAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  useEffect(() => () => service.cancelRequests(unmountedMsg), [service]);

  const res = useQuery<APISuccess<InstrumentGroupAlert[]>, APIError>(
    [`/instrument-groups/alerts`],
    () => adaptUseQueryFetcher(service.fetchMany()),
    {
      enabled: hasPermission(user, PermissionGroup.InstrumentGroupAlerts),
      refetchInterval: 5 * 60 * 1000, // 5 minutes
    },
  );

  return adaptUseQuerySuspenseResult<InstrumentGroupAlert[], false>(res);
}

export function useInstrumentGroupAlertsCreate() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (ig: InstrumentGroupAlertCreate) =>
      adaptUseQueryFetcher(new InstrumentGroupAlertAPIService(getAccessTokenSilently).create(ig)),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([`/instrument-groups/alerts`]);
      },
    },
  );
}

export function useInstrumentGroupAlertsUpdate() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (ig: InstrumentGroupAlert) =>
      adaptUseQueryFetcher(new InstrumentGroupAlertAPIService(getAccessTokenSilently).update(ig)),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([`/instrument-groups/alerts`]);
      },
    },
  );
}

export function useInstrumentGroupAlertsDelete() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (igaId: string) =>
      adaptUseQueryFetcher(
        new InstrumentGroupAlertAPIService(getAccessTokenSilently).delete({ igaId }),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([`/instrument-groups/alerts`]);
      },
    },
  );
}

export function useLatestInstrumentsMeasurements(
  iIds: string[],
): UseDeferrableAPIResult<{ [key: string]: Measurement }> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new InstrumentAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<{ [key: string]: Measurement }>, APIError>(
    [`instruments/measurements/latest`, iIds],
    () => adaptUseQueryFetcher(service.latestMeasurements({ iIds })),
    { enabled: iIds.length > 0 },
  );

  return adaptUseQuerySuspenseResult<{ [key: string]: Measurement }, true>(res);
}

export function useSiteLatestMeasurements(
  sId: string,
): UseAPIResult<{ [key: string]: Measurement }> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<{ [key: string]: Measurement }>, APIError>(
    [`/sites/instruments/measurements/latest`, sId],
    () => adaptUseQueryFetcher(service.latestMeasurements({ sId })),
    {
      suspense: false,
    },
  );

  return adaptUseQueryResult<{ [key: string]: Measurement }, false>(res);
}

function queryKeyDefaultAlerts(): QueryKey {
  return [`/default-alerts`];
}

export function useDefaultAlerts(): UseAPIResultSuccess<SitesAlerts> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new AlertApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<SitesAlerts>, APIError>(queryKeyDefaultAlerts(), () =>
    adaptUseQueryFetcher(service.sitesAlerts()),
  );

  return adaptUseQuerySuspenseResult<SitesAlerts, false>(res);
}

export function useHeatingProductionUnitAlertAcknowledge() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (aid: number) =>
      adaptUseQueryFetcher(
        new AlertApiService(getAccessTokenSilently).acknowledgeProductionUnitAlert(aid),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeyDefaultAlerts(), {
          refetchType: "all",
        }),
    },
  );
}

export function useHeatingDistributionUnitAlertAcknowledge() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (aid: number) =>
      adaptUseQueryFetcher(
        new AlertApiService(getAccessTokenSilently).acknowledgeDistributionCircuitAlert(aid),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeyDefaultAlerts(), {
          refetchType: "all",
        }),
    },
  );
}

export function useInstrumentGroupAlertAcknowledge() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (aid: number) =>
      adaptUseQueryFetcher(
        new AlertApiService(getAccessTokenSilently).acknowledgeInstrumentGroupAlert(aid),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeyDefaultAlerts(), {
          refetchType: "all",
        }),
    },
  );
}

export function useSitePeriodMeasurements(
  sId: string,
  from: number,
  to: number,
): UseAPIResult<{ [key: string]: AggregatedMeasurement }> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new SiteAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<{ [key: string]: AggregatedMeasurement }>, APIError>(
    [`/sites/instruments/measurements/period`, sId],
    () => adaptUseQueryFetcher(service.periodMeasurements({ sId, from, to })),
    {
      suspense: false,
    },
  );

  return adaptUseQueryResult<{ [key: string]: AggregatedMeasurement }, false>(res);
}

export function useMeasurements(
  iIds: string[],
  from: number,
  to: number,
): UseDeferrableAPIResult<Measurement[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MeasurementAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<Measurement[]>, APIError>(
    [`/instruments/measurements`, iIds, from, to],
    () => adaptUseQueryFetcher(service.fetch({ iIds, from, to })),
    { enabled: iIds.length > 0 },
  );

  return adaptUseQuerySuspenseResult<Measurement[], true>(res);
}

function queryKeyUserRequiredActions(): QueryKey {
  return [`/users/required-actions`];
}

export function useUserRequiredActions(): UseAPIResultSuccess<UserRequiredActions> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new UserManagementAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<UserRequiredActions>, APIError>(
    queryKeyUserRequiredActions(),
    () => adaptUseQueryFetcher(service.fetchRequiredActions()),
  );

  return adaptUseQuerySuspenseResult<UserRequiredActions, false>(res);
}

function queryKeyUsers(): QueryKey {
  return [`/users`];
}

export function useUsers(): UseAPIResultSuccess<UserManagementUser[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new UserManagementAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<UserManagementUser[]>, APIError>(queryKeyUsers(), () =>
    adaptUseQueryFetcher(service.fetch()),
  );

  return adaptUseQuerySuspenseResult<UserManagementUser[], false>(res);
}

export function useRoles(): UseAPIResultSuccess<Role[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new UserManagementAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<Role[]>, APIError>(["/user-management/users/roles"], () =>
    adaptUseQueryFetcher(service.fetchRoles()),
  );

  return adaptUseQuerySuspenseResult<Role[], false>(res);
}

export function useLazyUserRoles(userId: string): UseQueryResult<UseAPIResultSuccess<Role[]>> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new UserManagementAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );
  const res = useQuery<APISuccess<Role[]>, APIError>(
    [`/user-management/users/role/${userId}`],
    () => adaptUseQueryFetcher(service.fetchUserRoles(userId)),
    { enabled: false },
  );

  return res;
}

export function queryKeyRequeaLastUptime(): QueryKey {
  return [`/requea/last-uptime`];
}

export function useRequeaLastUptime(
  isns: string[],
): UseAPIResultSuccess<{ [key: string]: string }> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new ProvidersAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<{ [key: string]: string }>, APIError>(
    queryKeyRequeaLastUptime(),
    () => adaptUseQueryFetcher(service.getRequeaLastUptime({ isns })),
  );

  return adaptUseQuerySuspenseResult<{ [key: string]: string }, false>(res);
}

function queryKeyInstrumentAllSessions(): QueryKey {
  return ["/deployment-sessions/all"];
}

export function useDeploymentAllSessions() {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new InstrumentAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<InstrumentDeploymentSession[]>, APIError>(
    queryKeyInstrumentAllSessions(),
    () => adaptUseQueryFetcher(service.fetchInstallationSessions()),
  );

  return adaptUseQuerySuspenseResult<InstrumentDeploymentSession[], false>(res);
}

function queryKeyMappingSessions(): QueryKey {
  return ["/mapping-sessions"];
}

export function useMappingSessions(): UseAPIResultSuccess<MappingSession[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new InstrumentAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<MappingSession[]>, APIError>(queryKeyMappingSessions(), () =>
    adaptUseQueryFetcher(service.fetchMappingSessions()),
  );

  return adaptUseQuerySuspenseResult<MappingSession[], false>(res);
}

export function useMappingSessionCreate() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (params: { name: string }) =>
      adaptUseQueryFetcher(
        new InstrumentAPIService(getAccessTokenSilently).createMappingSession(params),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyMappingSessions());
      },
    },
  );
}

function queryKeyInstrumentSessions(dsid: string): QueryKey {
  return ["/deployment-sessions", dsid];
}

export function useDeploymentSession(
  dsid: string,
): UseAPIResultSuccess<InstrumentDeploymentSession> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new InstrumentAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<InstrumentDeploymentSession>, APIError>(
    queryKeyInstrumentSessions(dsid),
    () => adaptUseQueryFetcher(service.fetchOneInstallationSession(dsid)),
  );

  return adaptUseQuerySuspenseResult<InstrumentDeploymentSession, false>(res);
}

export function useDeploymentSessionCreate() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (session: { name: string; slug: string; supplierId: number; mappingSessionId: number }) =>
      adaptUseQueryFetcher(
        new InstrumentAPIService(getAccessTokenSilently).createInstallationSession(session),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyInstrumentAllSessions());
      },
    },
  );
}

export function useUpdateDeploymentSessionMappingSession(dsid: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (mappingSessionId: number) =>
      adaptUseQueryFetcher(
        new InstrumentAPIService(getAccessTokenSilently).updateInstallationSessionMapping({
          dsid,
          msid: mappingSessionId,
        }),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyInstrumentSessions(dsid), {
          refetchType: "all",
        });
        await queryClient.invalidateQueries(queryKeyInstrumentAllSessions(), {
          refetchType: "all",
        });
      },
    },
  );
}

export function useQueryClientSetDeploymentSession() {
  const queryClient = useQueryClient();

  return (ds: InstrumentDeploymentSession) =>
    queryClient.setQueryData(queryKeyInstrumentSessions(ds.id.toString()), { data: ds });
}

export function useUpdateDeploymentSessionStatus(dsid: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      adaptUseQueryFetcher(
        new InstrumentAPIService(getAccessTokenSilently).updateInstallationSessionStatus(dsid),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyInstrumentSessions(dsid));
        await queryClient.invalidateQueries(queryKeyInstrumentAllSessions());
        await queryClient.invalidateQueries(queryKeySessionInstrumentStock(dsid));
      },
    },
  );
}

function queryKeyInstrumentStock(): QueryKey {
  return ["/instrument-stock"];
}

function queryKeySiteInstrumentStock(): QueryKey {
  return ["/instrument-stock/site"];
}

export function useInstrumentStocks(): UseAPIResultSuccess<InstrumentStock[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new InstrumentAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<InstrumentStock[]>, APIError>(queryKeyInstrumentStock(), () =>
    adaptUseQueryFetcher(service.fetchAllStock()),
  );

  return adaptUseQuerySuspenseResult<InstrumentStock[], false>(res);
}

export function useSiteInstrumentStock(slug: string): UseAPIResultSuccess<InstrumentStock[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new InstrumentAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<InstrumentStock[]>, APIError>(queryKeySiteInstrumentStock(), () =>
    adaptUseQueryFetcher(service.fetchSiteInstrumentStock(slug)),
  );

  return adaptUseQuerySuspenseResult<InstrumentStock[], false>(res);
}

function queryKeySessionInstrumentStock(dsid: string): QueryKey {
  return ["/instrument-stock", dsid];
}

export function useInstrumentStock(dsid: string): UseAPIResultSuccess<InstrumentStock[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new InstrumentAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<InstrumentStock[]>, APIError>(
    queryKeySessionInstrumentStock(dsid),
    () => adaptUseQueryFetcher(service.fetchInstrumentStock(dsid)),
  );

  return adaptUseQuerySuspenseResult<InstrumentStock[], false>(res);
}

export function useStockInstrumentCreate(onSuccessCallback: any) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (stockInstrument: InstrumentStockCreate) =>
      adaptUseQueryFetcher(
        new InstrumentAPIService(getAccessTokenSilently).createStockInstrument(stockInstrument),
      ),
    {
      onSuccess: async (data) => {
        const hardwareNameRes = data;

        if (onSuccessCallback) {
          onSuccessCallback(hardwareNameRes.data);
        }

        await queryClient.invalidateQueries(queryKeyInstrumentStock());
        await queryClient.invalidateQueries(queryKeySiteInstrumentStock());
      },
    },
  );
}

export function useInstrumentStockUpdate() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (stockInstrument: InstrumentStock) =>
      adaptUseQueryFetcher(
        new InstrumentAPIService(getAccessTokenSilently).updateStockInstrument(stockInstrument),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyInstrumentStock());
      },
    },
  );
}

export function useStockInstrumentDelete() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (sn: string) =>
      adaptUseQueryFetcher(
        new InstrumentAPIService(getAccessTokenSilently).deleteStockInstrument(sn),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyInstrumentStock());
      },
    },
  );
}

export function useUsersCreate() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (u: UserManagementUserCreate) =>
      adaptUseQueryFetcher(new UserManagementAPIService(getAccessTokenSilently).create(u)),
    {
      onSuccess: (newUser) => {
        queryClient.setQueryData(
          queryKeyUsers(),
          // @ts-expect-error
          (old: APISuccess<UserManagementUser[]> | undefined) => ({
            ...old,
            // @ts-expect-error
            data: [...old.data, newUser.data],
          }),
        );
      },
    },
  );
}

export function useUsersUpdate() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (u: UserManagementUserUpdate) =>
      adaptUseQueryFetcher(new UserManagementAPIService(getAccessTokenSilently).update(u)),
    {
      onSuccess: (updatedUser) => {
        // https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns/#updating-an-item-in-an-array
        // @ts-expect-error
        queryClient.setQueryData(queryKeyUsers(), (old: APISuccess<UserManagementUser[]>) => ({
          ...old,
          data: old.data.map((oldUser) => {
            if (oldUser.id !== updatedUser.data.id) return oldUser;
            return { ...oldUser, ...updatedUser.data };
          }),
        }));
      },
    },
  );
}

export function useUsersDelete() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (uId: string) =>
      adaptUseQueryFetcher(new UserManagementAPIService(getAccessTokenSilently).delete({ uId })),
    {
      onSuccess: (updatedUser, uId: string) => {
        // https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns/#updating-an-item-in-an-array
        // @ts-expect-error
        queryClient.setQueryData(queryKeyUsers(), (old: APISuccess<UserManagementUser[]>) => ({
          ...old,
          data: old.data.filter((u) => u.id !== uId),
        }));
      },
    },
  );
}

export function useWeatherData(
  sid: number,
  from: number,
  to: number,
): UseAPIResultSuccess<WeatherData> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new WeatherAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<WeatherData>, APIError>([`/weather`, from, to, sid], () =>
    adaptUseQueryFetcher(service.fetchWeatherData({ sid, from, to })),
  );

  return adaptUseQuerySuspenseResult<WeatherData, false>(res);
}

export function useHealthZ(): any {
  const service = new HealthZAPIService(() => new Promise((resolve) => resolve(""))); // No need for auth

  return useQuery([`/healthz`], () => adaptUseQueryFetcher(service.fetch()));
}

export function useSiteHeatingDistributionSchedulingParameters(
  sId: string,
  hdcId: string,
): UseAPIResultSuccess<SchedulingParameters> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new RegulationAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<SchedulingParameters>, APIError>(
    queryKeySiteDistributionCircuitSchedulingParameters(sId, hdcId),
    () => adaptUseQueryFetcher(service.fetchSchedulingParameters({ sId, hdcId })),
  );

  return adaptUseQuerySuspenseResult<SchedulingParameters, false>(res);
}

export function useSiteHeatingDistributionRegulationParameters(
  sId: string,
  hdcId: string,
): UseAPIResultSuccess<RegulationParameters> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new RegulationAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<RegulationParameters>, APIError>(
    queryKeySiteDistributionCircuitRegulationParameters(sId, hdcId),
    () => adaptUseQueryFetcher(service.fetchRegulationParameters({ sId, hdcId })),
  );

  return adaptUseQuerySuspenseResult<RegulationParameters, false>(res);
}

function queryKeyPredictiveMonitoring(hdcId?: number): QueryKey {
  return hdcId ? [`/monitoring/predictive/${hdcId}`] : [`/monitoring/predictive`];
}

export function usePredictiveMonitoring(): UseAPIResultSuccess<PredictiveMonitoring[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<PredictiveMonitoring[]>, APIError>(
    queryKeyPredictiveMonitoring(),
    () => adaptUseQueryFetcher(service.fetchPredictiveMonitoring()),
  );

  return adaptUseQuerySuspenseResult<PredictiveMonitoring[], false>(res);
}

export function usePredictiveMonitoringForCircuit(
  hdcId: number,
): UseAPIResultSuccess<PredictiveMonitoring> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<PredictiveMonitoring>, APIError>(
    queryKeyPredictiveMonitoring(hdcId),
    () => adaptUseQueryFetcher(service.fetchPredictiveMonitoringForCircuit(hdcId)),
  );

  return adaptUseQuerySuspenseResult<PredictiveMonitoring, false>(res);
}

function queryKeyPlcsMonitoring(): QueryKey {
  return [`/monitoring/plcs`];
}

export function usePlcsMonitoring(): UseAPIResultSuccess<PlcJobMonitoring[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<PlcJobMonitoring[]>, APIError>(queryKeyPlcsMonitoring(), () =>
    adaptUseQueryFetcher(service.fetchPlcsMonitoring()),
  );

  return adaptUseQuerySuspenseResult<PlcJobMonitoring[], false>(res);
}

function queryKeyMonitoringJobAlerts(): QueryKey {
  return [`/monitoring/job-alerts`];
}

export function useJobAlerts(): UseAPIResultSuccess<JobAlerts> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<JobAlerts>, APIError>(queryKeyMonitoringJobAlerts(), () =>
    adaptUseQueryFetcher(service.fetchJobAlerts()),
  );

  return adaptUseQuerySuspenseResult<JobAlerts, false>(res);
}

function queryKeyRequeaGateways(): QueryKey {
  return [`/requea/gateways`];
}

export function useRequeaGateways(): UseAPIResultSuccess<RequeaGateway[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new ProvidersAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<RequeaGateway[]>, APIError>(queryKeyRequeaGateways(), () =>
    adaptUseQueryFetcher(service.getRequeaGateways()),
  );

  return adaptUseQuerySuspenseResult<RequeaGateway[], false>(res);
}

export function useGetPlcHeatlthz(plcId: number): UseAPIResultSuccess<PlcHealthStatus> {
  const { getAccessTokenSilently } = useAuth0();
  const monitoringService = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<PlcHealthStatus>, APIError>(
    [`/monitoring/plcs/${plcId}/healthz`],
    () => adaptUseQueryFetcher(monitoringService.getPlcHealthz(plcId)),
  );

  return adaptUseQuerySuspenseResult<PlcHealthStatus, false>(res);
}

function queryKeyHeatingDistributionCircuitsConfiguration(): QueryKey {
  return [`/monitoring/distribution-circuits`];
}

function queryKeyPlcsWithJobConfiguration(): QueryKey {
  return [`/plcs/jobs/configuration`];
}

export function usePlcsJobConfiguration(): UseAPIResultSuccess<PlcWithJobConfiguration[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<PlcWithJobConfiguration[]>, APIError>(
    queryKeyPlcsWithJobConfiguration(),
    () => adaptUseQueryFetcher(service.fetchPlcsWithJobsConfiguration()),
  );

  return adaptUseQuerySuspenseResult<PlcWithJobConfiguration[], false>(res);
}

export function useUpdatePlcJobsConfiguration() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (jobConfiguration: JobConfiguration) =>
      adaptUseQueryFetcher(
        new MonitoringApiService(getAccessTokenSilently).updatePlcJobConfiguration({
          jobConfiguration,
        }),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyPlcsWithJobConfiguration(), {
          refetchType: "all",
        });
        await queryClient.invalidateQueries(queryKeyMonitoringJobAlerts(), {
          refetchType: "all",
        });
        await queryClient.invalidateQueries(queryKeyPlcsMonitoring(), {
          refetchType: "all",
        });
      },
    },
  );
}

export function usePlcHeatlthzHistory(
  slug: string,
  plcIds: number[],
): UseAPIResultSuccess<PlcHealthStatus[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<PlcHealthStatus[]>, APIError>(
    [`/monitoring/plcs/${slug}/healthz/history`],
    () => adaptUseQueryFetcher(service.fetchPlcHealthzHistory(plcIds)),
  );

  return adaptUseQuerySuspenseResult<PlcHealthStatus[], false>(res);
}

export function useSchedulingMeasurements(
  hdcId: number,
  from: number,
  to: number,
): UseAPIResultSuccess<SchedulingMeasurement[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<SchedulingMeasurement[]>, APIError>(
    [`/monitoring/scheduling-measurements`, hdcId, from, to],
    () => adaptUseQueryFetcher(service.fetchSchedulingMeasurements(hdcId, from, to)),
  );

  return adaptUseQuerySuspenseResult<SchedulingMeasurement[], false>(res);
}

export function useSchedulingParameters(): UseAPIResultSuccess<SchedulingParameters[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<SchedulingParameters[]>, APIError>(
    [`/monitoring/scheduling-parameters`],
    () => adaptUseQueryFetcher(service.fetchSchedulingParameters()),
  );

  return adaptUseQuerySuspenseResult<SchedulingParameters[], false>(res);
}

export function useDistributionCircuits(): UseAPIResultSuccess<HeatingDistributionCircuit[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HeatingDistributionCircuit[]>, APIError>(
    queryKeyHeatingDistributionCircuitsConfiguration(),
    () => adaptUseQueryFetcher(service.fetchDistributionCircuits()),
  );

  return adaptUseQuerySuspenseResult<HeatingDistributionCircuit[], false>(res);
}

function queryKeyHdcsWithJobConfiguration(): QueryKey {
  return [`/hdcs/jobs/configuration`];
}

export function useHdcsJobConfiguration(): UseAPIResultSuccess<HdcWithJobConfiguration[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HdcWithJobConfiguration[]>, APIError>(
    queryKeyHdcsWithJobConfiguration(),
    () => adaptUseQueryFetcher(service.fetchHdcsWithJobsConfiguration()),
  );

  return adaptUseQuerySuspenseResult<HdcWithJobConfiguration[], false>(res);
}

function queryKeyHdcsJobMonitoring(): QueryKey {
  return [`/hdcs/jobs`];
}

export function useHdcsJobMonitoring(): UseAPIResultSuccess<
  HeatingDistributionCircuitJobMonitoring[]
> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HeatingDistributionCircuitJobMonitoring[]>, APIError>(
    queryKeyHdcsJobMonitoring(),
    () => adaptUseQueryFetcher(service.fetchHdcJobMonitoring()),
  );

  return adaptUseQuerySuspenseResult<HeatingDistributionCircuitJobMonitoring[], false>(res);
}

export function useUpdateHdcJobsConfiguration() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (configuration: JobConfiguration) =>
      adaptUseQueryFetcher(
        new MonitoringApiService(getAccessTokenSilently).updateDistributionCircuitJobsConfiguration(
          configuration,
        ),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyHdcsWithJobConfiguration(), {
          refetchType: "all",
        });
        await queryClient.invalidateQueries(queryKeyMonitoringJobAlerts(), {
          refetchType: "all",
        });
        await queryClient.invalidateQueries(queryKeyHdcsJobMonitoring(), {
          refetchType: "all",
        });
      },
    },
  );
}

function queryKeyHpusJobMonitoring(): QueryKey {
  return [`/hpus/jobs`];
}

export function useHpusJobConfiguration(): UseAPIResultSuccess<HpuWithJobConfiguration[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HpuWithJobConfiguration[]>, APIError>(
    queryKeyHpusJobMonitoring(),
    () => adaptUseQueryFetcher(service.fetchHpusWithJobsConfiguration()),
  );

  return adaptUseQuerySuspenseResult<HpuWithJobConfiguration[], false>(res);
}

function queryKeyHpusWithJobConfiguration(): QueryKey {
  return [`/hpus/jobs/configuration`];
}
export function useHpusJobsMonitoring(): UseAPIResultSuccess<HpuJobMonitoring[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HpuJobMonitoring[]>, APIError>(
    queryKeyHpusWithJobConfiguration(),
    () => adaptUseQueryFetcher(service.fetchHpuJobsMonitoring()),
  );

  return adaptUseQuerySuspenseResult<HpuJobMonitoring[], false>(res);
}

export function useUpdateHpuJobsConfiguration() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (jobConfiguration: JobConfiguration) =>
      adaptUseQueryFetcher(
        new MonitoringApiService(getAccessTokenSilently).updateHpuJobConfiguration({
          jobConfiguration,
        }),
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyHpusWithJobConfiguration(), {
          refetchType: "all",
        });
        await queryClient.invalidateQueries(queryKeyMonitoringJobAlerts(), {
          refetchType: "all",
        });
        await queryClient.invalidateQueries(queryKeyHpusJobMonitoring(), {
          refetchType: "all",
        });
      },
    },
  );
}

export function useComfortMonitoring(): UseAPIResultSuccess<ComfortMonitoring[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<ComfortMonitoring[]>, APIError>([`/monitoring/comfort`], () =>
    adaptUseQueryFetcher(service.fetchComfortMonitoring()),
  );

  return adaptUseQuerySuspenseResult<ComfortMonitoring[], false>(res);
}

export function queryKeyComfortIndicator(from: number, to: number): QueryKey {
  return [`/monitoring/comfort-indicators`, from, to];
}

export function useComfortIndicators(
  from: number,
  to: number,
): UseAPIResultSuccess<ComfortIndicator[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<ComfortIndicator[]>, APIError>(
    queryKeyComfortIndicator(from, to),
    () => adaptUseQueryFetcher(service.fetchComfortIndicators(from, to)),
  );

  return adaptUseQuerySuspenseResult<ComfortIndicator[], false>(res);
}

export function useSchedulingEvents(
  hdcId: number,
  from: number,
  to: number,
): UseAPIResultSuccess<SchedulingEvent[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<SchedulingEvent[]>, APIError>(
    [`/monitoring/scheduling-regulation/events`, hdcId, from, to],
    () => adaptUseQueryFetcher(service.fetchSchedulingEvents(hdcId, from, to)),
  );

  return adaptUseQuerySuspenseResult<SchedulingEvent[], false>(res);
}

export function useSiteSchedulingMeasurements(
  sId: string,
  hdcId: number,
  from: number,
  to: number,
): UseAPIResultSuccess<SchedulingMeasurement[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new RegulationAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<SchedulingMeasurement[]>, APIError>(
    [`/sites/heating-installations/scheduling/measurements`, sId, hdcId, from, to],
    () => adaptUseQueryFetcher(service.fetchSchedulingMeasurements({ sId, hdcId, from, to })),
  );

  return adaptUseQuerySuspenseResult<SchedulingMeasurement[], false>(res);
}

export function useSiteSchedulingEvents(
  sId: string,
  hdcId: number,
  from: number,
  to: number,
): UseAPIResultSuccess<SchedulingEvent[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new RegulationAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<SchedulingEvent[]>, APIError>(
    [`/sites/heating-installations/scheduling/events`, sId, hdcId, from, to],
    () => adaptUseQueryFetcher(service.fetchSchedulingEvents({ sId, hdcId, from, to })),
  );

  return adaptUseQuerySuspenseResult<SchedulingEvent[], false>(res);
}

export function useDistributionCircuitDJUSavings(
  slug: string,
  from: number,
  to: number,
  freq: "daily" | "hourly",
  dcIds: string[],
  enable = true,
): UseAPIResultSuccess<DJUSaving[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new RegulationAPIService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<DJUSaving[]>, APIError>(
    [`/sites/heating-installations/circuits/regulation/dju-savings`, slug, from, to, freq, dcIds],
    () =>
      adaptUseQueryFetcher(
        service.fetchDistributionCircuitDJUSavings({ from, to, freq, slug, dcIds }),
      ),
    { enabled: !!enable },
  );

  return adaptUseQuerySuspenseResult<DJUSaving[], false>(res);
}

export function queryKeySiteHeatingProductionUnitAlerts(mail: string): QueryKey {
  return [`/alerts/hpu`, mail];
}

export function useHeatingProductionUnitAlerts(
  mail: string,
): UseAPIResultSuccess<HeatingProductionUnitAlert[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new AlertApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<HeatingProductionUnitAlert[]>, APIError>(
    queryKeySiteHeatingProductionUnitAlerts(mail),
    () => adaptUseQueryFetcher(service.fetchHpuAlerts(mail)),
  );

  return adaptUseQuerySuspenseResult<HeatingProductionUnitAlert[], false>(res);
}

export function useSaveHeatingProductionUnitAlert(mail: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (alert: HeatingProductionUnitAlert) =>
      adaptUseQueryFetcher(new AlertApiService(getAccessTokenSilently).saveHpuAlert(alert)),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteHeatingProductionUnitAlerts(mail), {
          refetchType: "all",
        }),
    },
  );
}

export function useDeleteHeatingProductionUnitAlert(mail: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (alertId: number) =>
      adaptUseQueryFetcher(new AlertApiService(getAccessTokenSilently).deleteHpuAlert(alertId)),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteHeatingProductionUnitAlerts(mail), {
          refetchType: "all",
        }),
    },
  );
}

export function useToggleAcknowledgeAlert(mail: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (alertId: number) =>
      adaptUseQueryFetcher(
        new AlertApiService(getAccessTokenSilently).acknowledgeProductionUnitAlert(alertId),
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(queryKeySiteHeatingProductionUnitAlerts(mail), {
          refetchType: "all",
        }),
      // TO BE COMPLETED WITH OTHER ALERTS QUERIES
    },
  );
}

export function queryKeyBanner(): QueryKey {
  return [`/alerts/banners`];
}

export function queryKeyActiveBanner(): QueryKey {
  return [`/alerts/active-banners`];
}

export function useBanners(): UseAPIResultSuccess<AdminBanner[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new AlertApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<AdminBanner[]>, APIError>(queryKeyBanner(), () =>
    adaptUseQueryFetcher(service.fetchAllBanners()),
  );

  return adaptUseQuerySuspenseResult<AdminBanner[], false>(res);
}

export function useActiveBanners(): UseAPIResultSuccess<AdminBanner[]> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new AlertApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<AdminBanner[]>, APIError>(queryKeyActiveBanner(), () =>
    adaptUseQueryFetcher(service.fetchActiveBanners()),
  );

  return adaptUseQuerySuspenseResult<AdminBanner[], false>(res);
}

export function useUpdateBanners() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (banner: AdminBanner) =>
      adaptUseQueryFetcher(new AlertApiService(getAccessTokenSilently).updateBanner(banner)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeyBanner(), { refetchType: "all" });
        queryClient.invalidateQueries(queryKeyActiveBanner(), { refetchType: "all" });
      },
    },
  );
}

export function useSaveBanners() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (banner: AdminBannerCreate) =>
      adaptUseQueryFetcher(new AlertApiService(getAccessTokenSilently).saveBanner(banner)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeyBanner(), { refetchType: "all" });
        queryClient.invalidateQueries(queryKeyActiveBanner(), { refetchType: "all" });
      },
    },
  );
}

export function useDeleteBanners() {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    (bId: number) =>
      adaptUseQueryFetcher(new AlertApiService(getAccessTokenSilently).deleteBanner(bId)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeyBanner(), { refetchType: "all" });
        queryClient.invalidateQueries(queryKeyActiveBanner(), { refetchType: "all" });
      },
    },
  );
}

export function useInstrumentStats(): UseAPIResultSuccess<InstrumentStatsResponse> {
  const { getAccessTokenSilently } = useAuth0();
  const service = useMemo(
    () => new MonitoringApiService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const res = useQuery<APISuccess<InstrumentStatsResponse>, APIError>(
    ["monitoring", "instrument", "stats"],
    () => adaptUseQueryFetcher(service.fetchInstrumentStats()),
  );

  return adaptUseQuerySuspenseResult<InstrumentStatsResponse, false>(res);
}
