import { Card } from "antd";
import moment from "moment";
import React, { Suspense } from "react";
import DatePickerDownloader from "src/components/DatePickerDownloader";
import Loader from "src/components/Loader";
import { HeatingDistributionCircuit } from "src/lib/api";
import SiteSchedulingRegulationMeasurements from "./SiteSchedulingRegulationMeasurements";

const SchedulingRegulationMonitoring: React.FC<{
  siteSlug: string;
  hdc: HeatingDistributionCircuit;
}> = ({ siteSlug, hdc }) => {
  return (
    <Card title="Monitoring des salles">
      <DatePickerDownloader
        ranges={{
          "1 jour": [moment().subtract(1, "d"), moment()],
          "2 jours": [moment().subtract(2, "d"), moment()],
          "3 jours": [moment().subtract(3, "d"), moment()],
          "1 semaine": [moment().subtract(7, "d"), moment()],
        }}
        defaultRange={[moment().subtract(1, "d"), moment()]}
        roundDate={false}
      >
        {({ from, to }) => (
          <Suspense fallback={<Loader />}>
            <SiteSchedulingRegulationMeasurements
              siteSlug={siteSlug}
              from={from}
              to={to}
              hdcId={hdc.id}
            />
          </Suspense>
        )}
      </DatePickerDownloader>
    </Card>
  );
};

export default SchedulingRegulationMonitoring;
