import { useAuth0 } from "@auth0/auth0-react";
import { Col, Divider, Empty, Row, Tabs } from "antd";
import React, { Suspense } from "react";
import { hasPermission, Permissions } from "src/lib/access-control";
import { HeatingDistributionCircuit, Site } from "src/lib/api";
import { useWindowWidth } from "src/lib/hooks/window-width";
import { ellipsis } from "src/lib/utils";
import CommandControls from "./CommandControls";
import ErrorBoundaryWithFallback from "./ErrorBoundaryWithFallback";
import HeatingCurve from "./HeatingCurve";
import PredictiveSupervision from "./PredictiveSupervision";
import RenderIf from "./RenderIf";
import Synoptic from "./Synoptic";
import Loader from "./Loader";

const LiveMonitoringTab: React.FC<{ site: Site; hdcs: HeatingDistributionCircuit[] }> = ({
  site,
  hdcs,
}) => {
  const isWideScreen = useWindowWidth();
  const { user } = useAuth0();

  const isAdmin = hasPermission(user, Permissions.HeatingInstallationsUpdate);
  return (
    <RenderIf predicate={hdcs.length > 0} fallback={<Empty />}>
      <Tabs defaultActiveKey="1" tabPosition={isWideScreen ? "left" : "top"}>
        {hdcs.map((hdc: HeatingDistributionCircuit) => (
          <Tabs.TabPane
            tab={isWideScreen ? ellipsis(hdc.name, 30) : hdc.name}
            key={`heating-distribution-circuit-${hdc.id}`}
          >
            {hasPermission(user, Permissions.HeatingInstallationsRead) && (
              <>
                <Row>
                  <Suspense fallback={<Loader />}>
                    <ErrorBoundaryWithFallback fallback={<Empty />}>
                      <Synoptic
                        slug={site.slug}
                        heatingProductionUnitId={hdc.heatingProductionUnitId}
                        heatingDistributionCircuit={hdc}
                      />
                    </ErrorBoundaryWithFallback>
                  </Suspense>
                </Row>
                <Divider />
              </>
            )}
            <Row style={{ display: "flex", alignItems: "center" }}>
              {hasPermission(user, Permissions.HeatingInstallationsRead) && (
                <Col span={12}>
                  <Suspense fallback={<Loader />}>
                    <ErrorBoundaryWithFallback fallback={<Empty />}>
                      <HeatingCurve slug={site.slug} heatingDistributionCircuitId={hdc.id} />
                    </ErrorBoundaryWithFallback>
                  </Suspense>
                </Col>
              )}
              <Col span={1} />
              <Col span={11}>
                {hasPermission(user, Permissions.HeatingInstallationsRead) && (
                  <Row>
                    <Suspense fallback={<Loader />}>
                      <ErrorBoundaryWithFallback fallback={<Empty />}>
                        <CommandControls slug={site.slug} heatingDistributionCircuitId={hdc.id} />
                      </ErrorBoundaryWithFallback>
                    </Suspense>
                  </Row>
                )}
                {isAdmin && (
                  <Row>
                    <Divider />
                    <Suspense fallback={<Loader />}>
                      <ErrorBoundaryWithFallback fallback={<Empty />}>
                        <PredictiveSupervision
                          slug={site.slug}
                          heatingDistributionCircuitId={hdc.id}
                        />
                      </ErrorBoundaryWithFallback>
                    </Suspense>
                  </Row>
                )}
              </Col>
            </Row>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </RenderIf>
  );
};

export default LiveMonitoringTab;
