import { useAuth0 } from "@auth0/auth0-react";
import { Button, DatePicker, Empty, Modal, Space, Typography, message } from "antd";
import moment, { Moment } from "moment";
import * as React from "react";
import { Link } from "react-router-dom";
import EnergyMetersTable from "src/components/EnergyMetersTable";
import RenderIf from "src/components/RenderIf";
import { Permissions, hasPermission } from "src/lib/access-control";
import { Site, SiteAPIService } from "src/lib/api";
import { useSiteEnergyMeters } from "src/lib/hooks/api";
import Loader from "./Loader";

const EnergyMetersTab: React.FC<{ site: Site }> = ({ site }) => {
  const { data: energyMeters } = useSiteEnergyMeters(site.slug);
  const { getAccessTokenSilently, user } = useAuth0();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [year, setYear] = React.useState<Moment | null>(moment(null));

  const handleRange = (value: any) => {
    const date = value ?? null;
    setYear(date);
  };

  async function onSiteConsumptionExport(value: any) {
    setIsModalVisible(false);
    setIsLoading(true);
    try {
      if (year === null) return;

      await new SiteAPIService(getAccessTokenSilently).downloadSiteConsumption({
        sId: site.slug,
        fileName: `${site.name} - Export des consommation d'énergie ${year.year()}.csv`,
        from: year.startOf("year").unix(),
        to: year.endOf("year").unix(),
      });
    } catch (e) {
      message.error({
        content: `L'export des données a échoué. Notre équipe a été prévenue. Veuillez ressayer ultérieurement.`,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <RenderIf
        predicate={energyMeters.length > 0}
        fallback={
          <Empty
            description={
              <Space direction="vertical">
                <Typography.Text>Aucun compteurs d'énergie déclaré</Typography.Text>
                <Link to={`/sites/${site.slug}/energy-meters/0`}>Accéder au reste des données</Link>
              </Space>
            }
          />
        }
      >
        <EnergyMetersTable energyMeters={energyMeters} slug={site.slug} />
        <div style={{ padding: 10, alignItems: "flex-end" }}>
          <Button
            type="primary"
            onClick={() => setIsModalVisible(true)}
            disabled={isLoading}
            style={{ height: "auto" }}
          >
            {!isLoading ? (
              "Exporter les consommations annuelles"
            ) : (
              <Loader tip="Récupération des données en cours ..." />
            )}
          </Button>
        </div>
        <Modal
          title={`Export des consommations annuelles de ${site.name}`}
          visible={isModalVisible}
          onOk={onSiteConsumptionExport}
          confirmLoading={false}
          onCancel={() => setIsModalVisible(false)}
          okText="Continuer"
          okButtonProps={{ disabled: year === undefined }}
        >
          <Typography.Paragraph strong={true}>
            Cette opération pourra prendre quelques minutes en fonction de la période choisie et le
            nombre de sondes par site.
          </Typography.Paragraph>
          <Typography.Paragraph>Veuillez choisir l'année d'export</Typography.Paragraph>
          <DatePicker
            picker={"year"}
            disabledDate={(current: Moment | null) =>
              current
                ? // Disable dates in two cases:
                  //  1. future since we won't have data.
                  //  2. more than one year ago to limit the load on the database.
                  hasPermission(user, Permissions.ExportCreate)
                  ? current > moment()
                  : current > moment() || current.isBefore(moment().subtract(1, "years"))
                : true
            }
            onChange={handleRange}
          />
        </Modal>
      </RenderIf>
    </>
  );
};

export default EnergyMetersTab;
