import * as React from "react";
import { Button, Result } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { Auth0Context } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { useWindowWidth } from "src/lib/hooks/window-width";
import ErrorReportDialogButton from "src/components/errors/ErrorReportDialogButton";

const RefreshButton = () => (
  <Button
    key="refresh-page"
    type="primary"
    onClick={() => window.location.reload()}
    icon={<ReloadOutlined />}
  >
    Rafraîchir maintenant
  </Button>
);

const ResultNewAppVersion = () => {
  return (
    <Result
      title="Une nouvelle version de l'application est disponible."
      subTitle="Rafraîchissez la page pour continuer à utiliser l'application"
      extra={<RefreshButton />}
    />
  );
};

const ResultUnexpectedError = (props: { eventId: string | null }) => {
  const { isWideScreen } = useWindowWidth();

  return (
    <Result
      status="error"
      title="Une erreur inattendue est survenue"
      subTitle="Veuillez rafraîchir la page ou réessayez plus tard."
      extra={[
        <RefreshButton key="refresh-button" />,
        !isWideScreen && <div key="margin" style={{ marginBottom: "1rem" }} />,
        <ErrorReportDialogButton key="report-error" eventId={props.eventId ?? undefined} />,
      ]}
    />
  );
};

/**
 * A class component becomes an error boundary if it defines either (or both) of the lifecycle
 * methods static getDerivedStateFromError() or componentDidCatch().
 * Use static getDerivedStateFromError() to render a fallback UI after an error has been thrown.
 * Use componentDidCatch() to log error information.
 *
 * https://reactjs.org/docs/error-boundaries.html
 * */
class ErrorBoundary extends React.Component {
  state: { error: Error | null; eventId: string | null };
  static contextType = Auth0Context;

  constructor(props: {}) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  /** Use static getDerivedStateFromError() to render a fallback UI. */
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  /** Use componentDidCatch() to log error information. */
  public componentDidCatch(error: Error, { componentStack }: React.ErrorInfo): void {
    const eventId = Sentry.captureException(error, {
      contexts: { react: { componentStack } },
      user: { id: this.context.user.sub },
    });

    this.setState({ eventId });
  }

  render() {
    if (this.state.error?.name === "ChunkLoadError") return <ResultNewAppVersion />;
    else if (this.state.error) return <ResultUnexpectedError eventId={this.state.eventId} />;

    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
