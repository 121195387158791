import ReactDOM from "react-dom";
import App from "src/pages/_app";
import * as serviceWorker from "src/serviceWorker";
import reportWebVitals from "src/reportWebVitals";
import analytics from "src/lib/analytics";

import "src/index.css";

// @ts-expect-error
window.reactAppBuildGitSha1 = process.env.REACT_APP_BUILD_GIT_SHA;

analytics.load();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
