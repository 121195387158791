import React from "react";
import { Empty, Typography } from "antd";
import moment from "moment";
import { Site, Instrument, InstrumentGroup, Measurement } from "src/lib/api";
import { groupBy } from "src/lib/group-by";
import { useWeatherData, useInstrumentGroupMeasurements, useMeasurements } from "src/lib/hooks/api";
import { timestampCompareFn } from "../Chart/interpolate-data";
import ChartWithSelect, { LineData } from "../charts/ChartWithSelect";
import ScatterChart from "../charts/ScatterChart";
import withSuspenseComponent from "../HOC/withSuspenseComponent";
import { interpolateTemperature, interpolateWeather } from "./utils";

interface ComfortAnalysisChartProps {
  site: Site;
  is: Instrument[];
  igs: InstrumentGroup[];
  from: number;
  to: number;
  hideTimeSeries?: boolean;
}

const ComfortAnalysisChart = (props: ComfortAnalysisChartProps) => {
  const { site, is, igs, from, to, hideTimeSeries } = props;

  const { data: weather } = useWeatherData(site.id, from, to);

  const dataIgsMeasurementsRes = useInstrumentGroupMeasurements(
    igs.map((ig) => ig.id.toString()),
    from,
    to,
  );
  const igsms = dataIgsMeasurementsRes.status === "success" ? dataIgsMeasurementsRes.data : [];

  const igmsGroupedByTimestamp = groupBy(igsms, (m: Measurement) => m.timestamp);
  const w = interpolateTemperature(
    Object.keys(igmsGroupedByTimestamp),
    weather.weather.map((e) => ({ timestamp: e.timestamp, temperature: e.airTemperature })),
  );
  const igsmsDataWithWeather: any[] = w
    .map((e) => ({
      timestamp: moment(e.timestamp).unix() * 1000,
      airTemperature: e.temperature,
      ...igmsGroupedByTimestamp[e.timestamp].reduce(
        (acc, m) => ({
          ...acc,
          [m.instrumentId]: m.temperatureMeasurement,
        }),
        {},
      ),
    }))
    .sort(timestampCompareFn);

  const igmsGroupedByIg = groupBy(igsms, (m: Measurement) => m.instrumentId);
  const dataIsmsRes = useMeasurements(
    is.map((i) => i.id.toString()),
    from,
    to,
  );
  const isms = dataIsmsRes.status === "success" ? dataIsmsRes.data : [];
  const ismsGroup = groupBy(isms, (m: Measurement) => m.instrumentId);

  const weatherData = weather.weather
    .map((e) => ({ timestamp: e.timestamp, temperature: e.airTemperature }))
    .sort(timestampCompareFn);

  const igmsData = Object.entries(igmsGroupedByIg).map(([key, value]) => {
    const valueSorted = value
      .map((e) => ({ timestamp: e.timestamp, temperature: e.temperatureMeasurement }))
      .sort(timestampCompareFn);
    const igsData = interpolateWeather(valueSorted, weatherData).sort((a, b) => a.x - b.x);
    return { key, data: igsData, name: igs.find((ig) => ig.id.toString() === key)?.name };
  });

  const ismsData = Object.entries(ismsGroup).map(([key, value]) => {
    const valueSorted = value
      .map((e) => ({ timestamp: e.timestamp, temperature: e.temperatureMeasurement }))
      .sort(timestampCompareFn);
    const isData = interpolateWeather(valueSorted, weatherData).sort((a, b) => a.x - b.x);
    return { key, data: isData, name: is.find((i) => i.id.toString() === key)?.name };
  });

  const ismsDataGroupedByTimestamp = groupBy(isms, (m: Measurement) => m.timestamp);
  const w2 = interpolateTemperature(
    Object.keys(ismsDataGroupedByTimestamp),
    weather.weather.map((e) => ({ timestamp: e.timestamp, temperature: e.airTemperature })),
  );
  const ismsDataWithWeather: any[] = w2.map((e) => ({
    timestamp: moment(e.timestamp).unix() * 1000,
    airTemperature: e.temperature,
    ...ismsDataGroupedByTimestamp[e.timestamp].reduce(
      (acc, m) => ({
        ...acc,
        [m.instrumentId]: m.temperatureMeasurement,
      }),
      {},
    ),
  }));

  const igslineData: LineData = {
    dataKey: "timestamp",
    data: igsmsDataWithWeather,
    lines: [
      ...igs.map((ig) => ({ name: ig.name, dataKey: ig.id.toString() })),
      { name: "T° Météo", dataKey: "airTemperature" },
    ],
    dot: false,
  };

  const isLineData: LineData = {
    dataKey: "timestamp",
    data: ismsDataWithWeather,
    lines: [
      ...is.map((i) => ({ name: i.name, dataKey: i.id.toString() })),
      { name: "T° Météo", dataKey: "airTemperature" },
    ],
    dot: false,
  };

  return (
    <>
      <ScatterChart
        xLabel="T° Météo (°C)"
        yLabel="T° (°C)"
        scatters={igmsData.concat(ismsData).map((e) => ({
          dataKey: e.key,
          name: e.name ?? "",
          data: e.data,
          showLine: true,
        }))}
      />
      {!hideTimeSeries && (
        <>
          <Typography.Title level={4}>Groupes d'instrument</Typography.Title>
          {igslineData.data.length > 0 ? <ChartWithSelect lineData={igslineData} /> : <Empty />}
          <Typography.Title level={4}>Instruments</Typography.Title>
          {isLineData.data.length > 0 ? <ChartWithSelect lineData={isLineData} /> : <Empty />}
        </>
      )}
    </>
  );
};

export default withSuspenseComponent(ComfortAnalysisChart);
