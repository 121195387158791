import moment from "moment";
import {
  Customized,
  Label,
  Line,
  LineChart,
  Polygon,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { dashboardColors } from "src/constants";
import { Site } from "src/lib/api";
import {
  GetDashboardHeatingPeriods,
  consumptionOnly,
  getConsumptionRepartitionData,
} from "src/lib/dashboard-utils";
import { DashboardNoData, RenderLabel, renderLegends } from "./DashboardComponents";

function dateFormatter(d: string) {
  return moment.unix(parseInt(d)).format("DD MMM");
}

export const DashboardConsumptionRepartition: React.FC<{
  site: Site;
  consumptions: consumptionOnly[];
  unitOfMeasure: string;
}> = ({ site, consumptions, unitOfMeasure }) => {
  const [hp, hpRef] = GetDashboardHeatingPeriods(site.slug);

  const data = getConsumptionRepartitionData(consumptions, hp, hpRef);

  if (data.length === 0) {
    return <DashboardNoData />;
  }

  const firstData = data[0];
  const lastData = data[data.length - 1];

  const currentHeatingPeriodConsumptions = data.filter((t) => t.consumption);

  const lastConsumption = currentHeatingPeriodConsumptions
    ? currentHeatingPeriodConsumptions[currentHeatingPeriodConsumptions.length - 1]
    : undefined;

  var lastConsumptionVariation: number | undefined;
  if (lastConsumption) {
    if (
      lastConsumption.consumptionRef !== 0 &&
      lastConsumption.consumption !== undefined &&
      lastConsumption.consumptionRef !== undefined
    ) {
      lastConsumptionVariation =
        ((lastConsumption.consumption - lastConsumption.consumptionRef) /
          lastConsumption.consumptionRef) *
        100;
    } else {
      lastConsumptionVariation = 0;
    }
  }

  const ticks = lastConsumption
    ? [firstData.timestamp, lastConsumption?.timestamp, lastData.timestamp]
    : [firstData.timestamp, lastData.timestamp];

  const year = `${moment.unix(lastData.timestamp).subtract(1, "year").format("YYYY")}-${moment
    .unix(lastData.timestamp)
    .format("YY")}`;
  const yearRef = `${moment.unix(lastData.timestamp).subtract(2, "year").format("YYYY")}-${moment
    .unix(lastData.timestamp)
    .subtract(1, "year")
    .format("YY")}`;

  const CustomizedRectangle = (props: any) => {
    const { formattedGraphicalItems } = props;
    const maxY = 30;

    const firstSeries = formattedGraphicalItems[0];
    const secondSeries = formattedGraphicalItems[1];

    const firstSerieFirstPoint = firstSeries?.props?.points.reduce((prev: any, current: any) => {
      if (current.y === null) {
        return prev;
      }
      return prev.y < current.y || prev.y === null ? current : prev;
    });
    const firstSerieLastPoint = firstSeries?.props?.points.reduce((prev: any, current: any) => {
      if (current.y === null) {
        return prev;
      }
      return prev.y > current.y || prev.y === null ? current : prev;
    });

    const secondSerieFirstPoint = secondSeries?.props?.points.reduce((prev: any, current: any) => {
      if (current.y === null) {
        return prev;
      }
      return prev.y < current.y || prev.y === null ? current : prev;
    });
    const secondSerieLastPoint = secondSeries?.props?.points.reduce((prev: any, current: any) => {
      if (current.y === null) {
        return prev;
      }
      return prev.y > current.y || prev.y === null ? current : prev;
    });

    return (
      <>
        <Polygon
          points={[
            { x: firstSerieFirstPoint?.x, y: maxY },
            { x: firstSerieLastPoint?.x, y: maxY },
            { x: firstSerieLastPoint?.x, y: maxY + 10 },
            { x: firstSerieFirstPoint?.x, y: maxY + 10 },
          ]}
          fill={dashboardColors.blueFade}
        />
        <Polygon
          points={[
            { x: secondSerieFirstPoint?.x, y: maxY + 10 },
            { x: secondSerieLastPoint?.x, y: maxY + 10 },
            { x: secondSerieLastPoint?.x, y: maxY + 20 },
            { x: secondSerieFirstPoint?.x, y: maxY + 20 },
          ]}
          fill={dashboardColors.yellowFade}
        />
      </>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data} margin={{ top: 50, right: 50, bottom: 25, left: 0 }}>
        <XAxis
          dataKey="timestamp"
          type="number"
          tickFormatter={dateFormatter}
          ticks={ticks}
          domain={[
            firstData.timestamp,
            lastData.timestamp + (lastData.timestamp - firstData.timestamp) * 0.2,
          ]}
        />
        <YAxis tickCount={1} domain={[0, "auto"]}>
          <Label
            value={`Consommation (${unitOfMeasure})`}
            angle={-90}
            position="insideLeft"
            offset={30}
            fill={dashboardColors.grey}
            fontStyle="italic"
            style={{ textAnchor: "middle" }}
          />
        </YAxis>

        <Tooltip
          labelFormatter={dateFormatter}
          formatter={(value: number) => [value.toFixed(2), unitOfMeasure]}
        />

        {lastConsumption && <ReferenceLine x={lastConsumption.timestamp} />}

        <ReferenceLine
          opacity={0}
          segment={[
            { x: firstData.timestamp, y: lastData.consumptionRef },
            { x: lastData.timestamp, y: lastData.consumptionRef },
          ]}
        >
          <Label
            content={
              <RenderLabel
                labelValue={`${lastData.consumptionRef?.toFixed(0)} ${unitOfMeasure}`}
                labelTitle={"Référence"}
                col={dashboardColors.blue}
                scaleX={2}
                scaleY={1.5}
                positionX="right"
              />
            }
          />
        </ReferenceLine>

        <ReferenceLine
          opacity={1}
          segment={[
            { x: firstData.timestamp, y: 0 },
            { x: lastData.timestamp, y: 0 },
          ]}
        >
          <Label content={(props) => renderLegends(props, year, yearRef, { x: 0, y: -45 })} />
        </ReferenceLine>

        <Line type="monotone" stroke={dashboardColors.blue} dataKey="consumptionRef" dot={false} />

        {lastConsumption && (
          <ReferenceLine
            opacity={0}
            segment={[
              { x: firstData.timestamp, y: lastConsumption.consumption },
              { x: lastConsumption.timestamp, y: lastConsumption.consumption },
            ]}
          >
            <Label
              content={
                <RenderLabel
                  labelValue={`${lastConsumption.consumption?.toFixed(0)} ${unitOfMeasure}`}
                  subValue={
                    lastConsumptionVariation
                      ? `variation : ${lastConsumptionVariation.toFixed(1)}%`
                      : undefined
                  }
                  labelTitle={`Instantannée au ${dateFormatter(
                    lastConsumption.timestamp.toString(),
                  )}`}
                  col={dashboardColors.yellow}
                  scaleX={2}
                  scaleY={2}
                  positionX="right"
                />
              }
            />
          </ReferenceLine>
        )}

        {lastConsumption && (
          <Line type="monotone" stroke={dashboardColors.yellow} dataKey="consumption" dot={false} />
        )}

        <Customized component={CustomizedRectangle} />
      </LineChart>
    </ResponsiveContainer>
  );
};
