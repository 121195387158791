import * as React from "react";
import { Button } from "antd";
import { FormOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";

const ErrorReportDialogButton: React.FC<{ eventId?: string }> = ({ eventId }) => {
  const { user } = useAuth0();

  function handleSubmitReport() {
    const reportDialogOptions: Sentry.ReportDialogOptions = {
      user: {
        email: user.email,
        name: user.name,
      },
      lang: "fr",
      title: "",
      eventId,
    };

    Sentry.showReportDialog(reportDialogOptions);
  }

  return (
    <Button type="primary" onClick={() => handleSubmitReport()} icon={<FormOutlined />}>
      Soumettre un rapport d'incident
    </Button>
  );
};

export default ErrorReportDialogButton;
