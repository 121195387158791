import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Carousel, Image, Input, Modal, Space, Typography, Upload } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import domtoimage from "dom-to-image";
import * as React from "react";
import { ImageElementPosition, SiteImage, saveBlob } from "src/lib/api";
import Loader from "./Loader";
import ImgCrop from "antd-img-crop";

const CarouselItem = ({
  children,
  dimensions,
}: {
  children: JSX.Element;
  dimensions?: { width: number; height: number };
}) => {
  const contentStyle: React.CSSProperties = {
    ...dimensions,
    backgroundColor: "#F0F0F0",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: 10,
    overflow: "hidden",
  };

  return (
    <div>
      <div style={contentStyle}>{children}</div>
    </div>
  );
};

export const CarouselImageItem = ({
  image,
  onAddElementPosition,
  children,
  dimensions,
  hideDownload,
}: {
  image: SiteImage;
  onAddElementPosition?: (fileId: number, position: ImageElementPosition) => void;
  children: any;
  dimensions: { width: number; height: number };
  hideDownload?: boolean;
}) => {
  const [isDownloadingImage, setIsDownloadingImage] = React.useState(false);
  const saveChart = (imageUrl: string) => {
    setIsDownloadingImage(true);
    domtoimage
      .toBlob(document.getElementById(imageUrl)!, {
        filter: (node: any) => node.id !== "novisualiser",
        bgcolor: "black",
        width: dimensions.width,
        height: dimensions.height,
      })
      .then((blob: any) => {
        saveBlob(blob, {
          name: `Export visualisation températures.png`,
        });
        setIsDownloadingImage(false);
      })
      .catch((e) => {
        console.error("saveChart", e);
        setIsDownloadingImage(false);
      });
  };

  return (
    <CarouselItem dimensions={dimensions}>
      <div style={{ position: "relative" }}>
        {image?.url ? (
          <div id={image?.url} style={{ ...dimensions, height: dimensions.height }}>
            <Image
              src={image.url}
              preview={false}
              onClick={
                onAddElementPosition
                  ? (e) => onAddElementPosition(image.id, getClickCoords(e))
                  : undefined
              }
              style={{ ...dimensions }}
            />
            {children}
            <div
              style={{
                position: "absolute",
                height: 40,
                padding: 7,
                backgroundColor: "rgba(1,1,1,0.4)",
                width: "100%",
                bottom: 0,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography.Text style={{ color: "white" }}>{image.name}</Typography.Text>
              {!hideDownload && (
                <Button
                  loading={isDownloadingImage}
                  id="novisualiser"
                  type="primary"
                  shape="circle"
                  icon={<DownloadOutlined />}
                  size={"small"}
                  onClick={() => saveChart(image?.url)}
                />
              )}
            </div>
          </div>
        ) : (
          <Loader tip="Enregistrement en cours.. Cela peut prendre plusieurs dizaines de secondes selon la taille de votre image." />
        )}
      </div>
    </CarouselItem>
  );
};

const getClickCoords = (event: any) => {
  // from: https://stackoverflow.com/a/29296049/14198287
  var e = event.target;
  var dim = e.getBoundingClientRect();
  var xPercentage = ((event.clientX - dim.left) / dim.width) * 100;
  var yPercentage = ((event.clientY - dim.top) / dim.height) * 100;
  return { xPercentage, yPercentage };
};

const InteractiveImageCarousel: React.FC<{
  imageList: SiteImage[];
  displayImageElements: (imageId: number) => JSX.Element[];
  dimensions: { width: number; height: number };
  onAddImageElementPosition?: (fileId: number, coordinates: ImageElementPosition) => void;
  imageUploader?: (file: RcFile, name: string) => void;
  imageDeleter?: (file: RcFile) => void;
  carouselRef?: React.RefObject<CarouselRef>;
  setCurrentSlide?: (s: any) => void;
  hideDownload?: boolean;
}> = ({
  imageList,
  displayImageElements,
  dimensions,
  onAddImageElementPosition,
  imageUploader,
  imageDeleter,
  carouselRef,
  setCurrentSlide,
  hideDownload,
}) => {
  const [carouselImages, setCarouselImages] = React.useState<SiteImage[]>([]);
  const [isUploadModalVisible, setIsUploadModalVisible] = React.useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState<boolean>(false);
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const [fileToDelete, setFileToDelete] = React.useState<UploadFile>();
  const [fileToUpload, setFileToUpload] = React.useState<UploadFile>();
  const [fileName, setFileName] = React.useState<string>();

  React.useEffect(() => {
    // updating local images when images are loaded
    setCarouselImages(imageList.map((si: SiteImage) => ({ ...si, status: "done" })));
  }, [imageList]);

  async function uploadImage(
    file: RcFile,
    uploader: (file: RcFile, fileName: string) => void,
    name: string,
  ) {
    // local carousel state management for loading display
    const tempCarouselImages = [...carouselImages];
    const newSiteImage: SiteImage = {
      id: 0,
      uid: file.uid,
      url: "",
      status: "uploading",
      name: "",
    };
    tempCarouselImages.push(newSiteImage);
    setCarouselImages([...tempCarouselImages]);

    uploader(file, name);
    const newSlideIndex = tempCarouselImages.length - 1;
    carouselRef?.current?.goTo(newSlideIndex);
    if (setCurrentSlide) {
      setCurrentSlide(newSlideIndex);
    }
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Ajouter une image</div>
    </div>
  );

  const onChange = (id: number) => {
    if (setCurrentSlide) {
      setCurrentSlide(id);
    }
  };

  return (
    <>
      <Carousel ref={carouselRef} style={{ ...dimensions }} afterChange={onChange}>
        {carouselImages?.map((image: SiteImage) => (
          <CarouselImageItem
            image={image}
            onAddElementPosition={onAddImageElementPosition}
            dimensions={dimensions}
            hideDownload={hideDownload}
          >
            {displayImageElements(image.id)}
          </CarouselImageItem>
        ))}
        {imageUploader && (
          <CarouselItem dimensions={dimensions}>
            <Space
              direction="vertical"
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <Typography.Text strong>
                Vous pouvez ajouter de nouvelles images pour décrire votre immeuble !
              </Typography.Text>
              <ImgCrop
                modalTitle="Edition de l'image"
                quality={1}
                aspect={dimensions.width / dimensions.height}
              >
                <Upload
                  customRequest={({ file }) => {
                    setFileToUpload(file as UploadFile);
                    setIsUploadModalVisible(true);
                  }}
                  onRemove={(file) => {
                    setFileToDelete(file);
                    setIsDeleteModalVisible(true);
                  }}
                  listType="picture-card"
                  fileList={carouselImages as unknown as UploadFile[]}
                >
                  {carouselImages.length >= 7 ? null : uploadButton}
                </Upload>
              </ImgCrop>
            </Space>
          </CarouselItem>
        )}
      </Carousel>
      <Modal
        visible={isDeleteModalVisible}
        title={"Suppression d'une image"}
        okText="Confirmer"
        cancelText="Annuler"
        onCancel={() => setIsDeleteModalVisible(false)}
        onOk={
          imageDeleter && fileToDelete
            ? async () => {
                setIsDeleting(true);
                await imageDeleter(fileToDelete as RcFile);
                setFileToDelete(undefined);
                setIsDeleting(false);
                setIsDeleteModalVisible(false);
              }
            : undefined
        }
        forceRender={true}
        destroyOnClose={true}
        okButtonProps={{ disabled: imageDeleter === undefined, loading: isDeleting }}
      >
        <div style={{ marginBottom: 20 }}>
          <Typography.Text>
            Etes vous sûr de supprimer cette image ? Les positions de sondes seront également
            supprimées. Cette action est irréversible.
          </Typography.Text>
        </div>
      </Modal>
      <Modal
        visible={isUploadModalVisible}
        title={"Ajout d'une image"}
        okText="Confirmer"
        cancelText="Annuler"
        onCancel={() => setIsUploadModalVisible(false)}
        onOk={
          imageUploader && fileName
            ? () => {
                uploadImage(fileToUpload as RcFile, imageUploader, fileName);
                setIsUploadModalVisible(false);
                setFileName("");
              }
            : undefined
        }
        forceRender={true}
        destroyOnClose={true}
        okButtonProps={{ disabled: imageDeleter === undefined }}
      >
        <div style={{ marginBottom: 20 }}>
          <Typography.Text>Quel nom souhaitez vous donner à cette image ?</Typography.Text>
          <Input
            placeholder="Nom de l'image"
            onChange={(e: any) => setFileName(e.target.value)}
            value={fileName}
          />
        </div>
      </Modal>
    </>
  );
};

export default InteractiveImageCarousel;
