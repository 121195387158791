import React from "react";
import { Site } from "src/lib/api";
import HeatingProductionUnitAlertsConfiguration from "./HeatingProductionUnitAlertsConfiguration";
import { Space, Typography } from "antd";

const AlertConfigurationTab: React.FC<{ site: Site }> = ({ site }) => {
  return (
    <Space direction="vertical">
      <Typography.Text>
        Configurez les alertes de votre choix dans cet onglet. Vous recevrez des alertes par mail
        lorsque les critères que vous aurez définis seront atteints. Vous pourrez consultez et
        acquitter vos alertes dans cet onglet.
      </Typography.Text>
      <HeatingProductionUnitAlertsConfiguration siteSlug={site.slug} />
    </Space>
  );
};

export default AlertConfigurationTab;
