import moment from "moment";
import { ChangeEvent, useState } from "react";
import {
  Area,
  AreaChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { dashboardColors } from "src/constants";
import { DailyDHH } from "src/lib/api";
import { consumptionOnly, getConsumptionPerfoData } from "src/lib/dashboard-utils";
import { DashboardNoData, RenderLabel } from "./DashboardComponents";
import { Button } from "antd";
import { CheckOutlined, CloseOutlined, UndoOutlined, EditOutlined } from "@ant-design/icons";

function dateFormatter(d: string) {
  return moment.unix(parseInt(d)).format("DD MMM");
}

export const DashboardConsumptionPerfo: React.FC<{
  consumptions: consumptionOnly[];
  hdd: DailyDHH[];
  meanHdw?: string;
  unitOfMeasure: string;
}> = ({ consumptions, hdd, meanHdw, unitOfMeasure }) => {
  const [meanHdwValue, setMeanHdwValue] = useState(meanHdw);
  const [tempMeanHdwValue, setTempMeanHdwValue] = useState(meanHdw);
  const [editing, setEditing] = useState(false);

  const hdw = meanHdw ? parseFloat(meanHdwValue as string) : 0;
  const data = getConsumptionPerfoData(
    consumptions
      .map((c) => ({
        ...c,
        consumption: meanHdw ? c.consumption - (isNaN(hdw) ? 0 : hdw) : c.consumption,
      }))
      .map((c) => ({ ...c, consumption: c.consumption < 0 ? 0 : c.consumption })),
    hdd,
  );

  if (data.length === 0) {
    return <DashboardNoData />;
  }

  const firstData = data[0];
  const lastData = data[data.length - 1];

  const totalConsumption = data.reduce((acc, current) => {
    acc += current.consumption;
    return acc;
  }, 0);
  const avgConsumption = totalConsumption / data.length;

  const handleMeanHdwChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTempMeanHdwValue(event.target.value);
  };

  const handleValidateMeanHdw = () => {
    setMeanHdwValue(tempMeanHdwValue);
    setEditing(false);
  };

  const handleCancelMeanHdw = () => {
    setTempMeanHdwValue(meanHdwValue);
    setEditing(false);
  };

  const handleResetMeanHdw = () => {
    setMeanHdwValue(meanHdw);
    setTempMeanHdwValue(meanHdw);
    setEditing(false);
  };

  return (
    <>
      {meanHdw !== undefined && (
        <div style={{ paddingLeft: 20, fontSize: 17, cursor: "pointer" }}>
          ECS estimé : 
          {!editing && (
            <Button type="link" onClick={() => setEditing(true)} icon={<EditOutlined />}>
              {meanHdwValue} {unitOfMeasure}
            </Button>
          )}
          {editing && (
            <>
              <input
                style={{
                  fontSize: 15,
                  textAlign: "center",
                  width: 70,
                  margin: 2,
                  borderBottom: "0.5px solid blue",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                }}
                value={tempMeanHdwValue}
                onChange={handleMeanHdwChange}
              />
              <Button
                type="default"
                onClick={handleValidateMeanHdw}
                icon={<CheckOutlined />}
                style={{
                  borderRadius: 7,
                  marginRight: 3,
                  borderColor: dashboardColors.green,
                  color: dashboardColors.green,
                }}
              />
              <Button
                type="default"
                onClick={handleCancelMeanHdw}
                icon={<CloseOutlined />}
                style={{
                  borderRadius: 7,
                  borderColor: dashboardColors.red,
                  color: dashboardColors.red,
                }}
              />
              <Button
                type="default"
                onClick={handleResetMeanHdw}
                icon={<UndoOutlined />}
                style={{
                  borderRadius: 7,
                  marginLeft: 3,
                  color: dashboardColors.blue,
                  borderColor: dashboardColors.blue,
                }}
              />
            </>
          )}
        </div>
      )}
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data} margin={{ top: 30, right: 25, left: 25, bottom: 5 }}>
          <Tooltip
            labelFormatter={dateFormatter}
            formatter={(value: number) => [value.toFixed(2), `${unitOfMeasure}/DJU`]}
          />
          <XAxis
            dataKey="timestamp"
            type="number"
            tickFormatter={dateFormatter}
            domain={[firstData.timestamp, lastData.timestamp]}
            tickCount={3}
          />
          <YAxis domain={[0, "auto"]} tickCount={1} yAxisId="left"></YAxis>
          <YAxis tickCount={2} yAxisId="right" orientation="right"></YAxis>

          <ReferenceLine
            opacity={0}
            yAxisId="left"
            segment={[
              { x: firstData.timestamp, y: lastData.consumption },
              { x: lastData.timestamp, y: lastData.consumption },
            ]}
          >
            <Label
              content={
                <RenderLabel
                  labelValue={`${lastData.consumption?.toFixed(2)}`}
                  labelTitle={"Instantannée"}
                  col={dashboardColors.blue}
                  positionX="right"
                />
              }
            />
          </ReferenceLine>

          <Area
            type="monotone"
            yAxisId="left"
            dataKey="consumption"
            fill={dashboardColors.lightblue}
            fillOpacity={0.9}
            stroke={dashboardColors.blue}
            dot={false}
            strokeWidth={1.5}
          />

          <ReferenceLine
            strokeDasharray="2 2"
            stroke={dashboardColors.grey}
            segment={[
              { x: firstData.timestamp, y: avgConsumption },
              { x: lastData.timestamp, y: avgConsumption },
            ]}
            yAxisId="left"
          >
            <Label
              content={
                <RenderLabel
                  labelValue={`${avgConsumption.toFixed(2)}`}
                  labelTitle={"Moyenne"}
                  col={dashboardColors.grey}
                  positionX="left"
                />
              }
            />
          </ReferenceLine>
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};
