import * as React from "react";

import { Col, Empty, Row } from "antd";
import moment from "moment";
import { Suspense } from "react";
import { Site } from "src/lib/api";
import ErrorBoundaryWithFallback from "../ErrorBoundaryWithFallback";
import { DashDiv } from "./DashDiv";
import { DashboardConsumptionBox } from "./DashboardConsumptionBox";
import { DashboardInstrumentInfos } from "./DashboardInstrumentsInfos";
import { DashboardTemperatureGR } from "./DashboardTemperatureGR";
import Loader from "../Loader";

const DashboardTab: React.FC<{ site: Site }> = ({ site }) => {
  const to = moment();
  const from = moment().subtract(2, "years"); // represent the past 25 months

  return (
    <div>
      <Row style={{ paddingRight: 10 }}>
        <Col span={18} style={{ padding: 5 }}>
          <Suspense fallback={<Loader tip="Chargement des températures" />}>
            <DashDiv style={{ height: "100%" }}>
              <ErrorBoundaryWithFallback fallback={<Empty />}>
                <DashboardTemperatureGR site={site} />
              </ErrorBoundaryWithFallback>
            </DashDiv>
          </Suspense>
        </Col>

        <Col span={6}>
          <Suspense fallback={<Loader tip="Chargement des sondes" />}>
            <ErrorBoundaryWithFallback fallback={<Empty />}>
              <DashboardInstrumentInfos site={site} />
            </ErrorBoundaryWithFallback>
          </Suspense>
        </Col>
      </Row>

      <Suspense fallback={<Loader tip="Chargement des consommations" />}>
        <ErrorBoundaryWithFallback fallback={<Empty />}>
          <DashboardConsumptionBox site={site} from={from} to={to} />
        </ErrorBoundaryWithFallback>
      </Suspense>
    </div>
  );
};

export default DashboardTab;
