import * as React from "react";
import { Button, Form, Input, InputNumber, Select, Space, Typography, DatePicker } from "antd";
import { Store } from "antd/lib/form/interface";
import { useUsers, useWeatherLocations } from "src/lib/hooks/api";
import { Site, SiteFormInput, UserManagementUser, WeatherLocation } from "src/lib/api";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import { hasPermission, Permissions } from "src/lib/access-control";
import { useAuth0 } from "@auth0/auth0-react";
import { colors, formItemLayout, formSection } from "src/constants";

const transmitterTypeOptions = [
  {
    key: "transmitter",
    value: "Radiateurs",
  },
  {
    key: "convertor",
    value: "Convecteurs",
  },
  {
    key: "groundHeating",
    value: "Planchers chauffant",
  },
];

const refConsoTypeOptions = [
  {
    key: "chauffage",
    value: "Chauffage",
  },
  {
    key: "ecs",
    value: "ECS",
  },
  {
    key: "others",
    value: "Autres",
  },
];

const energyTypesOptions = [
  {
    key: "reseauChaleur",
    value: "Réseau de chaleur",
  },
  {
    key: "gaz",
    value: "Gaz",
  },
  {
    key: "fioul",
    value: "Fioul",
  },
  {
    key: "wood",
    value: "Bois",
  },
  {
    key: "electricity",
    value: "Electricité",
  },
  {
    key: "autre",
    value: "Autre",
  },
];

const categoryOptions = [
  {
    key: "copro",
    value: "Copropriété",
  },
  {
    key: "residence",
    value: "Résidence",
  },
  {
    key: "school",
    value: "Ecole",
  },
  {
    key: "tertiaryBuilding",
    value: "Bâtiment tertiaire",
  },
  {
    key: "gymnase",
    value: "Gymnase",
  },
];

const controlerOptions = [
  {
    key: "distech",
    value: "Distech",
  },
  {
    key: "siemens",
    value: "Siemens",
  },
  {
    key: "trend",
    value: "TREND",
  },
  {
    key: "wit",
    value: "WIT",
  },
  {
    key: "regulProduction",
    value: "Régulateur intégré à la production",
  },
];

const surfaceTypeOptions = [
  {
    key: "shon",
    value: "SHON",
  },
  {
    key: "sre",
    value: "SRE",
  },
  {
    key: "heatedSurface",
    value: "Surface chauffée",
  },
  {
    key: "totalSurface",
    value: "Surface totale",
  },
  {
    key: "unknown",
    value: "Non précisée",
  },
];

const productionTypeOptions = [
  {
    key: "chaudiere",
    value: "Chaudière",
  },
  {
    key: "pac",
    value: "Pompe à chaleur",
  },
  {
    key: "echangeur",
    value: "Echangeur de chaleur",
  },
];

const AddressesForm: React.FC<{ disabled: boolean; form: FormInstance<any> }> = ({
  disabled,
  form,
}) => {
  const formStreets = form.getFieldValue(["metadata", "address", "streets"]);
  let formStreetKeys;
  if (formStreets === undefined || formStreets === null || formStreets.length === 0) {
    formStreetKeys = ["street1"];
  } else {
    formStreetKeys = Object?.keys(formStreets) ?? ["street1"];
  }
  const [streets, setStreets] = React.useState<string[]>(formStreetKeys);
  function addStreet() {
    const newStreets = [...streets];
    newStreets.push(`street${streets.length + 1}`);
    setStreets(newStreets);
  }

  function deleteStreet(name: string) {
    const newStreets = streets?.filter((s: string) => s !== name);
    setStreets(newStreets);
  }

  return (
    <>
      <Form.Item
        labelCol={{ span: 6 }}
        label={<Typography.Text strong>Adresse(s) du site</Typography.Text>}
        style={formSection}
      >
        {streets?.map((street: string, index: number) => {
          return (
            <>
              <Form.Item
                name={["metadata", "address", "streets", street]}
                label="Rue"
                rules={[{ required: true }]}
              >
                <Input placeholder={disabled ? "" : "13 avenue Emile Dubois"} disabled={disabled} />
              </Form.Item>
              {index > 0 && !disabled && (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Button
                    type="link"
                    onClick={() => deleteStreet(street)}
                    style={{ width: "30%", color: "red" }}
                    icon={<MinusCircleOutlined />}
                    disabled={disabled}
                  >
                    Supprimer cette entrée
                  </Button>
                </div>
              )}
            </>
          );
        })}
        {!disabled && (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Button
              type="dashed"
              onClick={() => addStreet()}
              style={{ width: "30%", marginBottom: 20 }}
              icon={<PlusOutlined />}
              disabled={disabled}
            >
              Ajouter une entrée
            </Button>
          </div>
        )}

        <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name={["metadata", "address", "postalCode"]}
            label="CodePostal"
            rules={[{ required: true }]}
          >
            <Input placeholder={disabled ? "" : "75014"} disabled={disabled} />
          </Form.Item>
          <Form.Item
            name={["metadata", "address", "city"]}
            label="Ville"
            rules={[{ required: true }]}
          >
            <Input placeholder={disabled ? "" : "Paris XIV"} disabled={disabled} />
          </Form.Item>
        </Space>
        <Form.Item
          name={["metadata", "address", "country"]}
          label="Pays"
          rules={[{ required: true }]}
        >
          <Input placeholder={disabled ? "" : "France"} disabled={disabled} />
        </Form.Item>
      </Form.Item>
    </>
  );
};

const CoordinatesForm: React.FC<{
  weatherLocations: WeatherLocation[];
  disabled: boolean;
}> = ({ weatherLocations, disabled }) => {
  return (
    <Form.Item
      name=""
      label={<Typography.Text strong>Coordonées du site</Typography.Text>}
      style={formSection}
    >
      <Form.Item name={["weatherLocationID"]} label="Zone météo" rules={[{ required: true }]}>
        <Select
          placeholder={disabled ? "" : "Choisir une zone météo"}
          allowClear
          disabled={disabled}
        >
          {weatherLocations.map((weatherLocation) => {
            return <Select.Option value={weatherLocation.id}>{weatherLocation.name}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Space>
        <Form.Item
          name={["coordinates", "latitude"]}
          label="Latitude (° décimaux)"
          rules={[{ required: true }]}
        >
          <InputNumber
            placeholder={disabled ? "" : "48.859504"}
            style={{ width: "100%" }}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item
          name={["coordinates", "longitude"]}
          label="Longitude (° décimaux)"
          rules={[{ required: true }]}
        >
          <InputNumber
            placeholder={disabled ? "" : "2.336836"}
            style={{ width: "100%" }}
            disabled={disabled}
          />
        </Form.Item>
      </Space>
    </Form.Item>
  );
};

const ConsoRefForm: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  return (
    <Form.Item
      name=""
      label={<Typography.Text strong>Consommation de référence</Typography.Text>}
      style={formSection}
    >
      <Form.Item
        name={["metadata", "refConsumption", "value"]}
        label="Conso de référence (NB en MWh)"
      >
        <InputNumber placeholder="Saisir un nombre" disabled={disabled} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name={["metadata", "refConsumption", "types"]} label="Usages de l'énergie:">
        <Select
          placeholder={disabled ? "" : "Choisir une option"}
          allowClear
          mode="multiple"
          disabled={disabled}
        >
          {refConsoTypeOptions.map((rcto) => {
            return <Select.Option value={rcto.key}>{rcto.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item name={["metadata", "refConsumption", "period"]} label="Période concernée">
        <Input placeholder={disabled ? "" : "2017-2019 ..."} disabled={disabled} />
      </Form.Item>
      <Form.Item name={["metadata", "refConsumption", "dju"]} label="DJU sur la période">
        <InputNumber placeholder="Saisir un nombre" disabled={disabled} style={{ width: "100%" }} />
      </Form.Item>
    </Form.Item>
  );
};

const SiteDescriptionForm: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  return (
    <Form.Item
      name=""
      label={<Typography.Text strong>Description du site</Typography.Text>}
      style={formSection}
    >
      <Form.Item name={["metadata", "siteDescription", "category"]} label="Catégorie">
        <Select
          placeholder={disabled ? "" : "Choisir une catégorie"}
          allowClear
          disabled={disabled}
        >
          {categoryOptions.map((tto) => {
            return <Select.Option value={tto.key}>{tto.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Space>
        <Form.Item name={["metadata", "siteDescription", "buildings"]} label="Nombre de bâtiments">
          <InputNumber
            placeholder="Saisir un nombre"
            disabled={disabled}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item name={["metadata", "siteDescription", "flats"]} label="Nombre de lots">
          <InputNumber
            placeholder="Saisir un nombre"
            disabled={disabled}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Space>
      <Form.Item name={["metadata", "siteDescription", "energyTypes"]} label="Type d'énergie">
        <Select
          placeholder={disabled ? "" : "Choisir une catégorie"}
          allowClear
          mode="multiple"
          disabled={disabled}
        >
          {energyTypesOptions.map((eto) => {
            return <Select.Option value={eto.key}>{eto.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item name="surfaceArea" label="Superficie totale (m²)">
        <InputNumber min={0} placeholder={"1400"} style={{ width: "100%" }} disabled={disabled} />
      </Form.Item>
      <Form.Item name={["metadata", "siteDescription", "surfaceType"]} label="Type de surface">
        <Select
          placeholder={disabled ? "" : "Choisir un type de surface"}
          allowClear
          disabled={disabled}
        >
          {surfaceTypeOptions.map((sto) => {
            return <Select.Option value={sto.key}>{sto.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
    </Form.Item>
  );
};

const ClientForm: React.FC<{ disabled: boolean; siteUsers: UserManagementUser[] }> = ({
  disabled,
  siteUsers,
}) => {
  return (
    <Form.Item
      name=""
      label={<Typography.Text strong>Informations client</Typography.Text>}
      style={formSection}
    >
      <Form.Item name={["metadata", "clientDescription", "clientName"]} label="Nom du client">
        <Input placeholder={disabled ? "" : "RCU, ..."} disabled={disabled} />
      </Form.Item>
      <Form.Item name={["metadata", "clientDescription", "syndic"]} label="Syndicat gérance">
        <Input placeholder={disabled ? "" : "LOISELET& DAIGREMONT..."} disabled={disabled} />
      </Form.Item>
      <Form.Item name={["metadata", "clientDescription", "manager"]} label="Gestionnaire">
        <Input placeholder={disabled ? "" : "Thomas lemont..."} disabled={disabled} />
      </Form.Item>
      <Form.Item name={["heatingReferentUserId"]} label="Référent chauffage">
        <Select
          disabled={disabled}
          placeholder="Choisir un référent pour le chauffage"
          notFoundContent={
            <Typography.Text style={{ fontStyle: "italic", color: colors.blue.dark }}>
              Aucun utilisateur n'existe pour ce site car il n'a pas encore été créé. Jean-Baptiste
              sera nommé responsable chauffage par défaut. Une fois les premiers utilisateurs créés
              vous pourrez venir modifier le responsable chauffage dans l'onglet Configuration du
              Site.
            </Typography.Text>
          }
        >
          {siteUsers.map((u: UserManagementUser) => {
            return <Select.Option value={u.id}>{u.name}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name={["metadata", "clientDescription", "creation"]}
        label="Date de création du client"
      >
        <DatePicker style={{ width: "100%" }} disabled={disabled} />
      </Form.Item>
      <Form.Item
        name={["metadata", "clientDescription", "expiration"]}
        label="Date d'expiration du client"
      >
        <DatePicker style={{ width: "100%" }} disabled={disabled} />
      </Form.Item>
    </Form.Item>
  );
};

const HeatingInstallationForm: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  return (
    <Form.Item
      name=""
      label={<Typography.Text strong>Installations de chauffage</Typography.Text>}
      style={formSection}
    >
      <Form.Item
        name={["metadata", "heatingInstallationDescription", "productionUnitNumber"]}
        label="Nombre d'unités de production"
      >
        <InputNumber placeholder="Saisir un nombre" disabled={disabled} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name={["metadata", "heatingInstallationDescription", "productionUnitTypes"]}
        label="Type(s) de production"
      >
        <Select
          placeholder={disabled ? "" : "Choisir un ou plusieurs types de production"}
          allowClear
          mode="multiple"
          disabled={disabled}
        >
          {productionTypeOptions.map((pto) => {
            return <Select.Option value={pto.key}>{pto.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name={["metadata", "heatingInstallationDescription", "transmitterTypes"]}
        label="Types d'émetteurs"
      >
        <Select
          placeholder={disabled ? "" : "Choisir un ou plusieurs types d'émetteurs"}
          allowClear
          mode="multiple"
          disabled={disabled}
        >
          {transmitterTypeOptions.map((tto) => {
            return <Select.Option value={tto.key}>{tto.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name={["metadata", "heatingInstallationDescription", "circuits"]}
        label="Nombre et noms des circuits"
      >
        <Input
          placeholder={disabled ? "" : "2 circuits régulés : SC3 et SC4"}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        name={["metadata", "heatingInstallationDescription", "controllers"]}
        label="Modèle de régulateur/automate"
      >
        <Select
          placeholder={disabled ? "" : "Choisir un ou plusieurs types d'automates"}
          allowClear
          mode="multiple"
          disabled={disabled}
        >
          {controlerOptions.map((tto) => {
            return <Select.Option value={tto.key}>{tto.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
    </Form.Item>
  );
};

function formatStreets(streets?: string[]) {
  const initialValue = {};
  return streets?.reduce((val, item, index) => {
    return {
      ...val,
      [`street${index + 1}`]: item,
    };
  }, initialValue);
}

export const SiteForm: React.FC<{
  onValidateForm: (site: SiteFormInput) => void;
  mode: "creation" | "edition";
  isLoading: boolean;
  initialValues?: Site;
}> = ({ onValidateForm, mode, initialValues, isLoading }) => {
  const [form] = Form.useForm();
  const { data: users } = useUsers();
  const [paramMode, setParamMode] = React.useState<"edit" | "consult">("consult");
  const { data: weatherLocations } = useWeatherLocations();
  const { user } = useAuth0();

  const siteId = initialValues?.id;

  const siteUsers = [] as UserManagementUser[];

  const defaultReferent = users.find(
    (u: UserManagementUser) => u.id === "auth0|5e269dfe21830d0f1b685c21",
  );

  if (siteId) {
    siteUsers.push(
      ...users.filter(
        (u: UserManagementUser) =>
          u.authorizedSites.includes(siteId) || u.authorizedSites.includes(-1),
      ),
    );
  }

  if (defaultReferent && !siteUsers.some((u: UserManagementUser) => u.id === defaultReferent?.id)) {
    siteUsers.push(defaultReferent);
  }

  const onFinish = async (values: Store) => {
    const formStreets = values.metadata.address.streets;
    const streets: string[] = Object.keys(formStreets).map((k: string) => formStreets[k]);

    const site: SiteFormInput = {
      name: values.name,
      trigram: values.trigram,
      weatherLocationID: values.weatherLocationID,
      surfaceArea: values.surfaceArea ?? 0,
      comments: values.comments ?? "",
      coordinates: {
        longitude: values?.coordinates?.longitude ?? 0,
        latitude: values?.coordinates?.latitude ?? 0,
      },
      metadata: {
        ...values.metadata,
        address: {
          ...values.metadata.address,
          streets: streets,
        },
      },
      heatingReferentUserId: values.heatingReferentUserId,
    };

    onValidateForm(site);
    setParamMode("consult");
  };

  return (
    <Form
      name="site"
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      initialValues={{
        ...initialValues,
        weatherLocationID:
          initialValues?.weatherLocationID === 0 ? undefined : initialValues?.weatherLocationID,
        metadata: {
          ...initialValues?.metadata,
          address: {
            ...initialValues?.metadata?.address,
            streets: formatStreets(initialValues?.metadata?.address.streets),
          },
          clientDescription: {
            ...initialValues?.metadata?.clientDescription,
            creation: initialValues?.metadata?.clientDescription.creation
              ? moment(new Date(initialValues?.metadata?.clientDescription.creation), "YYYY-MM-DD")
              : moment(new Date()),
            expiration: initialValues?.metadata?.clientDescription.expiration
              ? moment(
                  new Date(initialValues?.metadata?.clientDescription.expiration),
                  "YYYY-MM-DD",
                )
              : moment(new Date()),
          },
          heatingReferentUserId: initialValues?.heatingReferentUser?.id,
        },
      }}
    >
      <Form.Item name="name" label="Nom du site" rules={[{ required: true }]}>
        <Input
          placeholder={"75014 SdC Le Méridien"}
          disabled={paramMode === "consult" && mode === "edition"}
        />
      </Form.Item>
      <Form.Item
        name="trigram"
        label="Trigramme"
        rules={[
          {
            required: true,
            message: "Veuillez entrer le champ!",
          },
          {
            pattern: /^[A-Z0-9]{3}$/,
            message: "Le trigramme doit contenir 3 lettres majuscules ou chiffres uniquement.",
          },
        ]}
      >
        <Input placeholder={"MDP"} disabled={paramMode === "consult" && mode === "edition"} />
      </Form.Item>
      <AddressesForm disabled={paramMode === "consult" && mode === "edition"} form={form} />
      <SiteDescriptionForm disabled={paramMode === "consult" && mode === "edition"} />
      <ConsoRefForm disabled={paramMode === "consult" && mode === "edition"} />
      <HeatingInstallationForm disabled={paramMode === "consult" && mode === "edition"} />
      <CoordinatesForm
        weatherLocations={weatherLocations}
        disabled={paramMode === "consult" && mode === "edition"}
      />
      <ClientForm disabled={paramMode === "consult" && mode === "edition"} siteUsers={siteUsers} />
      <Form.Item name="comments" label="Commentaires">
        <Input
          placeholder={"Rajouter un commentaire à propos du site"}
          disabled={paramMode === "consult" && mode === "edition"}
        />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 6,
        }}
      >
        <div style={{ justifyContent: "center", display: "flex" }}>
          {mode === "creation" && (
            <Button type="primary" htmlType="submit">
              Créer le site
            </Button>
          )}
          {mode === "edition" && (
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Button
                type="primary"
                loading={isLoading}
                onClick={paramMode === "edit" ? () => form.submit() : () => setParamMode("edit")}
                disabled={!hasPermission(user, Permissions.SitesUpdate)}
              >
                {paramMode === "edit" ? "Enregistrer" : "Modifier"}
              </Button>
              {paramMode === "edit" && (
                <Button
                  type="ghost"
                  style={{ color: "red", marginLeft: 10 }}
                  onClick={() => {
                    setParamMode("consult");
                    form.resetFields();
                  }}
                >
                  Abandonner
                </Button>
              )}
            </div>
          )}
        </div>
      </Form.Item>
    </Form>
  );
};
