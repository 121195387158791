import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Collapse, Divider, Input, Select, Space, Table, Typography } from "antd";
import { ColumnType } from "antd/lib/table";
import * as React from "react";
import { colors } from "src/constants";
import { Permissions, hasPermission } from "src/lib/access-control";
import type { PLC, ProductionDataPoint, ProductionDataPointCategory } from "src/lib/api";
import {
  getDataPointValue,
  getPlcOptions,
  isDataPointModified,
  updateDataPointPlcAddress,
  updateDataPointPlcId,
} from "src/lib/data-points";
import {
  useDeleteProductionDataPoint,
  useProductionDataPoints,
  useSaveProductionDataPoint,
  useUpdateProductionDataPoint,
} from "src/lib/hooks/api";
import { withNotify } from "src/lib/notify";
import { showIf, sorter } from "src/lib/tableutils";

const productionDataPointsOptions: {
  value: string;
  key: ProductionDataPointCategory;
}[] = [
  {
    value: "Température départ production",
    key: "water_flow_temperature",
  },
  {
    value: "Température consigne production",
    key: "water_flow_set_point_temperature",
  },
  {
    value: "Température retour circuit",
    key: "water_return_temperature",
  },
];

const defaultConfigurations = [
  {
    value: "Aucune",
    key: "aucune",
  },
  {
    value: "Production OZW",
    key: "ozw_production",
  },
];

const ProductionDataPointConfigurationTable: React.FC<{
  siteSlug: string;
  hpuId: number;
  sitePlcs: PLC[];
}> = ({ siteSlug, hpuId, sitePlcs }) => {
  const { user } = useAuth0();
  // limit of this architecture is that when invalidate query all data points are
  // refreshed. An alternative would be to treat each data point individually,
  // but it isn ot worth implemting at this stage.
  const { data: pdps } = useProductionDataPoints(siteSlug, hpuId);

  const { mutateAsync: saveProductionDataPoint } = useSaveProductionDataPoint(siteSlug, hpuId);
  const { mutateAsync: updateProductionDataPoint } = useUpdateProductionDataPoint(siteSlug, hpuId);
  const { mutateAsync: deleteProductionDataPoint } = useDeleteProductionDataPoint(siteSlug, hpuId);
  const [productionDataPoints, setProductionDataPoints] = React.useState<ProductionDataPoint[]>([]);

  const emptyProductionDataPoint: ProductionDataPoint = React.useMemo(
    () => ({
      id: 0,
      category: "",
      productionUnitId: hpuId,
      plcId: null,
      plcAddress: null,
    }),
    [hpuId],
  );

  React.useEffect(() => {
    // Building a table with all production data point, either empty, either filled with data if they exist
    setProductionDataPoints(
      productionDataPointsOptions.map((o: { value: string; key: ProductionDataPointCategory }) =>
        getDataPointValue(o.key, pdps, emptyProductionDataPoint),
      ) as ProductionDataPoint[],
    );
  }, [pdps, emptyProductionDataPoint]);

  function updateProductionDataPointPlcId(plcId: number | null, dataPoint: ProductionDataPoint) {
    const newProductionDataPoints = updateDataPointPlcId(
      plcId,
      dataPoint,
      productionDataPoints,
    ) as ProductionDataPoint[];
    setProductionDataPoints(newProductionDataPoints);
  }

  function updateProductionDataPointPlcAddress(
    address: string | null,
    dataPoint: ProductionDataPoint,
  ) {
    const newProductionDataPoints = updateDataPointPlcAddress(
      address,
      dataPoint,
      productionDataPoints,
    ) as ProductionDataPoint[];
    setProductionDataPoints(newProductionDataPoints);
  }

  function onApplyConfiguration(key: string, pdp: ProductionDataPoint) {
    switch (key) {
      case "ozw_production":
        const plcId = sitePlcs.find((plc: PLC) => plc.supplierId === 3)?.id ?? null;
        updateProductionDataPointPlcId(plcId, pdp);
        switch (pdp.category) {
          case "water_flow_temperature":
            updateProductionDataPointPlcAddress("SN:0x20fe1001:0", pdp);
            break;
          case "water_flow_set_point_temperature":
            updateProductionDataPointPlcAddress("SN:0x3f701001:0", pdp);
            break;
          case "water_return_temperature":
            updateProductionDataPointPlcAddress("SN:0x29fe1001:0", pdp);
            break;
          default:
            break;
        }
        break;
      default:
        updateProductionDataPointPlcId(null, pdp);
        updateProductionDataPointPlcAddress(null, pdp);
        break;
    }
  }

  const columns: ColumnType<ProductionDataPoint>[] = [
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: 30,
      render: (id: number) => {
        return id === 0 ? (
          <CloseCircleTwoTone twoToneColor={colors.red.dark} style={{ fontSize: 20 }} />
        ) : (
          <CheckCircleTwoTone twoToneColor={colors.green.dark} style={{ fontSize: 20 }} />
        );
      },
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
      width: 200,
      sorter: sorter("category", "string"),
      render: (category: string) => {
        const relevantCategory = productionDataPointsOptions.find(
          (opt: any) => opt.key === category,
        );
        return relevantCategory?.value ?? "";
      },
    },
    ...showIf<ProductionDataPoint>(
      () => hasPermission(user, Permissions.HeatingInstallationsUpdate),
      {
        title: "Conf de référence",
        key: "refConf",
        responsive: ["lg" as const],
        width: 180,
        render: (record: ProductionDataPoint) => (
          <Select
            style={{ width: 180 }}
            placeholder="Choisir une configuration"
            onSelect={(value: string) => onApplyConfiguration(value, record)}
          >
            {defaultConfigurations.map((dc) => {
              return <Select.Option value={dc.key}>{dc.value}</Select.Option>;
            })}
          </Select>
        ),
      },
    ),
    {
      title: "Automate",
      dataIndex: "plcId",
      key: "plcId",
      width: 180,
      render: (plcId: number, pdp: ProductionDataPoint) => {
        return (
          <Select
            placeholder={"Choisir un automate"}
            style={{ width: 170 }}
            value={plcId}
            onSelect={(value: number) => updateProductionDataPointPlcId(value, pdp)}
          >
            {getPlcOptions(sitePlcs).map((po) => {
              return <Select.Option value={po.key}>{po.value}</Select.Option>;
            })}
          </Select>
        );
      },
    },
    {
      title: "Adresse du registre",
      dataIndex: "plcAddress",
      key: "plcAddress",
      width: 200,
      render: (plcAddress: string, pdp: ProductionDataPoint) => {
        return (
          <Input
            placeholder="AV101, ..."
            value={plcAddress}
            onChange={(event: any) => updateProductionDataPointPlcAddress(event.target.value, pdp)}
          />
        );
      },
    },
    ...showIf<ProductionDataPoint>(
      () => hasPermission(user, Permissions.HeatingInstallationsUpdate),
      {
        title: "Edition",
        key: "edition",
        responsive: ["lg" as const],
        width: 120,
        render: (record: ProductionDataPoint) => (
          <Space>
            <Button
              type="link"
              disabled={
                !hasPermission(user, Permissions.HeatingInstallationsUpdate) ||
                !isDataPointModified(record, pdps, emptyProductionDataPoint)
              }
              icon={<SaveOutlined style={{ fontSize: 20 }} />}
              onClick={async () => {
                if (record.id === 0) {
                  await withNotify(
                    saveProductionDataPoint(record),
                    "Le point de donnée a été créé avec succès",
                    "Une erreur est survenue lors de la création du point de donnée.",
                  );
                } else {
                  await withNotify(
                    updateProductionDataPoint(record),
                    "Le point de donnée a été mis à jour avec succès",
                    "Une erreur est survenue lors de la mise à jour du point de donnée.",
                  );
                }
              }}
            />
            <Button
              type="link"
              danger
              disabled={
                !hasPermission(user, Permissions.HeatingInstallationsUpdate) || record.id === 0
              }
              icon={<DeleteOutlined style={{ fontSize: 20 }} />}
              onClick={async () => {
                if (record.id !== 0) {
                  await withNotify(
                    deleteProductionDataPoint(record.id),
                    "Le point de donnée a été supprimé avec succès",
                    "Une erreur est survenue lors de la suppression du point de donnée.",
                  );
                }
              }}
            />
          </Space>
        ),
      },
    ),
  ];

  return (
    <Collapse>
      <Collapse.Panel header="Points de donnée" key="1">
        {hpuId === 0 ? (
          <Typography.Text>
            Pour configurer les points de données vous devez enregistrer l'unité de production une
            première fois
          </Typography.Text>
        ) : (
          <>
            <Table
              dataSource={productionDataPoints}
              columns={columns}
              scroll={{ y: 240 }}
              pagination={false}
              size="small"
            />
            <Divider></Divider>
            <Typography.Text style={{ fontStyle: "italic", color: colors["blue"].dark }}>
              L'enregistrement des points de donnée se fait point par point. Lorsque vous avec
              renseigné un point de donnée enregistrez le avant de passer au suivant.
            </Typography.Text>
          </>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default ProductionDataPointConfigurationTable;
