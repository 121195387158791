import { Empty } from "antd";
import moment from "moment";
import { Suspense } from "react";
import { ComfortIndicators } from "src/components/ComfortIndicator";
import ErrorBoundaryWithFallback from "src/components/ErrorBoundaryWithFallback";
import Loader from "src/components/Loader";

export const ComfortIndicatorsPage: React.FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundaryWithFallback fallback={<Empty />}>
        <ComfortIndicators
          initialFrom={moment().subtract(1, "month").startOf("day").unix()}
          initialTo={moment().unix()}
        />
      </ErrorBoundaryWithFallback>
    </Suspense>
  );
};
