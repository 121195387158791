import * as React from "react";

// Error boundaries currently have to be classes.
class ErrorBoundaryWithFallback extends React.Component<{ fallback: React.ReactNode }> {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    };
  }
  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default ErrorBoundaryWithFallback;
