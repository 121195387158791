export function round(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function min(xs: number[]): number {
  return xs.reduce((acc, curr) => (acc < curr ? acc : curr), xs[0] ?? NaN);
}

export function max(xs: number[]): number {
  return xs.reduce((acc, curr) => (acc > curr ? acc : curr), xs[0] ?? NaN);
}

export function sum(xs: number[]): number {
  return xs.reduce((acc, curr) => acc + curr, xs.length > 0 ? 0 : NaN);
}

export function avg(xs: number[]): number {
  return sum(xs) / xs.length;
}

export function generatePassword(length: number): string {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }

  return retVal;
}

export function findLineByLeastSquares(xs: number[], ys: number[]) {
  var sum_x = 0;
  var sum_y = 0;
  var sum_xy = 0;
  var sum_xx = 0;
  var count = 0;

  /*
   * We'll use those variables for faster read/write access.
   */
  var x = 0;
  var y = 0;
  var values_length = xs.length;

  if (values_length !== ys.length) {
    // throw new Error('The parameters values_x and values_y need to have same size!');
    return { m: 0, b: 0 };
  }

  /*
   * Nothing to do.
   */
  if (values_length === 0) {
    return { m: 0, b: 0 };
  }

  /*
   * Calculate the sum for each of the parts necessary.
   */
  for (var v = 0; v < values_length; v++) {
    x = xs[v];
    y = ys[v];
    sum_x += x;
    sum_y += y;
    sum_xx += x * x;
    sum_xy += x * y;
    count++;
  }

  /*
   * Calculate m and b for the formular:
   * y = x * m + b
   */
  var m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x);
  var b = sum_y / count - (m * sum_x) / count;

  return { m, b };

  // /*
  //  * We will make the x and y result line now
  //  */
  // var result_values_x = [];
  // var result_values_y = [];

  // for (var v = 0; v < values_length; v++) {
  //     x = values_x[v];
  //     y = x * m + b;
  //     result_values_x.push(x);
  //     result_values_y.push(y);
  // }

  // return [result_values_x, result_values_y];
}
