import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ReloadOutlined,
  StockOutlined,
} from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Card, Space, Typography } from "antd";
import { useState } from "react";
import { colors } from "src/constants";
import {
  queryKeySiteHeatingInstallationCircuitPredictiveSystem,
  queryKeySiteHeatingInstallationV3VRegulation,
  queryKeySiteHeatingInstallationWatchdogState,
  useSiteHeatingDistributionCircuitV3VRegulation,
  useSiteHeatingDistributionCircuitWatchdogState,
  useSiteHeatingInstallationCircuitPredictiveSystem,
} from "src/lib/hooks/api";
import ErrorBoundaryWithFallback from "./ErrorBoundaryWithFallback";

function IconFromState(value: boolean | null) {
  switch (value) {
    case true:
      return <CheckCircleTwoTone twoToneColor={colors.green.dark} style={{ fontSize: 20 }} />;
    case false:
      return <CloseCircleTwoTone twoToneColor={colors.red.dark} style={{ fontSize: 20 }} />;
    default:
      return <CloseCircleTwoTone twoToneColor={"#8A8A8A"} style={{ fontSize: 20 }} />;
  }
}

const CommandDisplay: React.FC<{
  icon: React.ReactElement;
  title: string;
  description?: string;
}> = ({ icon, title, description }) => (
  <div style={{ marginBottom: "0.5rem", alignItems: "center", display: "flex" }}>
    {icon}
    <Typography.Text style={{ marginLeft: 5 }} strong>
      {title}
    </Typography.Text>
    {description && <Typography.Text style={{ marginLeft: 5 }}>({description})</Typography.Text>}
  </div>
);

const V3VRegulationStatus: React.FC<{
  slug: string;
  heatingDistributionCircuitId: number;
}> = ({ slug, heatingDistributionCircuitId }) => {
  const { data: v3vRegulation } = useSiteHeatingDistributionCircuitV3VRegulation(
    slug,
    heatingDistributionCircuitId.toString(),
  );

  return <CommandDisplay icon={IconFromState(v3vRegulation)} title={"Régulation de la V3V"} />;
};

const CommandControls: React.FC<{
  slug: string;
  heatingDistributionCircuitId: number;
}> = ({ slug, heatingDistributionCircuitId }) => {
  const queryClient = useQueryClient();

  const { data: predictiveSystem } = useSiteHeatingInstallationCircuitPredictiveSystem(
    slug,
    heatingDistributionCircuitId.toString(),
  );

  const { data: watchdogState } = useSiteHeatingDistributionCircuitWatchdogState(
    slug,
    heatingDistributionCircuitId.toString(),
  );

  const [loading, setLoading] = useState(false);

  const onRefresh = async () => {
    setLoading(true);
    await queryClient.invalidateQueries(queryKeySiteHeatingInstallationWatchdogState(slug));
    await queryClient.invalidateQueries(queryKeySiteHeatingInstallationV3VRegulation(slug));
    await queryClient.invalidateQueries(
      queryKeySiteHeatingInstallationCircuitPredictiveSystem(
        slug,
        heatingDistributionCircuitId.toString(),
      ),
    );
    setLoading(false);
  };

  return (
    <div style={{ marginBottom: "1.5rem" }}>
      <Card
        title={
          <Space direction="horizontal">
            <StockOutlined />
            Contrôle commande Efficap
          </Space>
        }
        style={{ borderRadius: 10 }}
        extra={<Button icon={<ReloadOutlined />} onClick={onRefresh} />}
        loading={loading}
      >
        <div style={{ marginBottom: "0.5rem", alignItems: "center", display: "flex" }}>
          <CommandDisplay
            icon={IconFromState(predictiveSystem.localState === "on")}
            title={"Switch local"}
          />
        </div>
        <div style={{ marginBottom: "0.5rem", alignItems: "center", display: "flex" }}>
          <CommandDisplay
            icon={IconFromState(predictiveSystem.remoteState === "on")}
            title={"Switch remote"}
          />
        </div>
        <div style={{ marginBottom: "0.5rem", alignItems: "center", display: "flex" }}>
          <CommandDisplay icon={IconFromState(watchdogState.state === "off")} title={"Watchdog"} />
        </div>
        <ErrorBoundaryWithFallback
          fallback={
            <CommandDisplay
              icon={IconFromState(null)}
              title={"Régulation de la V3V"}
              description={"non renseigné"}
            />
          }
        >
          <V3VRegulationStatus
            slug={slug}
            heatingDistributionCircuitId={heatingDistributionCircuitId}
          />
        </ErrorBoundaryWithFallback>
      </Card>
    </div>
  );
};

export default CommandControls;
