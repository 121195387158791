import { AuditOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  Modal,
  Radio,
  Select,
  Typography,
} from "antd";
import moment, { Moment } from "moment";
import * as React from "react";
import { colors } from "src/constants";
import {
  EnergyMeter,
  HeatingPeriod,
  HeatingSeason,
  InstrumentGroup,
  ReportFormData,
  SiteAPIService,
} from "src/lib/api";
import { defaultHeatingPeriod, heatingPeriodOptions } from "src/lib/heating-period";
import {
  useSiteEnergyMeters,
  useSiteHeatingSeasons,
  useSiteReferenceInstrumentGroups,
} from "src/lib/hooks/api";
import { withNotify } from "src/lib/notify";
import RenderIf from "./RenderIf";

const ReportGenerationForm: React.FC<{
  form: FormInstance<any>;
  slug: string;
}> = ({ form, slug }) => {
  const { data: groups } = useSiteReferenceInstrumentGroups(slug);
  const { data: rawEnergyMeters } = useSiteEnergyMeters(slug);
  const heatingPeriods = heatingPeriodOptions();
  const [manualStartDate, setManualStartDate] = React.useState<Boolean>(false);
  const [manualEndDate, setManualEndDate] = React.useState<Boolean>(false);
  const { data: heatingSeasons } = useSiteHeatingSeasons(slug);

  const getCurrentSeason = () => {
    return heatingSeasons?.find((hs: HeatingSeason) => {
      return moment().isBetween(moment(hs.startTime), moment(hs.endTime));
    });
  };

  const [selectedSeason, setSelectedSeason] = React.useState<
    HeatingSeason | HeatingPeriod | undefined
  >(getCurrentSeason());
  const [referencePeriod, setReferencePeriod] = React.useState<
    HeatingSeason | HeatingPeriod | undefined
  >(selectedSeason);

  const energyMeters = React.useMemo(
    () => rawEnergyMeters.filter((em: EnergyMeter) => em.category === "reference"),
    [rawEnergyMeters],
  );

  const getEndDate = (endDateStr: string | undefined) => {
    const endDate = moment(endDateStr);
    if (endDate.isSameOrAfter(moment())) {
      return moment();
    }
    return endDate;
  };

  const [startDateRef, setStartDateRef] = React.useState<Moment | undefined>(undefined);
  const [endDateRef, setEndDateRef] = React.useState<Moment | undefined>(undefined);

  const [startDate, setStartDate] = React.useState<Moment | undefined>(
    moment(selectedSeason?.startTime),
  );
  const [endDate, setEndDate] = React.useState<Moment | undefined>(
    getEndDate(selectedSeason?.endTime),
  );
  const [deltaYear, setDeltaYear] = React.useState<number>(0);

  React.useEffect(() => {
    const startDateRef = manualStartDate
      ? moment(startDate).subtract(deltaYear, "year")
      : referencePeriod
        ? moment(referencePeriod?.startTime)
        : undefined;
    const endDateRef = manualEndDate
      ? moment(endDate).subtract(deltaYear, "year")
      : referencePeriod
        ? moment(referencePeriod?.endTime)
        : undefined;

    setStartDateRef(startDateRef);
    setEndDateRef(endDateRef);
    form.setFieldsValue({ startDateRef: startDateRef, endDateRef: endDateRef });
  }, [manualStartDate, manualEndDate, referencePeriod, startDate, endDate, deltaYear, form]);

  const onChangeReferencePeriod = (e: any, season: HeatingSeason | HeatingPeriod | undefined) => {
    setDeltaYear(e.target.value);

    if (e.target.value === 0) {
      setReferencePeriod(undefined);
      return;
    }

    const y = season?.key.split("-");
    if (!y) {
      return;
    }

    const y1 = parseInt(y[0]) - e.target.value;
    const y2 = parseInt(y[1]) - e.target.value;
    const newKey = `${y1}-${y2}`;

    const newHs = heatingSeasons?.find((hs: HeatingSeason) => hs.key === newKey);

    setReferencePeriod(newHs ?? defaultHeatingPeriod(newKey));
  };

  const onSelectSeason = (e: any) => {
    const newHs =
      heatingSeasons?.find((hs: HeatingSeason) => hs.key === e) ?? defaultHeatingPeriod(e);
    setSelectedSeason(newHs);

    setStartDate(moment(newHs.startTime));
    setEndDate(getEndDate(newHs.endTime));
    form.setFieldsValue({ startDate: moment(newHs.startTime), endDate: getEndDate(newHs.endTime) });
    onChangeReferencePeriod({ target: { value: deltaYear } }, newHs);
  };

  return (
    <Form layout="horizontal" form={form} name="report-generator-form">
      <Typography.Text italic style={{ color: colors.blue.dark }}>
        Selectionnez les dates pour définir la période de génération des rapports.
      </Typography.Text>

      <Form.Item
        label="Saison à analyser"
        name="targetPeriodSeason"
        initialValue={getCurrentSeason()?.key}
        style={{ marginBottom: 10 }}
      >
        <Select onChange={onSelectSeason}>
          {heatingPeriods.map((hp: string) => (
            <Select.Option key={hp} value={hp}>
              Saison {hp}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
        <Checkbox
          onChange={(e) => {
            setManualStartDate(e.target.checked);
            setStartDate(moment(selectedSeason?.startTime));
            form.setFieldsValue({ startDate: moment(selectedSeason?.startTime) });
          }}
        >
          Date de début manuelle :
        </Checkbox>
        <Form.Item
          name="startDate"
          initialValue={moment(selectedSeason?.startTime)}
          style={{ marginBottom: 0 }}
        >
          <DatePicker
            onChange={(e) => {
              if (e !== null) {
                setStartDate(e);
              }
            }}
            disabled={!manualStartDate}
            disabledDate={(current: Moment | null) =>
              current ? current.isAfter(moment(endDate)) : true
            }
            format={"DD/MM/YYYY"}
          />
        </Form.Item>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          onChange={(e) => {
            setManualEndDate(e.target.checked);
            const endDate = getEndDate(selectedSeason?.endTime);
            setEndDate(endDate);
            form.setFieldsValue({ endDate: endDate });
          }}
        >
          Date de fin manuelle :
        </Checkbox>
        <Form.Item
          name="endDate"
          initialValue={getEndDate(selectedSeason?.endTime)}
          style={{ marginBottom: 0 }}
        >
          <DatePicker
            onChange={(e) => {
              if (e !== null) {
                setEndDate(e);
              }
            }}
            disabled={!manualEndDate}
            disabledDate={(current: Moment | null) =>
              current ? current.isBefore(moment(startDate)) || current.isAfter(moment()) : true
            }
            format={"DD/MM/YYYY"}
          />
        </Form.Item>
      </div>

      <Form.Item
        label="Période à comparer"
        name="deltaYear"
        initialValue={0}
        style={{ marginTop: 10, marginBottom: 5 }}
      >
        <Radio.Group
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
          onChange={(e) => onChangeReferencePeriod(e, selectedSeason)}
        >
          <Radio.Button value={0}>Aucune</Radio.Button>
          <Radio.Button value={1}>Année N-1</Radio.Button>
          <Radio.Button value={2}>Année N-2</Radio.Button>
          <Radio.Button value={3}>Année N-3</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item name="startDateRef" hidden={true} />
      <Form.Item name="endDateRef" hidden={true} />

      <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <Typography.Text italic style={{ color: colors.green.dark }}>
          {`Période à analyser : ${moment(startDate).format("DD/MM/YYYY")} - ${moment(
            endDate,
          ).format("DD/MM/YYYY")}`}
        </Typography.Text>

        <RenderIf predicate={deltaYear > 0}>
          <Typography.Text italic style={{ color: colors.green.dark }}>
            {`Période à comparer : ${startDateRef?.format("DD/MM/YYYY")} - ${endDateRef?.format(
              "DD/MM/YYYY",
            )}`}
          </Typography.Text>
        </RenderIf>
      </div>

      <Divider />

      <Typography.Text italic style={{ color: colors.blue.dark }}>
        Sélectionnez les groupes d'instruments que vous souhaitez inclure dans le rapport parmis les
        groupes de référence.
      </Typography.Text>

      <RenderIf predicate={groups.length > 0}>
        <Form.Item name="selectedGroupIds" initialValue={groups.map((g: InstrumentGroup) => g.id)}>
          <Checkbox.Group>
            {groups.map((g: InstrumentGroup) => (
              <div key={g.id}>
                <Checkbox value={g.id}>{g.name}</Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </RenderIf>

      <RenderIf predicate={groups.length === 0}>
        <br />
        <Typography.Text type="danger">
          Aucun groupe de référence n'est disponible pour ce site.
        </Typography.Text>
      </RenderIf>

      <Divider />

      <Typography.Text italic style={{ color: colors.blue.dark }}>
        Sélectionnez les compteurs d'énergie que vous souhaitez inclure dans le rapport parmis les
        compteurs de référence.
      </Typography.Text>

      <RenderIf predicate={energyMeters.length > 0}>
        <Form.Item
          name="selectedEnergyMeterIds"
          initialValue={energyMeters.map((em: EnergyMeter) => em.id)}
        >
          <Checkbox.Group>
            {energyMeters.map((em: EnergyMeter) => (
              <div key={em.id}>
                <Checkbox value={em.id}>{em.name}</Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </RenderIf>

      <RenderIf predicate={energyMeters.length === 0}>
        <br />
        <Typography.Text type="danger">
          Aucun compteur de référence n'est disponible pour ce site.
        </Typography.Text>
      </RenderIf>

      <Divider />

      <Form.Item
        name="directorParagraph"
        label="Commentaire Efficap"
        validateFirst
        rules={[
          {
            validator: (rule, value) => {
              if (value && value.split("\n").length > 10) {
                return Promise.reject("Le commentaire ne doit pas dépasser 10 lignes.");
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input.TextArea
          placeholder="Saisir le texte ici"
          autoSize={{ minRows: 3, maxRows: 7 }}
          onChange={(e) => {
            form.setFieldsValue({ directorParagraph: e.target.value });
          }}
        />
      </Form.Item>

      <Divider />

      <Typography.Text italic style={{ color: colors.blue.dark }}>
        Selectionnez les pages à inclure dans le rapport.
      </Typography.Text>

      <Form.Item name="selectedPages" initialValue={["summary", "groups", "consumption"]}>
        <Checkbox.Group
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Checkbox value="summary">Page de synthèse</Checkbox>
          <Checkbox value="groups">Page des températures</Checkbox>
          <Checkbox value="consumption">Page des consommations</Checkbox>
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};

const ReportGeneration: React.FC<{ slug: string }> = ({ slug }) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();

  function onGenerateReport() {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(async (formData: ReportFormData) => {
        await withNotify(
          new SiteAPIService(getAccessTokenSilently).downloadSiteReport({
            sId: slug,
            ...formData,
          }),
          `Rapport généré pour la période d'analyse du ${formData.startDate.format(
            "DD/MM/YYYY",
          )} au ${formData.startDate.format("DD/MM/YYYY")}
                ${
                  formData.startDateRef
                    ? `et la période de référence du ${formData.startDateRef?.format(
                        "DD/MM/YYYY",
                      )} au ${formData.endDateRef?.format("DD/MM/YYYY")}.`
                    : "et sans période de référence."
                }
                Vous pouvez le retrouver vos téléchargements.`,
          "La génération du rapport a échoué.",
        );
        setConfirmLoading(false);
      })
      .catch((err: any) => {
        setConfirmLoading(false);
        console.error("loading report error", err);
      });
  }

  return (
    <div>
      <Button type="primary" icon={<AuditOutlined />} onClick={() => setIsModalVisible(true)}>
        Générer un rapport de chauffe
      </Button>
      <Modal
        visible={isModalVisible}
        title={"Générateur de rapport"}
        okText="Générer le rapport"
        cancelText="Annuler"
        onCancel={() => setIsModalVisible(false)}
        confirmLoading={confirmLoading}
        onOk={onGenerateReport}
        forceRender={true}
        destroyOnClose={true}
        width={800}
      >
        <ReportGenerationForm form={form} slug={slug} />
      </Modal>
    </div>
  );
};

export default ReportGeneration;
