import {
  CalendarOutlined,
  CaretDownOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  SearchOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Affix,
  Badge,
  Button,
  Col,
  Input,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import type {
  Key,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import moment from "moment";
import * as React from "react";
import { useReducer, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BatteryIcon, { batteryIcon } from "src/components/BatteryIcon";
import ExportSiteMeasurementsModal from "src/components/ExportSiteMeasurementsModal";
import InstrumentGroupModal, {
  InstrumentGroupCreateFormValues,
} from "src/components/InstrumentGroupModal";
import InstrumentModal, { InstrumentUpdateFormValues } from "src/components/InstrumentModal";
import RenderIf from "src/components/RenderIf";
import {
  batteryPercentages,
  batteryVoltages,
  colors,
  plcSupplierName,
  weatherInstrument,
} from "src/constants";
import { Permissions, hasPermission } from "src/lib/access-control";
import { instrumentGroupHasOpenAlert } from "src/lib/alerts";
import {
  AggregatedMeasurement,
  ComfortRange,
  Instrument,
  InstrumentCategory,
  InstrumentDisposition,
  InstrumentGroup,
  InstrumentGroupAlert,
  InstrumentGroupCategory,
  InstrumentGroupCreate,
  InstrumentGroupRow,
  InstrumentRow,
  InstrumentTableRow,
  Measurement,
  Site,
} from "src/lib/api";
import { co2MeasurementColor, temperatureMeasurementColor } from "src/lib/colors";
import {
  UseAPIResult,
  useInstrumentGroupMeasurements,
  useInstrumentUpdateDisposition,
  useQueryClientSetInstrument,
  useQueryClientSetInstrumentGroup,
} from "src/lib/hooks/api";
import { useWindowWidth } from "src/lib/hooks/window-width";
import { round } from "src/lib/math";
import { withNotify } from "src/lib/notify";
import {
  GetColumnSearchPropsState,
  ecco2SerialNumberFromURL,
  getColumnSearchProps,
  showIf,
  sorter,
} from "src/lib/tableutils";
import InstrumentDispositionModal from "./InstrumentDispositionModal";
import Loader from "./Loader";
import { MeasurementDisplayMode } from "./MeasurementDisplayModeSelector";

function columnSorter(
  latestMeasurementsRes: UseAPIResult<{ [p: string]: Measurement | AggregatedMeasurement | null }>,
  sortOrder: "ascend" | "descend",
  key: "timestamp" | "temperatureMeasurement" | "humidityMeasurement" | "co2Measurement",
  compareFunc: (a: Measurement[typeof key], b: Measurement[typeof key]) => number,
  a: InstrumentTableRow,
  b: InstrumentTableRow,
) {
  if (latestMeasurementsRes.status !== "success") return Infinity;
  if (a.type !== "instrument") return sortOrder === "ascend" ? Infinity : -Infinity;
  if (b.type !== "instrument") return sortOrder === "ascend" ? -Infinity : Infinity;

  const mesA = latestMeasurementsRes.data[a.id]?.[key];
  const mesB = latestMeasurementsRes.data[b.id]?.[key];

  if (mesA && !mesB) return sortOrder === "ascend" ? -Infinity : Infinity;
  else if (mesB && !mesA) return sortOrder === "ascend" ? Infinity : -Infinity;
  else if (!mesA || !mesB) return sortOrder === "ascend" ? Infinity : -Infinity;

  return compareFunc(mesA, mesB);
}

export function renderCategory(category?: InstrumentCategory | InstrumentGroupCategory) {
  const CategoryLine = (color: string) => (
    <div style={{ width: 2, height: 50, backgroundColor: color }} />
  );
  switch (category) {
    case InstrumentCategory.Chaud:
      return CategoryLine(colors.red.medium);
    case InstrumentCategory.Confort:
      return CategoryLine(colors.green.medium);
    case InstrumentCategory.Froid:
      return CategoryLine(colors.blue.dark);
    case InstrumentCategory.Other:
      return CategoryLine("#A9A9A9");
    case InstrumentCategory.ExternalTemperature:
      return CategoryLine(colors.purple.medium);
    default:
      return <div></div>;
  }
}

function renderMeasurement(
  result: UseAPIResult<{ [p: string]: Measurement | AggregatedMeasurement | null }>,
  groupMeasurements: Measurement[],
  row: InstrumentTableRow,
  key: keyof Pick<
    Measurement,
    "timestamp" | "temperatureMeasurement" | "humidityMeasurement" | "co2Measurement"
  >,
  suffix: string,
  comfortRange: ComfortRange,
) {
  switch (result.status) {
    case "loading":
      return <Loader />;

    case "success":
      let measurement: {
        timestamp: number;
        temperatureMeasurement: number;
        humidityMeasurement: number | undefined;
        co2Measurement: number | undefined;
      };

      switch (row.type) {
        case "instrument":
          const value = result.data[row.id] as Measurement | AggregatedMeasurement | null;
          measurement = {
            timestamp: moment(value?.timestamp).unix(),
            temperatureMeasurement: value?.temperatureMeasurement ?? 0,
            humidityMeasurement: value?.humidityMeasurement,
            co2Measurement: value?.co2Measurement ?? 0,
          };
          break;

        case "group":
          const group = groupMeasurements.find((g) => g.id === row.id);

          measurement = {
            timestamp: moment(group?.timestamp).unix(),
            temperatureMeasurement: group?.temperatureMeasurement ?? 0,
            humidityMeasurement: group?.humidityMeasurement ?? 0,
            co2Measurement: group?.co2Measurement ?? 0,
          };
          break;
      }

      const field = measurement[key];
      if (!field) return null;

      const tooltipTimestamp = (
        <>
          <b>
            <CalendarOutlined /> Date:
          </b>{" "}
          {moment.unix(measurement.timestamp).format("lll")}
        </>
      );

      if (key === "humidityMeasurement") {
        return (
          <Tooltip title={tooltipTimestamp}>
            <Typography.Text>
              {round(field as number)
                .toFixed(0)
                .toString() + suffix}
            </Typography.Text>
          </Tooltip>
        );
      } else if (key === "timestamp") {
        const m = moment.unix(field as number);
        const danger = moment().diff(m, "days") > 1;
        const formatted = m.format("lll");

        return danger ? (
          <Tooltip title={"Pas de relevés de cette sonde dans les dernières 24 heures."}>
            <Typography.Text type={danger ? "danger" : undefined}>{formatted}</Typography.Text>
          </Tooltip>
        ) : (
          formatted
        );
      } else if (key === "temperatureMeasurement") {
        const color = temperatureMeasurementColor(comfortRange, field as number);

        return (
          <Tooltip title={tooltipTimestamp}>
            <Tag color={color?.name}>{round(field as number).toString() + suffix}</Tag>
          </Tooltip>
        );
      } else if (key === "co2Measurement") {
        const color = co2MeasurementColor(field as number);

        return (
          <Tooltip title={tooltipTimestamp}>
            <Tag color={color?.name}>{round(field as number).toString() + suffix}</Tag>
          </Tooltip>
        );
      }
      break;

    case "error":
      return <WarningOutlined twoToneColor="#FFFFFF" />;
  }
}

function instrumentToRow(i: Instrument): InstrumentRow {
  return { ...i, uid: `instrument-${i.id}`, type: "instrument" };
}

function instrumentToRowFromGroup(i: Instrument, ig: InstrumentGroup): InstrumentRow {
  return { ...i, uid: `instrument-${i.id}-${ig.id}`, type: "instrument", isInGroup: true };
}

function instrumentGroupToRow(
  ig: InstrumentGroup,
  is: { [k: number]: Instrument },
): InstrumentGroupRow {
  return {
    ...ig,
    id: ig.id,
    uid: `instrument-group-${ig.id}`,
    type: "group",
    serialNumber: "N/A",
    battery: { type: "unknown" },
    children: (
      ig.instrumentIds?.filter((elt) => {
        return Object.keys(is).includes(String(elt));
      }) ?? []
    ).map((iId) => instrumentToRowFromGroup(is[iId], ig)),
  };
}

type InstrumentsTableProps = {
  instruments: Instrument[];
  instrumentGroups: InstrumentGroup[];
  site: Site;
  openAlerts: InstrumentGroupAlert[];
  onSaveGroup: (ig: InstrumentGroupCreate | InstrumentGroup) => Promise<void>;
  onDeleteInstrumentGroup: (igID: string) => Promise<void>;
  onEditInstrument: (i: Instrument) => Promise<void>;
  onEditInstrumentComment: (i: Instrument) => Promise<void>;
  onDeleteInstrument: (iID: string) => Promise<void>;
  measurementsRes: UseAPIResult<{ [p: string]: Measurement | AggregatedMeasurement | null }>;
  createAnalysisBatchs: () => Promise<void>;
  enableInstrumentNameSorter?: "instrumentName";
};

type Action =
  | { type: "reset" }
  | { type: "compare" }
  | { type: "edit-group"; data: InstrumentGroupRow }
  | { type: "edit-instrument"; data: InstrumentRow }
  | { type: "exportXY" }
  | { type: "create-group" };

type TableState =
  | { status: "idle" }
  | { status: "comparing" }
  | { status: "editing-group"; data: InstrumentGroupRow }
  | { status: "editing-instrument"; data: InstrumentRow }
  | { status: "creating-group" }
  | { status: "exportingXY" };

function reducer(state: TableState, action: Action): TableState {
  switch (action.type) {
    case "reset":
      return { status: "idle" };
    case "compare":
      return { status: "comparing" };
    case "edit-group":
      return { status: "editing-group", data: action.data };
    case "edit-instrument":
      return { status: "editing-instrument", data: action.data };
    case "create-group":
      return { status: "creating-group" };
    case "exportXY":
      return { status: "exportingXY" };
  }
}

const SearchDisposition: React.FC<{
  dispositionSearch: { circuit: string; building: string };
  setDispositionSearch: (disposition: { circuit: string; building: string }) => void;
  allCircuits: Set<string>;
  allBuildings: Set<string>;
}> = ({ dispositionSearch, setDispositionSearch, allCircuits, allBuildings }) => {
  const [visible, setVisible] = useState(false);
  const [circuit, setCircuit] = useState(dispositionSearch.circuit);
  const [building, setBuilding] = useState(dispositionSearch.building);

  const handleSearchDisposition = () => {
    setVisible(!visible);
  };

  return (
    <>
      <SearchOutlined onClick={handleSearchDisposition} style={{ fontSize: 12, marginLeft: 8 }} />
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          backgroundColor: "white",
          padding: 4,
          display: visible ? "block" : "none",
          top: "3rem",
          border: "1px solid #d9d9d9",
          width: "300px",
          boxShadow: "0 2px 8px rgba(0,0,0,0.25)",
        }}
      >
        <Row style={{ marginBottom: 2 }}>
          <Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography.Text strong>Circuit :</Typography.Text>
          </Col>
          <Col
            span={12}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Select
              style={{ width: "100%" }}
              onChange={(value) => setCircuit(value)}
              value={circuit}
            >
              {Array.from(allCircuits).map((circuit) => (
                <Select.Option value={circuit} key={circuit}>
                  {circuit}
                </Select.Option>
              ))}
              <Select.Option value="unattributed" key="unattributed">
                Non attribué
              </Select.Option>
            </Select>
          </Col>
        </Row>

        <Row style={{ marginBottom: 2 }}>
          <Col
            span={12}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Typography.Text strong>Bâtiment :</Typography.Text>
          </Col>
          <Col
            span={12}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Select
              style={{ width: "100%" }}
              onChange={(value) => setBuilding(value)}
              value={building}
            >
              {Array.from(allBuildings).map((building) => (
                <Select.Option value={building} key={building}>
                  {building}
                </Select.Option>
              ))}
              <Select.Option value="unattributed" key="unattributed">
                Non attribué
              </Select.Option>
            </Select>
          </Col>
        </Row>

        <Row style={{ padding: 1 }}>
          <Col span={12} style={{ paddingRight: 1 }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {
                setDispositionSearch({ circuit, building });
                setVisible(false);
              }}
            />
          </Col>
          <Col span={12} style={{ paddingLeft: 1 }}>
            <Button
              type="default"
              icon={<CloseOutlined />}
              style={{ width: "100%" }}
              onClick={() => {
                setCircuit("");
                setBuilding("");
                setDispositionSearch({ circuit: "", building: "" });
                setVisible(false);
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const InstrumentsTable: React.FC<InstrumentsTableProps> = ({
  instruments,
  instrumentGroups,
  site,
  openAlerts,
  onSaveGroup,
  onDeleteInstrumentGroup,
  onEditInstrument,
  onEditInstrumentComment,
  onDeleteInstrument,
  measurementsRes,
  createAnalysisBatchs,
}) => {
  const ref = useRef<Input>();
  const { user } = useAuth0();
  const { isWideScreen } = useWindowWidth();
  const navigate = useNavigate();
  const queryCacheInstrument = useQueryClientSetInstrument();
  const queryCacheInstrumentGroup = useQueryClientSetInstrumentGroup();
  const [state, dispatch] = useReducer(reducer, { status: "idle" });
  const [groupCreateModalOpen, setGroupCreateModalOpen] = useState(false);
  const [instrumentUpdateModalOpen, setInstrumentUpdateModalOpen] = useState(false);
  const [filtered, setFiltered] = useState<InstrumentTableRow[]>([]);
  const [selectedInstrumentRows, setSelectedInstrumentRows] = useState<
    InstrumentTableRow[] | undefined
  >(undefined);
  const [nameSearch, setNameSearch] = useState<GetColumnSearchPropsState<"name">>({
    searchText: "",
  });
  const [visible, setVisible] = useState(false);
  const { mutateAsync: updateInstrumentDisposition } = useInstrumentUpdateDisposition(site.slug);
  const [isDispositionModalVisible, setIsDispositionModalVisible] = useState(false);
  const [clickedInstrument, setClickedInstrument] = useState<InstrumentRow | undefined>(undefined);
  const [showAlternatives, setShowAlternatives] = useState(false);
  const [exportType, setExportType] = useState<"classique" | "xy">("classique");
  const [serialNumberSearch, setSerialNumberSearch] = useState<
    GetColumnSearchPropsState<"serialNumber">
  >({ searchText: "" });
  const [commentSearch, setCommentSearch] = useState<GetColumnSearchPropsState<"comments">>({
    searchText: "",
  });
  const [dispositionSearch, setDispositionSearch] = useState<{ circuit: string; building: string }>(
    {
      circuit: "",
      building: "",
    },
  );
  const [allInstruments, setInstruments] = useState<Instrument[]>(instruments);
  const [allInstrumentsGroups, setInstrumentGroups] = useState<InstrumentGroup[]>(instrumentGroups);

  const groupMeasurementsRes = useInstrumentGroupMeasurements(
    allInstrumentsGroups.map((ig) => ig.id.toString()),
    moment().startOf("hour").subtract(1, "hour").unix(),
    moment().startOf("hour").unix(),
  );

  const groupMeasurements =
    groupMeasurementsRes.status === "success" ? groupMeasurementsRes.data : [];

  const allCircuits = new Set(
    instruments.filter((i) => i.disposition?.circuit).map((i) => i.disposition!.circuit),
  );
  const allBuildings = new Set(
    instruments.filter((i) => i.disposition?.building).map((i) => i.disposition!.building),
  );

  const instrumentsByIds: { [k: number]: Instrument } = allInstruments.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }),
    {},
  );

  const formattedInstruments = allInstruments.map((i) => instrumentToRow(i));
  const showModal = () => {
    setVisible(true);
  };

  const formattedInstrumentGroups = allInstrumentsGroups.map((ig) =>
    instrumentGroupToRow(ig, instrumentsByIds),
  );

  const comfortRange = site.currentHeatingSeason.comfortRange;

  const rows: InstrumentTableRow[] = [...formattedInstruments, ...formattedInstrumentGroups];

  async function onSaveInstrumentDisposition(iid: string, disposition: InstrumentDisposition) {
    await withNotify(
      updateInstrumentDisposition({ d: disposition, iid }),
      "L'emplacement de l'instrument a bien été mis à jour",
      "Une erreur est survenue lors de la mise à jour de l'emplacement de l'instrument.",
    );
    setIsDispositionModalVisible(false);
  }

  React.useEffect(() => {
    const allInstruments =
      state.status === "comparing" ? [...instruments, weatherInstrument(site.id)] : instruments;
    setInstruments(allInstruments);
  }, [state, instruments, site]);

  React.useEffect(() => {
    const allInstruments = instruments.filter((i) => {
      const disposition = i.disposition;
      const circuit = disposition?.circuit ?? "";
      const building = disposition?.building ?? "";
      return (
        (circuit.toLowerCase().includes(dispositionSearch.circuit.toLowerCase()) ||
          (circuit === "" && dispositionSearch.circuit === "unattributed")) &&
        (building.toLowerCase().includes(dispositionSearch.building.toLowerCase()) ||
          (building === "" && dispositionSearch.building === "unattributed"))
      );
    });
    setInstruments(allInstruments);

    if (dispositionSearch.circuit !== "" || dispositionSearch.building !== "") {
      setInstrumentGroups([]);
    } else {
      setInstrumentGroups(instrumentGroups);
    }
  }, [dispositionSearch, instruments, instrumentGroups]);

  const columns = [
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      sorter: sorter("name", "string"),
      defaultSortOrder: "ascend" as const,
      ...getColumnSearchProps(ref as React.RefObject<Input>, "name", nameSearch, setNameSearch),
      render: (text: string, r: InstrumentTableRow) => {
        const nAlerts = r.type === "group" ? instrumentGroupHasOpenAlert(openAlerts, r) : 0;
        // If the row is an instrument group and it has open alerts, navigate to the page with alerts.
        const to =
          r.type === "group"
            ? nAlerts > 0
              ? `/instrument-groups/${r.id}/alerts`
              : `/instrument-groups/${r.id}`
            : `/instruments/${r.id}`;

        return (
          <Space
            direction="horizontal"
            style={{ display: "flex", width: "100%", marginLeft: r.isInGroup ? 15 : 0 }}
          >
            {hasPermission(user, Permissions.InstrumentsUpdate) && (
              <div style={{ width: 2, height: "100%", backgroundColor: "red" }}>
                {renderCategory(r.category)}
              </div>
            )}
            <Link
              to={to}
              onClick={() =>
                r.type === "group" ? queryCacheInstrumentGroup(r) : queryCacheInstrument(r)
              }
            >
              <Badge offset={[6, -3]} count={nAlerts} title="Alerte ouverte pour ce groupe">
                <Typography.Link>{text}</Typography.Link>
              </Badge>
            </Link>
          </Space>
        );
      },
    },
    {
      title: "Température",
      dataIndex: "id",
      key: "latestTempMeasurement",
      render: (n: string, r: InstrumentTableRow) =>
        renderMeasurement(
          measurementsRes,
          groupMeasurements,
          r,
          "temperatureMeasurement",
          "°C",
          comfortRange,
        ),
      sorter: (
        a: InstrumentTableRow,
        b: InstrumentTableRow,
        sortOrder: "ascend" | "descend" | null | undefined,
      ) =>
        columnSorter(
          measurementsRes,
          sortOrder!,
          "temperatureMeasurement",
          (a, b) => (a as number) - (b as number),
          a,
          b,
        ),
    },
    {
      title: "Hygrométrie",
      dataIndex: "id",
      key: "latestHumMeasurement",
      render: (n: string, r: InstrumentTableRow) =>
        renderMeasurement(
          measurementsRes,
          groupMeasurements,
          r,
          "humidityMeasurement",
          "%",
          comfortRange,
        ),
      sorter: (
        a: InstrumentTableRow,
        b: InstrumentTableRow,
        sortOrder: "ascend" | "descend" | null | undefined,
      ) =>
        columnSorter(
          measurementsRes,
          sortOrder!,
          "humidityMeasurement",
          (a, b) => (a as number) - (b as number),
          a,
          b,
        ),
    },
    {
      title: "CO2",
      dataIndex: "id",
      key: "latestC02Measurement",
      render: (n: string, r: InstrumentTableRow) =>
        renderMeasurement(
          measurementsRes,
          groupMeasurements,
          r,
          "co2Measurement",
          "ppm",
          comfortRange,
        ),
      sorter: (
        a: InstrumentTableRow,
        b: InstrumentTableRow,
        sortOrder: "ascend" | "descend" | null | undefined,
      ) =>
        columnSorter(
          measurementsRes,
          sortOrder!,
          "co2Measurement",
          (a, b) => (a as number) - (b as number),
          a,
          b,
        ),
    },
    {
      title: "Dernier relevé",
      dataIndex: "id",
      key: "latestMeasurementTimestamp",
      responsive: ["xl" as const],
      render: (n: string, r: InstrumentTableRow) =>
        renderMeasurement(measurementsRes, groupMeasurements, r, "timestamp", "", comfortRange),
      sorter: (
        a: InstrumentTableRow,
        b: InstrumentTableRow,
        sortOrder: "ascend" | "descend" | null | undefined,
      ) =>
        columnSorter(
          measurementsRes,
          sortOrder!,
          "timestamp",
          (a, b) => +new Date(a!) - +new Date(b!),
          a,
          b,
        ),
    },
    {
      title: "Pile",
      dataIndex: "battery",
      key: "battery",
      filters: [{ text: "Faible", value: "low" }],
      responsive: ["md" as const],
      onFilter: (value: string | number | boolean, record: InstrumentTableRow) => {
        switch (record.battery.type) {
          case "voltage":
            const voltage = parseFloat(record.battery.value);
            return voltage < batteryVoltages.low && voltage !== 0.0;
          case "percentage":
            const percentage = parseFloat(record.battery.value);
            return percentage < batteryPercentages.low && percentage !== 0.0;
          case "flag":
            return record.battery.value === "true";

          default:
            return false;
        }
      },
      render: (n: string, r: InstrumentTableRow) => <BatteryIcon battery={r.battery} />,
      sorter: (
        a: InstrumentTableRow,
        b: InstrumentTableRow,
        sortOrder: "ascend" | "descend" | null | undefined,
      ) => {
        const aLevel = batteryIcon(a.battery);
        const bLevel = batteryIcon(b.battery);

        // Unknown battery level is always last.
        if (aLevel === bLevel) return 0;
        switch (aLevel) {
          case "full":
            if (bLevel === "unknown") return sortOrder === "ascend" ? -Infinity : Infinity;
            return 1;
          case "low":
            if (bLevel === "full") return -1;
            else return 1;
          case "dead":
            if (bLevel === "full" || bLevel === "low") return -1;
            else return 1;
          case "unknown":
            return sortOrder === "ascend" ? Infinity : -Infinity;
        }
      },
    },
    {
      title: "Fournisseur",
      dataIndex: "supplierId",
      key: "supplierId",
      render: (supplierId: number) => {
        return plcSupplierName(supplierId);
      },
      onFilter: (value: string | number | boolean, record: any) => record.supplierId === value,
      filters: [1, 2, 4, 7].map((id: number) => ({
        text: plcSupplierName(id),
        value: id,
      })),
    },
    {
      title: "Commentaires",
      dataIndex: "comments",
      key: "comments",
      responsive: ["sm" as const],
      ...getColumnSearchProps(
        ref as React.RefObject<Input>,
        "comments",
        commentSearch,
        setCommentSearch,
      ),
    },
    {
      title: "Numéro de série",
      dataIndex: "serialNumber",
      key: "serialNumber",
      responsive: ["lg" as const],
      ...getColumnSearchProps(
        ref as React.RefObject<Input>,
        "serialNumber",
        serialNumberSearch,
        setSerialNumberSearch,
        {
          inputTransform: ecco2SerialNumberFromURL,
        },
      ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color:
              dispositionSearch.circuit || dispositionSearch.building ? colors.blue.dark : "black",
          }}
        >
          Emplacement
          <SearchDisposition
            dispositionSearch={dispositionSearch}
            setDispositionSearch={setDispositionSearch}
            allCircuits={allCircuits}
            allBuildings={allBuildings}
          />
        </div>
      ),
      dataIndex: "disposition",
      key: "disposition",
      responsive: ["lg" as const],
      render: (n: string, r: InstrumentTableRow) => {
        return (
          r.type === "instrument" && (
            <Button
              type="link"
              disabled={state.status !== "idle"}
              icon={<EyeOutlined />}
              onClick={() => {
                setClickedInstrument(r);
                setIsDispositionModalVisible(true);
              }}
            >
              Voir
            </Button>
          )
        );
      },
    },
    ...showIf<InstrumentTableRow>(() => hasPermission(user, Permissions.InstrumentGroupsUpdate), {
      title: "Edition",
      key: "edition",
      responsive: ["lg" as const],
      width: 120,
      filters: [
        { text: "Sonde", value: "instrument" },
        { text: "Groupe", value: "group" },
      ],
      onFilter: (value: string | number | boolean, record: InstrumentTableRow) =>
        record.type === value,
      render: (text: string, record: InstrumentTableRow) => (
        <span>
          {record.type === "group" && (
            <Button
              type="link"
              disabled={state.status !== "idle"}
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedInstrumentRows(
                  // Handle empty groups
                  (record.instrumentIds ?? []).map((i) => instrumentToRow(instrumentsByIds[i])),
                );
                dispatch({
                  type: "edit-group",
                  data: instrumentGroupToRow(record as InstrumentGroup, instrumentsByIds),
                });
              }}
            >
              Modifier
            </Button>
          )}
          {record.type === "instrument" &&
            (hasPermission(user, Permissions.InstrumentsUpdate) ||
              hasPermission(user, Permissions.InstrumentCommentsUpdate)) && (
              <Button
                type="link"
                disabled={state.status !== "idle"}
                icon={<EditOutlined />}
                onClick={() => {
                  dispatch({
                    type: "edit-instrument",
                    data: record as InstrumentRow,
                  });
                  setInstrumentUpdateModalOpen(true);
                }}
              >
                Modifier
              </Button>
            )}
        </span>
      ),
    }),
    ...showIf<InstrumentTableRow>(
      () => {
        return (
          hasPermission(user, Permissions.InstrumentGroupsDelete) ||
          hasPermission(user, Permissions.InstrumentsDelete)
        );
      },
      {
        title: "Suppresion",
        key: "suppresion",
        responsive: ["lg" as const],
        width: 120,
        filters: [
          { text: "Sonde", value: "instrument" },
          { text: "Groupe", value: "group" },
        ],
        onFilter: (value: string | number | boolean, record: InstrumentTableRow) =>
          record.type === value,
        render: (text: string, record: InstrumentTableRow) => (
          <span>
            {record.type === "group" && (
              <Popconfirm
                title="Cette action est irréversible, êtes-vous sûr·e ?"
                onConfirm={() => onDeleteInstrumentGroup(record.id.toString())}
                okText="Oui"
                cancelText="Non"
              >
                <Button type="link" disabled={state.status !== "idle"} icon={<DeleteOutlined />}>
                  Supprimer
                </Button>
              </Popconfirm>
            )}
            {record.type === "instrument" && hasPermission(user, Permissions.InstrumentsDelete) && (
              <Popconfirm
                title="Cette action est irréversible, êtes-vous sûr·e ?"
                onConfirm={() => onDeleteInstrument(record.id.toString())}
                okText="Oui"
                cancelText="Non"
              >
                <Button type="link" disabled={state.status !== "idle"} icon={<DeleteOutlined />}>
                  Supprimer
                </Button>
              </Popconfirm>
            )}
          </span>
        ),
      },
    ),
  ];

  const onCompare = () => {
    const reduced = selectedInstrumentRows?.reduce(
      (acc, curr) => ({
        ...acc,
        iId: [...acc.iId, ...(curr.type === "instrument" ? [curr.id] : [])],
        igId: [...acc.igId, ...(curr.type === "group" ? [curr.id] : [])],
      }),
      { iId: [], igId: [] } as { iId: number[]; igId: number[] },
    );

    const urlSearchParams = new URLSearchParams();

    reduced!.iId.forEach((i) => urlSearchParams.append("iid", i.toString()));
    reduced!.igId.forEach((i) => urlSearchParams.append("igid", i.toString()));

    if (reduced?.iId.includes(0)) {
      urlSearchParams.append("weather", "true");
    }

    navigate({
      pathname: `/sites/${site.slug}/measurements`,
      search: "?" + urlSearchParams.toString(),
    });
  };

  const onClassicExport = () => {
    setExportType("classique");
    setShowAlternatives(false);
    showModal();
  };

  const onXYExport = () => {
    setExportType("xy");
    setShowAlternatives(false);
    dispatch({ type: "exportXY" });
  };

  const onValidateExportXY = () => {
    showModal();
    dispatch({ type: "reset" });
  };

  return (
    <>
      {isWideScreen && (
        <div style={{ display: "flex", marginBottom: "1rem" }}>
          <RenderIf predicate={hasPermission(user, Permissions.InstrumentGroupsCreate)}>
            {/*
                If we are not currently creating a group, show the button to start group creation.
              */}
            {!["creating-group", "editing-group"].includes(state.status) && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                disabled={state.status === "comparing"}
                onClick={() => dispatch({ type: "create-group" })}
              >
                Créer un groupe
              </Button>
            )}

            {/*
                If we are currently creating a group, only show the buttons to either save the group
                or cancel editing
              */}
            {["creating-group", "editing-group"].includes(state.status) && (
              <div>
                <InstrumentGroupModal
                  visible={groupCreateModalOpen}
                  instrumentGroup={state.status === "editing-group" ? state.data : undefined}
                  site={site}
                  onSave={async (values: InstrumentGroupCreateFormValues) => {
                    if (selectedInstrumentRows) {
                      if (state.status === "creating-group") await onSaveGroup(values);
                      else if (state.status === "editing-group") {
                        await onSaveGroup({
                          id: state.data.id,
                          ...values,
                        });
                      }

                      dispatch({ type: "reset" });
                      setGroupCreateModalOpen(false);
                      setSelectedInstrumentRows(undefined);
                    }
                  }}
                  onCancel={() => setGroupCreateModalOpen(false)}
                  instruments={selectedInstrumentRows?.map((i) => i.id) ?? []}
                />
                <Affix offsetTop={20}>
                  <Space>
                    <Button
                      type="primary"
                      icon={<CheckOutlined />}
                      disabled={selectedInstrumentRows === undefined}
                      onClick={() => setGroupCreateModalOpen(true)}
                    >
                      Sauvegarder
                    </Button>
                    <Button
                      type="primary"
                      danger={true}
                      icon={<CloseOutlined />}
                      onClick={() => {
                        dispatch({ type: "reset" });
                        setSelectedInstrumentRows(undefined);
                      }}
                    >
                      Annuler
                    </Button>
                  </Space>
                </Affix>
              </div>
            )}
          </RenderIf>
          <RenderIf
            predicate={
              hasPermission(user, Permissions.InstrumentCommentsUpdate) ||
              hasPermission(user, Permissions.InstrumentsUpdate)
            }
          >
            {/*
                If we are editing instrument show the modal to edit instrument
              */}
            {["editing-instrument"].includes(state.status) && (
              <div>
                <InstrumentModal
                  visible={instrumentUpdateModalOpen}
                  instrument={state.status === "editing-instrument" ? state.data : undefined}
                  site={site}
                  mode={
                    hasPermission(user, Permissions.InstrumentsUpdate) ? "update" : "update-comment"
                  }
                  onSave={async (values: InstrumentUpdateFormValues) => {
                    if (state.status === "editing-instrument") {
                      if (hasPermission(user, Permissions.InstrumentsUpdate)) {
                        await onEditInstrument({
                          ...state.data,
                          name: values.name,
                          comments: values.comments,
                          category: values.category,
                        });
                      } else if (hasPermission(user, Permissions.InstrumentCommentsUpdate)) {
                        await onEditInstrumentComment({
                          ...state.data,
                          comments: values.comments,
                        });
                      }
                    }
                    dispatch({ type: "reset" });
                    setInstrumentUpdateModalOpen(false);
                  }}
                  onCancel={() => {
                    dispatch({ type: "reset" });
                    setInstrumentUpdateModalOpen(false);
                  }}
                />
              </div>
            )}
          </RenderIf>

          <div style={{ marginLeft: "auto" }}>
            <Space>
              {(hasPermission(user, Permissions.InstrumentsUpdate) ||
                user.sub === site.heatingReferentUser.id) && (
                <Popconfirm
                  title={
                    <div>
                      <div>
                        Les groupes d'analyses de température regroupent les sondes dans 5
                        catégories : froid, confort, chaud, extérieures et autres.
                      </div>
                      <div>
                        En cliquant sur ce bouton les groupes vont automatiquement être définis en
                        se basant sur les données des 7 derniers jours.
                      </div>
                      <div>Les groupes d'analyse existants seront écrasés, êtes vous sûr ?</div>
                    </div>
                  }
                  onConfirm={createAnalysisBatchs}
                  okText="Oui"
                  cancelText="Non"
                >
                  <Button type="ghost" disabled={state.status !== "idle"}>
                    Générer les lots d'analyse
                  </Button>
                </Popconfirm>
              )}
              {state.status !== "comparing" && state.status !== "exportingXY" && (
                <Popover
                  title="Comparaison de mesures"
                  content={
                    <div>
                      <p>Comparer les mesures de plusieurs sondes entre elles.</p>
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    onClick={() => dispatch({ type: "compare" })}
                    disabled={state.status !== "idle"}
                  >
                    Comparaison
                  </Button>
                </Popover>
              )}
              {state.status === "comparing" && (
                <Affix offsetTop={20}>
                  <Space>
                    <Popover
                      placement="bottom"
                      title={"Lancer la comparaison"}
                      content={
                        <div>
                          <p>Il suffit de sélectionner plusieurs sondes du tableau.</p>
                          <p>Vous devez sélectionner au moins deux sondes.</p>
                          <p>Valider pour comparer les mesures des sondes sélectionnées.</p>
                        </div>
                      }
                    >
                      <Button
                        id="start-measurement-comparison-button"
                        type="primary"
                        icon={<CheckOutlined />}
                        disabled={
                          selectedInstrumentRows === undefined || selectedInstrumentRows.length < 2
                        }
                        onClick={onCompare}
                      >
                        Valider
                      </Button>
                    </Popover>
                    <Button
                      type="primary"
                      danger={true}
                      icon={<CloseOutlined />}
                      onClick={() => dispatch({ type: "reset" })}
                    >
                      Annuler
                    </Button>
                  </Space>
                </Affix>
              )}
              {state.status === "exportingXY" && (
                <Affix offsetTop={20}>
                  <Space>
                    <Popover
                      placement="bottom"
                      title={"Lancer l'export X/Y"}
                      content={
                        <div>
                          <p>Il suffit de sélectionner une ou plusiuers sondes du tableau</p>
                          <p>Valider pour lancer l'export des mesures moyenées par heure.</p>
                        </div>
                      }
                    >
                      <Button
                        id="start-measurement-comparison-button"
                        type="primary"
                        icon={<CheckOutlined />}
                        disabled={
                          selectedInstrumentRows === undefined || selectedInstrumentRows.length < 1
                        }
                        onClick={onValidateExportXY}
                      >
                        Valider
                      </Button>
                    </Popover>
                    <Button
                      type="primary"
                      danger={true}
                      icon={<CloseOutlined />}
                      onClick={() => dispatch({ type: "reset" })}
                    >
                      Annuler
                    </Button>
                  </Space>
                </Affix>
              )}

              <ExportSiteMeasurementsModal
                site={site}
                showModal={visible}
                closeModal={() => {
                  setVisible(false);
                  setSelectedInstrumentRows(undefined);
                }}
                exportType={exportType}
                selectedInstrumentRows={selectedInstrumentRows}
              />

              <div style={{ position: "relative" }}>
                <Button
                  id="site-measurements-export-button"
                  type="primary"
                  onClick={() => setShowAlternatives(!showAlternatives)}
                  style={{ width: 150 }}
                  disabled={state.status !== "idle"}
                >
                  Export Mesures
                  <CaretDownOutlined />
                </Button>
                {showAlternatives ? (
                  <div style={{ ...styles.exportOptionsContainer, position: "absolute", left: 0 }}>
                    <Button
                      id="site-measurements-export-button-classic"
                      type="primary"
                      onClick={onClassicExport}
                      block={true}
                      style={{ ...styles.exportOptionButton, zIndex: 1 }}
                    >
                      Export site
                    </Button>
                    <Button
                      id="site-measurements-export-button-xy"
                      type="primary"
                      onClick={onXYExport}
                      block={true}
                      style={{
                        ...styles.exportOptionButton,
                        borderBottomRightRadius: 2,
                        borderBottomLeftRadius: 2,
                      }}
                    >
                      Export instruments
                    </Button>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </Space>
          </div>
        </div>
      )}
      {(state.status === "comparing" || state.status === "creating-group") && (
        <Space>
          <Typography.Text>Sélection des sondes par lot d'analyse: </Typography.Text>
          <div>
            <Radio.Button
              style={{ margin: 2 }}
              onClick={() =>
                setSelectedInstrumentRows(
                  rows.filter((r: InstrumentTableRow) => r.category === InstrumentCategory.Froid),
                )
              }
            >
              Lot sondes froid
            </Radio.Button>
            <Radio.Button
              style={{ margin: 2 }}
              value={MeasurementDisplayMode.Humidity}
              onClick={() =>
                setSelectedInstrumentRows(
                  rows.filter((r: InstrumentTableRow) => r.category === InstrumentCategory.Confort),
                )
              }
            >
              Lot sondes confort
            </Radio.Button>
            <Radio.Button
              style={{ margin: 2 }}
              value={MeasurementDisplayMode.CO2}
              onClick={() =>
                setSelectedInstrumentRows(
                  rows.filter((r: InstrumentTableRow) => r.category === InstrumentCategory.Chaud),
                )
              }
            >
              Lot sondes chaud
            </Radio.Button>
            <Radio.Button
              style={{ margin: 2 }}
              value={MeasurementDisplayMode.CO2}
              onClick={() =>
                setSelectedInstrumentRows(
                  rows.filter((r: InstrumentTableRow) => r.category === InstrumentCategory.Other),
                )
              }
            >
              Lot sondes défectueuses
            </Radio.Button>
          </div>
        </Space>
      )}
      <InstrumentDispositionModal
        visible={isDispositionModalVisible}
        instrument={clickedInstrument}
        onSave={onSaveInstrumentDisposition}
        onCancel={() => setIsDispositionModalVisible(false)}
      />
      <Table<InstrumentTableRow>
        title={() =>
          filtered.length > 0 && filtered.length !== rows.length ? (
            <Typography.Text>Résultat: {`(${filtered.length} / ${rows.length})`}</Typography.Text>
          ) : (
            ""
          )
        }
        onChange={(
          pagination: TablePaginationConfig,
          filters: Record<string, (Key | boolean)[] | null>,
          sorter: SorterResult<InstrumentTableRow> | SorterResult<InstrumentTableRow>[],
          extra: TableCurrentDataSource<InstrumentTableRow>,
        ) => setFiltered(extra.currentDataSource)}
        size="middle"
        rowKey="uid"
        dataSource={rows}
        columns={columns}
        scroll={{ x: true }}
        pagination={{
          pageSize: 100,
          hideOnSinglePage: true,
          position: ["topRight", "bottomRight"],
        }}
        rowSelection={
          state.status !== "idle"
            ? {
                selectedRowKeys: selectedInstrumentRows?.map((i) => i.uid),
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedInstrumentRows(selectedRows);
                },
                getCheckboxProps: (record) => ({
                  disabled:
                    record.type === "group" &&
                    !(state.status === "comparing" || state.status === "exportingXY"),
                }),
              }
            : undefined
        }
      />
    </>
  );
};

const styles = {
  exportOptionsContainer: {
    backgroundColor: "#C3C3C3",
    zIndex: 1,
    width: 150,
    borderWidth: 3,
    paddingRight: 1,
    paddingBottom: 1,
    borderBottomRightRadius: 2,
    borderBottomLeftRadius: 2,
  },
  exportOptionButton: {
    backgroundColor: "white",
    borderWidth: 0,
    color: "black",
    boxShadow: "none",
    borderRadius: 0,
  },
};

export default InstrumentsTable;
