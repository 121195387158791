import { useState } from "react";
import * as React from "react";
import { Checkbox, Form, Input, Modal } from "antd";
import { InstrumentGroup, InstrumentGroupCategory, Site } from "src/lib/api";
import { colors } from "src/constants";
import Text from "antd/lib/typography/Text";
import { Permissions, hasPermission } from "src/lib/access-control";
import { useAuth0 } from "@auth0/auth0-react";

export type InstrumentGroupCreateFormValues = Omit<InstrumentGroup, "id">;

export interface InstrumentGroupCreateModalProps {
  visible: boolean;
  instrumentGroup?: InstrumentGroup;
  site: Site;
  instruments: number[];
  onSave: (values: InstrumentGroupCreateFormValues) => Promise<void>;
  onCancel: () => void;
}

const InstrumentGroupModal: React.FC<InstrumentGroupCreateModalProps> = ({
  visible,
  instrumentGroup,
  site,
  instruments,
  onSave,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const { user } = useAuth0();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isReferenceGroup, setIsReferenceGroup] = useState(
    instrumentGroup?.category === InstrumentGroupCategory.Reference,
  );

  function onToggleReferenceGroup(isChecked: boolean) {
    setIsReferenceGroup(isChecked);
    const currentName = form.getFieldValue("name");
    const referenceGroupTag = "GR - ";
    if (isChecked && !currentName?.startsWith(referenceGroupTag)) {
      form.setFieldsValue({ name: referenceGroupTag + currentName });
    } else if (currentName?.startsWith(referenceGroupTag)) {
      form.setFieldsValue({
        name: form
          .getFieldValue("name")
          .slice(referenceGroupTag.length, form.getFieldValue("name").length - 1),
      });
    }
  }
  return (
    <Modal
      visible={visible}
      title={
        instrumentGroup === undefined
          ? "Créer un groupe de sondes"
          : `Modifier le groupe de sondes ${instrumentGroup.name}`
      }
      okText="Soumettre"
      cancelText="Annuler"
      onCancel={onCancel}
      okButtonProps={{
        disabled: isReferenceGroup && !hasPermission(user, Permissions.InstrumentsCreate),
      }}
      confirmLoading={confirmLoading}
      onOk={() => {
        form
          .validateFields()
          .then(async ({ name, comments, instrumentIds }: any) => {
            setConfirmLoading(true);
            await onSave({
              name,
              comments,
              siteId: site.id,
              instrumentIds: instruments,
              category: isReferenceGroup
                ? InstrumentGroupCategory.Reference
                : InstrumentGroupCategory.Aucune,
            });
            form.resetFields();
          })
          .catch((err: any) => {
            console.error("InstrumentGroupModal error:", err);
          });
      }}
    >
      <Form
        layout="vertical"
        form={form}
        name="instrument-group-modal"
        initialValues={instrumentGroup}
      >
        <Form.Item
          label="Nom du groupe"
          name="name"
          rules={[{ required: true, min: 3, max: 50 }]}
          hasFeedback={true}
        >
          <Input placeholder="Ex: Groupe Nord" autoFocus={true} />
        </Form.Item>
        <Form.Item label="Commentaires" name="comments" hasFeedback={true}>
          <Input placeholder="Commentaires sur le groupe" />
        </Form.Item>
      </Form>
      <Checkbox
        checked={isReferenceGroup}
        onChange={(e) => onToggleReferenceGroup(e.target.checked)}
        disabled={!hasPermission(user, Permissions.InstrumentsCreate)}
      >
        Définir ce groupe comme un groupe de référence
      </Checkbox>
      {isReferenceGroup && (
        <div style={{ marginLeft: 24 }}>
          <Text
            style={{ color: colors.red.dark, fontSize: 12, lineHeight: 0.5, fontStyle: "italic" }}
          >
            {`Attention ! En définissant ce groupe comme un groupe de référence il sera utilisé pour la génération des rapports de consommation. 
          Veillez à la bonne composition du groupe, ainsi que sa pérennité dans le temps !`}
          </Text>
        </div>
      )}
    </Modal>
  );
};

export default InstrumentGroupModal;
