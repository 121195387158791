import { Button, Tooltip } from "antd";

const TooltipButton = ({
  onClick,
  okButtonText,
  disabled,
  disableMessage,
  loading,
  buttonStyle,
}: {
  onClick: (a: any) => void;
  okButtonText?: string;
  disabled?: boolean;
  disableMessage?: string;
  loading?: boolean;
  buttonStyle?: any;
}) => {
  return (
    <Tooltip title={disabled ? disableMessage : undefined}>
      <Button
        key="customOk"
        type="primary"
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        style={buttonStyle}
      >
        {okButtonText ?? "Enregistrer"}
      </Button>
    </Tooltip>
  );
};

export default TooltipButton;
