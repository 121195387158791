import moment from "moment";
import {
  ComposedChart,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import { dashboardColors } from "src/constants";
import { consumptionHdd, getDotCloudLineCoordinates } from "src/lib/dashboard-utils";
import { DashboardNoData, RenderLabel } from "./DashboardComponents";

export const DashboardDotCloud: React.FC<{
  data: consumptionHdd[];
  unitOfMeasure: string;
}> = ({ data, unitOfMeasure }) => {
  if (data.length === 0) {
    return <DashboardNoData />;
  }

  const formattedData = data.map((d) => {
    const isBefore7days = moment.unix(d.timestamp).isBefore(moment().subtract(7, "days"));

    return {
      timestamp: d.timestamp,
      hdd: d.hdd,
      consumptionBefore7days: isBefore7days ? d.consumption : undefined,
      last7daysConsumption: !isBefore7days ? d.consumption : undefined,
      size: isBefore7days ? 0 : 20,
    };
  });

  const [x1, y1, x2, y2] = getDotCloudLineCoordinates(data);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart data={formattedData} margin={{ top: 30, right: 50, left: 20, bottom: 20 }}>
        <Tooltip
          labelFormatter={(s) => `DJU : ${s.toFixed(2)}`}
          formatter={(value: number) => [`${value.toFixed(2)}`, unitOfMeasure]}
        />
        <XAxis dataKey="hdd" type="number" tickCount={2} domain={[0, "auto"]}>
          <Label
            value="DJU"
            position="insideBottom"
            fill={dashboardColors.grey}
            fontStyle="italic"
            style={{ textAnchor: "middle" }}
          />
        </XAxis>
        <YAxis domain={[0, "auto"]} tickCount={2}>
          <Label
            value={`Consommation (${unitOfMeasure})`}
            offset={10}
            angle={-90}
            position="insideLeft"
            fill={dashboardColors.grey}
            fontStyle="italic"
            style={{ textAnchor: "middle" }}
          />
        </YAxis>

        <Legend
          align="right"
          iconType="circle"
          iconSize={8}
          formatter={(value) =>
            value === "consumptionBefore7days"
              ? "365 derniers jours"
              : "7 derniers jours (si disponible)"
          }
        />

        <ZAxis range={[10, 30]} dataKey={"size"} />

        <Scatter dataKey="consumptionBefore7days" fill={dashboardColors.blueFade} />
        <Scatter dataKey="last7daysConsumption" fill={dashboardColors.red} />

        <ReferenceLine
          strokeWidth={1}
          stroke={dashboardColors.black}
          segment={[
            { x: x1, y: y1 },
            { x: x2, y: y2 },
          ]}
        >
          <Label
            position="top"
            value={`Pente moyenne : ${
              x2 - x1 !== 0 ? ((y2 - y1) / (x2 - x1)).toFixed(2) : "-"
            } ${unitOfMeasure}/DJU `}
            style={{ fontStyle: "italic", fontSize: 12 }}
          />
        </ReferenceLine>

        <ReferenceLine
          strokeWidth={1}
          stroke={dashboardColors.black}
          segment={[
            { x: 0, y: y1 },
            { x: x1, y: y1 },
          ]}
        >
          <Label
            content={
              <RenderLabel
                labelValue={`${y1?.toFixed(0)} ${unitOfMeasure} / Jour`}
                labelTitle="Conso d'ECS"
                col={dashboardColors.blueFade}
                positionX="right"
                scaleX={2.3}
                customOffset={{ x: 50 }}
              />
            }
          />
        </ReferenceLine>
      </ComposedChart>
    </ResponsiveContainer>
  );
};
