import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { hasPermission, Permissions } from "src/lib/access-control";

const RenderIf: React.FC<{
  predicate?: boolean;
  fallback?: React.ReactNode;
  permissions?: Permissions[];
}> = ({ predicate, children, fallback, permissions }) => {
  const { user } = useAuth0();

  for (const permission of permissions ?? []) {
    if (!hasPermission(user, permission)) {
      return <>{fallback}</> ?? null;
    }
  }

  if (predicate === undefined) {
    return <>{children}</>;
  }

  return predicate ? <>{children}</> : <>{fallback}</> ?? null;
};

export default RenderIf;
