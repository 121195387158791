import React from "react";
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { COLORS } from "src/lib/chart-utils";
import { datetimeFormatter } from "../Chart/Chart";
import { timestampCompareFn } from "../Chart/interpolate-data";

export interface LineData {
  dataKey: string;
  data: ({ timestamp: string | number } & { [key: string]: number })[];
  lines: { name: string; dataKey: string; yAxisId?: string }[];
  dot?: boolean;
  syncId?: string;
}

interface ChartWithSelectProps {
  lineData: LineData;
}

const ChartWithSelect = (props: ChartWithSelectProps) => {
  const { lineData } = props;

  const data = lineData.data.sort(timestampCompareFn);

  const [lineProps, setLineProps] = React.useState<any>(
    lineData.lines.map((line) => line.dataKey).reduce((acc, key) => ({ ...acc, [key]: true }), {}),
  );

  const toggleLine = (dataKey: string) => {
    setLineProps({ ...lineProps, [dataKey]: !lineProps[dataKey] });
  };

  return (
    <ResponsiveContainer height={400}>
      <LineChart data={data} syncId={lineData.syncId}>
        {lineData.lines.map((line, index) => (
          <Line
            key={index}
            type="monotone"
            dataKey={line.dataKey}
            name={line.name}
            stroke={COLORS[index % COLORS.length]}
            dot={props.lineData.dot}
            hide={!lineProps[line.dataKey]}
            isAnimationActive={false}
            yAxisId={line?.yAxisId ?? "left"}
          />
        ))}
        <YAxis yAxisId="left" allowDecimals={false} domain={["auto", "auto"]} />
        <YAxis yAxisId="right" orientation="right" allowDecimals={false} />
        <XAxis
          domain={["auto", "auto"]}
          dataKey={lineData.dataKey}
          scale="time"
          type="number"
          tickFormatter={datetimeFormatter}
        />
        <Legend onClick={({ dataKey }) => toggleLine(dataKey)} />
        <Tooltip
          labelFormatter={datetimeFormatter}
          formatter={(value: number) => value.toFixed(2)}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ChartWithSelect;
