import { CloseCircleOutlined } from "@ant-design/icons";
import { Modal, Typography } from "antd";
import React, { useMemo } from "react";
import { AdminBanner } from "src/lib/api";
import CookieManager, { cleanBannersCookies } from "src/lib/cookies";
import { useActiveBanners } from "src/lib/hooks/api";

export const bannerColors: { [key: string]: { color: string; subColor: string } } = {
  information: { color: "#5D9C59", subColor: "#44C767" },
  warning: { color: "#D46B08", subColor: "#FA8C16" },
  alert: { color: "#CF1322", subColor: "#FF7875" },
};

export function parseTextLinks(text: string) {
  // transform text between hooks into links
  return text.split(/(\[[^\]]+\])/g).map((part, i) => {
    const match = part.match(/\[([^\]]+)\]/);
    return match ? (
      <a
        key={i}
        href={/^https?:\/\//.test(match[1]) ? match[1] : `http://${match[1]}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "underline", color: "#ccffff" }}
      >
        {match[1]}
      </a>
    ) : (
      part
    );
  });
}

const BannerDisplay: React.FC = () => {
  //usememo for cookie
  const cookieManager = useMemo(() => new CookieManager("hiddenBanners"), []);
  const { data: activeBanners } = useActiveBanners();
  const [progressWidth, setProgressWidth] = React.useState(0);

  const [sortedBanners, setSortedBanners] = React.useState<(AdminBanner & { index: number })[]>(
    activeBanners
      .filter((banner: AdminBanner) => !cookieManager.get()?.includes(banner.id))
      .sort((a: AdminBanner, b: AdminBanner) => a.id - b.id)
      .map((b: AdminBanner, i: number) => ({
        ...b,
        index: i,
      })),
  );
  const [currentBanner, setCurrentBanner] = React.useState(sortedBanners[0]);

  React.useEffect(() => {
    if (!currentBanner) {
      return;
    }
    const bannerDisplayTime = computeBannerTime(currentBanner.content);
    setTimeout(() => {
      setProgressWidth(100);
    }, 1000);

    const intervalId = setInterval(() => {
      setCurrentBanner(sortedBanners[(currentBanner.index + 1) % sortedBanners.length]);
      setProgressWidth(0);
    }, bannerDisplayTime);

    return () => clearInterval(intervalId);
  }, [currentBanner, sortedBanners]);

  // Clean banners related cookies at banners load
  React.useEffect(() => {
    cleanBannersCookies(activeBanners);

    const newSortedBanners = activeBanners
      .filter((banner: AdminBanner) => !cookieManager.get()?.includes(banner.id))
      .sort((a: AdminBanner, b: AdminBanner) => a.id - b.id)
      .map((b: AdminBanner, i: number) => ({
        ...b,
        index: i,
      }));

    setSortedBanners(newSortedBanners);
    setCurrentBanner(newSortedBanners[0]);
  }, [activeBanners, cookieManager]);

  function onHideBanner(id: number) {
    const newHidedBanners = [...(cookieManager.get() ?? []), id];
    cookieManager.set(newHidedBanners);
    const newSortedBanners = sortedBanners.filter(
      (banner) => !newHidedBanners?.includes(banner.id),
    );
    setSortedBanners(newSortedBanners);
    setCurrentBanner(newSortedBanners[0]);
  }

  const computeBannerTime = (text: string) => {
    return Math.max(50 * text.length, 2000);
  };

  function onCloseClick() {
    const currentId = currentBanner.id;
    Modal.confirm({
      title: "Cacher la bannière",
      content: "Cette bannière ne sera plus visible sur cet appareil. Voulez-vous continuer?",
      onOk() {
        onHideBanner(currentId);
      },
    });
  }

  if (!currentBanner) {
    return <></>;
  }

  return (
    <div
      style={{
        backgroundColor: bannerColors[currentBanner.informationLevel]?.color,
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: 16,
        position: "relative",
      }}
    >
      <div style={{ margin: 10 }}>
        <Typography.Text style={{ color: "white" }}>
          {currentBanner.content.split("\n").map((line, index) => (
            <div key={index} style={{ textAlign: "center" }}>
              {parseTextLinks(line)}
            </div>
          ))}
        </Typography.Text>
        <CloseCircleOutlined
          style={{ position: "absolute", right: 5, top: 5, color: "white", cursor: "pointer" }}
          onClick={onCloseClick}
        />
        {sortedBanners.length > 1 && (
          <Typography.Text
            italic
            style={{
              position: "absolute",
              top: 5,
              left: 5,
              color: "white",
              fontSize: 13,
            }}
          >
            {currentBanner.index + 1}/{sortedBanners.length}
          </Typography.Text>
        )}
      </div>
      {sortedBanners.length > 1 && (
        <div
          style={{
            height: 5,
            width: `${progressWidth}%`,
            backgroundColor: bannerColors[currentBanner.informationLevel].subColor,
            transition: `${
              progressWidth === 0
                ? "none"
                : `width ${computeBannerTime(currentBanner.content) * 0.95}ms linear`
            }`,
            position: "relative",
          }}
        ></div>
      )}
    </div>
  );
};

export default BannerDisplay;
