import { Suspense } from "react";
import * as React from "react";
import { Button, Empty } from "antd";
import ErrorBoundaryWithFallback from "src/components/ErrorBoundaryWithFallback";
import Loader from "src/components/Loader";
import type { HeatingDistributionCircuit, Site } from "src/lib/api";
import { colors } from "src/constants";
import LiveMonitoringTab from "./LiveMonitoringTab";
import CircuitHistoryTab from "./CircuitHistoryTab";
import ScheduledRegulationTab from "./ScheduledRegulationTab";
import PredictiveRegulationTab from "./PredictiveRegulationTab";
import { hasPermission, Permissions } from "src/lib/access-control";
import { useAuth0 } from "@auth0/auth0-react";
import { useSiteHeatingDistributionCircuits } from "src/lib/hooks/api";
import { sortCircuits } from "src/lib/utils";

type Mode =
  | "live-monitoring"
  | "circuit-historic"
  | "scheduled-regulation"
  | "predictive-regulation";

function getTitleFromMode(mode: Mode) {
  switch (mode) {
    case "live-monitoring":
      return "Supervision live";
    case "circuit-historic":
      return "Historique circuits";
    case "scheduled-regulation":
      return "Régulation plannifiée";
    case "predictive-regulation":
      return "Régulation prédictive";
    default:
      return "";
  }
}

const FunctionnalButton: React.FC<{
  mode: Mode;
  activeMode: Mode;
  setMode: (mode: Mode) => void;
}> = ({ mode, activeMode, setMode }) => {
  return (
    <Button
      type="default"
      style={{
        width: "20%",
        height: 70,
        borderRadius: 10,
        color: activeMode === mode ? colors.blue.dark : "#696969",
        borderColor: activeMode === mode ? colors.blue.dark : "#696969",
      }}
      onClick={() => setMode(mode)}
    >
      {getTitleFromMode(mode)}
    </Button>
  );
};

function setDefaultMode(hdcs: HeatingDistributionCircuit[]) {
  if (hdcs.filter((hdc: HeatingDistributionCircuit) => hdc.monitoring !== "inactive").length > 0) {
    return "live-monitoring";
  } else if (
    hdcs.filter((hdc: HeatingDistributionCircuit) => hdc.isSchedulingRegulation === true).length > 0
  ) {
    return "scheduled-regulation";
  } else if (
    hdcs.filter((hdc: HeatingDistributionCircuit) => hdc.isPredictiveRegulation === true).length > 0
  ) {
    return "scheduled-regulation";
  } else {
    return "live-monitoring";
  }
}

const HeatingInstallationsTab: React.FC<{ site: Site }> = ({ site }) => {
  const { data: hdcs } = useSiteHeatingDistributionCircuits(site.slug);
  const [mode, setMode] = React.useState<Mode>(setDefaultMode(hdcs));
  const { user } = useAuth0();

  const sortedHdcs = sortCircuits(hdcs);

  return (
    <div style={{ marginBottom: "2rem" }}>
      <div style={{ display: "flex", justifyContent: "space-between", margin: 20 }}>
        <FunctionnalButton
          mode={"live-monitoring"}
          setMode={setMode}
          activeMode={mode}
        ></FunctionnalButton>
        <FunctionnalButton
          mode={"circuit-historic"}
          setMode={setMode}
          activeMode={mode}
        ></FunctionnalButton>
        <FunctionnalButton
          mode={"scheduled-regulation"}
          setMode={setMode}
          activeMode={mode}
        ></FunctionnalButton>
        <FunctionnalButton
          mode={"predictive-regulation"}
          setMode={setMode}
          activeMode={mode}
        ></FunctionnalButton>
      </div>
      {hasPermission(user, Permissions.HeatingInstallationsRead) ? (
        mode === "live-monitoring" && (
          <Suspense fallback={<Loader />}>
            <ErrorBoundaryWithFallback fallback={<Empty />}>
              <LiveMonitoringTab
                site={site}
                hdcs={sortedHdcs.filter(
                  (hdc: HeatingDistributionCircuit) => hdc.monitoring !== "inactive",
                )}
              />
            </ErrorBoundaryWithFallback>
          </Suspense>
        )
      ) : (
        <Empty />
      )}
      {hasPermission(user, Permissions.HeatingInstallationsRead) ? (
        mode === "circuit-historic" && (
          <Suspense fallback={<Loader />}>
            <ErrorBoundaryWithFallback fallback={<Empty />}>
              <CircuitHistoryTab
                site={site}
                hdcs={sortedHdcs.filter(
                  (hdc: HeatingDistributionCircuit) => hdc.monitoring !== "inactive",
                )}
              />
            </ErrorBoundaryWithFallback>
          </Suspense>
        )
      ) : (
        <Empty />
      )}
      {hasPermission(user, Permissions.SchedulingUpdate) ? (
        mode === "scheduled-regulation" && (
          <Suspense fallback={<Loader />}>
            <ErrorBoundaryWithFallback fallback={<Empty />}>
              <ScheduledRegulationTab
                site={site}
                hdcs={sortedHdcs.filter(
                  (hdc: HeatingDistributionCircuit) => hdc.isSchedulingRegulation === true,
                )}
              />
            </ErrorBoundaryWithFallback>
          </Suspense>
        )
      ) : (
        <Empty />
      )}
      {hasPermission(user, Permissions.HeatingInstallationsRead) ? (
        mode === "predictive-regulation" && (
          <Suspense fallback={<Loader />}>
            <ErrorBoundaryWithFallback fallback={<Empty />}>
              <PredictiveRegulationTab
                site={site}
                hdcs={sortedHdcs.filter(
                  (hdc: HeatingDistributionCircuit) => hdc.isPredictiveRegulation === true,
                )}
              />
            </ErrorBoundaryWithFallback>
          </Suspense>
        )
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default HeatingInstallationsTab;
