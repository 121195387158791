import { useState } from "react";
import * as React from "react";
import { Button, DatePicker, Form, Input, Modal, Popconfirm, Select, Typography } from "antd";
import { Instrument, InstrumentRow, Site } from "src/lib/api";
import { hasPermission, Permissions } from "src/lib/access-control";
import { useAuth0 } from "@auth0/auth0-react";
import { useInstrumentDeleteMeasurement } from "src/lib/hooks/api";
import { withNotify } from "src/lib/notify";
import moment, { Moment } from "moment";
import { instrumentCategoryOptions } from "src/constants";

export type InstrumentUpdateFormValues = Omit<
  Instrument,
  "id" | "battery" | "type" | "serialNumber" | "dataSource" | "supplierId"
>;

export interface InstrumentGroupCreateModalProps {
  visible: boolean;
  instrument?: InstrumentRow;
  site: Site;
  onSave: (values: InstrumentUpdateFormValues) => Promise<void>;
  onCancel: () => void;
  mode: "update" | "update-comment";
}

const InstrumentModal: React.FC<InstrumentGroupCreateModalProps> = ({
  visible,
  instrument,
  site,
  onSave,
  onCancel,
  mode,
}) => {
  const { mutateAsync: deleteInstrumentMeasurements } = useInstrumentDeleteMeasurement(
    site.slug,
    instrument?.id ?? 0,
  );
  const [isDeletingMeasurements, setIsDeletingMeasurements] = React.useState(false);
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { user } = useAuth0();
  const [from, setFrom] = React.useState<number | undefined>();
  const [to, setTo] = React.useState<number | undefined>();

  return (
    <Modal
      visible={visible}
      title={`Modifier l'instrument ${instrument?.name}`}
      okText="Soumettre"
      cancelText="Annuler"
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      confirmLoading={confirmLoading}
      onOk={() => {
        form
          .validateFields()
          .then(async ({ name, comments, category }: any) => {
            setConfirmLoading(true);
            await onSave({
              name: name !== instrument?.name ? name : instrument?.name,
              comments,
              siteId: site.id,
              category,
            });
            form.resetFields();
          })
          .catch((err: any) => {
            console.error("InstrumentGroupModal error:", err);
          });
      }}
    >
      <Form layout="vertical" form={form} name="instrument-modal">
        <Form.Item
          label="Nom de l'instrument"
          name="name"
          hasFeedback={true}
          initialValue={instrument?.name}
        >
          <Input placeholder={"75013-CSU001"} disabled={mode === "update-comment"} />
        </Form.Item>
        <Form.Item
          label="Commentaires"
          name="comments"
          hasFeedback={true}
          initialValue={instrument?.comments}
        >
          <Input
            placeholder={"r:C b:C hall:9 ét:6 o:OUEST et SUD p: lot:31 a:5A d:29/11/2019 n: f: c:"}
          />
        </Form.Item>
        <Form.Item
          label="Catégorie"
          name="category"
          hasFeedback={true}
          initialValue={instrument?.category}
        >
          <Select disabled={mode === "update-comment"}>
            {instrumentCategoryOptions.map((o: { key: string | null; value: string }) => (
              <Select.Option value={o.key}>{o.value}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      {hasPermission(user, Permissions.InstrumentsDelete) && (
        <>
          <Typography.Text>Suppression de mesures (sélectionnez la période)</Typography.Text>
          <DatePicker.RangePicker
            style={{ width: "100%", marginTop: 5 }}
            onChange={(value: any) => {
              setFrom(value?.[0].unix());
              setTo(value?.[1].unix());
            }}
            disabledDate={(current: Moment | null) =>
              // Disable dates :
              // 1. before 2018.
              // 2. after today.
              current ? current.isBefore(moment("2018-01-01")) || current.isAfter(moment()) : true
            }
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Popconfirm
              title="Cette action est irréversible, êtes-vous sûr·e ?"
              disabled={isDeletingMeasurements || from === undefined || to === undefined}
              onConfirm={async () => {
                setIsDeletingMeasurements(true);
                if (from === undefined || to === undefined) {
                  return;
                }
                await withNotify(
                  deleteInstrumentMeasurements({ from, to }),
                  "Les mesures de cet instruments ont bien été supprimées. Si vous les aviez déjà chargées en visualisation, rafraichissez la page.",
                  "Une erreur est survenue lors de la suppression des mesures de cet instrument.",
                );
                setIsDeletingMeasurements(false);
              }}
              okText="Oui"
              cancelText="Non"
            >
              <Button
                loading={isDeletingMeasurements}
                type="link"
                disabled={isDeletingMeasurements || from === undefined || to === undefined}
              >
                Supprimer les mesures
              </Button>
            </Popconfirm>
          </div>
        </>
      )}
    </Modal>
  );
};

export default InstrumentModal;
