import {
  DailyDHH,
  DJUSaving,
  EnergyConsumption,
  EnergyMeter,
  HeatingDistributionCircuitMeasurement,
  HeatingProductionUnitMeasurement,
  Instrument,
  InstrumentGroup,
  InstrumentGroupMeasurement,
  InstrumentMeasurement,
  Measurement,
  Weather,
} from "src/lib/api";
import { groupBy } from "src/lib/group-by";
import {
  ChartConsumptionProps,
  ChartDisplayMode,
  ChartHistoricProps,
  ChartMeasurementsProps,
  ChartProps,
  ChartSchedulingProps,
} from "src/components/Chart/Chart";
import { MeasurementDisplayMode } from "../MeasurementDisplayModeSelector";

export function timestampCompareFn(
  a: { timestamp: string | number },
  b: { timestamp: string | number },
) {
  // Turn strings into dates, and then subtract them
  // to get a value that is either negative, positive, or zero.
  return +new Date(a.timestamp) - +new Date(b.timestamp);
}

export function isInstrument(i: Instrument | InstrumentGroup | EnergyMeter): i is Instrument {
  return (
    // make sure it is not an instrument group, but it could be an energy meter.
    (i as Instrument).serialNumber !== undefined &&
    // make sure it is an instrument and not an energy meter.
    (i as Instrument).battery !== undefined &&
    // make sure it is not an instrument group.
    (i as InstrumentGroup)?.instrumentIds?.length === undefined
  );
}

export function isInstrumentGroup(
  i: Instrument | InstrumentGroup | EnergyMeter,
): i is InstrumentGroup {
  return !isInstrument(i);
}

function isInstrumentGroupMeasurement(
  m:
    | InstrumentMeasurement
    | InstrumentGroupMeasurement
    | EnergyConsumption
    | Weather
    | DailyDHH
    | DJUSaving,
): m is InstrumentGroupMeasurement {
  return (m as InstrumentGroupMeasurement).type === "instrument-group";
}

function isEnergyConsumption(
  m:
    | InstrumentMeasurement
    | InstrumentGroupMeasurement
    | EnergyConsumption
    | DailyDHH
    | Weather
    | DJUSaving,
): m is EnergyConsumption {
  return (m as EnergyConsumption).energyMeterId !== undefined;
}

function isInstrumentMeasurement(
  m: InstrumentMeasurement | InstrumentGroupMeasurement | EnergyConsumption | Weather | DailyDHH,
): m is InstrumentMeasurement {
  return !isInstrumentGroupMeasurement(m) && !isEnergyConsumption(m);
}

function isDJUSaving(
  m:
    | InstrumentMeasurement
    | InstrumentGroupMeasurement
    | EnergyConsumption
    | Weather
    | DailyDHH
    | DJUSaving,
): m is DJUSaving {
  return (m as DJUSaving).djuSaved !== undefined;
}

export function isDisplayModeTemperature(mds: MeasurementDisplayMode[]) {
  return mds.includes(MeasurementDisplayMode.Temperature);
}

export function isDisplayModeHumidity(mds: MeasurementDisplayMode[]) {
  return mds.includes(MeasurementDisplayMode.Humidity);
}

export function isDisplayModeCO2(mds: MeasurementDisplayMode[]) {
  return mds.includes(MeasurementDisplayMode.CO2);
}

export function isDisplayModeMeasurement(p: ChartProps): p is ChartMeasurementsProps {
  return [ChartDisplayMode.Measurement].includes(p.kind);
}

export function isDisplayModeConsumption(p: ChartProps): p is ChartConsumptionProps {
  return [ChartDisplayMode.Consumption].includes(p.kind);
}

export function isDisplayModeHistoric(p: ChartProps): p is ChartHistoricProps {
  return [ChartDisplayMode.Historic].includes(p.kind);
}

export function isDisplayModeSchedulingMeasurement(
  props: ChartProps,
): props is ChartSchedulingProps {
  return [ChartDisplayMode.SchedulingMeasurement].includes(props.kind);
}

function isWeather(m: Measurement | Weather): m is Weather {
  return (
    (m as Weather).airTemperature !== undefined && (m as Weather).relativeHumidity !== undefined
  );
}

function isHdcMeasurement(
  m: HeatingDistributionCircuitMeasurement,
): m is HeatingDistributionCircuitMeasurement {
  return "heatingDistributionId" in m;
}

function isHpuMeasurement(
  m: HeatingProductionUnitMeasurement,
): m is HeatingProductionUnitMeasurement {
  return "heatingProductionUnitId" in m;
}

function measurementChartKeyFromMeasurement(
  m: InstrumentMeasurement | InstrumentGroupMeasurement | EnergyConsumption,
  x: "t" | "h" | "c" | "co2",
): string {
  if (isInstrumentMeasurement(m)) return `i-${x}-${m.instrumentId}`;
  else if (isInstrumentGroupMeasurement(m)) return `ig-${x}-${m.instrumentId}`;
  else return `em-${x}-${m.energyMeterId}`;
}

function interpolateDataInstrumentAndInstrumentGroups(props: ChartMeasurementsProps) {
  const ws = props.weatherData.weather;
  const cd = [...props.measurements, ...ws];

  return Object.entries(groupBy(cd, (m) => m.timestamp))
    .map(([t, ms]) =>
      ms.reduce(
        (acc, curr) => ({
          ...acc,
          ...(isWeather(curr)
            ? curr
            : {
                [measurementChartKeyFromMeasurement(curr, "t")]: curr.temperatureMeasurement,
                [measurementChartKeyFromMeasurement(curr, "h")]: curr.humidityMeasurement,
                [measurementChartKeyFromMeasurement(curr, "co2")]: curr.co2Measurement,
              }),
        }),
        { timestamp: t },
      ),
    )
    .sort(timestampCompareFn);
}

function computeMwhDju(data: any) {
  return data.map((d: any) => {
    const energyKey = `em-c-${d?.energyMeterId}`;
    if (Object.keys(d).includes(energyKey) && d.hourHdd !== undefined && d.hourHdd !== 0) {
      return { ...d, mwhDju: Math.round((d[energyKey] / d.hourHdd) * 100) / 100 };
    }
    return { ...d, mwhDju: undefined };
  });
}

function interpolateDataEnergyMeter(props: ChartConsumptionProps) {
  const ws = props.weatherData.weather.map((w) => ({ ...w, date: w.timestamp }));
  const rgms = props.referenceGroupsMeasurements.map((rgm: InstrumentGroupMeasurement) => ({
    ...rgm,
    date: rgm.timestamp,
  }));
  const djuss = props.djuSavings.map((dju) => ({ ...dju, date: dju.timestamp }));
  const cd = [...props.consumptions, ...props.weatherData.dailyHDD, ...ws, ...rgms, ...djuss];

  const data = Object.entries(groupBy(cd, (m) => m.date))
    .map(([d, ms]) =>
      ms.reduce(
        (acc, curr) => ({
          ...acc,
          ...(isWeather(curr as Weather) ? curr : {}),
          ...(isInstrumentGroupMeasurement(curr)
            ? {
                [`rgm-${curr.instrumentId}`]: curr.temperatureMeasurement,
              }
            : {}),
          ...(isEnergyConsumption(curr)
            ? {
                [measurementChartKeyFromMeasurement(curr, "c")]: curr.consumption,
                energyMeterId: curr?.energyMeterId,
              }
            : (curr as DailyDHH)?.hdd !== undefined
              ? {
                  hdd: Math.round((curr as DailyDHH)?.hdd * 100) / 100,
                  hourHdd: Math.round((curr as DailyDHH)?.hourHdd * 100) / 100,
                }
              : {}),
          ...(isDJUSaving(curr)
            ? { [`djus-${curr.hdcId}`]: Math.round(curr.djuSaved * 100) / 100 }
            : {}),
        }),
        { timestamp: d },
      ),
    )
    .sort(timestampCompareFn);

  return computeMwhDju(data);
}

function interpolateDataHistoric(props: ChartHistoricProps) {
  const ws = props.weatherData.weather.map((w) => ({ ...w, date: w.timestamp }));
  const hdc = props.hdcMeasurements.map((hdc) => ({ ...hdc, date: hdc.timestamp }));
  const hpu = props.hpuMeasurements.map((hpu) => ({ ...hpu, date: hpu.timestamp }));
  const cd = [...props.weatherData.dailyHDD, ...ws, ...hdc, ...hpu];

  return Object.entries(groupBy(cd, (m) => m.date))
    .map(([d, ms]) =>
      ms.reduce(
        (acc, curr) => ({
          ...acc,
          ...(isWeather(curr as Weather) ? curr : {}),
          ...(isHdcMeasurement(curr as HeatingDistributionCircuitMeasurement) ? curr : {}),
          ...(isHpuMeasurement(curr as HeatingProductionUnitMeasurement) ? curr : {}),
        }),
        { timestamp: d },
      ),
    )
    .sort(timestampCompareFn);
}

function interpolateDataSchedulingMeasurement(props: ChartSchedulingProps) {
  return props.data.sort(timestampCompareFn);
}

export function interpolateData(
  props: ChartMeasurementsProps | ChartConsumptionProps | ChartHistoricProps | ChartSchedulingProps,
) {
  switch (props.kind) {
    case ChartDisplayMode.Measurement:
      return interpolateDataInstrumentAndInstrumentGroups(props);
    case ChartDisplayMode.Historic:
      return interpolateDataHistoric(props);
    case ChartDisplayMode.Consumption:
      return interpolateDataEnergyMeter(props);
    case ChartDisplayMode.SchedulingMeasurement:
      return interpolateDataSchedulingMeasurement(props);
  }
}
