import { useAuth0 } from "@auth0/auth0-react";
import { Button, Collapse, Divider, Form, InputNumber, Popover, Select } from "antd";
import * as React from "react";
import { regulationParametersParams } from "src/constants";
import { Permissions, hasPermission } from "src/lib/access-control";
import { RegulationParameterLabel, RegulationType, Site } from "src/lib/api";
import {
  useSiteHeatingDistributionRegulationParameters,
  useSiteHeatingInstallationCircuitSaveRegulationParameters,
  useSiteHeatingInstallationCircuitUpdateRegulationParameters,
} from "src/lib/hooks/api";
import { withNotify } from "src/lib/notify";
import ErrorBoundaryWithFallback from "./ErrorBoundaryWithFallback";
import Loader from "./Loader";

const RegulationParameterInput: React.FC<{
  label: RegulationParameterLabel;
  disabled: boolean;
}> = ({ label, disabled }) => {
  const params = regulationParametersParams[label];

  return (
    <>
      <Form.Item
        label={params.title}
        name={label}
        hasFeedback={true}
        style={{ marginTop: 20 }}
        tooltip={params.description}
        rules={[
          {
            type: params.type ?? "number",
            min: params.min,
            max: params.max,
            message: `${params.title} doit être comprise entre ${params.min} et ${
              params.max
            } et type ${params.type ?? "number"}`,
          },
        ]}
      >
        <InputNumber autoFocus={true} style={{ width: 100 }} disabled={disabled} />
      </Form.Item>
      <Popover
        title={params.title}
        content={
          <div>
            <p>Comparer les mesures de plusieurs sondes entre elles.</p>
          </div>
        }
      />
    </>
  );
};

const RegulationParametersDashboard: React.FC<{ siteSlug: string; hdcid: number }> = ({
  siteSlug,
  hdcid,
}) => {
  const [form] = Form.useForm();
  const [isSavingParams, setIsSavingParams] = React.useState(false);
  const [paramMode, setParamMode] = React.useState<"edit" | "consult">("consult");

  const { data: initRegulationParameters } = useSiteHeatingDistributionRegulationParameters(
    siteSlug,
    hdcid.toString(),
  );

  const [selectedMode, setSelectedMode] = React.useState<RegulationType>(
    initRegulationParameters?.regulationType as RegulationType,
  );

  const { mutateAsync: updateRegulationParameters } =
    useSiteHeatingInstallationCircuitUpdateRegulationParameters(siteSlug, hdcid.toString());

  function onUpdateParams() {
    form
      .validateFields()
      .then(
        async ({
          targetTemperature,
          noHeatingTemperature,
          heatingTimeConstant,
          coolingTimeConstant,
          heatingLawSlope,
          temperatureThreshold1,
          alphaThreshold1,
          temperatureThreshold2,
          alphaThreshold2,
          solarNormalisation,
          solarWeight,
          solarMaxTemperature,
          solarMeanTemperature,
          offsetUpperLimit,
          offsetLowerLimit,
          customOffset,
        }) => {
          setIsSavingParams(true);

          await withNotify(
            updateRegulationParameters({
              targetTemperature: targetTemperature ?? initRegulationParameters.targetTemperature,
              noHeatingTemperature:
                noHeatingTemperature ?? initRegulationParameters.noHeatingTemperature,
              airParameters: {
                heatingTimeConstant:
                  heatingTimeConstant ?? initRegulationParameters.airParameters.heatingTimeConstant,
                coolingTimeConstant:
                  coolingTimeConstant ?? initRegulationParameters.airParameters.coolingTimeConstant,
              },
              slopeParameters: {
                temperatureThreshold1:
                  temperatureThreshold1 ??
                  initRegulationParameters.slopeParameters.temperatureThreshold1,
                alphaThreshold1:
                  alphaThreshold1 ?? initRegulationParameters.slopeParameters.alphaThreshold1,
                temperatureThreshold2:
                  temperatureThreshold2 ??
                  initRegulationParameters.slopeParameters.temperatureThreshold2,
                alphaThreshold2:
                  alphaThreshold2 ?? initRegulationParameters.slopeParameters.alphaThreshold2,
              },
              solarParameters: {
                solarNormalisation:
                  solarNormalisation ?? initRegulationParameters.solarParameters.solarNormalisation,
                solarWeight: solarWeight ?? initRegulationParameters.solarParameters.solarWeight,
                solarMaxTemperature:
                  solarMaxTemperature ??
                  initRegulationParameters.solarParameters.solarMaxTemperature,
                solarMeanTemperature:
                  solarMeanTemperature ??
                  initRegulationParameters.solarParameters.solarMeanTemperature,
              },
              heatingLawSlope: heatingLawSlope ?? initRegulationParameters.heatingLawSlope,
              offsetUpperLimit: offsetUpperLimit ?? initRegulationParameters.offsetUpperLimit,
              offsetLowerLimit: offsetLowerLimit ?? initRegulationParameters.offsetLowerLimit,
              customOffset: customOffset ?? initRegulationParameters.customOffset,
              regulationType: selectedMode ?? initRegulationParameters.regulationType,
            }),
            "La mise à jour des paramètres a bien été prise en compte.",
            "Une erreur est survenue lors de la mise à jour des paramètres.",
          );
          setIsSavingParams(false);
          setParamMode("consult");
        },
      )
      .catch((err: any) => {
        console.error("Scheduling parameter update error:", err);
      });
  }

  function onCancelEdit() {
    setParamMode("consult");
    form.resetFields();
  }

  return (
    <>
      <Form
        layout={"inline"}
        form={form}
        name="scheduling-parameters"
        initialValues={{
          targetTemperature: initRegulationParameters?.targetTemperature,
          noHeatingTemperature: initRegulationParameters?.noHeatingTemperature,
          heatingTimeConstant: initRegulationParameters?.airParameters?.heatingTimeConstant,
          coolingTimeConstant: initRegulationParameters?.airParameters?.coolingTimeConstant,
          heatingLawSlope: initRegulationParameters?.heatingLawSlope,
          temperatureThreshold1: initRegulationParameters?.slopeParameters?.temperatureThreshold1,
          alphaThreshold1: initRegulationParameters?.slopeParameters?.alphaThreshold1,
          temperatureThreshold2: initRegulationParameters?.slopeParameters?.temperatureThreshold2,
          alphaThreshold2: initRegulationParameters?.slopeParameters?.alphaThreshold2,
          solarNormalisation: initRegulationParameters?.solarParameters?.solarNormalisation,
          solarWeight: initRegulationParameters?.solarParameters?.solarWeight,
          solarMaxTemperature: initRegulationParameters?.solarParameters?.solarMaxTemperature,
          solarMeanTemperature: initRegulationParameters?.solarParameters?.solarMeanTemperature,
          offsetUpperLimit: initRegulationParameters?.offsetUpperLimit,
          offsetLowerLimit: initRegulationParameters?.offsetLowerLimit,
          customOffset: initRegulationParameters?.customOffset,
          regulationType: initRegulationParameters?.regulationType,
        }}
      >
        <>
          <Divider>Paramètres généraux</Divider>
        </>
        <RegulationParameterInput
          label={RegulationParameterLabel.TargetTemperature}
          disabled={paramMode === "consult"}
        />
        <RegulationParameterInput
          label={RegulationParameterLabel.NoHeatingTemperature}
          disabled={paramMode === "consult"}
        />
        <Divider>Constantes de chauffe</Divider>
        <RegulationParameterInput
          label={RegulationParameterLabel.HeatingTimeConstant}
          disabled={paramMode === "consult"}
        />
        <RegulationParameterInput
          label={RegulationParameterLabel.CoolingTimeConstant}
          disabled={paramMode === "consult"}
        />
        <RegulationParameterInput
          label={RegulationParameterLabel.HeatingLawSlope}
          disabled={paramMode === "consult"}
        />
        <Divider>Seuils de température</Divider>
        <RegulationParameterInput
          label={RegulationParameterLabel.TemperatureThreshold1}
          disabled={paramMode === "consult"}
        />
        <RegulationParameterInput
          label={RegulationParameterLabel.AlphaThreshold1}
          disabled={paramMode === "consult"}
        />
        <RegulationParameterInput
          label={RegulationParameterLabel.TemperatureThreshold2}
          disabled={paramMode === "consult"}
        />
        <RegulationParameterInput
          label={RegulationParameterLabel.AlphaThreshold2}
          disabled={paramMode === "consult"}
        />
        <Divider>Paramètres solaires</Divider>
        <RegulationParameterInput
          label={RegulationParameterLabel.SolarNormalisation}
          disabled={paramMode === "consult"}
        />
        <RegulationParameterInput
          label={RegulationParameterLabel.SolatWeight}
          disabled={paramMode === "consult"}
        />
        <RegulationParameterInput
          label={RegulationParameterLabel.SolarMaxTemperature}
          disabled={paramMode === "consult"}
        />
        <RegulationParameterInput
          label={RegulationParameterLabel.SolarMeanTemperature}
          disabled={paramMode === "consult"}
        />
        <Divider>Contrôle de l'offset</Divider>
        <RegulationParameterInput
          label={RegulationParameterLabel.OffsetUpperLimit}
          disabled={paramMode === "consult"}
        />
        <RegulationParameterInput
          label={RegulationParameterLabel.OffsetLowerLimit}
          disabled={paramMode === "consult"}
        />
        <div>
          <text>Mode de régulation: </text>
          <Select
            defaultValue={selectedMode}
            style={{ width: 200, marginTop: 20, marginLeft: 10, marginRight: 10 }}
            placeholder="Sélectionner un mode de régulation"
            onChange={(mode: RegulationType) => setSelectedMode(mode)}
            disabled={paramMode === "consult"}
          >
            <Select.Option value={RegulationType.Eco}>Prédictop - éco</Select.Option>
            <Select.Option value={RegulationType.Comfort}>Prédictop - confort</Select.Option>
            <Select.Option value={RegulationType.Ecco2}>Ecco2</Select.Option>
            <Select.Option value={RegulationType.Custom}>Offset forcé</Select.Option>
            <Select.Option value={RegulationType.None}>Aucune</Select.Option>
          </Select>
        </div>
        <RegulationParameterInput
          label={RegulationParameterLabel.CustomOffset}
          disabled={paramMode === "consult"}
        />
      </Form>
      <div style={{ justifyContent: "center", display: "flex", margin: 30 }}>
        <Button
          type="primary"
          loading={isSavingParams}
          onClick={paramMode === "edit" ? () => onUpdateParams() : () => setParamMode("edit")}
        >
          {paramMode === "edit" ? "Enregistrer" : "Modifier"}
        </Button>
        {paramMode === "edit" && (
          <Button
            type="ghost"
            style={{ color: "red", marginLeft: 10 }}
            loading={isSavingParams}
            onClick={onCancelEdit}
          >
            Abandonner
          </Button>
        )}
      </div>
    </>
  );
};

const RegulationParametersDashboardFallback: React.FC<{
  site: Site;
  hdcid: number;
  resetErrorBoundary: () => void;
}> = ({ site, hdcid, resetErrorBoundary }) => {
  const { user } = useAuth0();
  const [isSavingParams, setIsSavingParams] = React.useState(false);
  const { mutateAsync: saveRegulationParameters } =
    useSiteHeatingInstallationCircuitSaveRegulationParameters(site.slug, hdcid.toString());

  async function onInitiateRegulation() {
    setIsSavingParams(true);
    await withNotify(
      saveRegulationParameters({
        targetTemperature: site.currentHeatingSeason.comfortRange.target,
        noHeatingTemperature: 20,
        airParameters: {
          heatingTimeConstant: 4,
          coolingTimeConstant: 4,
        },
        slopeParameters: {
          temperatureThreshold1: 0,
          alphaThreshold1: 95,
          temperatureThreshold2: 5,
          alphaThreshold2: 98,
        },
        solarParameters: {
          solarNormalisation: 1000,
          solarWeight: 10,
          solarMaxTemperature: 25,
          solarMeanTemperature: 20,
        },
        heatingLawSlope: -2,
        offsetUpperLimit: 0,
        offsetLowerLimit: -10,
        customOffset: 0,
        regulationType: "none",
      }),
      "L'initialisation de la régulation prédictive a bien été prise en compte, vous pouvez désormais régler vos paramètres.",
      "Une erreur est survenue lors de l'initialisation de la régulation prédictive.",
    );
    setIsSavingParams(false);
    resetErrorBoundary();
  }

  return (
    <>
      <div>
        Ce circuit n'est pas configuré en régulation. Si vous êtes administrateur Efficap vous
        pouvez activer la régulation prédictive pour ce circuit en cliquant sur le bouton
        ci-dessous. Les paramètres par défaut seront attribués dans un premier temps et vous n'aurez
        plus qu'à les éditer ici ou dans l'onglet "Installations de chauffage".
      </div>
      <div style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}>
        <Button
          type="primary"
          disabled={!hasPermission(user, Permissions.HeatingInstallationsUpdate)}
          loading={isSavingParams}
          onClick={onInitiateRegulation}
        >
          Initier la régulation prédictive
        </Button>
      </div>
    </>
  );
};

const RegulationParametersDashboardWithFallback: React.FC<{ site: Site; hdcid: number }> = ({
  site,
  hdcid,
}) => {
  const [resetKey, setResetKey] = React.useState(0);

  const handleReset = () => setResetKey(resetKey + 1);

  return (
    <Collapse>
      <Collapse.Panel header="Réglage des paramètres de régulation" key="1">
        <ErrorBoundaryWithFallback
          fallback={
            <RegulationParametersDashboardFallback
              site={site}
              hdcid={hdcid}
              resetErrorBoundary={handleReset}
            />
          }
          key={`regulation-parameters-dashboard-${resetKey}`}
        >
          <React.Suspense fallback={<Loader />}>
            <RegulationParametersDashboard siteSlug={site.slug} hdcid={hdcid} />
          </React.Suspense>
        </ErrorBoundaryWithFallback>
      </Collapse.Panel>
    </Collapse>
  );
};

export default RegulationParametersDashboardWithFallback;
