import { useAuth0 } from "@auth0/auth0-react";
import { Card, Space, Button, Switch, Typography } from "antd";
import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { hasPermission, Permissions } from "src/lib/access-control";
import { HeatingDistributionCircuit } from "src/lib/api";
import {
  useSiteHeatingInstallationCircuitPredictiveSystem,
  useSiteHeatingInstallationCircuitPredictiveSystemToggle,
  queryKeySiteHeatingInstallationCircuitPredictiveSystem,
} from "src/lib/hooks/api";
import ExportRegulationMeasurementsModal from "./ExportRegulationMeasurementsModal";
import { ReloadOutlined, VerticalAlignMiddleOutlined, DownloadOutlined } from "@ant-design/icons";

const PredictiveSystemControls: React.FC<{
  siteSlug: string;
  heatingDistributionCircuit: HeatingDistributionCircuit;
}> = ({ siteSlug, heatingDistributionCircuit }) => {
  const queryClient = useQueryClient();
  const { user } = useAuth0();
  const [visible, setVisible] = React.useState(false);
  const { data: predictiveSystem } = useSiteHeatingInstallationCircuitPredictiveSystem(
    siteSlug,
    heatingDistributionCircuit.id.toString(),
  );

  const { mutateAsync: togglePredictiveSystem } =
    useSiteHeatingInstallationCircuitPredictiveSystemToggle(
      siteSlug,
      heatingDistributionCircuit.id.toString(),
    );
  const [togglePredictiveSystemLoading, setTogglePredictiveSystemLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const onTogglePredictiveSystem = async () => {
    setTogglePredictiveSystemLoading(true);
    await togglePredictiveSystem();
    setTogglePredictiveSystemLoading(false);
  };

  const onRefresh = async () => {
    setLoading(true);
    await Promise.all([
      queryClient.invalidateQueries(
        queryKeySiteHeatingInstallationCircuitPredictiveSystem(
          siteSlug,
          heatingDistributionCircuit.id.toString(),
        ),
      ),
    ]);
    setLoading(false);
  };

  return (
    <Card
      title={
        <Space direction="horizontal">
          <ExportRegulationMeasurementsModal
            siteSlug={siteSlug}
            showModal={visible}
            closeModal={() => {
              setVisible(false);
            }}
            heatingDistributionCircuit={heatingDistributionCircuit}
          />
          <VerticalAlignMiddleOutlined />
          Gestion des commandes prédictives
        </Space>
      }
      extra={
        <div style={{ display: "flex" }}>
          {hasPermission(user, Permissions.ExportCreate) && (
            <>
              <Button
                id="export-regulation-measurement-button"
                type="primary"
                onClick={() => setVisible(true)}
                icon={<DownloadOutlined />}
              >
                Export des mesures
              </Button>
              <div style={{ width: 20 }}></div>
            </>
          )}
          <Button icon={<ReloadOutlined />} onClick={onRefresh} />
        </div>
      }
      loading={loading}
    >
      <div style={{ marginBottom: "0.5rem" }}>
        <b>Commande Locale prédictif</b>
        <Switch
          style={{ marginLeft: "0.5rem" }}
          checkedChildren={"I"}
          unCheckedChildren={"O"}
          checked={predictiveSystem.localState === "on"}
          disabled={true}
        />
      </div>
      <div style={{ marginBottom: "0.5rem" }}>
        <b>Commande Remote prédictif</b>
        <Switch
          style={{ marginLeft: "0.5rem" }}
          loading={togglePredictiveSystemLoading}
          checkedChildren={"I"}
          unCheckedChildren={"O"}
          checked={predictiveSystem.remoteState === "on"}
          onClick={onTogglePredictiveSystem}
          disabled={!hasPermission(user, Permissions.HeatingInstallationsOffsetToggleUpdate)}
        />
      </div>
      <div style={{ marginBottom: "0.5rem" }}>
        <span>
          <b>Offset lu sur l'automate:</b> {predictiveSystem?.offset.toFixed(2) ?? "-"} °K
        </span>
      </div>
    </Card>
  );
};

export const PredictiveSystemControlsFallback: React.FC<{}> = () => {
  return (
    <Card title="Gestion des commandes prédictives">
      <Typography.Text>
        Les commandes de contrôle du prédictif ne sont pas configurées pour ce circuit.
      </Typography.Text>
    </Card>
  );
};

export default PredictiveSystemControls;
