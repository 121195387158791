import Text from "antd/lib/typography/Text";
import { LabelProps } from "recharts";
import { CartesianViewBox } from "recharts/types/util/types";
import { dashboardColors } from "src/constants";

export const DashboardNoData: React.FC = () => {
  return (
    <div style={{ textAlign: "center", fontSize: 14, fontStyle: "italic", padding: 20 }}>
      <Text>Aucune donnée à afficher</Text>
    </div>
  );
};

function svgLabel(x: number, y: number, sx: number, sy: number) {
  return `M ${x} ${y} m ${2 * sx} 0 q 0 ${3 * sy} ${12 * sx} ${sy * 9} q ${sx * 6} ${sy * 3} ${
    sx * 12
  } ${sy * 3} l ${sx * 39} -0 q ${sx * 3} -0 ${sx * 3} ${sy * -3} l -0 ${sy * -18} q -0 ${
    sy * -3
  } ${sx * -3} ${sy * -3} l ${sx * -39} 0 q ${sx * -6} 0 ${sx * -12} ${sy * 3} q ${sx * -12} ${
    sy * 6
  } ${sx * -12} ${sy * 9}`;
}

function svgThermometerOuter(x: number, y: number, scale?: number) {
  const s = scale ? scale : 1;
  return `M ${x} ${y} m 0 ${s * 23} a ${s * 4} ${s * 4} 0 0 0 ${s * 4} ${s * -4} a ${s * 3} ${
    s * 3.5
  } 0 0 0 ${s * -2} ${s * -3.5} l 0 ${s * -13.5} a ${s * 1} ${s * 1} 0 0 0 ${s * -4} 0 l 0 ${
    s * 13.5
  } a ${s * 3} ${s * 3.5} 0 0 0 ${s * -2} ${s * 3.5} a ${s * 4} ${s * 4} 0 0 0 ${s * 4} ${s * 4}`;
}

function svgThermometerInner(x: number, y: number, scale?: number) {
  const s = scale ? scale : 1;
  return `M ${x} ${y} m 0 ${s * 21} a ${s * 2} ${s * 2} 0 0 0 ${s * 2} ${s * -2} a ${s * 2} ${
    s * 2
  } 0 0 0 ${s * -1.5} ${s * -2} l 0 ${s * -10} a ${s * 1} ${s * 1} 0 0 0 ${s * -1} 0 l 0 ${
    s * 10
  } a ${s * 2} ${s * 2} 0 0 0 ${s * -1.5} ${s * 2} a ${s * 2} ${s * 2} 0 0 0 ${s * 2} ${s * 2}`;
}

export const RenderThermometer: React.FC<LabelProps> = ({ viewBox }: LabelProps) => {
  const v = viewBox as CartesianViewBox;

  if (v.x !== undefined && v.y !== undefined && v.width !== undefined && v.height !== undefined) {
    const x = v.x + v.width;
    const y = v.y > 60 ? 2 : v.y + 10;

    return (
      <svg>
        <path fillOpacity={1} fill={dashboardColors.white} d={svgThermometerOuter(x, y, 2)} />
        <path
          fillOpacity={0}
          stroke={dashboardColors.grey}
          d={svgThermometerOuter(x, y, 2)}
          strokeWidth={2}
        />
        <path
          fillOpacity={1}
          fill={dashboardColors.red}
          d={svgThermometerInner(x, y, 2)}
          strokeWidth={0}
        />
      </svg>
    );
  }

  return <></>;
};

interface CustomLabelProps extends LabelProps {
  labelValue: string;
  subValue?: string;
  labelTitle?: string;
  col?: string;
  borderColor?: string;
  scaleX?: number;
  scaleY?: number;
  customOffset?: { x?: number; y?: number };
  positionX: "left" | "right";
}

/**
 * A customizable label component for use with recharts ReferenceLine.
 *
 * @param {ViewBox} viewBox - ViewBox of the parent element, which is passed in intrinsically.
 * @param {string} labelValue - The text to display as the main content of the label.
 * @param {string} subValue - Additional text to display under the `labelValue`.
 * @param {string} labelTitle - The text to display as the title of the label.
 * @param {string} borderColor - The borderColor to use for the label.
 * @param {string} color - The color to use for the label text and the border if no border color specified.
 * @param {number} scaleX - A value to stretch the label horizontally. Can be used to flip the label over Y axis.
 * @param {number} scaleY - A value to stretch the label vertically.
 * @param {{x?: number, y?: number}} customOffset - The offset used to move the label. A dashed line will be drawn to the original position of the label.
 * @param {"left" | "right"} positionX - The position to display the label relative to the parent's viewBox. The label will be automatically flipped over the Y axis.
 *
 * @returns {JSX.Element} A React component for a rechart label.
 */
export const RenderLabel: React.FC<CustomLabelProps> = ({
  viewBox,
  labelValue,
  subValue,
  labelTitle,
  col,
  borderColor,
  scaleX,
  scaleY,
  customOffset,
  positionX,
}: CustomLabelProps) => {
  const v = viewBox as CartesianViewBox;
  const sx = (scaleX ? scaleX : 1) * (positionX === "left" ? -1 : 1);
  const sy = scaleY ? scaleY : 1;
  const offsetX = customOffset ? (customOffset.x ? customOffset.x : 0) : 0;
  const offsetY = customOffset ? (customOffset.y ? customOffset.y : 0) : 0;
  const color = col ? col : dashboardColors.black;

  const labelWidth = 65 * sx;
  const labelHeight = 30 * sy;

  if (v.x !== undefined && v.y !== undefined && v.width !== undefined) {
    const x = v.x + (positionX === "right" ? v.width : 0) + offsetX;
    const y = v.y + offsetY;

    return (
      <svg>
        <line
          x1={x}
          y1={y}
          x2={x - offsetX}
          y2={y}
          strokeDasharray="1,1"
          stroke={dashboardColors.grey}
        />
        <path fillOpacity={1} fill={dashboardColors.white} d={svgLabel(x, y, sx, sy)} />
        <path stroke={borderColor ?? color} fillOpacity={0.04} d={svgLabel(x, y, sx, sy)} />
        {!subValue && (
          <text
            fill={color}
            x={x + (labelWidth * 1.2) / 2}
            y={y}
            textAnchor="middle"
            dominantBaseline={"middle"}
          >
            {labelValue}
          </text>
        )}
        {subValue && (
          <>
            <text
              fill={color}
              x={x + (labelWidth * 1.2) / 2}
              y={y - labelHeight / 6}
              textAnchor="middle"
              dominantBaseline={"middle"}
            >
              {labelValue}
            </text>
            <text
              fill={color}
              x={v.x + v.width + (labelWidth * 1.2) / 2}
              y={y + labelHeight / 6}
              textAnchor="middle"
              dominantBaseline={"middle"}
            >
              {subValue}
            </text>
          </>
        )}
        {labelTitle && (
          <text
            x={x + (labelWidth * 1.2) / 2}
            y={y - labelHeight / 2}
            textAnchor="middle"
            fontSize={9}
          >
            {labelTitle}
          </text>
        )}
      </svg>
    );
  }

  return <></>;
};

export const renderLegends = (
  { viewBox }: LabelProps,
  year: string,
  yearRef: string,
  offset: { x: number; y: number },
) => {
  const v = viewBox as CartesianViewBox;

  if (v.x !== undefined && v.y !== undefined && v.width !== undefined) {
    const rectWidth = 90;
    const rectHeight = 20;

    const x = v.x + v.width - rectWidth / 2;
    const y = v.y - offset.y - rectHeight / 2;

    return (
      <svg>
        <rect
          x={x - rectWidth / 2 - offset.x}
          y={y - rectHeight / 2}
          width={rectWidth}
          height={rectHeight}
          fill={dashboardColors.yellowFade}
          rx={5}
        />
        <text
          x={x - offset.x}
          y={y}
          textAnchor="middle"
          dominantBaseline={"central"}
          fill={dashboardColors.white}
          fontSize={17}
          fontStyle="italic"
        >
          {year}
        </text>

        <rect
          x={x - rectWidth / 2 - offset.x - rectWidth - 5}
          y={y - rectHeight / 2}
          width={rectWidth}
          height={rectHeight}
          fill={dashboardColors.blueFade}
          rx={5}
        />
        <text
          x={x - offset.x - rectWidth - 5}
          y={y}
          textAnchor="middle"
          dominantBaseline={"central"}
          fill={dashboardColors.white}
          fontSize={17}
          fontStyle="italic"
        >
          {yearRef}
        </text>
      </svg>
    );
  }
};
