import { Button, Divider, Popover, Select, Space, Typography } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { RcFile } from "antd/lib/upload/interface";
import * as React from "react";
import { buildingInstrumentImageRatio } from "src/constants";
import {
  AbstractImagePosition,
  ImageElementPosition,
  ImageInstrumentGroupPosition,
  ImageInstrumentPosition,
  Instrument,
  InstrumentGroup,
  InstrumentInstrumentGroup,
  InstrumentInstrumentGroupType,
  Site,
  SiteImage,
} from "src/lib/api";
import {
  useDeleteImageInstrumentGroupPositions,
  useDeleteImageInstrumentPositions,
  useImagesInstrumentGroupPositions,
  useImagesInstrumentPositions,
  useSiteBuildingImages,
  useSiteInstrumentGroups,
  useSiteInstruments,
  useUploadImageInstrumentGroupPositions,
  useUploadImageInstrumentPositions,
} from "src/lib/hooks/api";
import { withNotify } from "src/lib/notify";
import { ImagePositionOverlay, useImageDrawing } from "./HOC/useImageDrawing";
import InteractiveImageCarousel from "./InteractiveImageCarousel";

export const carouselMaxWidth = 800;

const InstrumentPositionConfigurationTab: React.FC<{ site: Site }> = ({ site }) => {
  const { data: instruments = [] } = useSiteInstruments(site.slug);
  const { data: instrumentGroups = [] } = useSiteInstrumentGroups(site.slug);
  const [selectedInstrumentInstrumentGroup, setSelectedInstrumentInstrumentGroup] =
    React.useState<InstrumentInstrumentGroup>();
  const instrumentInstrumentGroupList: InstrumentInstrumentGroup[] =
    instruments
      .map((i: Instrument) => ({
        id: i.id,
        type: "instrument" as InstrumentInstrumentGroupType,
        name: i.name,
        comments: i.comments,
      }))
      .concat(
        instrumentGroups.map((ig: InstrumentGroup) => ({
          id: ig.id,
          type: "instrument-group",
          name: ig.name,
          comments: ig.comments,
        })),
      ) ?? [];
  const carouselRef: React.RefObject<CarouselRef> = React.createRef();
  const siteBuildingImageRes = useSiteBuildingImages(site.slug);
  const siteBuildingImages =
    siteBuildingImageRes.status === "success" ? siteBuildingImageRes.data : [];
  const imageIds = siteBuildingImages?.map((si: SiteImage) => si.id);
  const refImageInstrumentPositionsRes = useImagesInstrumentPositions(site.slug, imageIds);
  const refImageInstrumentPositions =
    refImageInstrumentPositionsRes.status === "success" ? refImageInstrumentPositionsRes.data : [];
  const [imageInstrumentPositions, setImageInstrumentPositions] = React.useState<
    ImageInstrumentPosition[]
  >(refImageInstrumentPositions);

  const refImageInstrumentGroupPositionsRes = useImagesInstrumentGroupPositions(
    site.slug,
    imageIds,
  );
  const refImageInstrumentGroupPositions =
    refImageInstrumentGroupPositionsRes.status === "success"
      ? refImageInstrumentGroupPositionsRes.data
      : [];
  const [imageInstrumentGroupPositions, setImageInstrumentGroupPositions] = React.useState<
    ImageInstrumentGroupPosition[]
  >(refImageInstrumentGroupPositions);
  const { mutateAsync: uploadImagePosition } = useUploadImageInstrumentPositions(site.slug);
  const { mutateAsync: deleteImagePosition } = useDeleteImageInstrumentPositions(site.slug);
  const { mutateAsync: uploadImageGroupPosition } = useUploadImageInstrumentGroupPositions(
    site.slug,
  );
  const { mutateAsync: deleteImageGroupPosition } = useDeleteImageInstrumentGroupPositions(
    site.slug,
  );
  const [isSavingImageInstrumentPositions, setIsSavingImageInstrumentPositions] =
    React.useState<boolean>(false);
  const { addImagePosition, uploadImage, deleteImage, isPositionForElement } = useImageDrawing(
    site.slug,
    null,
  );

  async function onUploadImage(file: RcFile, filename: string) {
    uploadImage(file, filename);
  }

  async function onDeleteImage(file: any) {
    await deleteImage(file, () => {
      // removing image related positions (locally)
      setImageInstrumentPositions(
        [...imageInstrumentPositions].filter(
          (iip: ImageInstrumentPosition) => iip.imageId !== file.id,
        ),
      );
      // removing image related positions (locally)
      setImageInstrumentGroupPositions(
        [...imageInstrumentGroupPositions].filter(
          (iigp: ImageInstrumentGroupPosition) => iigp.imageId !== file.id,
        ),
      );
    });
  }

  async function onSaveImagePositions() {
    setIsSavingImageInstrumentPositions(true);

    const instrumentPositionsToDelete: ImageInstrumentPosition[] = [];
    refImageInstrumentPositions.forEach((iip: ImageInstrumentPosition) => {
      const allInstrumentPositions = imageInstrumentPositions.map(
        (ip: ImageInstrumentPosition) => ({
          elementId: ip.instrumentId,
          ...ip,
        }),
      );
      if (!isPositionForElement(iip.imageId, allInstrumentPositions, iip.instrumentId)) {
        instrumentPositionsToDelete.push(iip);
      }
    });

    const instrumentGroupPositionsToDelete: ImageInstrumentGroupPosition[] = [];
    const allInstrumentGroupPositions = imageInstrumentGroupPositions.map(
      (ip: ImageInstrumentGroupPosition) => ({
        elementId: ip.instrumentGroupId,
        ...ip,
      }),
    );
    refImageInstrumentGroupPositions.forEach((iigp: ImageInstrumentGroupPosition) => {
      if (
        !isPositionForElement(iigp.imageId, allInstrumentGroupPositions, iigp.instrumentGroupId)
      ) {
        instrumentGroupPositionsToDelete.push(iigp);
      }
    });

    async function updateAndDelete() {
      await uploadImagePosition(imageInstrumentPositions);
      await deleteImagePosition(instrumentPositionsToDelete);
      await uploadImageGroupPosition(imageInstrumentGroupPositions);
      await deleteImageGroupPosition(instrumentGroupPositionsToDelete);
    }

    await withNotify(
      updateAndDelete(),
      "Les nouvelles positions d'instruments ont été enregistrées avec succès",
      "Echec lors de la sauvegarde des positions d'instruments",
    );

    setIsSavingImageInstrumentPositions(false);
  }

  function onAddImagePosition(imageId: number, coordinates: ImageElementPosition) {
    if (!selectedInstrumentInstrumentGroup) {
      return;
    }
    const addElement = selectedInstrumentInstrumentGroup;

    if (selectedInstrumentInstrumentGroup.type === "instrument") {
      const allPositions = imageInstrumentPositions.map((ip: ImageInstrumentPosition) => ({
        elementId: ip.instrumentId,
        ...ip,
      }));

      const setAllPositions = (aps: AbstractImagePosition[]) => {
        setImageInstrumentPositions(
          aps.map((ap: AbstractImagePosition) => ({
            instrumentId: ap.elementId,
            ...ap,
          })),
        );
      };

      return addImagePosition(addElement, allPositions, setAllPositions, imageId, coordinates);
    }

    if (selectedInstrumentInstrumentGroup.type === "instrument-group") {
      const allPositions = imageInstrumentGroupPositions.map(
        (ip: ImageInstrumentGroupPosition) => ({
          elementId: ip.instrumentGroupId,
          ...ip,
        }),
      );
      const setAllPositions = (aps: AbstractImagePosition[]) => {
        setImageInstrumentGroupPositions(
          aps.map((ap: AbstractImagePosition) => ({
            instrumentGroupId: ap.elementId,
            ...ap,
          })),
        );
      };
      return addImagePosition(addElement, allPositions, setAllPositions, imageId, coordinates);
    }
  }

  function onDeleteImagePosition(imageId: number) {
    if (!selectedInstrumentInstrumentGroup) {
      return;
    }

    if (selectedInstrumentInstrumentGroup.type === "instrument") {
      const newPositions = [...imageInstrumentPositions].filter(
        (iip: ImageInstrumentPosition) =>
          !(iip.instrumentId === selectedInstrumentInstrumentGroup.id && iip.imageId === imageId),
      );

      setImageInstrumentPositions([...newPositions]);
    } else if (selectedInstrumentInstrumentGroup.type === "instrument-group") {
      const newPositions = [...imageInstrumentGroupPositions].filter(
        (iigp: ImageInstrumentGroupPosition) =>
          !(
            iigp.instrumentGroupId === selectedInstrumentInstrumentGroup.id &&
            iigp.imageId === imageId
          ),
      );

      setImageInstrumentGroupPositions([...newPositions]);
    }
  }

  function displayInstrumentPositions(imageId: number) {
    const instrumentsPositions = instruments?.map((instrument: Instrument) => {
      const relevantInstrumentPosition = imageInstrumentPositions?.find(
        (iip: ImageInstrumentPosition) =>
          iip.imageId === imageId && iip.instrumentId === instrument.id,
      );
      if (relevantInstrumentPosition) {
        return (
          <ImagePositionOverlay
            top={relevantInstrumentPosition.yPercentage}
            left={relevantInstrumentPosition.xPercentage}
            value={instruments?.find((i: Instrument) => i.id === instrument.id)?.name}
          />
        );
      }
      return <></>;
    });
    const instrumentGroupsPositions = instrumentGroups?.map((instrumentGroup: InstrumentGroup) => {
      const relevantInstrumentPosition = imageInstrumentGroupPositions?.find(
        (iigp: ImageInstrumentGroupPosition) =>
          iigp.imageId === imageId && iigp.instrumentGroupId === instrumentGroup.id,
      );
      if (relevantInstrumentPosition) {
        return (
          <ImagePositionOverlay
            top={relevantInstrumentPosition.yPercentage}
            left={relevantInstrumentPosition.xPercentage}
            value={
              instrumentGroups?.find((ig: InstrumentGroup) => ig.id === instrumentGroup.id)?.name
            }
          />
        );
      }
      return <></>;
    });
    return [...instrumentsPositions, ...instrumentGroupsPositions];
  }

  return (
    <Space direction="vertical">
      <Space direction="vertical">
        <Typography.Text strong>Ajout de sondes en 2D</Typography.Text>
        <Space>
          <Typography.Text>
            1. Sélectionnez l'instrument à positionner dans la liste suivante
          </Typography.Text>
          <Select
            placeholder="Choisissez un instrument"
            style={{ width: 250 }}
            onChange={(id: number) => {
              const i = instrumentInstrumentGroupList.find(
                (i: InstrumentInstrumentGroup) => i.id === id,
              );
              if (i?.type === "instrument") {
                const relevantInstrument = instruments.find((i: Instrument) => i.id === id);
                setSelectedInstrumentInstrumentGroup({
                  id,
                  type: "instrument",
                  name: relevantInstrument?.name,
                  comments: relevantInstrument?.name,
                });
              } else if (i?.type === "instrument-group") {
                const relevantInstrumentGroup = instrumentGroups.find(
                  (ig: InstrumentGroup) => ig.id === id,
                );
                setSelectedInstrumentInstrumentGroup({
                  id,
                  type: "instrument-group",
                  name: relevantInstrumentGroup?.name,
                  comments: relevantInstrumentGroup?.name,
                });
              }
            }}
            allowClear
          >
            {instrumentInstrumentGroupList
              .sort((a: InstrumentInstrumentGroup, b: InstrumentInstrumentGroup) =>
                (a?.name ?? "") < (b?.name ?? "") ? -1 : 1,
              )
              .map((i: InstrumentInstrumentGroup) => {
                return (
                  <Select.Option value={i.id}>
                    <Popover title={i.name} content={i.comments} placement="left">
                      <Typography.Text>{i.name}</Typography.Text>
                    </Popover>
                  </Select.Option>
                );
              })}
          </Select>
        </Space>
        <Typography.Text>
          2. Cliquez sur l'image à l'endroit ou vous souhaiter voir apparaitre la mesure de
          l'instrument.
        </Typography.Text>
        <Typography.Text>
          3. Pour déplacer un instrument déjà placé sur une image il vous suffit de le
          resélectionner dans la liste et le positionner à nouveau sur l'image.
        </Typography.Text>
        <Typography.Text>
          Vous pouvez à tout moment ajouter une nouvelle image présentant le bâtiment sous un autre
          angle.
        </Typography.Text>
        <Typography.Text>
          Lorsque vous avez fini de placer vos points sur les images, pensez à enregistrer les
          modifications !
        </Typography.Text>
      </Space>
      <Divider />
      <div style={{ justifyContent: "space-between", display: "flex", width: carouselMaxWidth }}>
        <Space>
          <Button
            onClick={() => {
              carouselRef.current?.prev();
            }}
          >
            Précédent
          </Button>
          <Button
            onClick={() => {
              carouselRef.current?.next();
            }}
          >
            Suivant
          </Button>
        </Space>
        <Space>
          <Button
            danger
            disabled={!selectedInstrumentInstrumentGroup}
            onClick={() =>
              onDeleteImagePosition(
                siteBuildingImages[carouselRef.current?.innerSlider.state.currentSlide]?.id,
              )
            }
          >
            Supprimer la position
          </Button>
          <Button
            type="primary"
            disabled={
              JSON.stringify(imageInstrumentPositions) ===
                JSON.stringify(refImageInstrumentPositions) &&
              JSON.stringify(imageInstrumentGroupPositions) ===
                JSON.stringify(refImageInstrumentGroupPositions)
            }
            onClick={onSaveImagePositions}
            loading={isSavingImageInstrumentPositions}
          >
            Enregistrer les positions
          </Button>
        </Space>
      </div>
      <InteractiveImageCarousel
        imageList={siteBuildingImages}
        displayImageElements={(imageId: number) => displayInstrumentPositions(imageId)}
        onAddImageElementPosition={onAddImagePosition}
        imageUploader={onUploadImage}
        imageDeleter={onDeleteImage}
        carouselRef={carouselRef}
        dimensions={{
          width: carouselMaxWidth,
          height: carouselMaxWidth / buildingInstrumentImageRatio,
        }}
      />
    </Space>
  );
};

export default InstrumentPositionConfigurationTab;
