import { Row } from "antd";
import { dashboardColors } from "src/constants";
import { DailyDHH, Site } from "src/lib/api";
import {
  GetDashboardHeatingPeriods,
  consumptionOnly,
  getGeneralInfos,
} from "src/lib/dashboard-utils";
import { DashboardIndicator } from "./DashboardIndicator";

export const DashboardGeneralInfosCells: React.FC<{
  site: Site;
  consumptions: consumptionOnly[];
  hdd: DailyDHH[];
}> = ({ site, consumptions, hdd }) => {
  const [hp, hpRef] = GetDashboardHeatingPeriods(site.slug);

  const [consumptionVariation, consumptionPerHddVariation, hddVariation, totalHdd] =
    getGeneralInfos(hp, hpRef, consumptions, hdd);

  return (
    <>
      <Row style={{ padding: 5 }}>
        <DashboardIndicator
          color={dashboardColors.blue}
          title="Var. conso"
          value={`${consumptionVariation.toFixed(1)}%`}
          subTitle="sur la saison de chauffe en cours"
        />
      </Row>

      <Row style={{ padding: 5 }}>
        <DashboardIndicator
          color={dashboardColors.green}
          title="Var. conso à DJU constant"
          value={`${consumptionPerHddVariation.toFixed(1)}%`}
          subTitle="sur la saison de chauffe en cours"
        />
      </Row>

      <Row style={{ padding: 5 }}>
        <DashboardIndicator
          color={dashboardColors.lightYellow}
          title="Variation de DJU"
          value={`${hddVariation.toFixed()}%`}
          subTitle={`(${totalHdd.toFixed(0)} sur la saison de chauffe)`}
        />
      </Row>
    </>
  );
};
