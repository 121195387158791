import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  PlusOutlined,
  QuestionCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Statistic,
  Table,
  Typography,
} from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { ColumnType } from "antd/lib/table";
import moment, { Moment } from "moment";
import React, { useRef, useState } from "react";
import { colors } from "src/constants";
import { globalStyles } from "src/globalStyles";
import { Permissions, hasPermission } from "src/lib/access-control";
import { HeatingSeason, SeasonStarChecks, Site } from "src/lib/api";
import getHeatingPeriod, { defaultHeatingPeriod } from "src/lib/heating-period";
import { useSites, useUpdateHeatingPeriod } from "src/lib/hooks/api";
import { useWindowWidth } from "src/lib/hooks/window-width";
import { withNotify } from "src/lib/notify";
import { GetColumnSearchPropsState, getColumnSearchProps, sorter } from "src/lib/tableutils";
import { isHeatingReferent } from "src/lib/utils";
import { ConfortRangeForm } from "./ComfortRange";
import TooltipButton from "./TooltipButton";

export function isMissingConfig(hp: HeatingSeason) {
  if (hp.isDefaultPeriod) {
    return true;
  } else if (hp.isDefaultComfortRange) {
    return true;
  } else if (!Object.values(hp.seasonStartChecks).every((value) => value === true)) {
    return true;
  }

  return false;
}

const FormCheckbox: React.FC<{
  label: string;
  formKey: string[];
}> = ({ label, formKey }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <span style={{ margin: 5 }}>{label}</span>
      <Form.Item name={formKey} valuePropName="checked">
        <Checkbox />
      </Form.Item>
    </div>
  );
};

const GeneralInformation: React.FC<{ heatingSeason: HeatingSeason; form: FormInstance<any> }> = ({
  heatingSeason,
  form,
}) => {
  const { isWideScreen } = useWindowWidth();

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Divider>
        <Space>
          <Typography.Text strong>Etape 1 - Date d’allumage du chauffage</Typography.Text>
          <Button
            icon={<QuestionCircleTwoTone />}
            style={{ border: "none", boxShadow: "none" }}
            href="https://www.notion.so/D-marrage-de-la-saison-de-chauffe-87bc53b209c34aee8fc80120e8df37b2?pvs=4#3b09443429c94ffca3fa525bfd1540ef"
            target="_blank"
          />
        </Space>
      </Divider>
      <Form form={form}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form.Item name={"startTime"} label="Date d'allumage du chauffage">
            <DatePicker
              style={{ width: "100%" }}
              disabledDate={(current: Moment | null) =>
                current ? current?.isAfter(form.getFieldsValue().endTime) : true
              }
            />
          </Form.Item>
          <FormCheckbox
            formKey={["seasonStartChecks", "startTimeValidation"]}
            label="Je confirme la date d'allumage du chauffage dans ma résidence (cocher la case) :"
          />
        </Space>
        <Divider>
          <Space>
            <Typography.Text strong>
              Etape 2 - Températures cibles de confort intérieur
            </Typography.Text>
            <Button
              icon={<QuestionCircleTwoTone />}
              style={{ border: "none", boxShadow: "none" }}
              href="https://www.notion.so/D-marrage-de-la-saison-de-chauffe-87bc53b209c34aee8fc80120e8df37b2?pvs=4#f22cdb0c9dd84be89bd5e69251827168"
              target="_blank"
            />
          </Space>
        </Divider>
        <Typography.Text>
          Renseignez les températures cibles du site pour la saison de chauffe :
        </Typography.Text>
        <Space direction="vertical" style={{ width: "100%" }}>
          <ConfortRangeForm
            data={heatingSeason.comfortRange}
            form={form}
            layout={isWideScreen ? "horizontal" : "inline"}
          ></ConfortRangeForm>
          <FormCheckbox
            formKey={["seasonStartChecks", "comfortRangeValidation"]}
            label="Je confirme les températures de confort désirées pour la saison de chauffe à venir (cocher la case) :"
          />
        </Space>
      </Form>
    </Space>
  );
};

const InstrumentsCheck: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  React.useEffect(
    () =>
      form.setFieldsValue({
        instrumentChecks: {
          instrumentInventory: false,
          circuitHasReferenceGroup: false,
          referenceGroupCorrectConstitution: false,
          hotGroupCorrectConstitution: false,
          comfortGroupCorrectConstitution: false,
          coldGroupCorrectConstitution: false,
        },
      }),
    [form],
  );

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Divider>
        <Space>
          <Typography.Text strong>Etape 3 - Groupes de sondes</Typography.Text>
          <Button
            icon={<QuestionCircleTwoTone />}
            style={{ border: "none", boxShadow: "none" }}
            href="https://www.notion.so/D-marrage-de-la-saison-de-chauffe-87bc53b209c34aee8fc80120e8df37b2?pvs=4#ec5e11648035415bad6cfd3a48848948"
            target="_blank"
          />
        </Space>
      </Divider>
      <Typography.Text style={{ fontStyle: "italic", color: colors.blue.dark }}>
        Vérifiez la constitution des groupes de sondes afin de garantir la précision du pilotage en
        répondant aux questions suivantes :
      </Typography.Text>
      <Form form={form}>
        <FormCheckbox
          formKey={["seasonStartChecks", "instrumentsInventory"]}
          label="Inventaire des sondes effectué (sondes qui n'émettent plus, batteries faibles..)"
        />
        <FormCheckbox
          formKey={["seasonStartChecks", "referenceGroupCorrectConstitution"]}
          label="Chaque groupe de référence est correctement constitué : nombre représentatif de sondes, sondes fonctionnelles"
        />
        <FormCheckbox
          formKey={["seasonStartChecks", "hotGroupCorrectConstitution"]}
          label="Votre groupe chaud est correctement constitué"
        />
        <FormCheckbox
          formKey={["seasonStartChecks", "coldGroupCorrectConstitution"]}
          label="Votre groupe froid est correctement constitué"
        />
        <FormCheckbox
          formKey={["seasonStartChecks", "temporaryGroupsCorrectConstitution"]}
          label="Vos groupes temporaires sont à jour et correctement constitués"
        />
      </Form>
    </Space>
  );
};

const AdditionalChecks: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  React.useEffect(
    () =>
      form.setFieldsValue({
        additionalChecks: {
          outsideTemperature: false,
          energyMeters: false,
        },
      }),
    [form],
  );

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Divider>
        <Space>
          <Typography.Text strong>Etape 4 - Eléments complémentaires</Typography.Text>
          <Button
            icon={<QuestionCircleTwoTone />}
            style={{ border: "none", boxShadow: "none" }}
            href="https://www.notion.so/D-marrage-de-la-saison-de-chauffe-87bc53b209c34aee8fc80120e8df37b2?pvs=4#1c1c1fd1faca45b3bc11a4b0efb8fdd4"
            target="_blank"
          />
        </Space>
      </Divider>
      <Typography.Text style={{ fontStyle: "italic", color: colors.blue.dark }}>
        Vérifiez également les éléments ci-dessous.
      </Typography.Text>
      <Form form={form}>
        <FormCheckbox
          formKey={["seasonStartChecks", "workingOutsideTemperature"]}
          label="La sonde de température extérieure est fonctionnelle et corrélée à la météo"
        />
        <FormCheckbox
          formKey={["seasonStartChecks", "workingEnergyMeters"]}
          label="Les compteurs d'énergie sont fonctionnels et ont des données récentes"
        />
        <Form.Item name="comment" label="Commentaire">
          <TextArea
            placeholder="Ajouter un commentaire ici."
            showCount
            maxLength={200}
            autoSize={{ minRows: 3 }}
          />
        </Form.Item>
      </Form>
    </Space>
  );
};

const SupervisionTable: React.FC<{ sites: Site[] }> = ({ sites }) => {
  const ref = useRef<Input>();
  const [nameSearch, setNameSearch] = useState<GetColumnSearchPropsState<"name">>({
    searchText: "",
  });

  const metadatacolumn = (title: string, key: keyof SeasonStarChecks) => {
    return {
      title: title,
      dataIndex: "currentHeatingSeason",
      key: key,
      width: 100,
      sorter: (a: Site) => {
        return a.currentHeatingSeason.seasonStartChecks[key] ? 1 : -1;
      },
      render: (currentHeatingSeason: HeatingSeason) => {
        return currentHeatingSeason.seasonStartChecks[key] ? (
          <CheckCircleTwoTone twoToneColor={colors.green.dark} style={{ fontSize: 20 }} />
        ) : (
          <CloseCircleTwoTone twoToneColor={colors.red.dark} style={{ fontSize: 20 }} />
        );
      },
    };
  };
  const columns: ColumnType<Site>[] = [
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      sorter: sorter("name", "string"),
      defaultSortOrder: "ascend" as const,
      ...getColumnSearchProps(ref as React.RefObject<Input>, "name", nameSearch, setNameSearch),
      render: (text: string, site: Site) => {
        return text;
      },
    },
    metadatacolumn("Démarrage chauffage", "startTimeValidation"),
    metadatacolumn("T° cibles", "comfortRangeValidation"),
    metadatacolumn("Inventaire sondes", "instrumentsInventory"),
    metadatacolumn("Constitution Gref", "referenceGroupCorrectConstitution"),
    metadatacolumn("Constitution groupe froid", "coldGroupCorrectConstitution"),
    metadatacolumn("Constitution groupe chaud", "hotGroupCorrectConstitution"),
    metadatacolumn("Constitution groupes temporaires", "temporaryGroupsCorrectConstitution"),
    metadatacolumn("Sonde externe valide", "workingOutsideTemperature"),
    metadatacolumn("Compteurs d'énergies valides", "workingEnergyMeters"),
  ];
  return (
    <div style={{ overflowX: "auto" }}>
      <Row>
        <Statistic
          title="Nombre de sites"
          prefix={<PlusOutlined />}
          value={sites.length}
          style={globalStyles.statistic}
        />
        <Statistic
          title="Sites complétés"
          prefix={<PlusOutlined />}
          value={
            sites.filter((s: Site) =>
              Object.values(s.currentHeatingSeason.seasonStartChecks).every((v) => v === true),
            ).length
          }
          style={globalStyles.statistic}
        />
        <Statistic
          title="Sites entamés"
          prefix={<PlusOutlined />}
          value={
            sites.filter(
              (s: Site) =>
                Object.values(s.currentHeatingSeason.seasonStartChecks).some((v) => v === true) &&
                Object.values(s.currentHeatingSeason.seasonStartChecks).some((v) => v === false),
            ).length
          }
          style={globalStyles.statistic}
        />
        <Statistic
          title="Sites sans information"
          prefix={<PlusOutlined />}
          value={
            sites.filter((s: Site) =>
              Object.values(s.currentHeatingSeason.seasonStartChecks).every((v) => v === false),
            ).length
          }
          style={globalStyles.statistic}
        />
      </Row>
      <Table
        rowKey="id"
        scroll={{ x: "auto", y: 500 }}
        dataSource={sites}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

const SiteSeasonStarter: React.FC<{
  site: Site;
}> = ({ site }) => {
  const [form] = useForm();
  const { mutateAsync: updateHeatingPeriod, isLoading } = useUpdateHeatingPeriod(site.slug);
  const { user } = useAuth0();

  React.useEffect(() => {
    form.setFieldsValue({
      ...site.currentHeatingSeason,
      startTime: moment(site.currentHeatingSeason.startTime),
    });
  }, [site, form]);

  async function onSave() {
    const formFeatures = form.getFieldsValue();
    const lower = parseFloat(formFeatures.comfortRange.lower);
    const target = parseFloat(formFeatures.comfortRange.target);
    const upper = parseFloat(formFeatures.comfortRange.upper);

    await withNotify(
      updateHeatingPeriod({
        ...site.currentHeatingSeason,
        ...formFeatures,
        startTime: formFeatures.startTime.format("YYYY-MM-DD") + "T00:00:00Z",
        endTime: defaultHeatingPeriod(site.currentHeatingSeason.key).endTime + "T00:00:00Z",
        comfortRange: {
          lower,
          target,
          upper,
        },
      }),
      "Les paramètres de la saison de chauffe ont bien été prise en compte pour ce site",
      "Une erreur est survenue lors de la mise à jour des paramètres de saison de chauffe de ce site.",
    );
  }

  return (
    <>
      <Collapse>
        <Collapse.Panel
          header={site.name}
          key={site.slug}
          extra={[
            <Typography.Text style={{ fontStyle: "italic", color: "grey", marginRight: 10 }}>
              {isHeatingReferent(user, site.heatingReferentUser)
                ? "✍"
                : "Référent : " + site.heatingReferentUser?.name}
            </Typography.Text>,
            isMissingConfig(site.currentHeatingSeason) ? "🚨" : "",
          ]}
        >
          <GeneralInformation heatingSeason={site.currentHeatingSeason} form={form} />
          <InstrumentsCheck form={form} />
          <AdditionalChecks form={form} />
          <Divider />
          <Typography.Text style={{ fontStyle: "italic", color: colors.blue.dark }}>
            Enregistrez vos informations pour les conserver, et complétez les éléments manquants dès
            que souhaité.
          </Typography.Text>
          <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: 10 }}>
            <TooltipButton
              onClick={onSave}
              disableMessage={`Pour modifier ces données veuillez contacter le référent chauffage de ce site: ${site.heatingReferentUser?.name}`}
              disabled={
                !(
                  isHeatingReferent(user, site.heatingReferentUser) ||
                  hasPermission(user, Permissions.SitesCreate)
                )
              }
              loading={isLoading}
            />
          </div>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

const SeasonStarter: React.FC<{}> = () => {
  const { data: sites } = useSites();
  const { user } = useAuth0();

  return (
    <Space direction="vertical">
      <Typography.Text strong>{`Saison de chauffe ${getHeatingPeriod(
        new Date(),
      )}`}</Typography.Text>
      <Typography.Paragraph>
        {`Afin d'optimiser la régulation de votre confort et vos économies pour la saison de chauffe 
        ${getHeatingPeriod(new Date())},
         veuillez compléter ou vérifier les informations suivantes.`}
      </Typography.Paragraph>

      {hasPermission(user, Permissions.SitesCreate) && (
        <Collapse>
          <Collapse.Panel
            header={
              <Space>
                <SearchOutlined />
                <Typography.Text strong>Supervision globale</Typography.Text>
              </Space>
            }
            key={"supervision"}
          >
            <SupervisionTable sites={sites}></SupervisionTable>
          </Collapse.Panel>
        </Collapse>
      )}
      {sites
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((site: Site) => (
          <SiteSeasonStarter site={site} />
        ))}
    </Space>
  );
};

export default SeasonStarter;
