import { Card, Image, Typography } from "antd";
import moment from "moment";
import chartOn from "src/assets/predictif-supervision-on.png";
import chartOff from "src/assets/predictif-supervision-off.png";
import { unitDegreeCelsius } from "src/constants";
import { useSiteHeatingDistributionCircuitMeasurements } from "src/lib/hooks/api";
import { latestMeasurement } from "src/lib/utils";
import { HeatingDistributionCircuitMeasurement } from "src/lib/api";

const fontSizeFactor = 8 / 1200;

function getRelevantImage(measurement?: HeatingDistributionCircuitMeasurement) {
  if (!measurement) {
    return chartOff;
  }

  return measurement.controls.predictiveRemoteSwitch &&
    measurement.controls.predictiveLocalSwitch &&
    measurement.controls.v3vRegulation !== false &&
    !measurement.controls.isWatchdogReleased
    ? chartOn
    : chartOff;
}

const PredictiveSupervisionImageOverlay: React.FC<{
  top: number;
  left: number;
  value?: number;
  unit: string;
}> = ({ top, left, value, unit }) => (
  <div
    style={{
      position: "absolute",
      width: "12.39%",
      height: "9.54%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      top: `${top}%`,
      left: `${left}%`,
    }}
  >
    <Typography.Text style={{ fontSize: fontSizeFactor * window.innerWidth }}>{`${
      value ? value.toFixed(1) : "-"
    } ${unit}`}</Typography.Text>
  </div>
);

const PredictiveSupervision: React.FC<{
  slug: string;
  heatingDistributionCircuitId: number;
}> = ({ slug, heatingDistributionCircuitId }) => {
  const { data: measurements } = useSiteHeatingDistributionCircuitMeasurements(
    slug,
    heatingDistributionCircuitId,
    moment().startOf("day").unix(),
  );

  const latestHeatingDistributionCircuitMeasurement = latestMeasurement(measurements);

  return (
    <Card
      title={`Supervision prédictif à ${moment(
        latestHeatingDistributionCircuitMeasurement?.timestamp,
      )
        .format("LT")
        .replace(":", "h")}`}
      style={{ borderRadius: 10 }}
      headStyle={{ borderBottomColor: "white" }}
    >
      <div style={{ position: "relative" }}>
        <Image
          src={getRelevantImage(latestHeatingDistributionCircuitMeasurement)}
          preview={false}
          title={"Synoptique"}
          style={{
            width: "100%",
            height: "100%",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        />
        <PredictiveSupervisionImageOverlay
          top={12.7}
          left={8.46}
          value={latestHeatingDistributionCircuitMeasurement?.offsetFromPLC}
          unit={unitDegreeCelsius}
        />
        <PredictiveSupervisionImageOverlay
          top={70.8}
          left={8.46}
          value={latestHeatingDistributionCircuitMeasurement?.waterFlowTemperature}
          unit={unitDegreeCelsius}
        />
        <PredictiveSupervisionImageOverlay
          top={12.7}
          left={42.15}
          value={
            latestHeatingDistributionCircuitMeasurement?.waterFlowTemperaturePlusOffsetCalculated
          }
          unit={unitDegreeCelsius}
        />
        <PredictiveSupervisionImageOverlay
          top={62.8}
          left={77.89}
          value={latestHeatingDistributionCircuitMeasurement?.waterFlowTemperatureEmulated}
          unit={unitDegreeCelsius}
        />
      </div>
    </Card>
  );
};

export default PredictiveSupervision;
