import * as React from "react";
import { Table, Typography } from "antd";
import { EnergyMeter, EnergyMeterCategory, EnergyMeterRole } from "src/lib/api";
import { Link } from "react-router-dom";
import { useQueryClientSetEnergyMeter } from "src/lib/hooks/api";
import { sorter } from "src/lib/tableutils";

const EnergyMetersTable: React.FC<{ energyMeters: EnergyMeter[]; slug: string }> = ({
  energyMeters,
  slug,
}) => {
  const queryCache = useQueryClientSetEnergyMeter();

  const columns = [
    {
      title: "N° de compteur",
      dataIndex: "serialNumber",
      key: "serialNumber",
      sorter: sorter("serialNumber", "string"),
      defaultSortOrder: "ascend" as const,
    },
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      sorter: sorter("name", "string"),
      render: (n: string, r: EnergyMeter) => (
        <Link
          to={`/sites/${slug}/energy-meters/${r.id}`}
          onClick={() => queryCache(r)}
          // @ts-expect-error
          as={Typography.Link}
        >
          {n}
        </Link>
      ),
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
      render: (category: EnergyMeterCategory) => {
        switch (category) {
          case EnergyMeterCategory.Reference:
            return "Compteur de référence";
          default:
            return "Compteur indicatif";
        }
      },
    },
    {
      title: "Rôle",
      dataIndex: "role",
      key: "role",
      render: (role: EnergyMeterRole) => {
        switch (role) {
          case EnergyMeterRole.Hdw:
            return "ECS";
          case EnergyMeterRole.Heating:
            return "Chauffage";
          case EnergyMeterRole.HeatingHdw:
            return "Chauffage et ECS";
          default:
            return "Non renseigné";
        }
      },
    },
    {
      title: "Commentaires",
      dataIndex: "comments",
      key: "comments",
    },
  ];

  return <Table rowKey="id" pagination={false} dataSource={energyMeters} columns={columns} />;
};

export default EnergyMetersTable;
