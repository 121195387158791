import * as React from "react";
import { Card } from "antd";
import { AbscissaOrigin, HeatingCurveHistory } from "src/lib/api";
import Table, { ColumnType } from "antd/lib/table";
import moment from "moment";
import { useSiteHeatingInstallationCircuitHeatingCurveHistory } from "src/lib/hooks/api";
import { SizeType } from "antd/es/config-provider/SizeContext";

function renderLawPoint(x?: number, y?: number, x_origin?: AbscissaOrigin) {
  if (x === undefined || x === null || x_origin === null) {
    return "Absent";
  }

  if (y === undefined || y === null) {
    return "Absent";
  }

  return x_origin === "dynamic"
    ? `(${x.toFixed(1)}, ${y.toFixed(1)})`
    : `([${x}], ${y.toFixed(1)})`;
}

const HeatingCurveModificationsHistory: React.FC<{
  siteSlug: string;
  hdcid: number;
  tableSize?: SizeType;
}> = ({ siteSlug, hdcid, tableSize }) => {
  const { data: heatingCurveModifications } = useSiteHeatingInstallationCircuitHeatingCurveHistory(
    siteSlug,
    hdcid.toString(),
  );

  const sortedHeatingCurveModifications = heatingCurveModifications.sort((a, b) =>
    moment(a.timestamp).isAfter(moment(b.timestamp)) ? 1 : -1,
  );

  const columns: ColumnType<HeatingCurveHistory>[] = [
    {
      title: "Date de la mise à jour",
      dataIndex: "timestamp",
      key: "timestamp",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => (moment(a.timestamp).isAfter(moment(b.timestamp)) ? 1 : -1),
      render: (text: string) => moment(text).format("lll"),
    },
    {
      title: "Point 1",
      dataIndex: "pt1",
      key: "pt1",
      render: (_, { x1, y1, x1_origin }) => renderLawPoint(x1, y1, x1_origin),
    },
    {
      title: "Point 2",
      dataIndex: "pt2",
      key: "pt2",
      render: (_, { x2, y2, x2_origin }) => renderLawPoint(x2, y2, x2_origin),
    },
    {
      title: "Point 3",
      dataIndex: "pt3",
      key: "pt3",
      render: (_, { x3, y3, x3_origin }) => renderLawPoint(x3, y3, x3_origin),
    },
    {
      title: "Point 4",
      dataIndex: "pt4",
      key: "pt4",
      render: (_, { x4, y4, x4_origin }) => renderLawPoint(x4, y4, x4_origin),
    },
    {
      title: "TNC",
      dataIndex: "no_heating_temperature",
      key: "no_heating_temperature",
      render: (_, { noHeatingTemperature }) => {
        return noHeatingTemperature ?? "Non renseigné";
      },
    },
    {
      title: "Correction (jour)",
      dataIndex: "dayCorrection",
      key: "dayCorrection",
      render: (_, { dayCorrection }) => {
        return dayCorrection ?? "Non renseigné";
      },
    },
    {
      title: "Correction (nuit)",
      dataIndex: "nightCorrection",
      key: "nightCorrection",
      render: (_, { nightCorrection }) => {
        return nightCorrection ?? "Non renseigné";
      },
    },
  ];

  return (
    <Card title="Modifications de loi de chauffe">
      {heatingCurveModifications.length < 1 ? (
        <div>Aucun historique de loi de chauffe n'est disponible pour ce site.</div>
      ) : (
        <Table dataSource={sortedHeatingCurveModifications} columns={columns} size={tableSize} />
      )}
    </Card>
  );
};

export default HeatingCurveModificationsHistory;
