import { Empty, Radio, Row, Statistic, Tabs } from "antd";
import moment from "moment";
import * as React from "react";
import InstrumentsTable from "src/components/InstrumentsTable";
import SiteStatistics from "src/components/SiteStatistics";
import { staticticStyle } from "src/constants";
import { filterAlertsForInstrumentGroups, filterOpenAlerts } from "src/lib/alerts";
import {
  Instrument,
  InstrumentCategory,
  InstrumentGroup,
  InstrumentGroupCreate,
  Site,
} from "src/lib/api";
import {
  useInstrumentDelete,
  useInstrumentGroupAlerts,
  useInstrumentGroupsDelete,
  useInstrumentGroupsSave,
  useInstrumentUpdate,
  useInstrumentUpdateComment,
  useInstrumentsUpdateCategory,
  useSiteInstrumentGroups,
  useSiteInstruments,
  useSiteLatestMeasurements,
  useSitePeriodMeasurements,
} from "src/lib/hooks/api";
import { withNotify } from "src/lib/notify";
import ErrorBoundaryWithFallback from "./ErrorBoundaryWithFallback";
import InstrumentsMeasurementsVisualiser from "./InstrumentsMeasurementsVisualiser";
import Loader from "./Loader";
import { LegendDisplayMode } from "./MeasurementDisplayModeSelector";
import { useAuth0 } from "@auth0/auth0-react";

const InstrumentsTab: React.FC<{ site: Site }> = ({ site }) => {
  const { user } = useAuth0();
  const { slug } = site;
  const instrumentTabRef: any = React.useRef(null);
  const alertsRes = useInstrumentGroupAlerts(user);
  const { data: instruments = [] } = useSiteInstruments(slug);
  const { data: instrumentGroups = [] } = useSiteInstrumentGroups(slug);

  const [dataDisplayDuration, setDataDisplayDuration] = React.useState<"live" | "week">("live");
  const { mutateAsync: saveInstrumentGroup } = useInstrumentGroupsSave(slug);
  const { mutateAsync: deleteInstrumentGroup } = useInstrumentGroupsDelete(slug);
  const { mutateAsync: updateInstrument } = useInstrumentUpdate(slug);
  const { mutateAsync: updateInstrumentComment } = useInstrumentUpdateComment(slug);
  const { mutateAsync: deleteInstrument } = useInstrumentDelete(slug);
  const { mutateAsync: updateInstrumentsCategory } = useInstrumentsUpdateCategory();
  const latestMeasurementsRes = useSiteLatestMeasurements(site.slug);
  const periodMeasurementsRes = useSitePeriodMeasurements(
    site.slug,
    moment().subtract(7, "d").unix(),
    moment().unix(),
  );
  const periodMeasurements =
    periodMeasurementsRes.status === "success" ? periodMeasurementsRes.data : {};

  const alerts = alertsRes.status === "success" ? alertsRes.data : [];

  const openAlerts = filterOpenAlerts(filterAlertsForInstrumentGroups(alerts, instrumentGroups));

  async function onGroupSave(instrumentGroup: InstrumentGroupCreate | InstrumentGroup) {
    await withNotify(
      saveInstrumentGroup(instrumentGroup),
      `Le groupe ${instrumentGroup.name} a bien été enregistré`,
      "Nous sommes désolés, nous n'avons pas pu créer ce groupe. Veuillez réessayer plus tard.",
    );
  }

  async function onGroupDelete(igid: string) {
    await withNotify(
      deleteInstrumentGroup(igid),
      "Le groupe a bien été supprimé",
      "Nous sommes désolés, nous n'avons pas pu supprimer ce groupe. Veuillez réessayer plus tard.",
    );
  }

  async function onEditInstrument(i: Instrument) {
    await withNotify(
      updateInstrument(i),
      "Votre instrument a bien été mis à jour",
      "Nous sommes désolés, nous n'avons pas pu mettre à jour cet instrument. Veuillez réessayer plus tard.",
    );
  }

  async function onEditInstrumentComment(i: Instrument) {
    await withNotify(
      updateInstrumentComment(i),
      "Votre commentaire a bien été mis à jour pour cet instrument",
      "Nous sommes désolés, nous n'avons pas pu mettre à jour ce commentaire. Veuillez réessayer plus tard.",
    );
  }

  async function onDeleteInstrument(iid: string) {
    await withNotify(
      deleteInstrument(iid),
      "L'instrument a bien été supprimé",
      "Nous sommes désolés, nous n'avons pas pu supprimer cet instrument. Veuillez réessayer plus tard.",
    );
  }

  async function createAnalysisBatchs() {
    var notConsideredInstrumentIds: number[] = [];
    var coldInstrumentIds: number[] = [];
    var comfortInstrumentIds: number[] = [];
    var hotInstrumentIds: number[] = [];

    instruments.forEach((i: Instrument) => {
      if (i.category === InstrumentCategory.ExternalTemperature) {
        // We don't modify external temperature intruments
        return;
      } else if (!periodMeasurements[i.id]) {
        notConsideredInstrumentIds.push(i.id);
      } else {
        if (
          periodMeasurements[i.id].temperatureMeasurement <=
          site.currentHeatingSeason.comfortRange.lower
        ) {
          coldInstrumentIds.push(i.id);
        }
        if (
          periodMeasurements[i.id].temperatureMeasurement >
            site.currentHeatingSeason.comfortRange.lower &&
          periodMeasurements[i.id].temperatureMeasurement <
            site.currentHeatingSeason.comfortRange.upper
        ) {
          comfortInstrumentIds.push(i.id);
        }
        if (
          periodMeasurements[i.id].temperatureMeasurement >=
          site.currentHeatingSeason.comfortRange.upper
        ) {
          hotInstrumentIds.push(i.id);
        }
      }
    });

    if (notConsideredInstrumentIds.length > 0) {
      await withNotify(
        updateInstrumentsCategory({
          iids: notConsideredInstrumentIds,
          category: InstrumentCategory.Other,
        }),
        "Les instruments défectueux on bien été identifiés",
        "Nous sommes désolés, nous n'avons pas pu catégoriser les instruments défectueux.",
      );
    }

    if (coldInstrumentIds.length > 0) {
      await withNotify(
        updateInstrumentsCategory({ iids: coldInstrumentIds, category: InstrumentCategory.Froid }),
        "Les instruments froids on bien été identifiés",
        "Nous sommes désolés, nous n'avons pas pu catégoriser les instruments froids.",
      );
    }

    if (comfortInstrumentIds.length > 0) {
      await withNotify(
        updateInstrumentsCategory({
          iids: comfortInstrumentIds,
          category: InstrumentCategory.Confort,
        }),
        "Les instruments confort on bien été identifiés",
        "Nous sommes désolés, nous n'avons pas pu catégoriser les instruments confort.",
      );
    }

    if (hotInstrumentIds.length > 0) {
      await withNotify(
        updateInstrumentsCategory({ iids: hotInstrumentIds, category: InstrumentCategory.Chaud }),
        "Les instruments chauds on bien été identifiés",
        "Nous sommes désolés, nous n'avons pas pu catégoriser les instruments chaud.",
      );
    }
  }

  return (
    <div style={{ width: "100%" }} ref={instrumentTabRef}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SiteStatistics
          openAlerts={openAlerts}
          instruments={instruments}
          instrumentGroups={instrumentGroups}
        />
        <Row>
          <Statistic
            title="Affichage des données sur :"
            style={staticticStyle}
            valueRender={() => (
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <Radio.Button
                  style={{ margin: 2 }}
                  value={LegendDisplayMode.Name}
                  checked={dataDisplayDuration === "week"}
                  onClick={() => setDataDisplayDuration("week")}
                >
                  7 jours
                </Radio.Button>
                <Radio.Button
                  style={{ margin: 2 }}
                  value={LegendDisplayMode.Comment}
                  checked={dataDisplayDuration === "live"}
                  onClick={() => setDataDisplayDuration("live")}
                >
                  Donnée live
                </Radio.Button>
              </div>
            )}
          ></Statistic>
        </Row>
      </div>
      <Tabs>
        <Tabs.TabPane tab={"Liste des sondes"} key={"instrument-list"}>
          <InstrumentsTable
            instruments={instruments}
            instrumentGroups={instrumentGroups}
            site={site}
            openAlerts={openAlerts}
            onSaveGroup={onGroupSave}
            onDeleteInstrumentGroup={onGroupDelete}
            onEditInstrument={onEditInstrument}
            onEditInstrumentComment={onEditInstrumentComment}
            onDeleteInstrument={onDeleteInstrument}
            measurementsRes={
              dataDisplayDuration === "live" ? latestMeasurementsRes : periodMeasurementsRes
            }
            createAnalysisBatchs={createAnalysisBatchs}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Visualisation des sondes"} key={"instrument-visualisation"}>
          <React.Suspense fallback={<Loader />}>
            <ErrorBoundaryWithFallback fallback={<Empty />}>
              <InstrumentsMeasurementsVisualiser
                site={site}
                measurementsRes={
                  dataDisplayDuration === "live" ? latestMeasurementsRes : periodMeasurementsRes
                }
                dataDisplayDuration={dataDisplayDuration}
              />
            </ErrorBoundaryWithFallback>
          </React.Suspense>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default InstrumentsTab;
