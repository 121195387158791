import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Form, Input, Modal, Select, Table, Typography, notification } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { ColumnType } from "antd/lib/table";
import * as React from "react";
import { formItemLayout, plcSupplierName } from "src/constants";
import { Permissions, hasPermission } from "src/lib/access-control";
import type { PLC, Site } from "src/lib/api";
import { useSaveSitePlc, useSitePlcs, useUpdateSitePlc } from "src/lib/hooks/api";
import { useWindowWidth } from "src/lib/hooks/window-width";
import { generatePassword } from "src/lib/math";
import { withNotify } from "src/lib/notify";
import { showIf, sorter } from "src/lib/tableutils";
import { isValidUrl } from "src/lib/url";

const plcSuppliers = [
  {
    value: "Siemens",
    key: 3,
  },
  {
    value: "Trend",
    key: 5,
  },
  {
    value: "Distech",
    key: 6,
  },
  {
    value: "Smart connector",
    key: 8,
  },
  {
    value: "WIT",
    key: 9,
  },
];

const standardConfigurations = [
  {
    name: "Configuration watchdog DISTECH",
    value: {
      watchdogCounterRead: "AV1001",
      watchdogCounterWrite: "AV1002",
      watchdogPresetRead: "AV1000",
      watchdogStateRead: "BV1001",
    },
    key: "distech",
  },
  {
    name: "Configuration watchdog TREND",
    value: {
      watchdogStateRead: "I100",
      watchdogPresetRead: "A100",
      watchdogCounterRead: "S601",
      watchdogCounterWrite: "S600",
    },
    key: "trend",
  },
  {
    name: "Aucune configuration",
    value: {
      watchdogCounterRead: "",
      watchdogCounterWrite: "",
      watchdogPresetRead: "",
      watchdogStateRead: "",
    },
    key: "none",
  },
];

const PlcMetadataForm: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  return (
    <>
      <Form layout="horizontal" form={form} size="small">
        <div style={{ display: "flex", justifyContent: "end", width: "80%" }}>
          <Form.Item
            name={["metadata", "watchdogCounterRead"]}
            label="Lecture timer"
            style={{ fontStyle: "italic" }}
          >
            <Input placeholder={"AV1001"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "end", width: "80%" }}>
          <Form.Item
            name={["metadata", "watchdogCounterWrite"]}
            label="Ecriture timer"
            style={{ fontStyle: "italic" }}
          >
            <Input placeholder={"AV1002"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "end", width: "80%" }}>
          <Form.Item
            name={["metadata", "watchdogPresetRead"]}
            label="Preset watchdog"
            style={{ fontStyle: "italic" }}
          >
            <Input placeholder={"AV1000"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "end", width: "80%" }}>
          <Form.Item
            name={["metadata", "watchdogStateRead"]}
            label="Flag watchdog"
            style={{ fontStyle: "italic" }}
          >
            <Input placeholder={"BV1001"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
        </div>
      </Form>
      <Form.Item label="Appliquer une conf standard" name="conf">
        <Select
          placeholder={"Optionnel"}
          onSelect={(value: any) => {
            if (value === "none") {
              form.setFieldsValue({ metadata: undefined });
            } else {
              const metadataConf = standardConfigurations.find((conf: any) => conf.key === value);
              form.setFieldsValue({ metadata: metadataConf?.value });
            }
          }}
        >
          {standardConfigurations.map((ps) => {
            return <Select.Option value={ps.key}>{ps.name}</Select.Option>;
          })}
        </Select>
      </Form.Item>
    </>
  );
};

const PlcForm: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  const [supplierId, setSupplierId] = React.useState<number>(form.getFieldValue("supplierId"));

  return (
    <Form name="plc-form" {...formItemLayout} form={form}>
      <Form.Item name="id" hidden />
      <Form.Item name="name" label="Nom de l'automate">
        <Input placeholder={"Distech Le Méridien"} />
      </Form.Item>
      <Form.Item name="supplierId" label="Fournisseur" rules={[{ required: true }]}>
        <Select
          placeholder={"Choisir une option"}
          allowClear
          onSelect={(value: number) => {
            setSupplierId(value);
            form.setFieldsValue({ supplierId: value });
          }}
        >
          {plcSuppliers.map((ps) => {
            return <Select.Option value={ps.key}>{ps.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name="url"
        label="Adresse Internet"
        rules={[
          { required: true },
          {
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              if (isValidUrl(value)) {
                return Promise.resolve();
              }
              return Promise.reject("Adresse url non valide");
            },
          },
        ]}
      >
        <Input placeholder={"https://192.160.1.30(:3000)"}></Input>
      </Form.Item>
      <Form.Item name="username" label="Nom d'utilisateur">
        <Input placeholder={"admin-efficap"} />
      </Form.Item>
      <Form.Item name="password" label={supplierId === 5 ? "Clé API" : "Mot de passe"}>
        <Input.Password
          placeholder={"examp!e"}
          addonAfter={
            <Button
              type="link"
              onClick={() => form.setFieldsValue({ password: generatePassword(12) })}
            >
              Générer
            </Button>
          }
        />
      </Form.Item>
      <Form.Item name="" label={<Typography.Text strong>Métadonnées</Typography.Text>}>
        <PlcMetadataForm form={form} />
      </Form.Item>
    </Form>
  );
};

const PlcsTab: React.FC<{ site: Site }> = ({ site }) => {
  const { data: plcs } = useSitePlcs(site.slug);

  const { user } = useAuth0();
  const [form] = useForm();
  const { isWideScreen } = useWindowWidth();

  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);

  const { mutateAsync: savePlc } = useSaveSitePlc(site.slug);
  const { mutateAsync: updatePlc } = useUpdateSitePlc(site.slug);

  function onAddPlc() {
    form.setFieldsValue({ id: 0, protocol: "https", metadata: {} });
    setIsModalVisible(true);
  }

  function onSavePlc() {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(async (plc: PLC) => {
        if (plc.id === 0) {
          await withNotify(
            savePlc(plc),
            "L'automate a été créé avec succès",
            "Une erreur est survenue lors de la création de l'automate.",
          );
        } else {
          await withNotify(
            updatePlc({
              ...plc,
              id: plc.id,
            }),
            "L'automate a été mis à jour avec succès",
            "Une erreur est survenue lors de la mise à jour de l'automate.",
          );
        }
        resetForm();
      })
      .catch(() => {
        notification.warning({
          message: `La modification ne peut pas être enregistrée car certains champs ne respectent pas les critères demandés.`,
        });
        setConfirmLoading(false);
      });
  }

  const formattedPlcs = plcs.map((plc: PLC) => ({
    ...plc,
    supplier: plcSupplierName(plc.supplierId),
    url: plc.url,
  }));

  function resetForm() {
    setIsModalVisible(false);
    setConfirmLoading(false);
    form.resetFields();
  }

  const columns: ColumnType<PLC>[] = [
    {
      title: "Nom de l'automate",
      dataIndex: "name",
      key: "name",
      sorter: sorter("name", "string"),
    },
    {
      title: "Fournisseur",
      dataIndex: "supplier",
      key: "supplier",
    },
    {
      title: "Adresse internet",
      dataIndex: "url",
      key: "url",
    },
    ...showIf<PLC>(() => hasPermission(user, Permissions.HeatingInstallationsUpdate), {
      // see if we create specific permission for plc update
      title: "Edition",
      key: "edition",
      responsive: ["lg" as const],
      width: 120,
      render: (record: PLC) => (
        <Button
          type="link"
          disabled={!hasPermission(user, Permissions.HeatingInstallationsUpdate)}
          icon={<EditOutlined />}
          onClick={() => {
            form.setFieldsValue(record);
            setIsModalVisible(true);
          }}
        >
          Modifier
        </Button>
      ),
    }),
    // not implementing deletion yet
  ];

  return (
    <>
      <Card title="Automates présents sur site">
        <Table dataSource={formattedPlcs} columns={columns} pagination={false} />
        <div style={{ justifyContent: "center", display: "flex", margin: 20 }}>
          <Button icon={<PlusOutlined />} type="link" onClick={onAddPlc}>
            Ajouter un automate
          </Button>
        </div>
      </Card>
      <Modal
        visible={isModalVisible}
        title={"Enregistrer un automate"}
        okText={"Enregistrer"}
        onCancel={resetForm}
        onOk={onSavePlc}
        width={isWideScreen ? 900 : "50%"}
        forceRender={true}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !hasPermission(user, Permissions.SitesUpdate) }}
      >
        <div style={{ marginBottom: 20 }}>
          <PlcForm form={form}></PlcForm>
        </div>
      </Modal>
    </>
  );
};

export default PlcsTab;
