import { useMemo, Suspense } from "react";
import { Descriptions, Empty, PageHeader, Tabs, Typography } from "antd";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSite } from "src/lib/hooks/api";
import PageTitle from "src/components/PageTitle";
import DashboardTab from "src/components/Dashboard/DashboardTab";
import InstrumentsTab from "src/components/InstrumentsTab";
import EnergyMetersTab from "src/components/EnergyMetersTab";
import HeatingInstallationsTab from "src/components/HeatingInstallationsTab";
import Loader from "src/components/Loader";
import ErrorBoundaryWithFallback from "src/components/ErrorBoundaryWithFallback";
import { hasPermission, Permissions } from "src/lib/access-control";
import { useAuth0 } from "@auth0/auth0-react";
import SiteHeatingPeriod from "src/components/HeatingPeriod";
import ReportGeneration from "src/components/ReportGeneration";
import SiteComfortRange from "src/components/ComfortRange";
import SiteConfiguration from "src/components/SiteConfiguration";
import { SiteEnergyMeterCount } from "src/components/EnergyMeterCount";
import { SiteHeatingCircuitCount } from "src/components/HeatingCircuitCount";
import AlertConfigurationTab from "src/components/AlertConfigurationTab";

type PathnameResources =
  | "dashboard"
  | "instruments"
  | "energy-meters"
  | "heating-installations"
  | "distribution-circuits-monitoring";

export function resourceFromPathname(pathname: string): PathnameResources | undefined {
  const regex =
    /\/sites\/[-a-z0-9]{2,}\/(?<resource>dashboard|instruments|energy-meters|heating-installations|distribution-circuits-monitoring|site-information|alert-create)/gm;
  const resource = regex.exec(pathname)?.groups?.resource;

  return resource as PathnameResources | undefined;
}

export const SiteInfoConsult: React.FC<{ textContent: string; updateAction: () => void }> = ({
  textContent,
  updateAction,
}) => {
  const { user } = useAuth0();

  return hasPermission(user, Permissions.SitesUpdate) ? (
    <Typography.Link onClick={updateAction}>{textContent}</Typography.Link>
  ) : (
    <Typography.Text>{textContent}</Typography.Text>
  );
};

const SitePage = () => {
  const { user } = useAuth0();
  const { slug } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const siteRes = useSite(slug as string);

  const site = siteRes.data;
  const pathnameResource = useMemo(() => resourceFromPathname(pathname), [pathname]);

  if (pathnameResource === undefined) {
    return <Navigate to={`/sites/${slug}/dashboard`} replace={true} />;
  }

  return (
    <>
      <PageTitle title={site.name} />
      <div>
        <PageHeader
          title={<Typography.Title>{site.name}</Typography.Title>}
          style={{ padding: 0, marginBottom: "1rem" }}
        >
          <div style={{ display: "flex" }}>
            <Descriptions size="small" column={2}>
              <Descriptions.Item
                label={<Typography.Text strong={true}>Saison de chauffe</Typography.Text>}
              >
                <SiteHeatingPeriod site={site} />
              </Descriptions.Item>

              <Descriptions.Item
                label={<Typography.Text strong={true}>Réseaux de chauffage</Typography.Text>}
              >
                <Suspense fallback={<Loader size="small" />}>
                  <ErrorBoundaryWithFallback fallback={<div>-</div>}>
                    <SiteHeatingCircuitCount site={site} />
                  </ErrorBoundaryWithFallback>
                </Suspense>
              </Descriptions.Item>

              <Descriptions.Item
                label={<Typography.Text strong={true}>Températures cibles</Typography.Text>}
              >
                <SiteComfortRange site={site} />
              </Descriptions.Item>

              <Descriptions.Item
                label={<Typography.Text strong={true}>Compteurs d'énergie</Typography.Text>}
              >
                <Suspense fallback={<Loader size="small" />}>
                  <ErrorBoundaryWithFallback fallback={<div>-</div>}>
                    <SiteEnergyMeterCount site={site} />
                  </ErrorBoundaryWithFallback>
                </Suspense>
              </Descriptions.Item>
            </Descriptions>
            {hasPermission(user, Permissions.ExportCreate) && <ReportGeneration slug={site.slug} />}
          </div>
        </PageHeader>
        <Tabs
          size="large"
          activeKey={pathnameResource}
          onTabClick={(key: string) => navigate(`/sites/${slug}/${key}`, { replace: true })}
        >
          <Tabs.TabPane key="dashboard" tab="Tableau de bord">
            <div style={{ marginBottom: "2rem" }}>
              <DashboardTab site={site} />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key="instruments" tab="Sondes de températures">
            <div style={{ marginBottom: "2rem" }}>
              <Suspense fallback={<Loader />}>
                <InstrumentsTab site={site} />
              </Suspense>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key="energy-meters" tab="Compteurs d'énergie">
            <div style={{ marginBottom: "2rem" }}>
              <Suspense fallback={<Loader />}>
                <EnergyMetersTab site={site} />
              </Suspense>
            </div>
          </Tabs.TabPane>
          {hasPermission(user, Permissions.HeatingInstallationsRead) && (
            <Tabs.TabPane key="heating-installations" tab="Installation de chauffage">
              <div style={{ marginBottom: "2rem" }}>
                <Suspense fallback={<Loader />}>
                  <ErrorBoundaryWithFallback fallback={<Empty />}>
                    <HeatingInstallationsTab site={site} />
                  </ErrorBoundaryWithFallback>
                </Suspense>
              </div>
            </Tabs.TabPane>
          )}
          {hasPermission(user, Permissions.SitesCreate) && (
            <Tabs.TabPane key="site-information" tab="Configuration du site">
              <div style={{ marginBottom: "2rem" }}>
                <Suspense fallback={<Loader />}>
                  <ErrorBoundaryWithFallback fallback={<Empty />}>
                    <SiteConfiguration site={site} />
                  </ErrorBoundaryWithFallback>
                </Suspense>
              </div>
            </Tabs.TabPane>
          )}
          {hasPermission(user, Permissions.SitesCreate) && (
            <Tabs.TabPane key="alert-create" tab="Mes alertes">
              <div style={{ marginBottom: "2rem" }}>
                <Suspense fallback={<Loader />}>
                  <ErrorBoundaryWithFallback fallback={<Empty />}>
                    <AlertConfigurationTab site={site} />
                  </ErrorBoundaryWithFallback>
                </Suspense>
              </div>
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    </>
  );
};

export default SitePage;
