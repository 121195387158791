import React, { Suspense } from "react";
import { Card, Divider, Empty, Space, Tabs, Typography } from "antd";
import RenderIf from "./RenderIf";
import { useWindowWidth } from "src/lib/hooks/window-width";
import { HeatingDistributionCircuit, Site } from "src/lib/api";
import Loader from "./Loader";
import ErrorBoundaryWithFallback from "./ErrorBoundaryWithFallback";
import SchedulingRegulationControls from "./SchedulingRegulationControls";
import { MonitorOutlined } from "@ant-design/icons";
import SiteSchedulingRegulationMonitoring from "./SiteSchedulingRegulationMonitoring";

const SchedulingControlsFallBack: React.FC<{}> = () => {
  return (
    <div style={{ marginBottom: "1.5rem" }}>
      <Card
        title={
          <Space direction="horizontal">
            <MonitorOutlined />
            Gestion de la régulation planifiée
          </Space>
        }
      >
        <Typography.Text>
          Un problème empêche la régulation planifiée de ce circuit. Vérifiez la configuration des
          automates et n'hésitez pas à nous contacter si le problème persiste.
        </Typography.Text>
      </Card>
    </div>
  );
};

const ScheduledRegulationTab: React.FC<{ site: Site; hdcs: HeatingDistributionCircuit[] }> = ({
  site,
  hdcs,
}) => {
  const { isWideScreen } = useWindowWidth();

  return (
    <RenderIf predicate={hdcs.length > 0} fallback={<Empty />}>
      <Tabs defaultActiveKey="1" tabPosition={isWideScreen ? "left" : "top"}>
        {hdcs.map((hdc) => {
          return (
            <Tabs.TabPane key={`distribution-circuit-${hdc.id}`} tab={hdc?.name}>
              <Suspense
                fallback={
                  <Card
                    loading={true}
                    style={{ marginBottom: "1.5rem" }}
                    title={
                      <Space direction="horizontal">
                        <MonitorOutlined />
                        Gestion de la régulation planifiée
                      </Space>
                    }
                  />
                }
              >
                <ErrorBoundaryWithFallback fallback={<SchedulingControlsFallBack />}>
                  <SchedulingRegulationControls
                    heatingDistributionCircuitId={hdc.id.toString()}
                    siteSlug={site.slug}
                  />
                </ErrorBoundaryWithFallback>
              </Suspense>
              <Divider />
              <Suspense fallback={<Loader />}>
                <ErrorBoundaryWithFallback fallback={<Empty />}>
                  <SiteSchedulingRegulationMonitoring siteSlug={site.slug} hdc={hdc} />
                </ErrorBoundaryWithFallback>
              </Suspense>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </RenderIf>
  );
};

export default ScheduledRegulationTab;
