import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import { hasSeveralAuthorizedSites } from "src/lib/access-control";

const BackButton = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const userHasSeveralAuthorizedSites = hasSeveralAuthorizedSites(user);

  const sitesUrlPattern: RegExp = /\/sites\/[a-z-]+$/;

  const canGoBackCommon = location.pathname !== "/" && window.history.length > 1;

  const canGoBack = userHasSeveralAuthorizedSites
    ? canGoBackCommon
    : canGoBackCommon && !location.pathname.match(sitesUrlPattern);

  return (
    <div style={{ margin: "1rem 0" }}>
      {canGoBack && (
        <Button type="link" onClick={() => navigate(-1)} icon={<ArrowLeftOutlined />}>
          Retour
        </Button>
      )}
    </div>
  );
};

export default BackButton;
