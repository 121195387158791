import * as React from "react";
import { dashboardColors } from "src/constants";

export const DashDiv: React.FC<{ children?: React.ReactNode; style?: {} }> = ({
  children,
  style,
}) => {
  return (
    <div
      style={{
        ...style,
        paddingTop: 5,
        borderRadius: "1rem",
        borderColor: "black",
        width: "100%",
        boxShadow: `0px 2px 3px ${dashboardColors.grey}`,
      }}
    >
      {children}
    </div>
  );
};
