import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { Instrument, InstrumentGroup } from "src/lib/api";

type DataType = Instrument | InstrumentGroup;

const columns: ColumnsType<DataType> = [
  {
    title: "Nom",
    dataIndex: "name",
  },
  {
    title: "Commentaires",
    dataIndex: "comments",
  },
];

interface InstrumentsSelectTableProps {
  instruments: DataType[];
  selectedInstrumentsIds: React.Key[];
  onSelectionChange: (ids: React.Key[]) => void;
}

const InstrumentsSelectTable = (props: InstrumentsSelectTableProps) => {
  const { instruments, onSelectionChange, selectedInstrumentsIds } = props;

  const rowSelection = {
    selectedRowKeys: selectedInstrumentsIds,
    onChange: onSelectionChange,
  };

  return (
    <Table
      rowKey={"id"}
      rowSelection={rowSelection}
      columns={columns}
      dataSource={instruments}
      pagination={false}
      scroll={{ y: 300 }}
    />
  );
};

export default InstrumentsSelectTable;
