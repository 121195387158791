import Cookies from "universal-cookie";
import { AdminBanner } from "./api";

class CookieManager {
  private cookies: Cookies;
  private cookieName: string;

  constructor(cookieName: string) {
    this.cookies = new Cookies();
    this.cookieName = cookieName;
  }

  set(value: any, options?: object): void {
    this.cookies.set(this.cookieName, value, options);
  }

  get(): any {
    return this.cookies.get(this.cookieName);
  }
}

export function cleanBannersCookies(activeBanners: AdminBanner[]): void {
  const cookie = new CookieManager("hiddenBanners");
  const hiddenBannersIds = ((cookie.get() as number[]) ?? []).filter((id: number) =>
    activeBanners.some((banner: AdminBanner) => banner.id === id),
  );
  cookie.set(hiddenBannersIds);
}

export default CookieManager;
