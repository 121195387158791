import moment from "moment";

interface TemperatureData {
  timestamp: string;
  temperature: number;
}

const lerp = (a: number, b: number, t: number) => {
  return a + (b - a) * t;
};

const interpolateWeather = (data: TemperatureData[], weather: TemperatureData[]) => {
  const res = data.map((d) => {
    const w = weather.find((w) => w.timestamp === d.timestamp);
    if (w) {
      return { x: w.temperature, y: d.temperature, timestamp: d.timestamp };
    }
    const wBefore = weather.find((w) => moment(w.timestamp).isBefore(d.timestamp));
    const wAfter = weather.find((w) => moment(w.timestamp).isAfter(d.timestamp));
    if (wBefore && wAfter) {
      const t =
        moment(d.timestamp).diff(moment(wBefore.timestamp)) /
        moment(wAfter.timestamp).diff(moment(wBefore.timestamp));
      return {
        x: lerp(wBefore.temperature, wAfter.temperature, t),
        y: d.temperature,
        timestamp: d.timestamp,
      };
    }
    return { x: 0, y: d.temperature, timestamp: d.timestamp };
  });

  return res;
};

function interpolateTemperature(ts: string[], data: TemperatureData[]): TemperatureData[] {
  const res = ts.map((t) => {
    const d = data.find((d) => moment(d.timestamp).isSame(t));
    if (d) {
      return { timestamp: t, temperature: d.temperature };
    }
    const dBefore = data.find((d) => moment(d.timestamp).isBefore(t));
    const dAfter = data.find((d) => moment(d.timestamp).isAfter(t));
    if (dBefore && dAfter) {
      const tmp =
        moment(t).diff(moment(dBefore.timestamp)) /
        moment(dAfter.timestamp).diff(moment(dBefore.timestamp));
      return { timestamp: t, temperature: lerp(dBefore.temperature, dAfter.temperature, tmp) };
    }
    return { timestamp: t, temperature: 0 };
  });

  return res;
}

export { interpolateWeather, interpolateTemperature };
