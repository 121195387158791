export function groupBy<T extends any, K extends string | number | symbol>(
  array: T[],
  keyFn: (currentValue: T) => K,
): Record<string, T[]> {
  return array.reduce(
    (previousValue, currentValue) => {
      const value = keyFn(currentValue);

      previousValue[value] = (previousValue[value] || []).concat(currentValue);

      return previousValue;
    },
    {} as Record<K, T[]>,
  );
}
