import { Result, Typography } from "antd";
import { supportEmail } from "src/constants";

const ServerUnreachable = () => {
  return (
    <Result
      status={500}
      title="Service momentanément indisponible"
      subTitle={
        <Typography.Text>
          Veuillez essayer ultérieurement ou contactez nous sur l'addresse suivante:
          <br />
          <Typography.Text copyable={{ text: supportEmail }}>
            <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
          </Typography.Text>
        </Typography.Text>
      }
    />
  );
};

export default ServerUnreachable;
