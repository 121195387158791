import moment from "moment";
import { useState } from "react";
import {
  Bar,
  ComposedChart,
  Label,
  LabelProps,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { CartesianViewBox } from "recharts/types/util/types";
import { dashboardColors } from "src/constants";
import { DailyDHH } from "src/lib/api";
import { consumptionOnly, getMonthlyData } from "src/lib/dashboard-utils";
import { DashboardNoData, renderLegends } from "./DashboardComponents";

function dateFormatter(d: string) {
  return moment.unix(parseInt(d)).format("MMM");
}

//may move this function to DashboardComponents.tsx later, if used somewhere else
const BarLabel: React.FC = ({ viewBox, value }: LabelProps) => {
  const v = viewBox as CartesianViewBox;
  const displayValue = value as number;

  if (v.width !== undefined && v.x !== undefined && v.y !== undefined) {
    return (
      <svg>
        <text
          x={v.x + v.width / 2}
          y={v.y - 4}
          textAnchor="middle"
          fontSize={14}
          fill={dashboardColors.grey}
          fontStyle={"italic"}
        >
          {displayValue.toFixed(0)}
        </text>
      </svg>
    );
  }

  return <></>;
};

export const DashboardMonthlyConsumptions: React.FC<{
  consumptions: consumptionOnly[];
  hdd: DailyDHH[];
  unitOfMeasure: string;
}> = ({ consumptions, hdd, unitOfMeasure }) => {
  const [hoverBar1, setHoverBar1] = useState(false);
  const [hoverBar2, setHoverBar2] = useState(false);

  const data = getMonthlyData(consumptions, hdd);

  if (data.length === 0) {
    return <DashboardNoData />;
  }

  const firstTimestamp = moment.unix(data[0].timestamp).subtract(2, "week").unix();
  const lastTimestamp = moment
    .unix(data[data.length - 1].timestamp)
    .add(2, "week")
    .unix();

  const year = `${moment.unix(firstTimestamp).format("YYYY")}-${moment
    .unix(lastTimestamp)
    .format("YY")}`;
  const yearRef = `${moment.unix(firstTimestamp).subtract(1, "year").format("YYYY")}-${moment
    .unix(lastTimestamp)
    .subtract(1, "year")
    .format("YY")}`;

  return (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart
        data={data}
        margin={{ top: 30, right: 0, left: 0, bottom: 5 }}
        barGap={0}
        barCategoryGap="2"
      >
        <XAxis
          dataKey="timestamp"
          type="number"
          tick={{ fontSize: 12 }}
          tickFormatter={dateFormatter}
          domain={[firstTimestamp, lastTimestamp]}
          ticks={data.map((c) => c.timestamp)}
        />
        <YAxis domain={[0, "auto"]} tickCount={2}>
          <Label
            value={`Consommation (${unitOfMeasure})`}
            offset={25}
            angle={-90}
            position="insideLeft"
            fill={dashboardColors.grey}
            fontStyle="italic"
            style={{ textAnchor: "middle" }}
          />
        </YAxis>
        <YAxis domain={[0, "auto"]} tickCount={2} yAxisId="right" orientation="right">
          <Label
            value="DJU"
            offset={25}
            angle={-90}
            position="insideRight"
            fill={dashboardColors.grey}
            fontStyle="italic"
            style={{ textAnchor: "middle" }}
          />
          <Label content={(props) => renderLegends(props, year, yearRef, { x: 90, y: 5 })} />
        </YAxis>

        <Bar
          id="refBar"
          dataKey="consumptionRef"
          fill={dashboardColors.blueFade}
          label={hoverBar1 ? <BarLabel /> : undefined}
          onMouseEnter={() => setHoverBar1(true)}
          onMouseLeave={() => setHoverBar1(false)}
        />
        <Bar
          dataKey="consumption"
          fill={dashboardColors.yellowFade}
          label={hoverBar2 ? <BarLabel /> : undefined}
          onMouseEnter={() => setHoverBar2(true)}
          onMouseLeave={() => setHoverBar2(false)}
        />

        <Line
          type="monotone"
          yAxisId="right"
          dataKey="hddRef"
          stroke={dashboardColors.blue}
          dot={false}
          strokeWidth={1.5}
        />
        <Line
          type="monotone"
          yAxisId="right"
          dataKey="hdd"
          stroke={dashboardColors.yellow}
          dot={false}
          strokeWidth={1.5}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
