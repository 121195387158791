import { useState } from "react";
import * as React from "react";
import { Button, DatePicker as AntdDatePicker, message, Space, Typography } from "antd";
import { DownloadOutlined, FileImageOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import domtoimage from "dom-to-image";
import { useWindowWidth } from "src/lib/hooks/window-width";
import { Link } from "react-router-dom";

interface DatePickerDownloaderChildrenProps {
  from: number;
  to: number;
}

interface DatePickerDownloaderProps {
  onDownload?: (from: number, to: number) => Promise<void>;
  onChartSave?: (blob: Blob) => void;
  defaultRange?: [from: Moment, to: Moment];
  ranges: { [range: string]: [from: Moment, to: Moment] };
  forceUTC?: boolean;
  children: (props: DatePickerDownloaderChildrenProps) => React.ReactElement;
  roundDate?: boolean;
}

function formatDate(date: Moment, forceUTC: boolean): number {
  const f = date.startOf("hour");
  return forceUTC ? f.utcOffset(0, true).unix() : f.unix();
}

const DatePickerDownloader: React.FC<DatePickerDownloaderProps> = ({
  onDownload,
  onChartSave,
  defaultRange = [moment().subtract(7, "d"), moment()],
  ranges,
  forceUTC,
  children,
  roundDate = true,
}) => {
  const { isWideScreen } = useWindowWidth();
  const [from, setFrom] = useState<Moment>(defaultRange![0]);
  const [to, setTo] = useState<Moment>(defaultRange![1]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const onOk = (value: any) => {
    const [from, to]: [Moment, Moment] = value;
    if (from) {
      setFrom(from);
    }

    if (to) {
      setTo(to);
    }
  };

  async function onClick() {
    setButtonLoading(true);
    if (onDownload) {
      try {
        await onDownload(from.unix(), to.unix());
      } catch (e) {
        message.error({
          content: `L'export des données a échoué. Notre équipe a été prévenue. Veuillez ressayer ultérieurement.`,
        });
      }
    }

    setButtonLoading(false);
  }

  const saveChart = () => {
    domtoimage
      .toBlob(document.getElementById("report")!, {
        bgcolor: "white",
        style: { padding: "4mm" },
        filter: (node: any) => node.id !== "noreport",
      })
      .then(onChartSave)
      .catch((e) => console.error("saveChart", e));
  };

  return (
    <div>
      <div style={{ margin: "40px 0px", display: "flex", justifyContent: "space-between" }}>
        <Space size="small">
          {isWideScreen && (
            <AntdDatePicker.RangePicker
              value={[from, to]}
              allowClear={false}
              onChange={onOk}
              disabledDate={(current: Moment | null) =>
                current
                  ? // Disable dates in two cases:
                    //  1. future since we won't have data.
                    //  2. more than two years ago to limit the load on the database.
                    current > moment() || current.isBefore(moment().subtract(2, "years"))
                  : true
              }
              renderExtraFooter={() => (
                <Typography.Paragraph>
                  <Typography.Text strong={true}>
                    La sélection de dates est limitée à 2 ans dans le passé.
                  </Typography.Text>
                  <br />
                  Veuillez{" "}
                  <Link
                    to={"/support"}
                    // @ts-expect-error
                    as={Typography.Link}
                  >
                    contacter le support
                  </Link>{" "}
                  pour voir plus de données.
                </Typography.Paragraph>
              )}
            />
          )}
          {Object.entries(ranges).map((range: [string, [Moment, Moment]]) => (
            <div id="noreport">
              <Button key={range[0]} type="dashed" onClick={() => onOk(range[1])}>
                {range[0]}
              </Button>
            </div>
          ))}
        </Space>
        <div id="noreport">
          {isWideScreen && (onChartSave || onDownload) && (
            <Space size="small">
              {onChartSave && (
                <Button
                  id="save-chart-button"
                  type="primary"
                  onClick={() => saveChart()}
                  icon={<FileImageOutlined />}
                >
                  Export Courbe
                </Button>
              )}
              {onDownload && (
                <Button
                  id="export-button"
                  type="primary"
                  icon={<DownloadOutlined />}
                  loading={buttonLoading}
                  onClick={onClick}
                >
                  Export Données
                </Button>
              )}
            </Space>
          )}
        </div>
      </div>

      {children({
        // always fetch data using from rounded to the beginning of the hour.
        // This makes the fetched data more consistent and makes is easy to
        // cache data.
        from: roundDate ? formatDate(from, forceUTC ?? false) : from.unix(),
        to: roundDate ? formatDate(to, forceUTC ?? false) : to.unix(),
      })}
    </div>
  );
};

export default DatePickerDownloader;
