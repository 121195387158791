import { Space } from "antd";
import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  ScatterChart as RechartsScatterChart,
  Legend,
  CartesianGrid,
  ReferenceLine,
  Label,
} from "recharts";
import { COLORS } from "src/lib/chart-utils";
import { findLineByLeastSquares } from "src/lib/math";

type ScatterData = { x: number; y: number };

const computeScatterProps = (
  scatters: { dataKey: string; name: string; data: ScatterData[]; showLine?: boolean }[],
) => {
  return scatters
    .map((scatter) => scatter.dataKey)
    .reduce((acc, dataKey) => ({ ...acc, [dataKey]: true }), { hover: null });
};

const computeLineParameters = (data: ScatterData[]) => {
  return findLineByLeastSquares(
    data.map(({ x, y }) => x),
    data.map(({ x, y }) => y),
  );
};

interface ScatterChartProps {
  xLabel: string;
  yLabel: string;
  scatters: {
    dataKey: string;
    name: string;
    data: ScatterData[];
    showLine?: boolean;
  }[];
}

const ScatterChart = (props: ScatterChartProps) => {
  const { xLabel, yLabel, scatters } = props;

  const [scatterProps, setScatterProps] = useState<any>(computeScatterProps(scatters));

  useEffect(() => {
    setScatterProps(computeScatterProps(scatters));
  }, [scatters]);

  const scattersWithLines = scatters.map((scatter) => ({
    ...scatter,
    ...computeLineParameters(scatter.data),
  }));

  const toggleData = (dataKey: string) => {
    setScatterProps({ ...scatterProps, [dataKey]: !scatterProps[dataKey] });
  };

  const handleMouseOver = (dataKey: string) => {
    if (!scatterProps[dataKey]) return;
    setScatterProps({ ...scatterProps, hover: dataKey });
  };

  const handleMouseOut = () => {
    setScatterProps({ ...scatterProps, hover: null });
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <ResponsiveContainer height={600}>
        <RechartsScatterChart>
          {scattersWithLines.map((scatter, index) => (
            <>
              <Scatter
                isAnimationActive={false}
                dataKey={scatter.dataKey}
                name={`${scatter.name} : y = ${scatter.m.toFixed(3)}*x + ${scatter.b.toFixed(2)}`}
                data={scatter.data}
                fill={COLORS[index % COLORS.length]}
                shape="cross"
                hide={!scatterProps[scatter.dataKey]}
                fillOpacity={
                  scatterProps.hover === scatter.dataKey || !scatterProps.hover ? 1 : 0.4
                }
              />
              {scatter.showLine && scatterProps[scatter.dataKey] && (
                <ReferenceLine
                  isFront={true}
                  stroke={COLORS[index % COLORS.length]}
                  strokeWidth={3}
                  segment={[
                    { x: scatter.data[0].x, y: scatter.m * scatter.data[0].x + scatter.b },
                    {
                      x: scatter.data[scatter.data.length - 1].x,
                      y: scatter.m * scatter.data[scatter.data.length - 1].x + scatter.b,
                    },
                  ]}
                  opacity={scatterProps.hover === scatter.dataKey || !scatterProps.hover ? 1 : 0.1}
                >
                  <Label
                    value={`y = ${scatter.m.toFixed(3)}*x + ${scatter.b.toFixed(2)}`}
                    fill={COLORS[index % COLORS.length]}
                    opacity={
                      scatterProps.hover === scatter.dataKey || !scatterProps.hover ? 1 : 0.1
                    }
                    position="right"
                  />
                </ReferenceLine>
              )}
            </>
          ))}
          <XAxis
            type="number"
            dataKey="x"
            unit="°C"
            allowDecimals={false}
            domain={["auto", "auto"]}
          >
            <Label value={xLabel} offset={0} position="insideBottom" />
          </XAxis>
          <YAxis
            type="number"
            dataKey="y"
            unit="°C"
            allowDecimals={false}
            domain={["auto", "auto"]}
          >
            <Label value={yLabel} offset={0} position="insideLeft" angle={-90} />
          </YAxis>
          <CartesianGrid strokeDasharray="3 3" />
          <Legend
            onClick={({ dataKey }) => toggleData(dataKey)}
            onMouseOver={({ dataKey }) => handleMouseOver(dataKey)}
            onMouseOut={handleMouseOut}
            verticalAlign="bottom"
            layout="vertical"
            align="center"
            wrapperStyle={{ paddingTop: "10px" }}
          />
          <Tooltip />
        </RechartsScatterChart>
      </ResponsiveContainer>
    </Space>
  );
};

export default ScatterChart;
