/* eslint-disable no-duplicate-case */
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Collapse, Divider, Input, Select, Space, Table, Typography } from "antd";
import { ColumnType } from "antd/lib/table";
import * as React from "react";
import { colors } from "src/constants";
import { hasPermission, Permissions } from "src/lib/access-control";
import {
  DataPointDataType,
  DistributionDataPoint,
  DistributionDataPointCategory,
  Instrument,
  InstrumentCategory,
  PLC,
} from "src/lib/api";
import {
  getDataPointValue,
  getPlcOptions,
  isDataPointModified,
  updateDataPointPlcAddress,
  updateDataPointPlcId,
} from "src/lib/data-points";
import {
  useDeleteDistributionDataPoint,
  useDistributionDataPoints,
  useInstrumentSave,
  useInstrumentUpdate,
  useSaveDistributionDataPoint,
  useSiteInstruments,
  useUpdateDistributionDataPoint,
} from "src/lib/hooks/api";
import { withNotify } from "src/lib/notify";
import { showIf, sorter } from "src/lib/tableutils";

function getdataTypeFromCategory(category: DistributionDataPointCategory) {
  const relevantDP = distributionDataPointsOptions.find(
    (ddp: { value: string; key: DistributionDataPointCategory; type: DataPointDataType }) =>
      ddp.key === category,
  );
  return relevantDP?.type ?? "float";
}

const AddOutsiteInstrument: React.FC<{
  slug: string;
  address: string;
  plc?: PLC;
}> = ({ slug, address, plc }) => {
  const { data: instruments } = useSiteInstruments(slug, plc?.supplierId ? [plc.supplierId] : []);
  const { mutateAsync: updateInstrument } = useInstrumentUpdate(slug);
  const { mutateAsync: saveInstrument } = useInstrumentSave(slug);
  const relevantInstrument = instruments.find(
    (i: Instrument) => i.serialNumber === plc?.id.toString() && i.dataSource === address,
  );
  const [nameValue, setNameValue] = React.useState(relevantInstrument?.name ?? "");

  return (
    <Space direction="horizontal" style={{ margin: 10 }}>
      {relevantInstrument?.name
        ? "Souhaitez vous modifier une sonde de température associée à ce point de mesure ?"
        : "Souhaitez vous créer une sonde de température associée à ce point de mesure ?"}
      <Input.Group compact>
        <Input
          style={{ width: 200 }}
          placeholder={"Nom de la sonde externe"}
          value={nameValue}
          onChange={(event: any) => setNameValue(event.target.value)}
        />
        <Button
          type="primary"
          onClick={async () => {
            if (relevantInstrument) {
              await withNotify(
                updateInstrument({
                  ...relevantInstrument,
                  name: nameValue,
                  category: InstrumentCategory.ExternalTemperature,
                }),
                "La sonde externe a été renommée avec succès",
                "Une erreur est survenue lors du renommage de la sonde externe. Vous pouvez essayer de la renommer depuis la page des sondes et nous contacter si le problème persiste.",
              );
            } else if (plc && address !== "") {
              await withNotify(
                saveInstrument({
                  serialNumber: plc.id.toString(),
                  name: nameValue,
                  supplierId: plc.supplierId,
                  type: "",
                  comments: "",
                  siteId: plc.siteId,
                  category: InstrumentCategory.ExternalTemperature,
                  dataSource: address,
                }),
                "La sonde externe a été créée avec succès",
                "Une erreur est survenue lors de la création de la sonde externe.",
              );
            }
          }}
          disabled={relevantInstrument?.name === nameValue || !nameValue}
        >
          {relevantInstrument ? "Modifier" : "Créer"}
        </Button>
      </Input.Group>
    </Space>
  );
};

const distributionDataPointsOptions: {
  value: string;
  key: DistributionDataPointCategory;
  type: DataPointDataType;
}[] = [
  {
    value: "Température départ circuit*",
    key: "water_flow_temperature",
    type: "float",
  },
  {
    value: "Lecture offset*",
    key: "offset_read",
    type: "float",
  },
  {
    value: "Ecriture offset*",
    key: "offset_write",
    type: "float",
  },
  {
    value: "Température consigne circuit*",
    key: "water_flow_set_point_temperature",
    type: "float",
  },
  {
    value: "Pourcentage ouverture vanne 3 voies*",
    key: "three_way_valve_travel_percent",
    type: "float",
  },
  {
    value: "Température départ + offset (calcul intermédiaire)*",
    key: "water_flow_temperature_plus_offset_calculated",
    type: "float",
  },
  {
    value: "Température départ émulée*",
    key: "water_flow_temperature_emulated",
    type: "float",
  },
  {
    value: "Température départ appliquée à la loi de chauffe*",
    key: "water_flow_temperature_applied",
    type: "float",
  },
  {
    value: "Température retour circuit*",
    key: "water_return_temperature",
    type: "float",
  },
  {
    value: "Température extérieure mélangée*",
    key: "composite_outside_temperature",
    type: "float",
  },
  {
    value: "Commande prédictif locale*",
    key: "predictive_system_local_state_read",
    type: "bool",
  },
  {
    value: "Commande prédictif distante*",
    key: "predictive_system_remote_state_read_and_write",
    type: "bool",
  },
  {
    value: "Régulation V3V",
    key: "v3v_regulation",
    type: "bool",
  },
  {
    value: "Point X1*",
    key: "heating_curve_point_x1",
    type: "float",
  },
  {
    value: "Point Y1*",
    key: "heating_curve_point_y1",
    type: "float",
  },
  {
    value: "Point X2*",
    key: "heating_curve_point_x2",
    type: "float",
  },
  {
    value: "Point Y2*",
    key: "heating_curve_point_y2",
    type: "float",
  },
  {
    value: "Point X3",
    key: "heating_curve_point_x3",
    type: "float",
  },
  {
    value: "Point Y3",
    key: "heating_curve_point_y3",
    type: "float",
  },
  {
    value: "Point X4",
    key: "heating_curve_point_x4",
    type: "float",
  },
  {
    value: "Point Y4",
    key: "heating_curve_point_y4",
    type: "float",
  },
  {
    value: "Alarme sonde T° départ déconnectée",
    key: "water_flow_temperature_disconnected_sensor_alarm",
    type: "bool",
  },
  {
    value: "Alarme sonde T° départ court-circuitée",
    key: "water_flow_temperature_short_circuit_sensor_alarm",
    type: "bool",
  },
  {
    value: "Alarme sonde T° retour déconnectée",
    key: "water_return_temperature_disconnected_sensor_alarm",
    type: "bool",
  },
  {
    value: "Alarme sonde T° retour court-circuitée",
    key: "water_return_short_circuit_sensor_alarm",
    type: "bool",
  },
  {
    value: "Température de non chauffage (TNC)",
    key: "no_heating_temperature",
    type: "float",
  },
  {
    value: "Correction jour (°C)",
    key: "heating_curve_day_correction",
    type: "float",
  },
  {
    value: "Correction nuit (°C)",
    key: "heating_curve_night_correction",
    type: "float",
  },
];

const defaultConfigurations = [
  {
    value: "Aucune",
    key: "aucune",
  },
  {
    value: "Distech 303 circuit 1",
    key: "distech_303_circuit1",
  },
  {
    value: "Distech 303 circuit 2",
    key: "distech_303_circuit2",
  },
  {
    value: "Distech 303 circuit 3",
    key: "distech_303_circuit3",
  },
  {
    value: "Siemens standard",
    key: "ozw_standard",
  },
  {
    value: "Distech S1000",
    key: "distech_1000_circuit1",
  },
  {
    value: "WIT circuit 1",
    key: "wit_circuit1",
  },
  {
    value: "WIT circuit 2",
    key: "wit_circuit2",
  },
  {
    value: "WIT circuit 3",
    key: "wit_circuit3",
  },
];

const DistributionDataPointConfigurationTable: React.FC<{
  siteSlug: string;
  hdcId: number;
  sitePlcs: PLC[];
}> = ({ siteSlug, hdcId, sitePlcs }) => {
  const { user } = useAuth0();
  // limit of this architecture is that when invalidate query all data points are
  // refreshed. An alternative would be to treat each data point individually,
  // but it isn ot worth implemting at this stage.
  const { data: ddps } = useDistributionDataPoints(siteSlug, hdcId);

  const { mutateAsync: saveDistributionDataPoint } = useSaveDistributionDataPoint(siteSlug, hdcId);
  const { mutateAsync: updateDistributionDataPoint } = useUpdateDistributionDataPoint(
    siteSlug,
    hdcId,
  );
  const { mutateAsync: deleteDistributionDataPoint } = useDeleteDistributionDataPoint(
    siteSlug,
    hdcId,
  );
  const [distributionDataPoints, setDistributionDataPoints] = React.useState<
    DistributionDataPoint[]
  >([]);

  const emptyDistributionDataPoint: DistributionDataPoint = React.useMemo(
    () => ({
      id: 0,
      category: "",
      heatingDistributionCircuitId: hdcId,
      plcId: null,
      plcAddress: null,
      dataType: "float",
    }),
    [hdcId],
  );

  React.useEffect(() => {
    // Building a table with all distribution data point, either empty, either filled with data if they exist
    setDistributionDataPoints(
      distributionDataPointsOptions.map(
        (o: { value: string; key: DistributionDataPointCategory }) =>
          getDataPointValue(o.key, ddps, emptyDistributionDataPoint),
      ) as DistributionDataPoint[],
    );
  }, [ddps, emptyDistributionDataPoint]);

  function updateDistributionDataPointPlcId(
    plcId: number | null,
    dataPoint: DistributionDataPoint,
  ) {
    const newDistributionDataPoints = updateDataPointPlcId(
      plcId,
      dataPoint,
      distributionDataPoints,
    ) as DistributionDataPoint[];
    setDistributionDataPoints(newDistributionDataPoints);
  }

  function updateDistributionDataPointPlcAddress(
    address: string | null,
    dataPoint: DistributionDataPoint,
  ) {
    const newDistributionDataPoints = updateDataPointPlcAddress(
      address,
      dataPoint,
      distributionDataPoints,
    ) as DistributionDataPoint[];
    setDistributionDataPoints(newDistributionDataPoints);
  }

  function onApplyConfiguration(key: string, ddp: DistributionDataPoint) {
    const distechPlcId = sitePlcs.find((plc: PLC) => plc.supplierId === 6)?.id ?? null;
    const ozwPlcId = sitePlcs.find((plc: PLC) => plc.supplierId === 3)?.id ?? null;
    const witPlcId = sitePlcs.find((plc: PLC) => plc.supplierId === 9)?.id ?? null;
    switch (key) {
      case "distech_303_circuit1":
        switch (ddp.category) {
          case "water_flow_temperature":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV101", ddp);
            break;
          case "water_return_temperature":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV102", ddp);
            break;
          case "offset_read":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV103", ddp);
            break;
          case "offset_write":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV103", ddp);
            break;
          case "water_flow_temperature_plus_offset_calculated":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV104", ddp);
            break;
          case "water_flow_temperature_emulated":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV106", ddp);
            break;
          case "water_flow_temperature_disconnected_sensor_alarm":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV100", ddp);
            break;
          case "water_flow_temperature_short_circuit_sensor_alarm":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV101", ddp);
            break;
          case "water_return_temperature_disconnected_sensor_alarm":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV102", ddp);
            break;
          case "predictive_system_remote_state_read_and_write":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV103", ddp);
            break;
          case "predictive_system_local_state_read":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV104", ddp);
            break;
          case "v3v_regulation":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV1001", ddp);
            break;
          case "no_heating_temperature":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV112", ddp);
            break;
          case "heating_curve_day_correction":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV110", ddp);
            break;
          case "heating_curve_night_correction":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV111", ddp);
            break;
          default:
            break;
        }
        break;
      case "distech_303_circuit2":
        updateDistributionDataPointPlcId(distechPlcId, ddp);
        switch (ddp.category) {
          case "water_flow_temperature":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV201", ddp);
            break;
          case "water_return_temperature":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV202", ddp);
            break;
          case "offset_read":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV203", ddp);
            break;
          case "offset_write":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV203", ddp);
            break;
          case "water_flow_temperature_plus_offset_calculated":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV204", ddp);
            break;
          case "water_flow_temperature_emulated":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV206", ddp);
            break;
          case "water_flow_temperature_disconnected_sensor_alarm":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV200", ddp);
            break;
          case "water_flow_temperature_short_circuit_sensor_alarm":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV201", ddp);
            break;
          case "water_return_temperature_disconnected_sensor_alarm":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV202", ddp);
            break;
          case "predictive_system_remote_state_read_and_write":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV203", ddp);
            break;
          case "predictive_system_local_state_read":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV204", ddp);
            break;
          case "v3v_regulation":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV1001", ddp);
            break;
          case "no_heating_temperature":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV212", ddp);
            break;
          case "heating_curve_day_correction":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV210", ddp);
            break;
          case "heating_curve_night_correction":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV211", ddp);
            break;
          default:
            break;
        }
        break;
      case "distech_303_circuit3":
        switch (ddp.category) {
          case "water_flow_temperature":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV301", ddp);
            break;
          case "water_return_temperature":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV302", ddp);
            break;
          case "offset_read":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV303", ddp);
            break;
          case "offset_write":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV303", ddp);
            break;
          case "water_flow_temperature_plus_offset_calculated":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV304", ddp);
            break;
          case "water_flow_temperature_emulated":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV306", ddp);
            break;
          case "water_flow_temperature_disconnected_sensor_alarm":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV300", ddp);
            break;
          case "water_flow_temperature_short_circuit_sensor_alarm":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV301", ddp);
            break;
          case "water_return_temperature_disconnected_sensor_alarm":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV302", ddp);
            break;
          case "predictive_system_remote_state_read_and_write":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV303", ddp);
            break;
          case "predictive_system_local_state_read":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV304", ddp);
            break;
          case "v3v_regulation":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV1001", ddp);
            break;
          case "no_heating_temperature":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV312", ddp);
            break;
          case "heating_curve_day_correction":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV310", ddp);
            break;
          case "heating_curve_night_correction":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV311", ddp);
            break;
          default:
            break;
        }
        break;
      case "wit_circuit1":
        switch (ddp.category) {
          case "water_flow_temperature":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV101_T_DEPART", ddp);
            break;
          case "water_return_temperature":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV102_T_RETOUR", ddp);
            break;
          case "offset_read":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV103_OFFSET_READ", ddp);
            break;
          case "offset_write":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV103_OFFSET_READ", ddp);
            break;
          case "water_flow_temperature_plus_offset_calculated":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV104_T_DEPART_OFFSET", ddp);
            break;
          case "water_flow_temperature_emulated":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV105_T_DEPART_EMULEE", ddp);
            break;
          case "water_flow_temperature_applied":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV105_T_DEPART_EMULEE", ddp);
            break;
          case "water_flow_set_point_temperature":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV114_T_CONSIGNE", ddp);
            break;
          case "three_way_valve_travel_percent":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV115_V3V", ddp);
            break;
          case "composite_outside_temperature":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV1_T_EXT_MELANGEE", ddp);
            break;
          case "heating_curve_point_x1":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV151_X1", ddp);
            break;
          case "heating_curve_point_y1":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV150_Y1", ddp);
            break;
          case "heating_curve_point_x2":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV153_X2", ddp);
            break;
          case "heating_curve_point_y2":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV152_Y2", ddp);
            break;
          case "water_flow_temperature_disconnected_sensor_alarm":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV100_ALARME_DEPART", ddp);
            break;
          case "water_flow_temperature_short_circuit_sensor_alarm":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("B101_ALARME_COURT_CIRCUIT", ddp);
            break;
          case "water_return_temperature_disconnected_sensor_alarm":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("B102_ALARME_RETOUR", ddp);
            break;
          case "predictive_system_remote_state_read_and_write":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV103_PREDICTIF_DISTANT", ddp);
            break;
          case "predictive_system_local_state_read":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV104_PREDICTIF_LOCAL", ddp);
            break;
          case "v3v_regulation":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV1001_REGULATION_V3V", ddp);
            break;
          default:
            break;
        }
        break;
      case "wit_circuit2":
        switch (ddp.category) {
          case "water_flow_temperature":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV201_T_DEPART", ddp);
            break;
          case "water_return_temperature":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV202_T_RETOUR", ddp);
            break;
          case "offset_read":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV203_OFFSET_READ", ddp);
            break;
          case "offset_write":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV203_OFFSET_READ", ddp);
            break;
          case "water_flow_temperature_plus_offset_calculated":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV204_T_DEPART_OFFSET", ddp);
            break;
          case "water_flow_temperature_emulated":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV205_T_DEPART_EMULEE", ddp);
            break;
          case "water_flow_temperature_applied":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV205_T_DEPART_EMULEE", ddp);
            break;
          case "water_flow_set_point_temperature":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV214_T_CONSIGNE", ddp);
            break;
          case "three_way_valve_travel_percent":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV215_V3V", ddp);
            break;
          case "composite_outside_temperature":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV1_T_EXT_MELANGEE", ddp);
            break;
          case "heating_curve_point_x1":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV251_X1", ddp);
            break;
          case "heating_curve_point_y1":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV250_Y1", ddp);
            break;
          case "heating_curve_point_x2":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV253_X2", ddp);
            break;
          case "heating_curve_point_y2":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV252_Y2", ddp);
            break;
          case "water_flow_temperature_disconnected_sensor_alarm":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV200_ALARME_DEPART", ddp);
            break;
          case "water_flow_temperature_short_circuit_sensor_alarm":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("B201_ALARME_COURT_CIRCUIT", ddp);
            break;
          case "water_return_temperature_disconnected_sensor_alarm":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("B202_ALARME_RETOUR", ddp);
            break;
          case "predictive_system_remote_state_read_and_write":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV203_PREDICTIF_DISTANT", ddp);
            break;
          case "predictive_system_local_state_read":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV204_PREDICTIF_LOCAL", ddp);
            break;
          case "v3v_regulation":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV2001_REGULATION_V3V", ddp);
            break;
          default:
            break;
        }
        break;
      case "wit_circuit3":
        switch (ddp.category) {
          case "water_flow_temperature":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV301_T_DEPART", ddp);
            break;
          case "water_return_temperature":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV302_T_RETOUR", ddp);
            break;
          case "offset_read":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV303_OFFSET_READ", ddp);
            break;
          case "offset_write":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV303_OFFSET_READ", ddp);
            break;
          case "water_flow_temperature_plus_offset_calculated":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV304_T_DEPART_OFFSET", ddp);
            break;
          case "water_flow_temperature_emulated":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV305_T_DEPART_EMULEE", ddp);
            break;
          case "water_flow_temperature_applied":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV305_T_DEPART_EMULEE", ddp);
            break;
          case "water_flow_set_point_temperature":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV314_T_CONSIGNE", ddp);
            break;
          case "three_way_valve_travel_percent":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV315_V3V", ddp);
            break;
          case "composite_outside_temperature":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV1_T_EXT_MELANGEE", ddp);
            break;
          case "heating_curve_point_x1":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV351_X1", ddp);
            break;
          case "heating_curve_point_y1":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV350_Y1", ddp);
            break;
          case "heating_curve_point_x2":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV353_X2", ddp);
            break;
          case "heating_curve_point_y2":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV352_Y2", ddp);
            break;
          case "water_flow_temperature_disconnected_sensor_alarm":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV300_ALARME_DEPART", ddp);
            break;
          case "water_flow_temperature_short_circuit_sensor_alarm":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("B301_ALARME_COURT_CIRCUIT", ddp);
            break;
          case "water_return_temperature_disconnected_sensor_alarm":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("B302_ALARME_RETOUR", ddp);
            break;
          case "predictive_system_remote_state_read_and_write":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV303_PREDICTIF_DISTANT", ddp);
            break;
          case "predictive_system_local_state_read":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV304_PREDICTIF_LOCAL", ddp);
            break;
          case "v3v_regulation":
            updateDistributionDataPointPlcId(witPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV3001_REGULATION_V3V", ddp);
            break;
          default:
            break;
        }
        break;
      case "distech_1000_circuit1":
        switch (ddp.category) {
          case "water_flow_temperature":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV101", ddp);
            break;
          case "water_return_temperature":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV102", ddp);
            break;
          case "offset_read":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV103", ddp);
            break;
          case "offset_write":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV103", ddp);
            break;
          case "water_flow_temperature_plus_offset_calculated":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV104", ddp);
            break;
          case "water_flow_temperature_emulated":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV105", ddp);
            break;
          case "water_flow_temperature_applied":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV105", ddp);
            break;
          case "water_flow_set_point_temperature":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV114", ddp);
            break;
          case "three_way_valve_travel_percent":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV115", ddp);
            break;
          case "composite_outside_temperature":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV1", ddp);
            break;
          case "heating_curve_point_x1":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV151", ddp);
            break;
          case "heating_curve_point_y1":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV150", ddp);
            break;
          case "heating_curve_point_x2":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV153", ddp);
            break;
          case "heating_curve_point_y2":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV152", ddp);
            break;
          case "water_flow_temperature_disconnected_sensor_alarm":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV100", ddp);
            break;
          case "water_flow_temperature_short_circuit_sensor_alarm":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV101", ddp);
            break;
          case "water_return_temperature_disconnected_sensor_alarm":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV102", ddp);
            break;
          case "predictive_system_remote_state_read_and_write":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV103", ddp);
            break;
          case "predictive_system_local_state_read":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV104", ddp);
            break;
          case "heating_energy_comsumption":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV180", ddp);
            break;
          case "water_flow_energy_comsumption":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV180", ddp);
            break;
          case "v3v_regulation":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("BV1001", ddp);
            break;
          case "no_heating_temperature":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV112", ddp);
            break;
          case "heating_curve_day_correction":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV110", ddp);
            break;
          case "heating_curve_night_correction":
            updateDistributionDataPointPlcId(distechPlcId, ddp);
            updateDistributionDataPointPlcAddress("AV111", ddp);
            break;
          default:
            break;
        }
        break;
      case "ozw_standard":
        switch (ddp.category) {
          case "water_flow_set_point_temperature":
            updateDistributionDataPointPlcId(ozwPlcId, ddp);
            updateDistributionDataPointPlcAddress("SN:0x42751001:0", ddp);
            break;
          case "water_flow_temperature_applied":
            updateDistributionDataPointPlcId(ozwPlcId, ddp);
            updateDistributionDataPointPlcAddress("SN:0x27fe1001:0", ddp);
            break;
          case "composite_outside_temperature":
            updateDistributionDataPointPlcId(ozwPlcId, ddp);
            updateDistributionDataPointPlcAddress("SN:0x42781001:0", ddp);
            break;
          case "three_way_valve_travel_percent":
            updateDistributionDataPointPlcId(ozwPlcId, ddp);
            updateDistributionDataPointPlcAddress("SN:0x5cfc1001:0", ddp);
            break;
          case "heating_curve_point_x1":
            updateDistributionDataPointPlcId(ozwPlcId, ddp);
            updateDistributionDataPointPlcAddress("SN:0x42f41001:0", ddp);
            break;
          case "heating_curve_point_y1":
            updateDistributionDataPointPlcId(ozwPlcId, ddp);
            updateDistributionDataPointPlcAddress("SN:0x42f31001:0", ddp);
            break;
          case "heating_curve_point_x2":
            updateDistributionDataPointPlcId(ozwPlcId, ddp);
            updateDistributionDataPointPlcAddress("SN:0x42f21001:0", ddp);
            break;
          case "heating_curve_point_y2":
            updateDistributionDataPointPlcId(ozwPlcId, ddp);
            updateDistributionDataPointPlcAddress("SN:0x42f11001:0", ddp);
            break;
          default:
            break;
        }
        break;
      default:
        updateDistributionDataPointPlcId(null, ddp);
        updateDistributionDataPointPlcAddress(null, ddp);
        break;
    }
  }

  const columns: ColumnType<DistributionDataPoint>[] = [
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: 30,
      render: (id: number) => {
        return id === 0 ? (
          <CloseCircleTwoTone twoToneColor={colors.red.dark} style={{ fontSize: 20 }} />
        ) : (
          <CheckCircleTwoTone twoToneColor={colors.green.dark} style={{ fontSize: 20 }} />
        );
      },
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
      width: 200,
      sorter: sorter("category", "string"),
      render: (category: string) => {
        const relevantCategory = distributionDataPointsOptions.find(
          (opt: any) => opt.key === category,
        );
        return relevantCategory?.value ?? "";
      },
    },
    ...showIf<DistributionDataPoint>(
      () => hasPermission(user, Permissions.HeatingInstallationsUpdate),
      {
        title: "Conf de référence",
        key: "refConf",
        responsive: ["lg" as const],
        width: 180,
        render: (record: DistributionDataPoint) => (
          <Select
            style={{ width: 200 }}
            placeholder="Choisir une configuration"
            onSelect={(value: string) => onApplyConfiguration(value, record)}
          >
            {defaultConfigurations.map((dc) => {
              return <Select.Option value={dc.key}>{dc.value}</Select.Option>;
            })}
          </Select>
        ),
      },
    ),
    {
      title: "Automate",
      dataIndex: "plcId",
      width: 180,
      key: "plcId",
      render: (plcId: number, ddp: DistributionDataPoint) => {
        return (
          <Select
            placeholder={"Choisir un automate"}
            style={{ width: 170 }}
            value={plcId}
            onSelect={(value: number) => updateDistributionDataPointPlcId(value, ddp)}
          >
            {getPlcOptions(sitePlcs).map((po) => {
              return <Select.Option value={po.key}>{po.value}</Select.Option>;
            })}
          </Select>
        );
      },
    },
    {
      title: "Adresse du registre",
      dataIndex: "plcAddress",
      key: "plcAddress",
      width: 200,
      render: (plcAddress: string, ddp: DistributionDataPoint) => {
        return (
          <Input
            placeholder="AV101, ..."
            value={plcAddress}
            onChange={(event: any) => {
              updateDistributionDataPointPlcAddress(event.target.value, ddp);
            }}
          />
        );
      },
    },
    ...showIf<DistributionDataPoint>(
      () => hasPermission(user, Permissions.HeatingInstallationsUpdate),
      {
        title: "Edition",
        key: "edition",
        responsive: ["lg" as const],
        width: 120,
        render: (record: DistributionDataPoint) => (
          <Space>
            <Button
              type="link"
              disabled={
                !hasPermission(user, Permissions.HeatingInstallationsUpdate) ||
                !isDataPointModified(record, ddps, emptyDistributionDataPoint)
              }
              icon={<SaveOutlined style={{ fontSize: 20 }} />}
              onClick={async () => {
                if (record.id === 0) {
                  await withNotify(
                    saveDistributionDataPoint({
                      ...record,
                      dataType: getdataTypeFromCategory(record.category),
                    }),
                    "Le point de donnée a été créé avec succès",
                    "Une erreur est survenue lors de la création du point de donnée.",
                  );
                } else {
                  await withNotify(
                    updateDistributionDataPoint({
                      ...record,
                      dataType: getdataTypeFromCategory(record.category),
                    }),
                    "Le point de donnée a été mis à jour avec succès",
                    "Une erreur est survenue lors de la mise à jour du point de donnée.",
                  );
                }
              }}
            />
            <Button
              type="link"
              danger
              disabled={
                !hasPermission(user, Permissions.HeatingInstallationsUpdate) || record.id === 0
              }
              icon={<DeleteOutlined style={{ fontSize: 20 }} />}
              onClick={async () => {
                if (record.id !== 0) {
                  await withNotify(
                    deleteDistributionDataPoint(record.id),
                    "Le point de donnée a été supprimé avec succès",
                    "Une erreur est survenue lors de la suppression du point de donnée.",
                  );
                }
              }}
            />
          </Space>
        ),
      },
    ),
  ];

  return (
    <Collapse>
      <Collapse.Panel header="Points de donnée" key="1">
        {hdcId === 0 ? (
          <Typography.Text>
            Pour configurer les points de données vous devez enregistrer le circuit de distribution
            une première fois
          </Typography.Text>
        ) : (
          <>
            <Typography.Text style={{ color: "grey", fontStyle: "italic" }}>
              Pour assurer le fonctionnement des circuits un certains de points sont nécessaires.
              Ces points de données sont marqués d'une étoile. Si l'un de ces points n'est pas
              renseigné une alerte sera automatiquement remontée. Si la configuration hardware ne
              permet pas de configurer l'un de ses points, vous pouvez tout de même l'enregistrer
              avec un automate "Non renseigné" et une adresse vide. Cela permettra d'éviter la
              remonter d'alerte pour le point concerné. La pastille à gauche indique si le point de
              donnée est enregistré ou non.
            </Typography.Text>
            <Divider />
            <Table
              dataSource={distributionDataPoints}
              columns={columns}
              scroll={{ y: 240 }}
              pagination={false}
              size="small"
              expandable={{
                expandedRowRender: (record) =>
                  record.plcAddress && (
                    <AddOutsiteInstrument
                      slug={siteSlug}
                      address={record.plcAddress}
                      plc={sitePlcs.find((p: PLC) => p.id === record.plcId)}
                    />
                  ),
                rowExpandable: (record) => record.category === "composite_outside_temperature",
              }}
            />
            <Divider></Divider>
            <Typography.Text style={{ fontStyle: "italic", color: colors["blue"].dark }}>
              L'enregistrement des points de donnée se fait point par point. Lorsque vous avec
              renseigné un point de donnée enregistrez le avant de passer au suivant.
            </Typography.Text>
          </>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default DistributionDataPointConfigurationTable;
