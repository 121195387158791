import { DataPoint, DistributionDataPointCategory, PLC, ProductionDataPointCategory } from "./api";

export function getPlcOptions(sitePlcs: PLC[], withNonAttributed = true) {
  const plcOptions = sitePlcs.map((plc: PLC) => ({
    value: plc.name,
    key: plc.id as number | null,
  }));
  if (withNonAttributed) {
    plcOptions.push({ value: "Non attribué", key: null });
  }

  return plcOptions;
}

export function getDataPointValue(
  category: ProductionDataPointCategory | DistributionDataPointCategory,
  pdps: DataPoint[],
  emptyDataPoint: DataPoint,
) {
  const relevantPdp = pdps.find((pdp: DataPoint) => pdp.category === category);
  if (!relevantPdp) {
    emptyDataPoint.category = category;
    return { ...emptyDataPoint };
  }

  return { ...relevantPdp };
}

export function updateDataPointPlcId(
  plcId: number | null,
  dataPoint: DataPoint,
  dataPoints: DataPoint[],
) {
  const newDataPoints = [...dataPoints];
  const index = newDataPoints.findIndex((dp: DataPoint) => dp.category === dataPoint.category);
  if (newDataPoints[index] !== undefined) {
    newDataPoints[index].plcId = plcId;
  }

  return newDataPoints;
}

export function updateDataPointPlcAddress(
  address: string | null,
  dataPoint: DataPoint,
  dataPoints: DataPoint[],
) {
  const newDataPoints = [...dataPoints];
  const index = newDataPoints.findIndex((dp: DataPoint) => dp.category === dataPoint.category);
  if (newDataPoints[index] !== undefined) {
    newDataPoints[index].plcAddress = address;
  }

  return newDataPoints;
}

export function isDataPointModified(dp: DataPoint, dps: DataPoint[], emptyDataPoint: DataPoint) {
  const referenceValue = getDataPointValue(dp.category, dps, emptyDataPoint);
  if (
    referenceValue.plcAddress === null &&
    referenceValue.plcId === null &&
    referenceValue.id === 0
  ) {
    return true;
  }

  if (referenceValue.plcId !== dp.plcId || referenceValue.plcAddress !== dp.plcAddress) {
    return true;
  }

  return false;
}
