import { useState } from "react";
import * as React from "react";
import { DatePicker, message, Modal, Typography, Checkbox } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { Site, SiteAPIService } from "src/lib/api";
import moment, { Moment } from "moment";

const ExportSiteMeasurementsModal: React.FC<{
  site: Site;
  showModal: boolean;
  closeModal: Function;
  selectedInstrumentRows: any;
  exportType: "classique" | "xy";
}> = ({ site, showModal, closeModal, exportType, selectedInstrumentRows }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [from, setFrom] = useState<Moment | null>(null);
  const [to, setTo] = useState<Moment | null>(null);
  const [exportWithWeather, setExportWithWeather] = React.useState(true);

  const handleRange = (value: any) => {
    const [from, to] = value ?? [null, null];
    setFrom(from);
    setTo(to);
  };

  const handleExport = async () => {
    setConfirmLoading(true);
    if (exportType === "classique") {
      await onSiteMeasurementsExport();
    } else if (exportType === "xy") {
      await onSiteMeasurementsExportXY();
    }
    setConfirmLoading(false);
    closeModal();
  };

  const onChangeWeatherExport = () => {
    setExportWithWeather(!exportWithWeather);
  };

  async function onSiteMeasurementsExport() {
    setConfirmLoading(true);

    if (from === null || to === null) return;

    try {
      await new SiteAPIService(getAccessTokenSilently).downloadSiteMeasurements({
        sId: site.slug,
        from: from?.startOf("day").add(1, "hours").unix(),
        to: to?.endOf("day").add(1, "hours").unix(),
        fileName: `${site.name} - Export des mesures de températures.csv`,
      });
    } catch (e) {
      message.error({
        content: `L'export des données a échoué. Notre équipe a été prévenue. Veuillez ressayer ultérieurement.`,
      });
    } finally {
      setConfirmLoading(false);
    }
  }

  async function onSiteMeasurementsExportXY() {
    const instrumentIds: any = selectedInstrumentRows
      .filter((elt: any) => elt.type === "instrument")
      .map((elt: any) => elt.id);
    const groupIds: any = selectedInstrumentRows
      .filter((elt: any) => elt.type === "group")
      .map((elt: any) => elt.id);

    setConfirmLoading(true);
    if (from === null || to === null) return;
    try {
      await new SiteAPIService(getAccessTokenSilently).downloadInstrumentsMeasurements({
        fileName: `${site.name} - Analyse des données de site.csv`,
        instrumentIds: instrumentIds,
        groupIds: groupIds,
        withWeather: exportWithWeather,
        from: from?.startOf("day").unix(),
        to: to?.endOf("day").unix(),
        sId: site.slug,
      });
    } catch (e) {
      message.error({
        content: `L'export des données a échoué. Notre équipe a été prévenue. Veuillez ressayer ultérieurement.`,
      });
    } finally {
      setConfirmLoading(false);
    }
  }

  return (
    <div>
      <Modal
        title={`Export ${exportType} des mesures du site ${site.name}`}
        visible={showModal}
        onOk={handleExport}
        confirmLoading={confirmLoading}
        onCancel={() => closeModal()}
        okText="Continuer"
        okButtonProps={{ disabled: from === null || to === null }}
      >
        <Typography.Paragraph strong={true}>
          Cette opération peut prendre quelques minutes en fonction de la période choisie et le
          nombre de sondes par site.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Veuillez choisir la durée de l'export si vous voulez continuer
        </Typography.Paragraph>
        <DatePicker.RangePicker
          onChange={handleRange}
          disabledDate={(current: Moment | null) => (current ? current > moment() : true)}
        />
        {exportType === "xy" && (
          <Checkbox
            onChange={onChangeWeatherExport}
            style={{ margin: 10 }}
            checked={exportWithWeather}
          >
            Exporter avec la météo
          </Checkbox>
        )}
      </Modal>
    </div>
  );
};

export default ExportSiteMeasurementsModal;
