import { Row, Typography } from "antd";
import moment from "moment";
import { batteryPercentages, batteryVoltages, dashboardColors } from "src/constants";
import { InstrumentCategory, Site } from "src/lib/api";
import { useSiteInstruments, useSitePeriodMeasurements } from "src/lib/hooks/api";
import { DashboardIndicator } from "./DashboardIndicator";

function LowBatteries(site: Site) {
  const instrumentsRes = useSiteInstruments(site.slug);
  if (instrumentsRes.status === "success") {
    const instruments = instrumentsRes.data.filter((i) => i.category !== InstrumentCategory.Other);

    const lowBatteryCount = instruments.filter(
      (i) =>
        (i.battery.type === "flag" && i.battery.value === "true") ||
        (i.battery.type === "voltage" &&
          parseFloat(i.battery.value) !== 0 &&
          parseFloat(i.battery.value) <= batteryVoltages.low) ||
        (i.battery.type === "percentage" &&
          parseFloat(i.battery.value) !== 0 &&
          parseFloat(i.battery.value) <= batteryPercentages.low),
    ).length;

    return `${lowBatteryCount}/${instruments.length}`;
  }
}

export const DashboardInstrumentInfos: React.FC<{
  site: Site;
}> = ({ site }) => {
  const instrumentsRes = useSiteInstruments(site.slug);
  const instruments =
    instrumentsRes.status === "success"
      ? instrumentsRes.data.filter((i) => i.category !== InstrumentCategory.Other)
      : [];

  const measurementsRes = useSitePeriodMeasurements(
    site.slug,
    moment().subtract(7, "d").unix(),
    moment().unix(),
  );
  const measurementsAll = measurementsRes.status === "success" ? measurementsRes.data : [];
  const measurementsNoOther = Object.entries(measurementsAll).filter((m) =>
    instruments.find((i) => i.id.toString() === m[0]),
  );
  const temperatures = measurementsNoOther
    .filter((m) => m[1] !== null)
    .map((m) => m[1].temperatureMeasurement);

  const noDataCount = measurementsNoOther.filter((m) => m[1] === null).length;
  const underheatingCount = temperatures.filter(
    (t) => t < site.currentHeatingSeason.comfortRange.lower,
  ).length;
  const nominalCount = temperatures.filter(
    (t) =>
      t > site.currentHeatingSeason.comfortRange.lower &&
      t < site.currentHeatingSeason.comfortRange.upper,
  ).length;
  const overheatingCount = temperatures.filter(
    (t) => t > site.currentHeatingSeason.comfortRange.upper,
  ).length;

  return (
    <>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          SUPERVISION DES SONDES
        </Typography.Text>
      </Row>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Typography.Text style={{ fontStyle: "italic", fontSize: 11 }}>
          (7 jours glissants)
        </Typography.Text>
      </Row>
      <Row style={{ padding: 5 }}>
        <DashboardIndicator
          color={dashboardColors.pink}
          title="EN SURCHAUFFE"
          value={`${overheatingCount}/${measurementsNoOther.length}`}
        />
      </Row>

      <Row style={{ padding: 5 }}>
        <DashboardIndicator
          color={dashboardColors.green}
          title="EN FONCTIONNEMENT NOMINAL"
          value={`${nominalCount}/${measurementsNoOther.length}`}
        />
      </Row>

      <Row style={{ padding: 5 }}>
        <DashboardIndicator
          color={dashboardColors.blue}
          title="EN SOUS CHAUFFE"
          value={`${underheatingCount}/${measurementsNoOther.length}`}
        />
      </Row>

      <Row style={{ padding: 5 }}>
        <DashboardIndicator
          color={dashboardColors.brown}
          title="SANS DONNEES"
          value={`${noDataCount}/${measurementsNoOther.length}`}
        />
      </Row>

      <Row style={{ padding: 5 }}>
        <DashboardIndicator
          color={dashboardColors.yellow}
          title="BATTERIE FAIBLE"
          value={LowBatteries(site)}
        />
      </Row>
    </>
  );
};
