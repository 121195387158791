import { Card, Empty } from "antd";
import moment from "moment";
import { Suspense } from "react";
import { Site, HeatingDistributionCircuit } from "src/lib/api";
import {
  useSiteHeatingDistributionCircuitMeasurements,
  useSiteHeatingProductionUnitMeasurements,
  useWeatherData,
} from "src/lib/hooks/api";
import Chart from "./Chart";
import { ChartDisplayMode } from "./Chart/Chart";
import { timestampCompareFn } from "./Chart/interpolate-data";
import DatePickerDownloader from "./DatePickerDownloader";
import ErrorBoundaryWithFallback from "./ErrorBoundaryWithFallback";
import Loader from "./Loader";

const HeatingDistributionCircuitMeasurementsChart: React.FC<{
  site: Site;
  heatingDistributionCircuit: HeatingDistributionCircuit;
  from: number;
  to: number;
}> = ({ site, heatingDistributionCircuit, from, to }) => {
  const { data: hdcMeasurements } = useSiteHeatingDistributionCircuitMeasurements(
    site.slug,
    heatingDistributionCircuit.id,
    from,
    to,
  );

  const { data: hpuMeasurements } = useSiteHeatingProductionUnitMeasurements(
    site.slug,
    heatingDistributionCircuit.heatingProductionUnitId,
    from,
    to,
  );

  const { data: weather } = useWeatherData(site.id, from, to);

  hdcMeasurements.sort(timestampCompareFn);

  const alignedWeather = {
    weather: weather.weather.filter(
      (w) =>
        // Filter any weather points before the first consumption date.
        !moment(w.timestamp).isBefore(hdcMeasurements?.[0]?.timestamp) &&
        // Filter any weather points after the last consumption date.
        !moment(w.timestamp).isAfter(hdcMeasurements?.[hdcMeasurements.length - 1]?.timestamp),
    ),
    dailyHDD: weather.dailyHDD.filter(
      (w) =>
        // Filter any weather points before the first consumption date.
        !moment(w.date).isBefore(hdcMeasurements?.[0]?.timestamp) &&
        // Filter any weather points after the last consumption date.
        !moment(w.date).isAfter(hdcMeasurements?.[hdcMeasurements.length - 1]?.timestamp),
    ),
    weatherLocation: weather.weatherLocation,
  };

  return (
    <>
      <Chart
        kind={ChartDisplayMode.Historic}
        weatherData={alignedWeather}
        hdcMeasurements={hdcMeasurements}
        hpuMeasurements={hpuMeasurements}
      />
    </>
  );
};

const HeatingDistributionMeasurementsHistory: React.FC<{
  site: Site;
  heatingDistributionCircuit: HeatingDistributionCircuit;
}> = ({ site, heatingDistributionCircuit }) => {
  return (
    <Card title="Mesures circuit & météo">
      <DatePickerDownloader
        ranges={{
          "1 jour": [moment().subtract(1, "d"), moment()],
          "1 semaine": [moment().subtract(7, "d"), moment()],
        }}
        defaultRange={[moment().subtract(1, "d"), moment()]}
        forceUTC
        roundDate={false}
      >
        {({ from, to }) => (
          <Suspense fallback={<Loader />}>
            <ErrorBoundaryWithFallback fallback={<Empty />}>
              <HeatingDistributionCircuitMeasurementsChart
                site={site}
                heatingDistributionCircuit={heatingDistributionCircuit}
                from={from}
                to={to}
              />
            </ErrorBoundaryWithFallback>
          </Suspense>
        )}
      </DatePickerDownloader>
    </Card>
  );
};

export default HeatingDistributionMeasurementsHistory;
