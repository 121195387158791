import * as React from "react";
import Icon, { CalendarOutlined, QuestionOutlined, ThunderboltOutlined } from "@ant-design/icons";

import { BatteryMeasurement } from "src/lib/api";
import { batteryPercentages, batteryVoltages } from "src/constants";
import { Tooltip } from "antd";
import moment from "moment";

const BatteryFullSvg = () => (
  <svg
    className="icon"
    viewBox="0 0 1316 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="1.5em"
    height="1em"
  >
    <path
      d="M1097.142857 292.571429v438.857142H146.285714V292.571429h950.857143z m73.142857 329.142857h73.142857V402.285714h-73.142857V237.714286q0-8-5.142857-13.142857t-13.142857-5.142858H91.428571q-8 0-13.142857 5.142858t-5.142857 13.142857v548.571428q0 8 5.142857 13.142857t13.142857 5.142858h1060.571429q8 0 13.142857-5.142858t5.142857-13.142857v-164.571428z m146.285715-219.428572v219.428572q0 30.285714-21.428572 51.714285T1243.428571 694.857143v91.428571q0 37.714286-26.857142 64.571429t-64.571429 26.857143H91.428571q-37.714286 0-64.571428-26.857143T0 786.285714V237.714286q0-37.714286 26.857143-64.571429t64.571428-26.857143h1060.571429q37.714286 0 64.571429 26.857143t26.857142 64.571429v91.428571q30.285714 0 51.714286 21.428572T1316.571429 402.285714z"
      fill="#52c41a"
    />
  </svg>
);

const BatteryLowSvg = () => (
  <svg
    className="icon"
    viewBox="0 0 1316 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="1.5em"
    height="1em"
  >
    <path
      d="M146.285714 731.428571V292.571429h292.571429v438.857142H146.285714z m1097.142857-402.285714q30.285714 0 51.714286 21.428572T1316.571429 402.285714v219.428572q0 30.285714-21.428572 51.714285T1243.428571 694.857143v91.428571q0 37.714286-26.857142 64.571429t-64.571429 26.857143H91.428571q-37.714286 0-64.571428-26.857143T0 786.285714V237.714286q0-37.714286 26.857143-64.571429t64.571428-26.857143h1060.571429q37.714286 0 64.571429 26.857143t26.857142 64.571429v91.428571z m0 292.571429V402.285714h-73.142857V237.714286q0-8-5.142857-13.142857t-13.142857-5.142858H91.428571q-8 0-13.142857 5.142858t-5.142857 13.142857v548.571428q0 8 5.142857 13.142857t13.142857 5.142858h1060.571429q8 0 13.142857-5.142858t5.142857-13.142857v-164.571428h73.142857z"
      fill="#fa8c16"
    />
  </svg>
);

const BatteryDeadSvg = () => (
  <svg
    className="icon"
    viewBox="0 0 1316 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="1.5em"
    height="1em"
  >
    <path
      d="M1243.428571 329.142857q30.285714 0 51.714286 21.428572T1316.571429 402.285714v219.428572q0 30.285714-21.428572 51.714285T1243.428571 694.857143v91.428571q0 37.714286-26.857142 64.571429t-64.571429 26.857143H91.428571q-37.714286 0-64.571428-26.857143T0 786.285714V237.714286q0-37.714286 26.857143-64.571429t64.571428-26.857143h1060.571429q37.714286 0 64.571429 26.857143t26.857142 64.571429v91.428571z m0 292.571429V402.285714h-73.142857V237.714286q0-8-5.142857-13.142857t-13.142857-5.142858H91.428571q-8 0-13.142857 5.142858t-5.142857 13.142857v548.571428q0 8 5.142857 13.142857t13.142857 5.142858h1060.571429q8 0 13.142857-5.142858t5.142857-13.142857v-164.571428h73.142857z"
      fill="#f5222d"
    />
  </svg>
);

const BatteryFullIcon = React.forwardRef((props: any, ref) => (
  <Icon component={BatteryFullSvg} {...props} ref={ref} />
));
const BatteryLowIcon = React.forwardRef((props: any, ref) => (
  <Icon component={BatteryLowSvg} {...props} ref={ref} />
));
const BatteryDeadIcon = React.forwardRef((props: any, ref) => (
  <Icon component={BatteryDeadSvg} {...props} ref={ref} />
));

export { BatteryFullIcon, BatteryLowIcon, BatteryDeadIcon };

// exported for testing
export function batteryIcon(battery: BatteryMeasurement): "unknown" | "full" | "low" | "dead" {
  switch (battery.type) {
    case "voltage":
      const v = parseFloat(battery.value);

      if (v > batteryVoltages.low) return "full";
      else if (v <= batteryVoltages.low && v > batteryVoltages.dead) {
        return "low";
      } else if (v < batteryVoltages.dead && v > 0) {
        return "dead";
        // /!\ Code executed only if the voltage is 0.
      } else return "unknown";

    case "percentage":
      const p = parseFloat(battery.value);

      if (p >= batteryPercentages.low) return "full";
      else if (p <= batteryPercentages.low && p > batteryPercentages.dead) {
        return "low";
      } else if (p <= batteryPercentages.dead && p > 0) {
        return "dead";
        // /!\ Code executed only if the voltage is 0.
      } else return "unknown";

    case "flag":
      return battery.value === "true" ? "dead" : "full";

    case "current_consumption":
      return "unknown";

    case "unknown":
      return "unknown";
  }
}

const BatteryIcon: React.FC<{ battery: BatteryMeasurement }> = ({ battery }) => {
  const voltageVoltageMsg = battery.type === "voltage" ? `${battery.value}v` : undefined;
  const percentageVoltageMsg =
    battery.type === "percentage"
      ? `${battery.value ? parseFloat(battery.value).toFixed(2) : "-"}%`
      : undefined;
  const flagVoltageMsg =
    battery.type === "flag" ? "Tension indisponible pour les sondes Adeunis" : undefined;
  const currentConsumptionVoltageMessage =
    battery.type === "current_consumption" ? `${battery.value}µAh` : undefined;

  const voltageMsg = (() => {
    switch (battery.type) {
      case "voltage":
        return voltageVoltageMsg;
      case "percentage":
        return percentageVoltageMsg;
      case "flag":
        return flagVoltageMsg;
      case "current_consumption":
        return currentConsumptionVoltageMessage;
      case "unknown":
        return undefined;
    }
  })();

  const icon = (function () {
    switch (batteryIcon(battery)) {
      case "full":
        return <BatteryFullIcon style={{ fontSize: "1.3rem" }} />;

      case "low":
        return <BatteryLowIcon style={{ fontSize: "1.3rem" }} />;

      case "dead":
        return <BatteryDeadIcon style={{ fontSize: "1.3rem" }} />;

      case "unknown":
        return <QuestionOutlined style={{ fontSize: "1.3rem" }} />;
    }
  })();

  return (
    <Tooltip
      title={
        voltageMsg && battery.type !== "unknown" ? (
          <span>
            <b>
              <ThunderboltOutlined /> {battery.type === "percentage" ? "Pourcentage:" : "Tension:"}
            </b>{" "}
            {voltageMsg}
            <br />
            <b>
              <CalendarOutlined /> Date:
            </b>{" "}
            {moment(battery.timestamp).format("lll")}
          </span>
        ) : (
          <span>État de la pile indisponible</span>
        )
      }
    >
      {icon}
    </Tooltip>
  );
};

export default BatteryIcon;
