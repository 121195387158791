import * as React from "react";
import { Helmet } from "react-helmet";

const PageTitle: React.FC<{ title?: string }> = ({ title }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Effic'App - {title ? title : ""}</title>
      <link rel="canonical" href="https://app.efficap-energie.com" />
    </Helmet>
  );
};

export default PageTitle;
