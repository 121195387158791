import { Card, Space, Button, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  useSiteHeatingDistributionLatestRegulationMeasurement,
  queryKeySiteDistributionCircuitLatestRegulationMeasurement,
} from "src/lib/hooks/api";
import { ReloadOutlined, MonitorOutlined } from "@ant-design/icons";

const PredictiveRegulationControls: React.FC<{
  siteSlug: string;
  heatingDistributionCircuitId: number;
}> = ({ siteSlug, heatingDistributionCircuitId }) => {
  const queryClient = useQueryClient();

  const { data: latestRegulationMeasurement } =
    useSiteHeatingDistributionLatestRegulationMeasurement(siteSlug, heatingDistributionCircuitId);

  const [loading, setLoading] = useState(false);

  const onRefresh = async () => {
    setLoading(true);
    await Promise.all([
      queryClient.invalidateQueries(
        queryKeySiteDistributionCircuitLatestRegulationMeasurement(
          siteSlug,
          heatingDistributionCircuitId,
        ),
      ),
    ]);
    setLoading(false);
  };

  const getRegulationOffset = () => {
    const regulationType = latestRegulationMeasurement.predictorParameters.regulationType;

    switch (regulationType) {
      case "eco":
        // applying minus to have a positive offset as what is sent to plc
        return -latestRegulationMeasurement.predictorRegulation.feedbackValueFiltered;
      case "comfort":
        // applying minus to have a positive offset as what is sent to plc
        return -latestRegulationMeasurement.predictorRegulation.feedbackValueFiltered;
      case "custom":
        return latestRegulationMeasurement.predictorParameters.customOffset;
      case "ecco2":
        return latestRegulationMeasurement.offsetEcco2;
      case "none":
        return undefined;
    }
  };

  const getRegulationOffsetType = () => {
    const regulationType = latestRegulationMeasurement.predictorParameters.regulationType;

    switch (regulationType) {
      case "eco":
        return "Prédictop - éco";
      case "comfort":
        return "Prédictop - confort";
      case "custom":
        return "Offset forcé";
      case "ecco2":
        return "Ecco2";
      case "none":
        return "Aucune";
    }
  };

  return (
    <div style={{ marginBottom: "1.5rem" }}>
      <Card
        title={
          <Space direction="horizontal">
            <MonitorOutlined />
            Gestion de la régulation prédictive
          </Space>
        }
        extra={<Button icon={<ReloadOutlined />} onClick={onRefresh} />}
        loading={loading}
      >
        <div style={{ marginBottom: "0.5rem" }}>
          <span>
            <b>Offset envoyé par le serveur: </b>
            {getRegulationOffset()?.toFixed(2) ?? "-"} °K
            {` (envoyé le ${moment(latestRegulationMeasurement.timestamp).format("llll")})`}
          </span>
        </div>
        <div style={{ marginBottom: "0.5rem" }}>
          <span>
            <b>Type de régulation serveur:</b> {getRegulationOffsetType()}
          </span>
        </div>
      </Card>
    </div>
  );
};

export const PredictiveRegulationControlsFallback: React.FC<{}> = () => {
  return (
    <Card title="Gestion de la régulation prédictive">
      <Typography.Text>
        La gestion de la régulation prédictive n'est pas disponible pour ce circuit.
      </Typography.Text>
    </Card>
  );
};

export default PredictiveRegulationControls;
