import auth0Config from "src/auth0-config";

export interface User {
  id: string;
  name: string;
  email: string;
  picture: string;
  authorizedSites: number[];
  permissions: string[];
}

export enum PermissionGroup {
  Users = "users:",
  InstrumentGroups = "instrument-groups:",
  InstrumentGroupAlerts = "instrument-group-alerts:",
  HeatingInstallations = "heating-installations:",
}

export enum Permissions {
  HeatingInstallationsRead = "heating-installations:read",
  HeatingInstallationsUpdate = "heating-installations:update",
  HeatingInstallationsOffsetToggleUpdate = "heating-installations-offset-toggle:update",

  InstrumentsCreate = "instruments:create",
  InstrumentsUpdate = "instruments:update",
  InstrumentsDelete = "instruments:delete",

  InstrumentCommentsUpdate = "instrument-comments:update",

  InstrumentGroupsCreate = "instrument-groups:create",
  InstrumentGroupsUpdate = "instrument-groups:update",
  InstrumentGroupsDelete = "instrument-groups:delete",

  LiveDataRead = "live-data:read",

  SitesCreate = `sites:create`,
  SitesUpdate = `sites:update`,

  UsersCreate = `users:create`,
  UsersRead = `users:read`,
  UsersUpdate = `users:update`,

  ExportCreate = `export:create`,

  SchedulingUpdate = `scheduling:update`,

  MonitorRead = `monitoring:read`,
  MonitorUpdate = `monitoring:update`,

  SavingsRead = `savings:read`,

  BannersCreate = `banners:create`,
}

function parseAuth0User(u: any): User {
  return {
    id: u.sub,
    email: u.email,
    name: u.name,
    authorizedSites: u[auth0Config.namespace + "/authorized_sites"],
    permissions: u[auth0Config.namespace + "/permissions"],
    picture: u.picture,
  };
}

export function hasPermission(user: any, check: Permissions | PermissionGroup): boolean {
  return parseAuth0User(user).permissions.some((p) => p.includes(check));
}

export function hasOnePermission(user: any, check: Array<Permissions | PermissionGroup>): boolean {
  return check.some((p) => hasPermission(user, p));
}

export function hasAllPermissions(
  user: User,
  check: Array<Permissions | PermissionGroup>,
): boolean {
  return check.every((p) => hasPermission(user, p));
}

export function hasSeveralAuthorizedSites(user: any): boolean {
  let parsed = parseAuth0User(user);

  return (
    parsed.authorizedSites.length > 1 ||
    (parsed.authorizedSites.length === 1 && parsed.authorizedSites[0] === -1)
  );
}
