import * as React from "react";
import { ConfigProvider, message, notification } from "antd";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter, Routes, useNavigate } from "react-router-dom";
import { QueryClientProvider, QueryClient, QueryClientConfig } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import config from "src/auth0-config";
import PrivateRoute from "src/components/PrivateRoute";
import frFR from "antd/lib/locale/fr_FR";
import moment from "moment";
import "moment/locale/fr";
import PageTitle from "src/components/PageTitle";
import { APIError, Errors } from "src/lib/api";
import Layout from "src/components/Layout";
import { useHealthZ } from "src/lib/hooks/api";
import Loader from "src/components/Loader";
import ServerUnreachable from "./server-unreachable";
import { ComfortIndicatorsPage } from "./comfort-indicators";

const HomePage = React.lazy(() => import("src/pages/home"));
const AlertsPage = React.lazy(() => import("src/pages/alerts"));
const BlankPage = React.lazy(() => import("src/pages/blank"));
const SitesPage = React.lazy(() => import("src/pages/sites"));
const SitesNewPage = React.lazy(() => import("src/pages/sites-new"));
const MeasurementsPage = React.lazy(() => import("src/pages/measurements"));
const InstrumentsPage = React.lazy(() => import("src/pages/instruments"));
const InstrumentGroupsPage = React.lazy(() => import("src/pages/instrument-groups"));
const EnergyMeterPage = React.lazy(() => import("src/pages/energy-meters"));
const ServerErrorPage = React.lazy(() => import("src/pages/server-error"));
const UnauthorizedPage = React.lazy(() => import("src/pages/unauthorized-page"));
const UsersPage = React.lazy(() => import("src/pages/users"));
const BannersPage = React.lazy(() => import("src/pages/banners"));
const EventsStreamPage = React.lazy(() => import("src/pages/events-stream"));
const NotFoundPage = React.lazy(() => import("src/pages/not-found-page"));
const SupportPage = React.lazy(() => import("src/pages/support"));
const LiveDataPage = React.lazy(() => import("src/pages/live-data"));
const MonitoringPage = React.lazy(() => import("src/pages/monitoring"));
const PlcMonitoringPage = React.lazy(() => import("src/pages/site-plcs-monitoring"));
const DebugPage = React.lazy(() => import("src/pages/debug"));
const DeploymentSessionPage = React.lazy(() => import("src/pages/deployment-session"));
const DeploymentInstrumentsSelectionPage = React.lazy(
  () => import("src/pages/deployment-instrument-selection"),
);
const Operations = React.lazy(() => import("src/pages/operations"));
const SitesOperations = React.lazy(() => import("src/pages/sites-operations/sites-operations"));

moment.locale("fr");

const routes = [
  {
    path: "/",
    component: <HomePage />,
  },
  {
    path: "/alerts",
    component: <AlertsPage />,
  },
  {
    path: "/blank",
    component: <BlankPage />,
  },
  {
    path: "/sites/new",
    component: <SitesNewPage />,
  },
  {
    path: "/sites/:slug/measurements",
    component: <MeasurementsPage />,
  },
  {
    path: "/sites/:slug/operations",
    component: <SitesOperations />,
  },
  {
    path: "/sites/:slug/*",
    component: <SitesPage />,
  },
  {
    path: "/instruments/:iId",
    component: <InstrumentsPage />,
  },
  {
    path: "/instrument-groups/:igId/*",
    component: <InstrumentGroupsPage />,
  },
  {
    path: "sites/:slug/energy-meters/:mId",
    component: <EnergyMeterPage />,
  },
  {
    path: "/support",
    component: <SupportPage />,
  },
  {
    path: "/users",
    component: <UsersPage />,
  },
  {
    path: "/banners",
    component: <BannersPage />,
  },
  {
    path: "/deployment-session",
    component: <DeploymentSessionPage />,
  },
  {
    path: "/deployment-session/:dsid",
    component: <DeploymentInstrumentsSelectionPage />,
  },
  {
    path: "/comfort-indicators",
    component: <ComfortIndicatorsPage />,
  },
  {
    path: "/events-stream",
    component: <EventsStreamPage />,
  },
  {
    path: "/live-data",
    component: <LiveDataPage />,
  },
  {
    path: "/monitoring/*",
    component: <MonitoringPage />,
  },
  {
    path: "/monitoring/:slug/plcs",
    component: <PlcMonitoringPage />,
  },
  {
    path: "/debug",
    component: <DebugPage />,
  },
  {
    path: "/operations",
    component: <Operations />,
  },
  {
    path: "/401",
    component: <UnauthorizedPage />,
  },
  {
    path: "/404",
    component: <NotFoundPage />,
  },
  {
    path: "/500",
    component: <ServerErrorPage />,
  },
  {
    // If none of the routes above match, render a Not Found page.
    path: "*",
    component: <NotFoundPage />,
  },
];

const ServerCheckWrapper: React.FC<{ children: any }> = ({ children }) => {
  const { data, isLoading, error } = useHealthZ();

  if (isLoading) return <Loader />;

  if (error || !data.status === true) {
    return <ServerUnreachable />;
  }

  return <>{children}</>;
};

const reactQueryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      suspense: true,
      retry: (failureCount: number, error: unknown) => {
        const nonRetryableErrors = [Errors.Unauthorized, Errors.NotFound, Errors.BadRequest];
        // Retry for 3 times only for retryable errors.
        return failureCount < 3 && !nonRetryableErrors.includes((error as APIError)?.error);
      },
      retryDelay: (attemptIndex: number) => Math.min(1000 * 2 ** attemptIndex, 5000),
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      refetchInterval: 10 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
    },
    mutations: {
      useErrorBoundary: true,
    },
  },
};

const queryClient = new QueryClient(reactQueryConfig);

const AppWithoutRouter: React.FC = () => {
  const navigate = useNavigate();

  message.config({
    duration: 20,
    maxCount: 3,
  });

  notification.config({ duration: 10 });

  // https://github.com/react-component/field-form/blob/master/src/utils/messages.ts
  const antdValidateMsgs = {
    validateMessages: {
      // eslint-disable-next-line no-template-curly-in-string
      required: "Le champ '${label}' est requis",
      types: {
        email: "Adresse email invalide",
        number: "Nombre invalide",
      },
      string: {
        // eslint-disable-next-line no-template-curly-in-string
        min: "Ce champ ne peut pas contenir moins de ${min} caractères",
      },
      number: {
        // eslint-disable-next-line no-template-curly-in-string
        min: "Ce champ ne peut pas contenir moins de ${min} caractères",
        // eslint-disable-next-line no-template-curly-in-string
        max: "Ce champ ne peut pas contenir plus de ${min} caractères",
        // eslint-disable-next-line no-template-curly-in-string
        range: "La valeur du champ doit être entre ${min} et ${max}'",
      },
      pattern: {
        mismatch: "Champ invalide",
      },
    },
  };

  return (
    <>
      <PageTitle />
      <Auth0Provider
        audience={config.audience}
        domain={config.domain}
        clientId={config.clientId}
        redirectUri={window.location.origin}
        useRefreshTokens={true}
        onRedirectCallback={(appState) => {
          if (appState.returnTo) navigate(appState.returnTo);
        }}
        cacheLocation="localstorage"
      >
        <ConfigProvider locale={frFR} form={antdValidateMsgs}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <React.Suspense fallback={<Loader />}>
              <ServerCheckWrapper>
                <Routes>
                  {routes.map((route) => (
                    <PrivateRoute
                      key={route.path}
                      path={route.path}
                      element={<Layout>{route.component}</Layout>}
                    />
                  ))}
                </Routes>
              </ServerCheckWrapper>
            </React.Suspense>
          </QueryClientProvider>
        </ConfigProvider>
      </Auth0Provider>
    </>
  );
};

/** App wrapped with router to be able to use the useNavigate() hook
 * inside the component AppWithoutRouter
 * */
const App = () => (
  <BrowserRouter>
    <AppWithoutRouter />
  </BrowserRouter>
);

export default App;
