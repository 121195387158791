import React from "react";
import {
  useSiteHeatingDistributionSchedulingParameters,
  useSiteSchedulingEvents,
  useSiteSchedulingMeasurements,
} from "src/lib/hooks/api";
import Chart from "./Chart";
import { ChartDisplayMode, ChartSchedulingProps } from "./Chart/Chart";

const SchedulingRegulationMeasurements: React.FC<{
  siteSlug: string;
  from: number;
  to: number;
  hdcId: number;
}> = ({ siteSlug, from, to, hdcId }) => {
  const { data: measurements } = useSiteSchedulingMeasurements(siteSlug, hdcId, from, to);
  const { data: sp } = useSiteHeatingDistributionSchedulingParameters(siteSlug, hdcId.toString());
  const { data: events } = useSiteSchedulingEvents(siteSlug, hdcId, from, to);

  const chartProps: ChartSchedulingProps = {
    kind: ChartDisplayMode.SchedulingMeasurement,
    data: measurements,
    eventsData: events,
    degreeMargin: sp?.degreeMargin ?? 0,
    weatherData: {
      weather: [],
      dailyHDD: [],
      weatherLocation: {
        id: 0,
        name: "N/A",
        coordinates: { latitude: 0, longitude: 0, elevation: 0 },
      },
    },
  };

  return (
    <div>
      <Chart {...chartProps} />
    </div>
  );
};

export default SchedulingRegulationMeasurements;
