import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect } from "react";
import { Route } from "react-router-dom";
import Loader from "./Loader";

interface PrivateRouteProps {
  element: React.ReactElement;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, path }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    const returnTo = `${window.location.pathname}${window.location.search}`;

    const opts = {
      appState: { returnTo },
    };

    (async (): Promise<void> => {
      await loginWithRedirect(opts);
    })();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return (
    <Route
      path={path}
      element={
        isAuthenticated ? (
          <>{element}</>
        ) : (
          <Loader tip="Nous vérifions votre compte EFFICAP-&eacute;nergie" size="large" />
        )
      }
    />
  );
};

export default PrivateRoute;
