import { BellFilled, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { FormInstance } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import React from "react";
import { FailureIcon, SuccessIcon } from "src/assets/icons";
import { colors } from "src/constants";
import { Permissions, hasPermission } from "src/lib/access-control";
import { HeatingProductionUnit, HeatingProductionUnitAlert } from "src/lib/api";
import {
  useDeleteHeatingProductionUnitAlert,
  useHeatingProductionUnitAlerts,
  useSaveHeatingProductionUnitAlert,
  useSiteHeatingProductionUnits,
  useToggleAcknowledgeAlert,
} from "src/lib/hooks/api";
import { useWindowWidth } from "src/lib/hooks/window-width";
import { withNotify } from "src/lib/notify";

const HeatingDistributionCircuitForm: React.FC<{
  form: FormInstance<any>;
  hpus: HeatingProductionUnit[];
}> = ({ form, hpus }) => {
  const minValue = 0;
  const maxValue = 100;
  const step = 0.5;
  const [upperThresholdMinValue, setUpperThresholdMinValue] = React.useState(minValue);

  const hpuOptions = hpus.map((hpu: HeatingProductionUnit) => ({
    key: hpu.id,
    value: hpu.name,
  }));

  return (
    <Form layout="vertical" form={form} name="create-instrument-group-alert">
      <Form.Item name={"id"} hidden>
        <Input autoFocus={true} />
      </Form.Item>
      <Form.Item
        name={"name"}
        label="Nom de l'alerte 🚨"
        hasFeedback={true}
        rules={[{ required: true, min: 3 }]}
      >
        <Input autoFocus={true} />
      </Form.Item>
      <Form.Item name={"comments"} label="Commentaires sur l'alerte 💬" hasFeedback={true}>
        <Input />
      </Form.Item>
      <Form.Item
        name={"userMail"}
        label="Email destinataire"
        hasFeedback={true}
        rules={[{ required: true, type: "email" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"alertType"}
        label="Type d'alerte"
        hasFeedback={true}
        rules={[{ required: true }]}
      >
        <Select>
          <Select.Option value="mean_primary_temperature">
            Température de départ moyenne
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={"heatingProductionUnitId"}
        label="Unité de production associée"
        hasFeedback={true}
        rules={[{ required: true }]}
      >
        <Select>
          {hpuOptions.map((ato: any) => {
            return <Select.Option value={ato.key}>{ato.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item name="lowerThreshold" label="Seuil Bas ❄️ (°C)">
        <InputNumber
          placeholder="Renseignez un seuil"
          min={minValue}
          max={maxValue}
          step={step}
          style={{ width: "8rem" }}
          onChange={(value) => {
            form.setFieldsValue({ lowerThreshold: value });
            setUpperThresholdMinValue(value + step);
          }}
        />
      </Form.Item>
      <Form.Item name="upperThreshold" label="Seuil Haut 🔥 (°C)">
        <InputNumber
          placeholder="Renseignez un seuil"
          min={upperThresholdMinValue}
          max={maxValue}
          step={step}
          style={{ width: "8rem" }}
        />
      </Form.Item>
    </Form>
  );
};

const HeatingProductionUnitAlertsConfiguration: React.FC<{ siteSlug: string }> = ({ siteSlug }) => {
  const isWideScreen = useWindowWidth();
  const [form] = useForm();
  const { user } = useAuth0();

  const { data: hpuAlerts } = useHeatingProductionUnitAlerts(user.email);
  const { data: hpus } = useSiteHeatingProductionUnits(siteSlug);
  const { mutateAsync: toggleAcknowledge } = useToggleAcknowledgeAlert(user.email);
  const { mutateAsync: deleteAlert } = useDeleteHeatingProductionUnitAlert(user.email);
  const { mutateAsync: saveAlert } = useSaveHeatingProductionUnitAlert(user.email);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);

  const columns = [
    {
      title: "",
      dataIndex: "isTriggered",
      key: "isTriggered",
      render: (isTriggered: boolean) =>
        isTriggered ? <BellFilled style={{ color: "#f5222d" }} /> : <></>,
    },
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      render: (name: string) => <Typography.Text>{name}</Typography.Text>,
    },
    {
      title: "Commentaires 💬",
      dataIndex: "comment",
      key: "comment",
      responsive: ["md" as const],
    },
    {
      title: "Dernier contrôle",
      dataIndex: "isDefective",
      key: "isDefective",
      responsive: ["md" as const],
      render: (isDefective: boolean, record: HeatingProductionUnitAlert) => (
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Tooltip title={`Effectué le ${moment(record.lastCheckDate).format("lll")}`}>
            {isDefective ? FailureIcon : SuccessIcon}
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Seuil Bas ❄️",
      dataIndex: "lowerThreshold",
      key: "lowerThreshold",
      render: (value: number) => (value ? value?.toString() + " °C" : ""),
    },
    {
      title: "Seuil haut 🔥",
      dataIndex: "upperThreshold",
      key: "upperThreshold",
      render: (value: number) => (value ? value.toString() + " °C" : ""),
    },
    {
      title: "",
      key: "action",
      responsive: ["md" as const],
      render: (alert: HeatingProductionUnitAlert) => (
        <span>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => {
              form.setFieldsValue(alert);
              console.log(alert);
              setIsModalVisible(true);
            }}
          >
            Modifier
          </Button>
          <Popconfirm
            title="Cette action est irréversible, êtes-vous sûr·e ?"
            okText="Oui"
            cancelText="Non"
            onConfirm={() => deleteAlert(alert.id)}
          >
            <Button type="link" icon={<DeleteOutlined />}>
              Supprimer
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  function resetForm() {
    setIsModalVisible(false);
    setConfirmLoading(false);
    form.resetFields();
  }

  function onSaveHeatingProductionUnitAlert() {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(async (hpua: HeatingProductionUnitAlert) => {
        await withNotify(
          saveAlert({
            ...hpua,
            id: hpua.id ?? 0,
          }),
          "L'alerte a été enregistrée avec succès",
          "Une erreur est survenue lors de la création de l'alerte",
        );
        resetForm();
      })
      .catch(() => {
        notification.warning({
          message: `La modification ne peut pas être enregistrée car certains champs ne respectent pas les critères demandés.`,
        });
        setConfirmLoading(false);
      });
  }

  return (
    <Card
      title="Alertes sur la température de départ de mes unités de production"
      extra={
        <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={() => {
            form.setFieldsValue({
              id: 0,
            });
            setIsModalVisible(true);
          }}
        >
          Ajouter une alerte
        </Button>
      }
    >
      {hpuAlerts.length === 0 ? (
        <Typography.Text>
          Aucune alerte sur les unités de production n'est configurée pour ce site.
        </Typography.Text>
      ) : (
        <Table
          size="small"
          bordered={false}
          pagination={false}
          rowKey="id"
          columns={columns}
          dataSource={hpuAlerts
            .filter((a) => !a.isDefault)
            .map((hpua: HeatingProductionUnitAlert) => ({
              ...hpua,
              key: hpua.id,
            }))}
          expandable={{
            expandedRowRender: (record) =>
              record.isTriggered ? (
                <Space style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography.Text style={{ color: colors.red.dark }}>
                    🚨{" "}
                    {record.triggerComment +
                      ` (alerte déclenchée à: ${moment(record.triggerDate).format("lll")})`}
                  </Typography.Text>
                  <Checkbox
                    checked={record.isAcknowledged}
                    onChange={() => toggleAcknowledge(record.id)}
                  >
                    {"J'acquitte cette alerte"}
                  </Checkbox>
                </Space>
              ) : (
                <Typography.Text style={{ color: colors.green.dark }}>
                  ✅{" "}
                  {record.triggerComment +
                    ` (dernier contrôle le ${moment(record.lastCheckDate).format("lll")})`}
                </Typography.Text>
              ),
          }}
        />
      )}
      <Modal
        visible={isModalVisible}
        title={"Enregistrer une alerte d'unité de production"}
        okText={"Enregistrer"}
        onCancel={resetForm}
        onOk={onSaveHeatingProductionUnitAlert}
        width={isWideScreen ? 1100 : "50%"}
        forceRender={false}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !hasPermission(user, Permissions.SitesUpdate) }}
      >
        <div style={{ marginBottom: 20 }}>
          <HeatingDistributionCircuitForm form={form} hpus={hpus} />
        </div>
      </Modal>
    </Card>
  );
};

export default HeatingProductionUnitAlertsConfiguration;
