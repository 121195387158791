import * as Sentry from "@sentry/react";
import config from "src/config";
import { appVersion } from "src/constants";

export interface AnalyticsUserTraits {
  email: string;
  name: string;
}

const analyticsService = {
  load() {
    if (window.location.hostname !== "app.local.efficap-energie.com") {
      Sentry.init({
        dsn: "https://34806fcab2c7ae335458ec1e3cc15321@o4506387796328448.ingest.sentry.io/4506387797704704",
        integrations: [
          new Sentry.BrowserTracing({
            tracingOrigins: ["https://app.efficap-energie.com"],
          }),
          new Sentry.Replay(),
        ],
        // Performance monitoring
        tracesSampleRate: 1.0,
        // Session replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: `webapp@${appVersion}`,
      });
      window.analytics.load(config.segmentioKey);
    }
  },
  page(properties?: { title?: string; url?: string; path?: string }) {
    if (window.location.hostname !== "app.local.efficap-energie.com") {
      window.analytics.page(properties);
    }
  },
  track(name: string, properties: any) {
    if (window.location.hostname !== "app.local.efficap-energie.com") {
      window.analytics.track(name, properties);
    }
  },
  identify(userId: string, traits: AnalyticsUserTraits) {
    if (window.location.hostname !== "app.local.efficap-energie.com") {
      window.analytics.identify(userId, traits);
    }
  },
};

export default analyticsService;
