import { Popover, Tag, Typography } from "antd";
import { RcFile } from "antd/lib/upload";
import moment from "moment";
import { colors } from "src/constants";
import {
  AbstractImagePosition,
  AggregatedMeasurement,
  ComfortRange,
  ImageElementPosition,
  Instrument,
  InstrumentGroup,
  InstrumentInstrumentGroup,
  Measurement,
} from "src/lib/api";
import { co2MeasurementColor, temperatureMeasurementColor } from "src/lib/colors";
import { useDeleteBuildingImage, useUploadBuildingImage } from "src/lib/hooks/api";
import { round } from "src/lib/math";
import { withNotify } from "src/lib/notify";

const MeasurementValueOverlay: React.FC<{
  top: number;
  left: number;
  confortRange: ComfortRange;
  measurement: Measurement | AggregatedMeasurement;
  instrument: InstrumentInstrumentGroup;
  imageWidth: number;
}> = ({ top, left, measurement, confortRange, instrument, imageWidth }) => {
  const temp = measurement?.temperatureMeasurement;
  const humidity = measurement?.humidityMeasurement;
  const co2 = measurement?.co2Measurement;
  const tagWidth = 60;
  const tagHeight = 25;
  const leftOffsetPercentage = (tagWidth / 2 / imageWidth) * 100;
  const topOffsetPercentage = (tagHeight / 2 / imageWidth) * 100;

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: `${top + topOffsetPercentage}%`,
        left: `${left - leftOffsetPercentage}%`,
        width: tagWidth,
        height: tagHeight,
        borderRadius: 10,
        borderColor: colors.green.dark,
        backgroundColor: "rgba(255,255,255,0.9)",
      }}
    >
      <Popover
        title={`Sonde ${instrument.name}`}
        placement="right"
        content={
          <div style={{ flexDirection: "column" }}>
            <div>
              Température :{" "}
              <Tag color={temperatureMeasurementColor(confortRange, temp)?.name}>
                {temp ? round(temp).toString() + "°C" : "-°C"}
              </Tag>
            </div>
            <div>{`Humidité : ${humidity ? humidity.toFixed(1) : "-"}%`}</div>
            {co2 ? (
              <div>
                Co2 :{" "}
                <Tag color={co2MeasurementColor(co2)?.name}>{round(temp).toString() + "ppm"}</Tag>
              </div>
            ) : (
              <div> Co2 : - ppm</div>
            )}
            <div style={{ fontStyle: "italic", fontSize: 10, marginTop: 10 }}>{`Relevé du ${moment(
              measurement?.timestamp,
            ).format("lll")}`}</div>
          </div>
        }
      >
        <Typography.Text
          strong
          style={{
            textAnchor: "middle",
            color: temperatureMeasurementColor(confortRange, temp)?.name,
          }}
        >
          {`${temp ? temp.toFixed(1) : "-"}°C`}
        </Typography.Text>
      </Popover>
    </div>
  );
};

const ImagePositionOverlay: React.FC<{
  top: number;
  left: number;
  value?: string;
}> = ({ top, left, value }) => (
  <div
    style={{
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      top: `${top}%`,
      left: `${left}%`,
      transform: "translate(-50%, -50%)",
      pointerEvents: "none",
    }}
  >
    <div
      style={{
        border: "2px solid red",
        borderRadius: 25,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: 25,
        pointerEvents: "none",
      }}
    >
      <Typography.Text
        style={{
          textAnchor: "middle",
          color: "black",
          paddingLeft: 5,
          paddingRight: 5,
          pointerEvents: "none",
        }}
        strong
      >
        {`${value ? value : "-"}`}
      </Typography.Text>
    </div>
  </div>
);

function useImageDrawing(slug: string, msId: number | null) {
  const { mutateAsync: uploadIm } = useUploadBuildingImage(slug, msId);
  const { mutateAsync: deleteIm } = useDeleteBuildingImage(slug, msId);

  function isPositionForElement(
    imageId: number,
    allPositions: AbstractImagePosition[],
    elementId: number,
  ) {
    if (elementId === undefined || elementId === null) {
      return false;
    }

    return allPositions.find(
      (aip: AbstractImagePosition) => aip.imageId === imageId && aip.elementId === elementId,
    ) === undefined
      ? false
      : true;
  }

  function addImagePosition(
    addedElement: Instrument | InstrumentGroup | { id: number },
    allPositions: AbstractImagePosition[],
    setAllPositions: (elt: AbstractImagePosition[]) => void,
    imageId: number,
    coordinates: ImageElementPosition,
  ) {
    const isNewPosition = !isPositionForElement(imageId, allPositions, addedElement.id);

    let newPositions = [...allPositions];
    // if position is not in current list we add it, otherwise we update
    if (isNewPosition) {
      newPositions.push({
        imageId: imageId,
        elementId: addedElement.id,
        ...coordinates,
      });
    } else {
      newPositions = newPositions.map((ip: AbstractImagePosition) => {
        if (ip.imageId === imageId && ip.elementId === addedElement.id) {
          return {
            ...ip,
            ...coordinates,
          };
        }
        return ip;
      });
    }

    setAllPositions([...newPositions]);
  }

  async function uploadImage(file: RcFile, filename: string) {
    await withNotify(
      uploadIm({ file: file, fileUID: file.uid, fileName: filename }),
      "L'image a été ajoutée à la base de données avec succès",
      "Echec lors de l'ajout de l'image de la base de donnée",
    );
  }

  async function deleteImage(file: any, postDeleteFn: (e: any) => void) {
    await withNotify(
      deleteIm(file.uid).then(postDeleteFn),
      "L'image a été supprimé de la base de données avec succès",
      "Echec lors de la suppression de l'image de la base de donnée",
    );
  }

  return { addImagePosition, uploadImage, deleteImage, isPositionForElement };
}

export { ImagePositionOverlay, MeasurementValueOverlay, useImageDrawing };
