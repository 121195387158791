import { User } from "@auth0/auth0-spa-js";
import {
  HeatingDistributionCircuit,
  HeatingDistributionCircuitCurve,
  HeatingDistributionCircuitMeasurement,
  HeatingProductionUnitMeasurement,
  Measurement,
  Site,
  UserManagementUser,
} from "./api";

export function ellipsis(str: string, n: number): string {
  return str.length > n ? str.substr(0, n - 1) + "…" : str;
}

export function sorterDate(a: string, b: string): number {
  return +new Date(b) - +new Date(a);
}

export function sorterMeasurement(
  a:
    | HeatingDistributionCircuitMeasurement
    | Measurement
    | HeatingProductionUnitMeasurement
    | HeatingDistributionCircuitCurve,
  b:
    | HeatingDistributionCircuitMeasurement
    | Measurement
    | HeatingProductionUnitMeasurement
    | HeatingDistributionCircuitCurve,
): number {
  return sorterDate(a.timestamp, b.timestamp);
}

export function latestMeasurement(measurements: HeatingDistributionCircuitMeasurement[]) {
  if (!measurements) {
    return undefined;
  }
  return measurements.sort(sorterMeasurement)?.[0];
}

export function sortCircuits(hdcs: HeatingDistributionCircuit[]) {
  return hdcs
    .sort((a: HeatingDistributionCircuit, b: HeatingDistributionCircuit) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    })
    .sort((a: HeatingDistributionCircuit, b: HeatingDistributionCircuit) => {
      if (a.role === "heating" && b.role === "domestic_hot_water") {
        return -1;
      } else if (b.role === "heating" && a.role === "domestic_hot_water") {
        return 1;
      } else {
        return 0;
      }
    });
}

export function getSiteHeatingReferent(site: Site, users: UserManagementUser[]) {
  return users.find((u: UserManagementUser) => u.id === site.heatingReferentUser?.id);
}

export function isHeatingReferent(user: User, userReferent?: UserManagementUser) {
  if (!userReferent) {
    return false;
  }

  return userReferent.id === user.sub;
}
