import { Avatar, Badge, Button, Dropdown, Menu, Space, Typography } from "antd";
import { DownOutlined, LogoutOutlined, NotificationOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";
import { appVersion } from "src/constants";
import { hasPermission, PermissionGroup } from "src/lib/access-control";
import { useInstrumentGroupAlerts } from "src/lib/hooks/api";
import { filterOpenAlerts } from "src/lib/alerts";

const Profile = () => {
  const { logout, user } = useAuth0();
  const navigate = useNavigate();
  const alertsRes = useInstrumentGroupAlerts(user);

  const alerts = alertsRes.status === "success" ? alertsRes.data : [];
  const nOpenAlerts = filterOpenAlerts(alerts).length;

  const menu = (
    <>
      <Menu>
        {hasPermission(user, PermissionGroup.InstrumentGroupAlerts) && (
          <Menu.Item>
            <Badge
              count={nOpenAlerts}
              offset={[-5, 10]}
              title={`Vous avez ${nOpenAlerts} alertes ouvertes`}
            >
              <Button
                type="link"
                onClick={() => navigate("/alerts")}
                icon={<NotificationOutlined />}
              >
                Vos Alertes
              </Button>
            </Badge>
          </Menu.Item>
        )}
        <Menu.Item>
          <Button type="link" onClick={() => logout()} icon={<LogoutOutlined />}>
            Se déconnecter
          </Button>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <small>Version de l'application: {appVersion}</small>
        </Menu.Item>
      </Menu>
    </>
  );

  return (
    <div>
      <Dropdown overlay={menu}>
        <Button type="link">
          <Space>
            <Avatar src={user.picture} />
            <Badge count={nOpenAlerts} offset={[5, -5]}>
              <Typography.Link>{user.name}</Typography.Link>
            </Badge>
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default Profile;
