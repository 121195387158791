import { Spin } from "antd";

const Loader: React.FC<{
  size?: "small" | "large" | "default";
  tip?: string;
  centered?: boolean;
}> = ({ size = "default", tip, centered = true }) => {
  const centeredStyle = centered
    ? {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: size === "small" ? 5 : 20,
      }
    : { padding: 20 };

  return (
    <div style={centeredStyle}>
      <Spin size={size} tip={tip} delay={400} />
    </div>
  );
};

export default Loader;
