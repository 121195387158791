import { ControlOutlined, ReloadOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Space, Switch, Typography } from "antd";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { hasPermission, Permissions } from "src/lib/access-control";
import {
  queryKeySiteHeatingInstallationWatchdogState,
  useSiteHeatingDistributionCircuitWatchdogState,
  useSitePlcWatchdogToggle,
} from "src/lib/hooks/api";

function getFormattedTimeClock(timeMinute: number) {
  const minutes = Math.trunc(timeMinute);
  const seconds = (timeMinute - minutes) * 60;

  if (seconds !== 0) {
    return `${minutes} minutes et ${seconds.toFixed(0)} secondes`;
  }

  return `${minutes} minutes`;
}

const WatchDogControls: React.FC<{
  siteSlug: string;
  hdcId: number;
}> = ({ siteSlug, hdcId }) => {
  const queryClient = useQueryClient();
  const { data: watchdogState } = useSiteHeatingDistributionCircuitWatchdogState(
    siteSlug,
    hdcId.toString(),
  );
  const { mutateAsync: toggleWatchdog } = useSitePlcWatchdogToggle(siteSlug);
  const { user } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingWatchdogLiberation, setIsLoadingWatchdogLiberation] = useState(false);

  const onRefresh = async () => {
    setIsLoading(true);
    await queryClient.invalidateQueries(queryKeySiteHeatingInstallationWatchdogState(siteSlug));
    setIsLoading(false);
  };

  async function onToggleWatchdog(plcId: string) {
    setIsLoadingWatchdogLiberation(true);
    await toggleWatchdog(plcId);
    setIsLoadingWatchdogLiberation(false);
  }

  if (watchdogState.plcId === 0) {
    return <WatchdogControlsFallback />;
  }

  return (
    <div style={{ marginBottom: "1.5rem" }}>
      <Card
        title={
          <Space direction="horizontal">
            <ControlOutlined />
            {`Gestion du watchdog`}
          </Space>
        }
        extra={<Button icon={<ReloadOutlined />} onClick={onRefresh} />}
        loading={isLoading}
      >
        <div style={{ marginBottom: "0.5rem" }}>
          <b>Etat alarme watchdog</b>
          <Switch
            style={{ marginLeft: "0.5rem" }}
            checkedChildren={"I"}
            unCheckedChildren={"O"}
            checked={watchdogState.state === "on"}
            disabled={true}
          />
        </div>
        <div style={{ marginBottom: "0.5rem" }}>
          <b>Temps écoulé depuis le réarmement du watchdog:</b>{" "}
          {getFormattedTimeClock(watchdogState.counterValue)}
        </div>
        <div style={{ marginBottom: "0.5rem" }}>
          <b>Durée de réarmement du watchdog:</b> {getFormattedTimeClock(watchdogState.presetValue)}
        </div>
        <b>Libération du watchdog:</b>
        <Switch
          style={{ marginLeft: "0.5rem" }}
          loading={isLoadingWatchdogLiberation}
          checkedChildren={"I"}
          unCheckedChildren={"O"}
          onChange={() => onToggleWatchdog(watchdogState.plcId.toString())}
          checked={!watchdogState.isWatchdogActive}
          disabled={!hasPermission(user, Permissions.HeatingInstallationsUpdate)}
        />
      </Card>
    </div>
  );
};

export const WatchdogControlsFallback: React.FC<{}> = () => {
  return (
    <Card title="Gestion du watchdog">
      <Typography.Text>
        Le contrôle du watchdog n'est pas configuré pour ce circuit.
      </Typography.Text>
    </Card>
  );
};

export default WatchDogControls;
