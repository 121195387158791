import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Form, Input, Modal, Table, Typography, notification } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { ColumnType } from "antd/lib/table";
import * as React from "react";
import { formItemLayout } from "src/constants";
import { Permissions, hasPermission } from "src/lib/access-control";
import type { HeatingProductionUnit, Site } from "src/lib/api";
import {
  useSaveSiteHeatingProductionUnit,
  useSiteHeatingProductionUnits,
  useSitePlcs,
  useUpdateSiteHeatingProductionUnit,
} from "src/lib/hooks/api";
import { useWindowWidth } from "src/lib/hooks/window-width";
import { withNotify } from "src/lib/notify";
import { showIf, sorter } from "src/lib/tableutils";
import ProductionDataPointConfigurationTable from "./ProductionDataPointConfiguration";

const HpuMetadataForm: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  return (
    <>
      <Form layout="horizontal" form={form} size="small">
        <div style={{ display: "flex", justifyContent: "end", width: "80%" }}>
          <Form.Item
            name={["metadata", "water_flow_set_point_temperature"]}
            label="Consigne T° départ (point fixe)"
            style={{ fontStyle: "italic" }}
          >
            <Input placeholder={"45"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

const HeatingProductionUnitForm: React.FC<{ form: FormInstance<any>; siteSlug: string }> = ({
  form,
  siteSlug,
}) => {
  const { data: plcs } = useSitePlcs(siteSlug);
  const hpuId = form.getFieldValue("id");

  return (
    <Form name="hpu-form" {...formItemLayout} form={form}>
      <Form.Item name="id" hidden />
      <Form.Item name="name" label="Nom de l'unité de production" rules={[{ required: true }]}>
        <Input placeholder={"Unité de production chaufferie Méridien"} />
      </Form.Item>
      <Form.Item name="" label={<Typography.Text strong>Métadonnées</Typography.Text>}>
        <HpuMetadataForm form={form} />
      </Form.Item>
      <ProductionDataPointConfigurationTable siteSlug={siteSlug} hpuId={hpuId} sitePlcs={plcs} />
    </Form>
  );
};

const HeatingProductionUnitsTab: React.FC<{ site: Site }> = ({ site }) => {
  const { data: hpus } = useSiteHeatingProductionUnits(site.slug);
  const { user } = useAuth0();
  const [form] = useForm();
  const { isWideScreen } = useWindowWidth();

  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);

  const { mutateAsync: saveHeatingProductionUnit } = useSaveSiteHeatingProductionUnit(site.slug);
  const { mutateAsync: updateHeatingProductionUnit } = useUpdateSiteHeatingProductionUnit(
    site.slug,
  );

  function onAddHpu() {
    form.setFieldsValue({ id: 0, isActive: true, metadata: "{}" });
    setIsModalVisible(true);
  }

  function onSaveHpu() {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(async (hpu: HeatingProductionUnit) => {
        if (hpu.id === 0) {
          await withNotify(
            saveHeatingProductionUnit({
              ...hpu,
            }),
            "L'unité de production a été créé avec succès",
            "Une erreur est survenue lors de la création de l'unité de production.",
          );
        } else {
          await withNotify(
            updateHeatingProductionUnit({
              ...hpu,
              id: hpu.id,
            }),
            "L'unité de production a été mis à jour avec succès",
            "Une erreur est survenue lors de la mise à jour de l'unité de production.",
          );
        }
        resetForm();
      })
      .catch(() => {
        notification.warning({
          message: `La modification ne peut pas être enregistrée car certains champs ne respectent pas les critères demandés.`,
        });
        setConfirmLoading(false);
      });
  }

  function resetForm() {
    setIsModalVisible(false);
    setConfirmLoading(false);
    form.resetFields();
  }

  const columns: ColumnType<HeatingProductionUnit>[] = [
    {
      title: "Nom de l'unité de production",
      dataIndex: "name",
      key: "name",
      sorter: sorter("name", "string"),
    },
    ...showIf<HeatingProductionUnit>(
      () => hasPermission(user, Permissions.HeatingInstallationsUpdate),
      {
        title: "Edition",
        key: "edition",
        responsive: ["lg" as const],
        width: 120,
        render: (record: HeatingProductionUnit) => (
          <Button
            type="link"
            disabled={!hasPermission(user, Permissions.HeatingInstallationsUpdate)}
            icon={<EditOutlined />}
            onClick={() => {
              form.setFieldsValue({
                ...record,
                metadata: JSON.stringify(record.metadata, null, " "),
              });
              setIsModalVisible(true);
            }}
          >
            Modifier
          </Button>
        ),
      },
    ),
    // not implementing deletion yet
  ];

  return (
    <>
      <Card title="Unités de production présentes sur le site">
        <Table dataSource={hpus} columns={columns} pagination={false} />
        <div style={{ justifyContent: "center", display: "flex", margin: 20 }}>
          <Button icon={<PlusOutlined />} type="link" onClick={onAddHpu}>
            Ajouter une unité de production
          </Button>
        </div>
      </Card>
      <Modal
        visible={isModalVisible}
        title={"Enregistrer une unité de production"}
        okText={"Enregistrer"}
        onCancel={resetForm}
        onOk={onSaveHpu}
        width={isWideScreen ? 1100 : "50%"}
        forceRender={false}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !hasPermission(user, Permissions.SitesUpdate) }}
      >
        <div style={{ marginBottom: 20 }}>
          <HeatingProductionUnitForm form={form} siteSlug={site.slug} />
        </div>
      </Modal>
    </>
  );
};

export default HeatingProductionUnitsTab;
