import { ControlOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Card, InputNumber, Space, Typography } from "antd";
import React from "react";
import {
  useSiteHeatingDistributionCircuitHeatingCurveState,
  useUpdateCircuitHeatingCurveParameters,
} from "src/lib/hooks/api";

const HeatingCurveControls: React.FC<{
  siteSlug: string;
  hdcId: number;
}> = ({ siteSlug, hdcId }) => {
  const { data: heatingCurveState } = useSiteHeatingDistributionCircuitHeatingCurveState(
    siteSlug,
    hdcId.toString(),
  );
  const { mutateAsync: updateHeatingCurveParameters, isLoading: isUpdateLoading } =
    useUpdateCircuitHeatingCurveParameters(siteSlug, hdcId.toString());
  const [heatingCurveParameters, setHeatingCurveParameters] = React.useState(heatingCurveState);

  React.useEffect(() => {
    setHeatingCurveParameters(heatingCurveState);
  }, [heatingCurveState]);

  return (
    <div style={{ marginBottom: "1.5rem" }}>
      <Card
        title={
          <Space direction="horizontal">
            <ControlOutlined />
            {`
            Réglage des paramètres`}
          </Space>
        }
        extra={
          <Button
            type="link"
            icon={<SaveOutlined />}
            loading={isUpdateLoading}
            onClick={() => updateHeatingCurveParameters(heatingCurveParameters)}
            disabled={JSON.stringify(heatingCurveParameters) === JSON.stringify(heatingCurveState)}
          >
            Enregistrer
          </Button>
        }
      >
        <Space direction="vertical" style={{ width: "100%", marginBottom: 10 }}>
          <Space
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <b>Correction de jour (°C):</b>
            <InputNumber
              disabled={isUpdateLoading || heatingCurveState.dayCorrection === null}
              style={{ width: 100 }}
              min={-10}
              max={10}
              value={parseFloat(heatingCurveParameters.dayCorrection?.toFixed(2))}
              onChange={(e: number) => {
                setHeatingCurveParameters({
                  ...heatingCurveParameters,
                  dayCorrection: e,
                });
              }}
            />
          </Space>
          <Space
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <b>Correction de nuit (°C):</b>
            <InputNumber
              style={{ width: 100 }}
              min={-10}
              max={10}
              disabled={isUpdateLoading || heatingCurveState.nightCorrection === null}
              value={parseFloat(heatingCurveParameters.nightCorrection?.toFixed(2))}
              onChange={(e: number) => {
                setHeatingCurveParameters({
                  ...heatingCurveParameters,
                  nightCorrection: e,
                });
              }}
            />
          </Space>
        </Space>
      </Card>
    </div>
  );
};

export const HeatingCurveControlsFallback: React.FC<{}> = () => {
  return (
    <Card title="Réglage de la courbe de chauffe">
      <Typography.Text>
        Le contrôle de la courbe de chauffe n'est pas configuré pour ce circuit.
      </Typography.Text>
    </Card>
  );
};

export default HeatingCurveControls;
