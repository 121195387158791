import Loader from "../Loader";
import { DashDiv } from "./DashDiv";

export const DashboardIndicator: React.FC<{
  color: string;
  title: string;
  value?: string;
  subTitle?: string;
}> = ({ color, title, value, subTitle }) => {
  const hasSub = subTitle !== undefined;

  const finalColor = hasSub ? "black" : color;

  return (
    <DashDiv style={{ textAlign: "center" }}>
      <div style={{ color: finalColor, fontSize: 14 }}>{title}</div>
      <div style={{ fontSize: 25, color: color }}>{value ? value : <Loader />}</div>
      {hasSub && (
        <div style={{ fontSize: 11, textAlign: "right", padding: "0 1rem", color: "black" }}>
          {subTitle}
        </div>
      )}
    </DashDiv>
  );
};
