import * as React from "react";
import { Radio, Switch, Typography } from "antd";

export enum MeasurementDisplayMode {
  Temperature,
  Humidity,
  CO2,
}

export enum LegendDisplayMode {
  Name,
  Comment,
}

const MeasurementDisplayModeSelector: React.FC<{
  measurementDisplayModes: MeasurementDisplayMode[];
  setMeasurementDisplayModes: (m: MeasurementDisplayMode[]) => void;
  hideCO2?: boolean;
  showLegendOptions?: boolean;
  legendDisplayModes?: LegendDisplayMode[];
  setLegendDisplayModes?: (m: LegendDisplayMode[]) => void;
  showPrecisionMode?: boolean;
  isPrecisionMode?: boolean;
  setIsPrecisionMode?: (m: boolean) => void;
}> = ({
  measurementDisplayModes,
  setMeasurementDisplayModes,
  hideCO2,
  showLegendOptions,
  legendDisplayModes,
  setLegendDisplayModes,
  showPrecisionMode,
  isPrecisionMode,
  setIsPrecisionMode,
}) => {
  function updateMeasurementDisplayModes(value: MeasurementDisplayMode) {
    if (measurementDisplayModes.includes(value)) {
      setMeasurementDisplayModes(measurementDisplayModes.filter((m) => m !== value));
    } else {
      setMeasurementDisplayModes(measurementDisplayModes.concat([value]));
    }
  }

  function updateLegendDisplayModes(value: LegendDisplayMode) {
    if (legendDisplayModes === undefined || setLegendDisplayModes === undefined) {
      return;
    }
    if (legendDisplayModes.includes(value)) {
      setLegendDisplayModes(legendDisplayModes.filter((m) => m !== value));
    } else {
      setLegendDisplayModes(legendDisplayModes.concat([value]));
    }
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        <Radio.Button
          style={{ margin: 2 }}
          value={MeasurementDisplayMode.Temperature}
          checked={measurementDisplayModes.includes(MeasurementDisplayMode.Temperature)}
          onClick={(e: any) => updateMeasurementDisplayModes(parseFloat(e.target.value))}
        >
          Température
        </Radio.Button>
        <Radio.Button
          style={{ margin: 2 }}
          value={MeasurementDisplayMode.Humidity}
          checked={measurementDisplayModes.includes(MeasurementDisplayMode.Humidity)}
          onClick={(e: any) => updateMeasurementDisplayModes(parseFloat(e.target.value))}
        >
          Humidité
        </Radio.Button>
        {!hideCO2 && (
          <Radio.Button
            style={{ margin: 2 }}
            value={MeasurementDisplayMode.CO2}
            checked={measurementDisplayModes.includes(MeasurementDisplayMode.CO2)}
            onClick={(e: any) => updateMeasurementDisplayModes(parseFloat(e.target.value))}
          >
            C02
          </Radio.Button>
        )}
        {showPrecisionMode && (
          <>
            <Switch
              style={{ margin: 2, marginLeft: 10 }}
              checked={isPrecisionMode}
              onChange={(checked) => setIsPrecisionMode && setIsPrecisionMode(checked)}
            />
            <Typography.Text style={{ margin: 2 }}>Mode précision</Typography.Text>
          </>
        )}
      </div>
      {showLegendOptions &&
        legendDisplayModes !== undefined &&
        setLegendDisplayModes !== undefined && (
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Radio.Button
              style={{ margin: 2 }}
              value={LegendDisplayMode.Name}
              checked={legendDisplayModes.includes(LegendDisplayMode.Name)}
              onClick={(e: any) => updateLegendDisplayModes(parseFloat(e.target.value))}
            >
              Nom de sondes
            </Radio.Button>
            <Radio.Button
              style={{ margin: 2 }}
              value={LegendDisplayMode.Comment}
              checked={legendDisplayModes.includes(LegendDisplayMode.Comment)}
              onClick={(e: any) => updateLegendDisplayModes(parseFloat(e.target.value))}
            >
              Commentaires
            </Radio.Button>
          </div>
        )}
    </div>
  );
};

export default MeasurementDisplayModeSelector;
