import {
  CheckCircleTwoTone,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  EditOutlined,
  ExclamationCircleTwoTone,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Popover,
  Row,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { Suspense, useState } from "react";
import { Link } from "react-router-dom";
import { colors } from "src/constants";
import { ComfortIndicator, Site, UnitOfMeasure } from "src/lib/api";
import { getHeatingPeriodReferenceMoment } from "src/lib/heating-period";
import {
  useComfortIndicators,
  useMeasurements,
  useMonthlyIndicator,
  useSaveSitePhysics,
  useSiteEnergyMeters,
  useSiteEnergyRegressionParams,
  useSiteHeatingSeasons,
  useSitePhysics,
  useSiteReferenceInstrumentGroups,
  useWeatherData,
} from "src/lib/hooks/api";
import ErrorBoundaryWithFallback from "./ErrorBoundaryWithFallback";
import Loader from "./Loader";
import RenderIf from "./RenderIf";

interface DataType {
  key: React.Key;
  site: Site;
  globalIndicator: number;
  energySaving: number;
  temperatures: number;
  breakdowns: number;
  breakdownsPercent: number;
  alpha: number;
}

const IndicatorIcon: React.FC<{ value: number; popoverText?: string }> = ({
  value,
  popoverText,
}) => {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center", fontSize: 24 }}>
      <Popover content={popoverText}>{getIcon(value)}</Popover>
    </div>
  );
};

const getIcon = (value: number) => {
  switch (value) {
    case 0:
      return <CheckCircleTwoTone twoToneColor={colors.green.dark} />;
    case 1:
      return <ExclamationCircleTwoTone twoToneColor={colors.gold.dark} />;
    case 2:
      return <CloseCircleTwoTone twoToneColor={colors.red.dark} />;
    default:
      return <QuestionCircleTwoTone twoToneColor={colors.grey.dark} />;
  }
};

const getGlobalIndicator = (energySaving: number, temperatures: number, breakdowns: number) => {
  if (energySaving === -1 || temperatures === -1 || breakdowns === -1) {
    return -1;
  } else {
    return Math.min(energySaving + temperatures + breakdowns, 2);
  }
};

// indicators are assigned the following values:
// 0: green
// 1: yellow
// 2: red
// -1: grey (no data or error)
// this allows to get the global indicator by adding the values (2 yellow implies red)
export const ComfortIndicators: React.FC<{
  initialFrom: number;
  initialTo: number;
}> = ({ initialFrom, initialTo }) => {
  const [from, setFrom] = useState(initialFrom);
  const [to, setTo] = useState(initialTo);

  const { data: comfortIndicators } = useComfortIndicators(from, to);

  const [show, setShow] = useState<1 | 2 | -1>(-1);
  const [search, setSearch] = useState<string>("");

  const dataSource: DataType[] = comfortIndicators
    .filter((comfortIndicator) => {
      if (search === "") {
        return true;
      }

      return comfortIndicator.site.name.toLowerCase().includes(search.toLowerCase());
    })
    .map((indicator, index) => {
      let temperatures = -1;
      if (indicator.minTemperature !== 100) {
        const minTemperature = indicator.minTemperature < indicator.comfortRange.lower - 1 ? 1 : 0;
        const lowestMeanTemperature =
          indicator.lowestMeanTemperature < indicator.comfortRange.lower ? 1 : 0;

        temperatures = minTemperature + lowestMeanTemperature;
      }

      const workingInstrumentsPercent =
        indicator.breakdowns.totalInstruments > 0
          ? (indicator.breakdowns.workingInstruments / indicator.breakdowns.totalInstruments) * 100
          : 0;
      const workingInstruments =
        workingInstrumentsPercent >= 95 ? 0 : workingInstrumentsPercent >= 80 ? 1 : 2;

      const energySavingPercent =
        (indicator.energySaving.consumptionSaved / indicator.energySaving.consumption) * 100;
      const energySaving = energySavingPercent >= 10 ? 0 : energySavingPercent >= 5 ? 1 : 2;

      const globalIndicator = getGlobalIndicator(energySaving, temperatures, workingInstruments);
      return {
        key: index,
        site: indicator.site,
        globalIndicator: globalIndicator,
        energySaving: energySaving,
        temperatures: temperatures,
        breakdowns: workingInstruments,
        breakdownsPercent: 100 - workingInstrumentsPercent,
        alpha: indicator.energySaving.alpha,
      };
    })
    .filter((data) => {
      if (search !== "") {
        return true;
      }

      switch (show) {
        case 1:
          return data.globalIndicator === 1;
        case 2:
          return data.globalIndicator === 2;
        default:
          return true;
      }
    });

  const columns = [
    {
      title: () => (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <DatePicker
            onChange={(date) => setFrom(moment(date, "DD/MM/YYYY").unix())}
            value={moment.unix(from)}
            format={"DD/MM/YYYY"}
            style={{ marginLeft: 8 }}
          />

          <DatePicker
            onChange={(date) => setTo(moment(date, "DD/MM/YYYY").unix())}
            value={moment.unix(to)}
            format={"DD/MM/YYYY"}
            style={{ marginLeft: 8 }}
          />
        </div>
      ),
      children: [
        {
          title: "",
          key: "globalIndicator",
          dataIndex: "globalIndicator",
          render: (value: number) => <IndicatorIcon value={value} />,
          width: 50,
        },
        {
          title: "Site",
          key: "site",
          dataIndex: "site",
          render: (site: Site) => <Link to={`/sites/${site.slug}`}>{site.name}</Link>,
        },
        {
          title: "Alpha",
          key: "alpha",
          dataIndex: "alpha",
          render: (value: number, record: DataType) => (
            <AlphaEditor site={record.site} alpha={value} />
          ),
        },
        {
          title: "Economies prédictive",
          key: "energySaving",
          dataIndex: "energySaving",
          render: (value: number) => <IndicatorIcon value={value} />,
          width: 160,
        },
        {
          title: "Temperatures",
          key: "temperatures",
          dataIndex: "temperatures",
          render: (value: number) => <IndicatorIcon value={value} />,
          width: 110,
        },
        {
          title: "Défaillances",
          key: "breakdowns",
          dataIndex: "breakdowns",
          render: (value: number, record: DataType) => (
            <IndicatorIcon value={value} popoverText={`${record.breakdownsPercent.toFixed(2)}%`} />
          ),
          width: 100,
        },
        {
          title: (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              {" "}
              Rapport{" "}
            </div>
          ),
          key: "report",
          render: (value: number, record: DataType) => <ReportGenerationButton record={record} />,
          width: 150,
        },
      ],
    },
  ];

  return (
    <>
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Input
            style={{ width: 300 }}
            placeholder="Rechercher un site"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <CloseCircleOutlined
            onClick={() => setSearch("")}
            style={{ marginLeft: 10, fontSize: 23 }}
          />
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography.Text italic style={{ marginRight: 10 }}>
            Afficher uniquement :
          </Typography.Text>
          <div
            style={{ fontSize: 24, cursor: "pointer", marginRight: 7 }}
            onClick={() => setShow(show === 1 ? -1 : 1)}
          >
            <ExclamationCircleTwoTone
              twoToneColor={show !== 2 ? colors.gold.dark : colors.grey.medium}
            />
          </div>
          <div
            style={{ fontSize: 24, cursor: "pointer" }}
            onClick={() => setShow(show === 2 ? -1 : 2)}
          >
            <CloseCircleTwoTone twoToneColor={show !== 1 ? colors.red.dark : colors.grey.medium} />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        size="small"
        bordered
        expandable={{
          expandedRowRender: (record) => (
            <SiteIndicators
              indicator={
                comfortIndicators.find((indicator) => indicator.site.id === record.site.id)!
              }
            />
          ),
        }}
      />
    </>
  );
};

const AlphaEditorContent: React.FC<{
  site: Site;
  from: number;
  to: number;
}> = ({ site, from, to }) => {
  const { mutateAsync: saveSitePhysics } = useSaveSitePhysics(site.slug);
  const { data: sitePhysics } = useSitePhysics(site.slug);
  const { data: regressionParams } = useSiteEnergyRegressionParams(site.slug, from, to);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Typography.Text>Valeur actuelle : {sitePhysics?.alpha.toFixed(3)} </Typography.Text>
      <RenderIf predicate={sitePhysics?.alpha !== 0}>
        ({moment(sitePhysics.startDate).format("DD/MM/YYYY")} -{" "}
        {moment(sitePhysics.endDate).format("DD/MM/YYYY")})
      </RenderIf>

      <div style={{ marginTop: 10 }}>
        <Typography.Text strong>Calcul d'un nouvel alpha</Typography.Text>
        <br />
        <Typography.Text>Alpha : {regressionParams?.alpha.toFixed(3)}</Typography.Text>
        <br />
        <Typography.Text>R² : {regressionParams?.r2.toFixed(3)}</Typography.Text>
      </div>

      <Button
        type="primary"
        loading={loading}
        onClick={async () => {
          setLoading(true);
          await saveSitePhysics({
            alpha: regressionParams?.alpha,
            startDate: moment.unix(from).format("YYYY-MM-DD") + "T00:00:00Z",
            endDate: moment.unix(to).format("YYYY-MM-DD") + "T00:00:00Z",
            siteId: site.id,
            r2: regressionParams?.r2,
          });
          setLoading(false);
        }}
      >
        Enregistrer ce nouvel Alpha
      </Button>
    </>
  );
};

const AlphaEditor: React.FC<{ site: Site; alpha: number }> = ({ site, alpha }) => {
  const [visible, setVisible] = useState(false);
  const [from, setFrom] = useState(moment().subtract(1, "month").unix());
  const [to, setTo] = useState(moment().unix());
  const [key, setKey] = useState(0);

  return (
    <>
      <Modal
        title="Modifier le coefficient alpha"
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        <DatePicker.RangePicker
          onChange={(dates) => {
            if (dates && dates[0] && dates[1]) {
              setFrom(dates[0].unix());
              setTo(dates[1].unix());
            }
            setKey((prevKey) => prevKey + 1);
          }}
          value={[moment.unix(from), moment.unix(to)]}
        />

        <br />

        <Suspense fallback={<Loader size="small" />}>
          <ErrorBoundaryWithFallback
            key={key}
            fallback={<div>Erreur, veuillez selectionner des dates différentes.</div>}
          >
            <AlphaEditorContent site={site} from={from} to={to} />
          </ErrorBoundaryWithFallback>
        </Suspense>
      </Modal>

      <Typography.Text>{alpha !== 0 ? alpha.toFixed(3) : "N/A"}</Typography.Text>
      <Button type="link" onClick={() => setVisible(true)} icon={<EditOutlined />}>
        Modifier
      </Button>
    </>
  );
};

const Indicator: React.FC<{
  title: string;
  value: string;
  unit?: string;
  subText: React.ReactNode;
  popoverText?: string;
}> = ({ title, value, unit, subText, popoverText }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 30,
      }}
    >
      <Typography.Text style={{ fontSize: 20 }}>{title}</Typography.Text>
      <div
        style={{
          width: "100%",
          height: 150,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={"/efficap_indicator.png"}
          alt={"efficap_indicator_img"}
          style={{
            width: 150,
            height: 150,
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Popover content={popoverText}>
            <div style={{ fontSize: 35 }}>{value}</div>
          </Popover>

          <div style={{ fontSize: 20, color: colors.grey.dark }}>{unit}</div>
        </div>
      </div>
      <div style={{ textAlign: "center", fontSize: 16 }}>{subText}</div>
    </div>
  );
};

const ReportGenerationButton: React.FC<{ record: DataType }> = ({ record }) => {
  const [visible, setVisible] = useState(false);
  const [month, setMonth] = useState(moment());
  const [breakdowns, setBreakdowns] = useState(0);
  const [consumptionSaved, setConsumptionSaved] = useState(0);

  return (
    <>
      <Modal
        title={`Générer un rapport - ${record.site.name}`}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={800}
      >
        <div style={{ marginBottom: 5 }}>
          Mois :
          <Button
            style={{ marginLeft: 5 }}
            onClick={() => setMonth(moment(month).subtract(1, "month"))}
          >
            -
          </Button>
          <DatePicker.MonthPicker
            value={moment(month)}
            onChange={(date) => date && setMonth(date)}
          />
          <Button onClick={() => setMonth(moment(month).add(1, "month"))}>+</Button>
        </div>

        <div style={{ marginBottom: 5 }}>
          Défaillances :
          <InputNumber
            style={{ marginLeft: 5 }}
            value={breakdowns}
            onChange={(value) => setBreakdowns(value ?? 0)}
          />
        </div>

        <div style={{ marginBottom: 5 }}>
          Economies d'énergie :
          <InputNumber
            style={{ marginLeft: 5 }}
            value={consumptionSaved}
            onChange={(value) => setConsumptionSaved(value ?? 0)}
          />
        </div>

        <Suspense fallback={<Loader size="small" />}>
          <ModalContent
            site={record.site}
            month={month}
            breakdowns={breakdowns}
            consumptionSaved={consumptionSaved}
          />
        </Suspense>
      </Modal>

      <Button type="primary" onClick={() => setVisible(true)}>
        Générer un rapport
      </Button>
    </>
  );
};

const ModalContent: React.FC<{
  site: Site;
  month: moment.Moment;
  breakdowns: number;
  consumptionSaved: number;
}> = ({ site, month, breakdowns, consumptionSaved }) => {
  const { data: energyMeters } = useSiteEnergyMeters(site.slug);
  const unitOfMeasure = energyMeters[0]?.unitOfMeasure === UnitOfMeasure.CubicMeters ? "m³" : "MWh";

  const { data: allHeatingPeriods } = useSiteHeatingSeasons(site.slug);
  const heatingPeriodReference = allHeatingPeriods.find((hp) => hp.isReference);

  const refMonth = getHeatingPeriodReferenceMoment(month, heatingPeriodReference);

  const { data: monthlyIndicator } = useMonthlyIndicator(
    site.slug,
    month.startOf("month").unix(),
    month.endOf("month").unix(),
  );
  const { data: monthlyIndicatorRef } = useMonthlyIndicator(
    site.slug,
    refMonth.startOf("month").unix(),
    refMonth.endOf("month").unix(),
  );

  const { data: referenceGroups } = useSiteReferenceInstrumentGroups(site.slug);
  const iids = referenceGroups
    .map((group) => group.instrumentIds.map((iid) => iid.toString()))
    .flat();

  const measurementsRes = useMeasurements(
    iids,
    month.startOf("month").unix(),
    month.endOf("month").unix(),
  );
  const measurements = measurementsRes.status === "success" ? measurementsRes.data : [];

  const meanTemperature =
    measurements.reduce((acc, m) => acc + m.temperatureMeasurement, 0) / measurements.length;

  const { data: weather } = useWeatherData(
    site.id,
    month.startOf("month").unix(),
    month.endOf("month").unix(),
  );
  const hdd = weather.dailyHDD.reduce((acc, h) => acc + h.hdd, 0);
  const globalRadiation = weather.weather.reduce((acc, w) => acc + w.globalRadiation, 0);

  const { data: weatherRef } = useWeatherData(
    site.id,
    refMonth.startOf("month").unix(),
    refMonth.endOf("month").unix(),
  );
  const hddRef = weatherRef.dailyHDD.reduce((acc, h) => acc + h.hdd, 0);

  const consumptionWithRefHdd = (monthlyIndicatorRef[0]?.heatingConsumption / hddRef) * hdd;
  const deltaConsumption = consumptionWithRefHdd - monthlyIndicator[0]?.heatingConsumption;

  return (
    <div id="report-img">
      <div
        style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography.Text style={{ color: colors.orange.dark }}>Bonjour,</Typography.Text>
        <Typography.Text>
          Votre Bilan <span style={{ color: colors.green.dark }}>EFFICAP Energie</span> du mois de{" "}
          {moment(month).format("MMMM")} est prêt !
        </Typography.Text>
      </div>

      <Row>
        <Col span={11}>
          <Indicator
            title="TENUE DU CONFORT"
            value={isNaN(meanTemperature) ? "N/A" : meanTemperature.toFixed(1)}
            unit="°C"
            subText={
              <>
                Notre système de{" "}
                <span style={{ color: colors.orange.dark }}>télé-relève des températures</span>{" "}
                permet d'assurer le suivi du confort et de traiter les anomalies.
              </>
            }
          />
        </Col>

        <Col span={2} />

        <Col span={11}>
          <Indicator
            title="ECONOMIES D'ENERGIE"
            value={
              consumptionSaved !== 0
                ? consumptionSaved.toFixed(0)
                : deltaConsumption
                  ? deltaConsumption.toFixed(0)
                  : "N/A"
            }
            unit={unitOfMeasure}
            subText={
              <>
                Notre <span style={{ color: colors.green.dark }}>algorithme prédictif </span>agit
                sur la commande de chauffage en réduisant la consigne lorsque la météo et
                l'ensoleillement le permettent.
              </>
            }
            popoverText={((deltaConsumption / consumptionWithRefHdd) * 100).toFixed(2) + "%"}
          />
        </Col>
      </Row>

      <Row>
        <Col span={11}>
          <Indicator
            title="METEO DU MOIS"
            value={hdd.toFixed(0)}
            unit="°C DJU"
            subText={
              <>
                Ce mois ci a été {hddRef < hdd ? "plus" : "moins"} chaud que la normale et votre
                résidence
                {globalRadiation > 0 ? (
                  <>
                    {" "}
                    a{" "}
                    <span style={{ color: colors.orange.dark }}>
                      capté {globalRadiation} W/m²
                    </span>{" "}
                    de rayonnement solaire contribuant à son chauffage.
                  </>
                ) : (
                  <> n'a pas capté de rayonnement solaire.</>
                )}
              </>
            }
          />
        </Col>

        <Col span={2} />

        <Col span={11}>
          <Indicator
            title="ANOMALIES DETECTEES"
            value={breakdowns.toFixed(0)}
            subText={
              <>
                Notre <span style={{ color: colors.green.dark }}>supervision à distance</span>{" "}
                permet d'anticiper et de traiter les anomalies de chaufferie.
              </>
            }
          />
        </Col>
      </Row>
    </div>
  );
};

const SiteIndicators: React.FC<{ indicator: ComfortIndicator }> = ({ indicator }) => {
  return (
    <>
      <Row>
        <Col span={8}>
          <Card
            title={
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography.Text style={{ marginRight: 16 }}>Températures</Typography.Text>
                </div>
                <div style={{ fontWeight: "normal", fontSize: 14 }}>
                  <Typography.Text italic>Cibles : </Typography.Text>
                  <Typography.Text style={{ color: colors.blue.dark }}>
                    {indicator.comfortRange.lower}°C
                  </Typography.Text>
                  {` - ${indicator.comfortRange.target}°C - `}
                  <Typography.Text style={{ color: colors.red.dark }}>
                    {indicator.comfortRange.upper}°C
                  </Typography.Text>
                </div>
              </div>
            }
            style={{ marginRight: 16 }}
          >
            <RenderIf predicate={indicator.minTemperature !== 100}>
              <Typography.Text>Température minimale : </Typography.Text>
              <Typography.Text>{indicator.minTemperature.toFixed(2)}</Typography.Text>

              <br />

              <Typography.Text>Température moyenne mini : </Typography.Text>
              <Typography.Text>{indicator.lowestMeanTemperature.toFixed(2)}</Typography.Text>
            </RenderIf>

            <RenderIf predicate={indicator.minTemperature === 100}>
              <Typography.Text>Données non disponibles</Typography.Text>
            </RenderIf>
          </Card>
        </Col>

        <Col span={8}>
          <Card title="Economies d'énergie" style={{ marginRight: 16 }}>
            <Typography.Text>Consommation : </Typography.Text>
            <Typography.Text>{indicator.energySaving.consumption.toFixed(2)} MWh</Typography.Text>

            <br />

            <Typography.Text>Economies : </Typography.Text>
            <Typography.Text>
              {indicator.energySaving.consumptionSaved.toFixed(2)} MWh
            </Typography.Text>

            <br />

            <Typography.Text>Pourcentage d'économies : </Typography.Text>
            <Typography.Text>
              {(
                (indicator.energySaving.consumptionSaved / indicator.energySaving.consumption) *
                100
              ).toFixed(2)}
              %
            </Typography.Text>
          </Card>
        </Col>

        <Col span={8}>
          <Card title="Défaillances">
            Sondes en fonctionnement : {indicator.breakdowns.workingInstruments} /{" "}
            {indicator.breakdowns.totalInstruments}
          </Card>
        </Col>
      </Row>
    </>
  );
};
