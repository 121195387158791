import { Button, Divider, Form, Input, Space, Tooltip, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import ReactJson from "react-json-view";
import { colors } from "src/constants";
import { EnergyMeter, GRDFConsentDetails } from "src/lib/api";
import { useGrdfConsentDemand } from "src/lib/hooks/api";
import { withNotify } from "src/lib/notify";

const ConsentDemandForm: React.FC<{
  initialValues: GRDFConsentDetails;
  energyMeter?: EnergyMeter;
  onSave?: (em: EnergyMeter, val: GRDFConsentDetails) => void;
}> = ({ initialValues, energyMeter, onSave }) => {
  const [form] = useForm();
  const [demandResponse, setDemandResponse] = React.useState<Object>({});
  const { mutateAsync: makeGrdfConsent, isLoading } = useGrdfConsentDemand();

  React.useEffect(() => {
    setDemandResponse({});
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  async function putDemand(consentFeatures: GRDFConsentDetails) {
    const response = await makeGrdfConsent({
      idPce: consentFeatures.pceId,
      demand: {
        role_tiers: "AUTORISE_CONTRAT_FOURNITURE",
        raison_sociale: consentFeatures.socialReason === "" ? null : consentFeatures.socialReason,
        nom_titulaire: consentFeatures.ownerName === "" ? null : consentFeatures.ownerName,
        code_postal: consentFeatures.postalCode,
        courriel_titulaire: consentFeatures.ownerEmail,
        date_debut_droit_acces: "2017-01-01",
        date_fin_droit_acces: "2028-12-31",
        perim_donnees_conso_debut: "2017-01-01",
        perim_donnees_conso_fin: "2028-12-31",
        perim_donnees_contractuelles: "VRAI",
        perim_donnees_techniques: "VRAI",
        perim_donnees_informatives: "VRAI",
        perim_donnees_publiees: "VRAI",
      },
    });

    setDemandResponse(JSON.parse(response.data));
  }

  async function onDemandConsent() {
    const consentFeatures = form.getFieldsValue();
    await withNotify(
      putDemand(consentFeatures),
      "La demande a été envoyée, retrouvez les détails de la réponse GRDF en bas de la fenêtre ouverte",
      "Echec lors de l'envoi de la demande, consultez les détails de la réponse GRDF en bas de la fenêtre ouverte",
    );
  }
  console.log("poipoi", energyMeter);
  return (
    <>
      <Form name="demand-consent" form={form}>
        <Form.Item name="pceId" label="ID PCE" rules={[{ required: true }]}>
          <Input placeholder={"Renseignez l'adresse du PCE visé par la demande"} />
        </Form.Item>
        <Form.Item name="postalCode" label="Code postal" rules={[{ required: true }]}>
          <Input placeholder={"Renseignez le code postal du compteur d'énergie"} />
        </Form.Item>
        <Form.Item name="ownerEmail" label="Email titulaire" rules={[{ required: true }]}>
          <Input
            placeholder={"Renseignez l'addresse email destinataire de la demande de consentement"}
          />
        </Form.Item>
        <Typography.Text style={{ fontStyle: "italic", color: colors.blue.dark }}>
          Renseignez soit la raison sociale soit le nom selon le type de titulaire. Laissez l'autre
          champ vide
        </Typography.Text>
        <Form.Item name="socialReason" label="Raison sociale du titulaire">
          <Input placeholder={"Renseignez la raison sociale du titulaire du compteur"} />
        </Form.Item>
        <Form.Item name="ownerName" label="Nom du titulaire">
          <Input placeholder={"Renseignez le nom du titulaire du compteur"} />
        </Form.Item>
      </Form>

      <Space style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {onSave && (
          <Button
            type="primary"
            onClick={() => {
              console.log(energyMeter, form.getFieldsValue());
              if (!energyMeter) {
                return;
              }
              onSave(energyMeter, form.getFieldsValue());
            }}
          >
            Enregistrer les paramètres
          </Button>
        )}
        <Tooltip
          title="En envoyant la demande le titulaire recevra une demande par email à laquelle il devra répondre sous 3j. 
    Si une demande est déjà en attente de validation il est inutile d'envoyer une nouvelle demande."
        >
          <Button type="dashed" onClick={onDemandConsent} loading={isLoading}>
            Envoyer une demande
          </Button>
        </Tooltip>
      </Space>

      <Divider>Réponse GRDF</Divider>
      <ReactJson src={demandResponse} />
    </>
  );
};

export default ConsentDemandForm;
