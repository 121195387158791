import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  FormInstance,
  Modal,
  Popover,
  Select,
  Space,
  Typography,
} from "antd";
import moment, { Moment } from "moment";
import * as React from "react";
import { HeatingSeason, Site } from "src/lib/api";
import getHeatingPeriod, {
  formatHeatingPeriodTitle,
  heatingPeriodDefaultEnd,
  heatingPeriodDefaultStart,
  heatingPeriodOptions,
} from "src/lib/heating-period";
import {
  useSetHeatingPeriodReference,
  useSiteHeatingSeasons,
  useUpdateHeatingPeriod,
} from "src/lib/hooks/api";
import { isHeatingReferent } from "src/lib/utils";
import { SiteInfoConsult } from "src/pages/sites";
import TooltipButton from "./TooltipButton";
import RenderIf from "./RenderIf";
import { Permissions } from "src/lib/access-control";

const HeatingPeriodForm: React.FC<{
  form: FormInstance<any>;
  slug: string;
  currentHeatingSeason: HeatingSeason;
}> = ({ slug, currentHeatingSeason, form }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedId, setSelectedId] = React.useState<number>(currentHeatingSeason.id ?? 0);

  const nowKey = getHeatingPeriod(new Date());

  const { data: allHeatingPeriods } = useSiteHeatingSeasons(slug);
  const heatingPeriodReference = allHeatingPeriods?.find((e: HeatingSeason) => e.isReference);
  const heatingPeriodReferenceKey = heatingPeriodReference
    ? heatingPeriodReference.key
    : "Non définie";

  const { mutateAsync: setHeatingPeriodReference } = useSetHeatingPeriodReference(slug);

  return (
    <>
      <Form
        layout="horizontal"
        form={form}
        name="heating-period-form"
        initialValues={{
          id: currentHeatingSeason.id,
          key: currentHeatingSeason?.key ?? nowKey,
          startTime: currentHeatingSeason?.startTime
            ? moment(currentHeatingSeason?.startTime, "YYYY-MM-DD")
            : heatingPeriodDefaultStart(nowKey),
          endTime: currentHeatingSeason?.endTime
            ? moment(currentHeatingSeason?.endTime, "YYYY-MM-DD")
            : heatingPeriodDefaultEnd(nowKey),
          isDefault: currentHeatingSeason?.isDefaultPeriod,
        }}
      >
        <Form.Item name="id" hidden />
        <Form.Item label="Saison de chauffe" name="key">
          <Select
            style={{ width: "100%" }}
            placeholder="Saison à renseigner"
            onChange={(value) => {
              const newHeatingPeriod = allHeatingPeriods?.find(
                (e: HeatingSeason) => e.key === value,
              );
              if (newHeatingPeriod) {
                setSelectedId(newHeatingPeriod.id ?? 0);
                form.setFieldsValue({
                  id: newHeatingPeriod.id,
                  startTime: moment(newHeatingPeriod?.startTime, "YYYY-MM-DD"),
                  endTime: moment(newHeatingPeriod?.endTime, "YYYY-MM-DD"),
                  isDefault: newHeatingPeriod.isDefaultPeriod,
                });
              } else {
                setSelectedId(0);
                form.setFieldsValue({
                  startTime: heatingPeriodDefaultStart(value),
                  endTime: heatingPeriodDefaultEnd(value),
                  isDefault: true,
                });
              }
            }}
          >
            {heatingPeriodOptions().map((key: string) => (
              <Select.Option key={key} value={key} label={key}>
                Saison {key}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Date de démarrage du chauffage" name="startTime">
          <DatePicker
            style={{ width: "100%" }}
            onChange={() => form.setFieldsValue({ isDefault: false })}
            disabledDate={(current: Moment | null) =>
              current ? current?.isAfter(form.getFieldsValue().endTime) : true
            }
          />
        </Form.Item>
        <Form.Item label="Date de d'extinction du chauffage" name="endTime">
          <DatePicker
            style={{ width: "100%" }}
            onChange={() => form.setFieldsValue({ isDefault: false })}
            disabledDate={(current: Moment | null) =>
              current ? current?.isBefore(form.getFieldsValue().startTime) : true
            }
          />
        </Form.Item>
        <Form.Item label="Dates par défaut" name="isDefault" valuePropName="checked">
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                form.setFieldsValue({
                  startTime: heatingPeriodDefaultStart(form.getFieldValue("key")),
                  endTime: heatingPeriodDefaultEnd(form.getFieldValue("key")),
                });
              }
            }}
          />
        </Form.Item>

        <RenderIf permissions={[Permissions.SitesCreate]}>
          <Popover
            content={
              selectedId !== 0
                ? ""
                : "Veuillez d'abord enregistrer cette saison de chauffe pour pouvoir la définir comme référence."
            }
          >
            <Button
              type="primary"
              loading={loading}
              disabled={selectedId === 0}
              onClick={async () => {
                setLoading(true);
                await setHeatingPeriodReference(form.getFieldValue("id"));
                setLoading(false);
              }}
            >
              Enregistrer cette saison comme référence
            </Button>
          </Popover>

          <br />

          <Typography.Text>
            (Saison de chauffe de référence actuelle : {heatingPeriodReferenceKey})
          </Typography.Text>
        </RenderIf>
      </Form>
    </>
  );
};

const SiteHeatingPeriod: React.FC<{ site: Site }> = ({ site }) => {
  const { mutateAsync: updateHeatingPeriod } = useUpdateHeatingPeriod(site.slug);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);
  const { user } = useAuth0();

  function resetForm() {
    setIsModalVisible(false);
    setConfirmLoading(false);
    form.resetFields();
  }

  function onSave() {
    form
      .validateFields()
      .then(async ({ id, key, startTime, endTime, isDefault }: any) => {
        setConfirmLoading(true);
        updateHeatingPeriod({
          id,
          key,
          startTime: moment(startTime).format("YYYY-MM-DD") + "T00:00:00Z",
          endTime: moment(endTime).format("YYYY-MM-DD") + "T00:00:00Z",
          isDefaultPeriod: isDefault,
        });
        setIsModalVisible(false);
        setConfirmLoading(false);
        if (key !== getHeatingPeriod(new Date())) {
          form.resetFields();
        }
      })
      .catch((err: any) => {
        resetForm();
        console.error("saving instruments error", err);
      });
  }

  return (
    <div>
      <SiteInfoConsult
        textContent={formatHeatingPeriodTitle(site.currentHeatingSeason)}
        updateAction={() => setIsModalVisible(true)}
      />
      <Modal
        visible={isModalVisible}
        title={"Saison de chauffe"}
        okText="Enregistrer"
        cancelText="Annuler"
        confirmLoading={confirmLoading}
        onCancel={resetForm}
        forceRender={true}
        destroyOnClose={true}
        footer={[
          <Space>
            <Button key="cancel" onClick={resetForm}>
              Annuler
            </Button>
            <TooltipButton
              onClick={onSave}
              disableMessage={`Pour modifier ces données veuillez contacter le référent chauffage de ce site: ${site.heatingReferentUser?.name}`}
              disabled={!isHeatingReferent(user, site.heatingReferentUser)}
            />
          </Space>,
        ]}
      >
        <div style={{ marginBottom: 20 }}>
          <Typography.Text>
            Vous pouvez renseigner la période de chauffe de votre site. Elle sera prise en compte
            pour la génération des rapports saisonniers.
          </Typography.Text>
        </div>
        <HeatingPeriodForm
          form={form}
          currentHeatingSeason={site.currentHeatingSeason}
          slug={site.slug}
        ></HeatingPeriodForm>
      </Modal>
    </div>
  );
};

export default SiteHeatingPeriod;
