import * as React from "react";
import type { Site, SiteFormInput } from "src/lib/api";
import { SiteForm } from "./SiteForm";
import { withNotify } from "src/lib/notify";
import { useSitesUpdate } from "src/lib/hooks/api";

const SiteInformationTab: React.FC<{ site: Site }> = ({ site }) => {
  const { mutateAsync: updateSite } = useSitesUpdate(site.slug);
  const [isUpdatingSite, setIsUpdatingSite] = React.useState<boolean>(false);

  async function onUpdateSite(siteFormInput: SiteFormInput) {
    setIsUpdatingSite(true);
    await withNotify(
      updateSite({ ...siteFormInput, id: site.id, slug: site.slug }).then((res: any) => {
        setIsUpdatingSite(false);
      }),
      `Le site « ${siteFormInput.name} » a bien été mis à jour`,
      `Nous sommes désolées, nous n'avons pas pu mettre à jour ce site. Veuillez réessayer plus tard.`,
    );
    setIsUpdatingSite(false);
  }
  return (
    <SiteForm
      onValidateForm={(siteInput: SiteFormInput) => onUpdateSite(siteInput)}
      mode="edition"
      initialValues={site}
      isLoading={isUpdatingSite}
    />
  );
};

export default SiteInformationTab;
