import { CheckOutlined, PlusOutlined, WarningOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Row, Statistic, Tooltip } from "antd";
import * as React from "react";
import { batteryPercentages, batteryVoltages, colors, staticticStyle } from "src/constants";
import { hasPermission, PermissionGroup } from "src/lib/access-control";
import { Instrument, InstrumentGroup, InstrumentGroupAlert } from "src/lib/api";

import "./SiteStatistics.css";

const SiteStatistics: React.FC<{
  openAlerts: InstrumentGroupAlert[];
  instruments: Instrument[];
  instrumentGroups: InstrumentGroup[];
}> = ({ openAlerts, instruments, instrumentGroups }) => {
  const { user } = useAuth0();

  const lowBatteryCount = instruments.filter(
    (i) =>
      (i.battery.type === "flag" && i.battery.value === "true") ||
      (i.battery.type === "voltage" &&
        parseFloat(i.battery.value) !== 0 &&
        parseFloat(i.battery.value) <= batteryVoltages.low) ||
      (i.battery.type === "percentage" &&
        parseFloat(i.battery.value) !== 0 &&
        parseFloat(i.battery.value) <= batteryPercentages.low),
  ).length;

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Row>
        <Statistic
          title="Sondes"
          prefix={<PlusOutlined />}
          value={instruments.length}
          style={staticticStyle}
        />
        <Statistic
          title="Groupes de Sondes"
          prefix={<PlusOutlined />}
          value={instrumentGroups.length}
          style={staticticStyle}
        />
        <Tooltip
          title={`Une pile est marquée faible 
          lorsque son voltage est inférieur à ${batteryVoltages.low}v pour les mesures en voltage 
          ou lorque son pourcentage de charge est inférieur à ${batteryPercentages.low}% pour les mesures en pourcentage.
          `}
        >
          <Statistic
            title="Piles faibles"
            prefix={lowBatteryCount > 0 ? <WarningOutlined /> : <CheckOutlined />}
            value={lowBatteryCount}
            valueStyle={
              lowBatteryCount > 0 ? { color: colors.orange.dark } : { color: colors.green.dark }
            }
            style={staticticStyle}
          />
        </Tooltip>
        {hasPermission(user, PermissionGroup.InstrumentGroupAlerts) && (
          <Statistic
            title="Alertes Ouvertes"
            prefix={openAlerts.length > 0 ? <WarningOutlined /> : <CheckOutlined />}
            value={openAlerts.length}
            valueStyle={
              openAlerts.length > 0 ? { color: colors.orange.dark } : { color: colors.green.dark }
            }
            style={staticticStyle}
          />
        )}
      </Row>
    </div>
  );
};

export default React.memo(SiteStatistics);
