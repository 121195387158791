import { useAuth0 } from "@auth0/auth0-react";
import { Divider, Empty, Tabs } from "antd";
import React, { Suspense } from "react";
import { hasPermission, Permissions } from "src/lib/access-control";
import { HeatingDistributionCircuit, Site } from "src/lib/api";
import { useWindowWidth } from "src/lib/hooks/window-width";
import { ellipsis } from "src/lib/utils";
import ErrorBoundaryWithFallback from "./ErrorBoundaryWithFallback";
import Loader from "./Loader";
import PredictiveRegulationControls, {
  PredictiveRegulationControlsFallback,
} from "./PredictiveRegulationControls";
import PredictiveSystemControls, {
  PredictiveSystemControlsFallback,
} from "./PredictiveSystemControls";
import RegulationParametersDashboardWithFallback from "./RegulationParametersDashboard";
import RenderIf from "./RenderIf";
import WatchDogControls, { WatchdogControlsFallback } from "./WatchdogControls";

const PredictiveRegulationTab: React.FC<{ site: Site; hdcs: HeatingDistributionCircuit[] }> = ({
  site,
  hdcs,
}) => {
  const { user } = useAuth0();
  const isWideScreen = useWindowWidth();

  return (
    <RenderIf predicate={hdcs.length > 0} fallback={<Empty />}>
      <Tabs defaultActiveKey="1" tabPosition={isWideScreen ? "left" : "top"}>
        {hdcs.map((hdc: HeatingDistributionCircuit) => (
          <Tabs.TabPane
            tab={isWideScreen ? ellipsis(hdc.name, 30) : hdc.name}
            key={`heating-distribution-circuit-${hdc.id}`}
          >
            <Suspense fallback={<Loader />}>
              <ErrorBoundaryWithFallback fallback={<PredictiveSystemControlsFallback />}>
                <PredictiveSystemControls siteSlug={site.slug} heatingDistributionCircuit={hdc} />
              </ErrorBoundaryWithFallback>
            </Suspense>
            <Divider />
            <Suspense fallback={<Loader />}>
              <ErrorBoundaryWithFallback fallback={<PredictiveRegulationControlsFallback />}>
                <PredictiveRegulationControls
                  siteSlug={site.slug}
                  heatingDistributionCircuitId={hdc.id}
                />
              </ErrorBoundaryWithFallback>
            </Suspense>
            <Divider />
            <Suspense fallback={<Loader />}>
              <ErrorBoundaryWithFallback fallback={<WatchdogControlsFallback />}>
                <WatchDogControls siteSlug={site.slug} hdcId={hdc.id} />
              </ErrorBoundaryWithFallback>
            </Suspense>

            {hasPermission(user, Permissions.HeatingInstallationsUpdate) && (
              <>
                <Divider />
                <Suspense fallback={<Loader />}>
                  <RegulationParametersDashboardWithFallback site={site} hdcid={hdc.id} />
                </Suspense>
              </>
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </RenderIf>
  );
};

export default PredictiveRegulationTab;
