import {
  ComfortRange,
  EnergyMeterType,
  Instrument,
  InstrumentCategory,
  RegulationParameterLabel,
  Role,
} from "./lib/api";

export const appVersion = process.env.REACT_APP_VERSION ?? "dev";

export const zeroTime = "0001-01-01T00:00:00Z";

export const phoneNumberRegexp: RegExp = /^\+[0-9]{11,14}$/;

export const supportEmail = "application@efficap-energie.com";

export const batteryVoltages = {
  low: 2.8,
  dead: 2.1,
};

export const batteryPercentages = {
  low: 60,
  dead: 20,
};

export const colorKeyList = [
  "purple",
  "orange",
  "red",
  "gold",
  "brown",
  "pink",
  "green",
  "blue",
  "grey",
  "white",
  "black",
] as const;

export type colorKey = (typeof colorKeyList)[number];

export const colors: {
  [key in colorKey]: {
    name: colorKey;
    light: string;
    medium: string;
    dark: string;
  };
} = {
  purple: { name: "purple", light: "#f9f0ff", medium: "#d3adf7", dark: "#722ed1" },
  blue: { name: "blue", light: "#e6f7ff", medium: "#91d5ff", dark: "#1890ff" },
  green: { name: "green", light: "#f6ffed", medium: "#b7eb8f", dark: "#52c41a" },
  orange: { name: "orange", light: "#fff7e6", medium: "#ffd591", dark: "#fa8c16" },
  gold: { name: "gold", light: "#fffbe6", medium: "#ffe58f", dark: "#faad14" },
  red: { name: "red", light: "#fff1f0", medium: "#ffa39e", dark: "#f5222d" },
  brown: { name: "brown", light: "#bfad8f", medium: "#90703a", dark: "#69470e" },
  pink: { name: "pink", light: "#FFB6C1", medium: "#FFC0CB", dark: "#FF69B4" },
  grey: { name: "grey", light: "#D8D9DA", medium: "#B4B4B3", dark: "#9E9FA5" },
  white: { name: "white", light: "#FFFFFF", medium: "#EEEEEE", dark: "#DDDDDD" },
  black: { name: "black", light: "#222222", medium: "#111111", dark: "#000000" },
};

export const dashboardColors: any = {
  pink: "#FF8181",
  red: "#EC2727",
  yellow: "#FFBA07",
  yellowFade: "#EECE7B",
  blue: "#438EF8",
  blueFade: "#7EB3FD",
  lightblue: "#ABCEFF",
  green: "#009B3E",
  brown: "#6D4200",
  lightYellow: "#EECE7B",
  grey: "#8A8A8A",
  white: "#FFFFFF",
  purple: "#985B90",
  black: "#000000",
};

export const graphColors = [
  dashboardColors.black,
  dashboardColors.blue,
  dashboardColors.grey,
  dashboardColors.purple,
  dashboardColors.brown,
];

export const regulationParametersParams: {
  [key in RegulationParameterLabel]: {
    title: string;
    description?: string;
    min?: number;
    max?: number;
    type?: any;
  };
} = {
  targetTemperature: {
    title: "La température cible (°C)",
    min: 10,
    max: 25,
    description: "Température intérieur de confort cible",
  },
  noHeatingTemperature: {
    title: "La température d'interruption de chauffe (°C)",
    min: 10,
    max: 25,
    description: "Température extérieure de non chauffage",
  },
  heatingTimeConstant: {
    title: "La constante de temps de chauffe (h)",
    min: 0,
    max: 48,
    description: "Temps de montée en chauffe de l'immeuble",
    type: "integer",
  },
  coolingTimeConstant: {
    title: "La constante de temps de refroidissement (h)",
    min: 0,
    max: 48,
    description: "Temps de refroidissement de l'immeuble",
    type: "integer",
  },
  heatingLawSlope: {
    title: "La pente de chauffe",
    min: -10,
    max: 0,
    description:
      "Pente correcte de la loi de chauffe (après réétalonage par la méthode de regression linéaire)",
  },
  temperatureThreshold1: {
    title: "La température seuil 1 (°C)",
    min: -10,
    max: 20,
    description:
      "Diminue de (100-alpha seuil 1)% la pente de la loi de chauffe pour les températures extérieures inférieures au seuil 1",
  },
  alphaThreshold1: {
    title: "Le alpha seuil 1 (%)",
    min: 0,
    max: 100,
    description:
      "Pourcentage de diminution de la pente de loi de chauffe pour les températures inférieures au seuil 1",
  },
  temperatureThreshold2: {
    title: "La température seuil 2 (°C)",
    min: -10,
    max: 20,
    description:
      "Diminue de (100-apha 2)% la pente de la loi de chauffe pour les températures extérieures supérieures au seuil 1 et inférieures au seuil 2",
  },
  alphaThreshold2: {
    title: "Le alpha seuil 2 (%)",
    min: 0,
    max: 100,
    description:
      "Pourcentage de diminution de la pente de loi de chauffe pour les températures inférieures au seuil 2 et supérieures au seuil 1",
  },
  solarNormalisation: {
    title: "La normalisation solaire (W/m²)",
    min: 0,
    max: 2000,
    description:
      "Normalisation solaire de l'information transmise par météofrance (pour passer du flux à un %)",
  },
  solarWeight: {
    title: "Le pourcentage d'influence solaire (%)",
    min: 0,
    max: 100,
    description: "Coefficient venant pondérer l'écart type calculé par Tmax_sol - Tmean_sol",
  },
  solarMaxTemperature: {
    title: "La température max solaire (°C)",
    min: -10,
    max: 50,
    description:
      "Température intérieure max de l'appartement le plus chaud un jour ensoleillé avec Text<Tconfort_cible",
  },
  solarMeanTemperature: {
    title: "La température moyenne solaire (°C)",
    min: -10,
    max: 50,
    description:
      "Température intérieure moyenne des appartements un jour ensoleillé avec Text<Tconfort_cible",
  },
  offsetLowerLimit: {
    title: "La limite basse d'offset (°K)",
    min: -20,
    max: 0,
    description:
      "Valeur du seuil passe-bande bas qui va écreter le signal a*OFFSETTOTAL pour éviter les problèmes d'inconfort (eau trop refroidie)",
  },
  offsetUpperLimit: {
    title: "La limite haute d'offset (°K)",
    min: 0,
    max: 5,
    description:
      "Valeur du seuil passe-bande haut qui va écreter le signal a*OFFSETTOTAL pour éviter les problèmes de sécurité (surchauffe de l'eau au départ et risque de surpression/ébullition)",
  },
  regulationType: { title: "Le type de régulation" },
  customOffset: {
    title: "L'offset forcé (°K)",
    min: -10,
    max: 30,
    description: "Offset forcé qui est écris tel quel dans l'automate",
  },
};

export const mapbox = {
  publicApiToken:
    "pk.eyJ1IjoiZWZmaWNhcC1lbmVyZ2llIiwiYSI6ImNrYjBzNTF4dzA1cWkydG1pdnp4M2NoYTMifQ.eDJI9zNzrUZjJdJClSesHw",
};

export const siteCoordinates: { [k: string]: { latitude: number; longitude: number } } = {
  "le-meridien": {
    latitude: 48.831707,
    longitude: 2.336628,
  },
  "felix-faure": {
    latitude: 48.839939,
    longitude: 2.285305,
  },
  balfourier: {
    latitude: 48.84506,
    longitude: 2.259163,
  },
  suchet: {
    latitude: 48.851568,
    longitude: 2.261177,
  },
  astrolabe: {
    latitude: 48.831598,
    longitude: 2.337621,
  },
  "motte-picquet": {
    latitude: 48.850493,
    longitude: 2.299633,
  },
  "clos-saint-benoit": {
    latitude: 48.829393,
    longitude: 2.281669,
  },
  "cap-sud": {
    latitude: 48.822043,
    longitude: 2.346641,
  },
  "parc-montsouris": {
    latitude: 48.822463,
    longitude: 2.340773,
  },
  "grand-parc": {
    latitude: 48.843253,
    longitude: 2.27513,
  },
  arcueil: {
    latitude: 48.820144,
    longitude: 2.342776,
  },
  "par-penthievre": {
    latitude: 48.77933,
    longitude: 2.292103,
  },
  "batiment-h": {
    latitude: 48.938571,
    longitude: 2.331858,
  },
  "le-chesnay": {
    latitude: 48.817797,
    longitude: 2.133393,
  },
  "brillat-mouchez": {
    latitude: 48.8220563,
    longitude: 2.3504257,
  },
};

export const weatherInstrument = (siteId: number): Instrument => {
  return {
    id: 0,
    serialNumber: "N/A",
    type: "weather",
    name: " T° météo",
    comments: "Température météo sur site",
    siteId: siteId,
    battery: { type: "unknown" },
    dataSource: "",
    supplierId: 0,
  };
};

export const isWeatherInInstrumentsArray = (siteId: number, instruments: Instrument[]) => {
  return instruments.some((e) => e.type === weatherInstrument(siteId).type);
};

export const plcSupplierName = (plcSupplierId: number) => {
  switch (plcSupplierId) {
    case 1:
      return "Connit";
    case 2:
      return "Adenuis";
    case 3:
      return "Siemens";
    case 4:
      return "ECCO2";
    case 5:
      return "Trend";
    case 6:
      return "Distech";
    case 7:
      return "Requea";
    case 8:
      return "Smart connector";
    case 9:
      return "WIT";
    default:
      return "Non renseigné";
  }
};

export const emTypeSource = (type: EnergyMeterType) => {
  switch (type) {
    case "cpcu":
      return "cpcu";
    case "grdf":
      return "grdf";
    case "iq4":
    case "distech":
      return "plc";
    default:
      return "none";
  }
};

export const defaultRole: Role = {
  id: "default_user",
  name: "Aucun rôle (Utilisateur de base)",
  description: "Rôle utilisateur par défaut",
};

export const formSection = {
  backgroundColor: "#fafafa",
  paddingTop: 20,
  paddingBottom: 20,
  borderRadius: 10,
};

export const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 12,
  },
};

export const chartLeftAxisWidth = 120;

export const unitDegreeCelsius = "°C";

export const instrumentCategoryOptions: { key: string | null; value: string }[] = [
  {
    key: null,
    value: "Aucun groupe",
  },
  {
    key: InstrumentCategory.Other,
    value: 'Groupe "autres"',
  },
  {
    key: InstrumentCategory.Froid,
    value: "Groupe froid",
  },
  {
    key: InstrumentCategory.Confort,
    value: "Groupe confort",
  },
  {
    key: InstrumentCategory.Chaud,
    value: "Groupe chaud",
  },
  {
    key: InstrumentCategory.ExternalTemperature,
    value: "Groupe températures externes",
  },
];

export const buildingInstrumentImageRatio = 8 / 5;

export const staticticStyle = {
  margin: "1rem 2rem 1rem 0rem",
};

export const comfortRangeDefaultLower = 19;
export const comfortRangeDefaultTarget = 20;
export const comfortRangeDefaultUpper = 22;

export const defaultComfortRange: ComfortRange = {
  lower: comfortRangeDefaultLower,
  target: comfortRangeDefaultTarget,
  upper: comfortRangeDefaultUpper,
};
