import { useState } from "react";
import * as React from "react";
import { DatePicker, message, Modal, Typography } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { HeatingDistributionCircuit, RegulationAPIService } from "src/lib/api";
import moment, { Moment } from "moment";

const ExportRegulationMeasurementsModal: React.FC<{
  siteSlug: string;
  showModal: boolean;
  closeModal: Function;
  heatingDistributionCircuit: HeatingDistributionCircuit;
}> = ({ siteSlug, showModal, closeModal, heatingDistributionCircuit }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [from, setFrom] = useState<Moment | null>(null);
  const [to, setTo] = useState<Moment | null>(null);

  const handleRange = (value: any) => {
    const [from, to] = value ?? [null, null];
    setFrom(from);
    setTo(to);
  };

  const handleExport = async () => {
    setConfirmLoading(true);
    await onRegulationMeasurementsExport();
    setConfirmLoading(false);
    closeModal();
  };

  async function onRegulationMeasurementsExport() {
    setConfirmLoading(true);

    if (from === null || to === null) return;

    try {
      await new RegulationAPIService(getAccessTokenSilently).downloadRegulationMeasurements({
        sId: siteSlug,
        hic: heatingDistributionCircuit.id.toString(),
        from: from?.startOf("day").add(1, "hours").unix(),
        to: to?.endOf("day").add(1, "hours").unix(),
        fileName: `${heatingDistributionCircuit.name} - Export des mesures de régulation.csv`,
      });
    } catch (e) {
      message.error({
        content: `L'export des données a échoué. Notre équipe a été prévenue. Veuillez ressayer ultérieurement.`,
      });
    } finally {
      setConfirmLoading(false);
    }
  }

  return (
    <div>
      <Modal
        title={`Export des mesures de régulation de ${heatingDistributionCircuit.name}`}
        visible={showModal}
        onOk={handleExport}
        confirmLoading={confirmLoading}
        onCancel={() => closeModal()}
        okText="Continuer"
        okButtonProps={{ disabled: from === null || to === null }}
      >
        <Typography.Paragraph strong={true}>
          Cette opération peut prendre quelques minutes en fonction de la période choisie et le
          nombre de sondes par site.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Veuillez choisir la durée de l'export si vous voulez continuer
        </Typography.Paragraph>
        <DatePicker.RangePicker
          onChange={handleRange}
          disabledDate={(current: Moment | null) => (current ? current > moment() : true)}
        />
      </Modal>
    </div>
  );
};

export default ExportRegulationMeasurementsModal;
