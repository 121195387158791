import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import moment from "moment";
import * as React from "react";
import { buildingInstrumentImageRatio } from "src/constants";
import {
  AggregatedMeasurement,
  ImageInstrumentGroupPosition,
  ImageInstrumentPosition,
  Instrument,
  InstrumentGroup,
  InstrumentInstrumentGroup,
  InstrumentInstrumentGroupType,
  Measurement,
  Site,
  SiteImage,
} from "src/lib/api";
import {
  UseAPIResult,
  useImagesInstrumentGroupPositions,
  useImagesInstrumentPositions,
  useInstrumentGroupMeasurements,
  useSiteBuildingImages,
  useSiteInstrumentGroups,
  useSiteInstruments,
} from "src/lib/hooks/api";
import { MeasurementValueOverlay } from "./HOC/useImageDrawing";
import { carouselMaxWidth } from "./InstrumentPositionConfigurationTab";
import InteractiveImageCarousel, { CarouselImageItem } from "./InteractiveImageCarousel";

const InstrumentsMeasurementsVisualiser: React.FC<{
  site: Site;
  measurementsRes: UseAPIResult<{ [p: string]: Measurement | AggregatedMeasurement }>;
  dataDisplayDuration: "live" | "week";
}> = ({ site, measurementsRes, dataDisplayDuration }) => {
  const { slug } = site;
  const carouselRef: React.RefObject<CarouselRef> = React.createRef();
  const { data: instruments = [] } = useSiteInstruments(slug);
  const { data: instrumentGroups = [] } = useSiteInstrumentGroups(slug);
  const [imageWidth, setImageWidth] = React.useState(carouselMaxWidth);
  const groupMeasurementsRes = useInstrumentGroupMeasurements(
    instrumentGroups.map((ig: InstrumentGroup) => ig.id.toString()),
    dataDisplayDuration === "live"
      ? moment().subtract(1, "hours").unix()
      : moment().subtract(1, "week").unix(),
    moment().unix(),
  );

  React.useEffect(() => {
    setImageWidth(document.getElementById("container")?.offsetWidth ?? carouselMaxWidth);
  }, []);

  const instrumentInstrumentGroupList: InstrumentInstrumentGroup[] =
    instruments
      .map((i: Instrument) => ({
        id: i.id,
        type: "instrument" as InstrumentInstrumentGroupType,
        name: i.name,
        comments: i.comments,
      }))
      .concat(
        instrumentGroups.map((ig: InstrumentGroup) => ({
          id: ig.id,
          type: "instrument-group",
          name: ig.name,
          comments: ig.comments,
        })),
      ) ?? [];

  const siteBuildingImageRes = useSiteBuildingImages(site.slug);
  const siteBuildingImages =
    siteBuildingImageRes.status === "success" ? siteBuildingImageRes.data : [];
  const imageIds = siteBuildingImages?.map((si: SiteImage) => si.id);
  const refImageInstrumentPositionsRes = useImagesInstrumentPositions(site.slug, imageIds);
  const imageInstrumentPositions =
    refImageInstrumentPositionsRes.status === "success" ? refImageInstrumentPositionsRes.data : [];
  const refImageInstrumentGroupPositionsRes = useImagesInstrumentGroupPositions(
    site.slug,
    imageIds,
  );
  const imageInstrumentGroupPositions =
    refImageInstrumentGroupPositionsRes.status === "success"
      ? refImageInstrumentGroupPositionsRes.data
      : [];

  function displayInstrumentMeasurements(imageId: number, imageWidth: number) {
    return instrumentInstrumentGroupList?.map((iig: InstrumentInstrumentGroup) => {
      if (iig.type === "instrument") {
        const relevantInstrumentPosition = imageInstrumentPositions.find(
          (iip: ImageInstrumentPosition) => iip.imageId === imageId && iip.instrumentId === iig.id,
        );

        const measurements = measurementsRes.status === "success" ? measurementsRes.data : {};

        if (relevantInstrumentPosition) {
          return (
            <MeasurementValueOverlay
              top={relevantInstrumentPosition.yPercentage}
              left={relevantInstrumentPosition.xPercentage}
              measurement={measurements?.[iig.id.toString()]}
              confortRange={site.currentHeatingSeason.comfortRange}
              instrument={iig}
              imageWidth={imageWidth}
            />
          );
        }

        return <></>;
      } else if (iig.type === "instrument-group") {
        const relevantInstrumentGroupPosition = imageInstrumentGroupPositions.find(
          (imigp: ImageInstrumentGroupPosition) =>
            imigp.imageId === imageId && imigp.instrumentGroupId === iig.id,
        );

        const groupMeasurements: Measurement[] =
          groupMeasurementsRes.status === "success" ? groupMeasurementsRes.data : [];

        const currentGroupMeasurement = groupMeasurements.filter(
          (gm: Measurement) => gm.instrumentId === iig.id,
        );

        const aggCurrentGroup = currentGroupMeasurement.reduce(
          (acc, cur) => ({
            ...acc,
            temperatureMeasurement: cur.temperatureMeasurement + acc.temperatureMeasurement,
            humidityMeasurement: (cur?.humidityMeasurement ?? 0) + (acc?.humidityMeasurement ?? 0),
            co2Measurement: (cur.co2Measurement ?? 0) + (acc.co2Measurement ?? 0),
          }),
          {
            id: 0,
            instrumentId: iig.id,
            timestamp: moment().unix().toString(),
            temperatureMeasurement: 0,
            humidityMeasurement: 0,
            co2Measurement: 0,
          },
        );

        const groupMeasurement: Measurement = {
          ...aggCurrentGroup,
          temperatureMeasurement:
            aggCurrentGroup.temperatureMeasurement / currentGroupMeasurement.length,
          humidityMeasurement:
            (aggCurrentGroup?.humidityMeasurement ?? 0) / currentGroupMeasurement.length,
          co2Measurement: (aggCurrentGroup.co2Measurement ?? 0) / currentGroupMeasurement.length,
        };

        if (relevantInstrumentGroupPosition) {
          return (
            <MeasurementValueOverlay
              top={relevantInstrumentGroupPosition.yPercentage}
              left={relevantInstrumentGroupPosition.xPercentage}
              measurement={groupMeasurement as Measurement}
              confortRange={site.currentHeatingSeason.comfortRange}
              instrument={iig}
              imageWidth={imageWidth}
            />
          );
        }

        return <></>;
      } else {
        return <></>;
      }
    });
  }

  return (
    <div style={{ width: "100%" }} id="container">
      {siteBuildingImages.length === 0 && (
        <Typography.Text style={{ fontStyle: "italic", marginLeft: 20 }}>
          Aucune image à afficher pour ce site, veuillez configurer la description 2D du site dans
          l'onglet Configuration du site -&gt; Positionnement des sondes
        </Typography.Text>
      )}
      {siteBuildingImages.length === 1 && (
        <CarouselImageItem
          image={siteBuildingImages[0]}
          dimensions={{
            width: imageWidth,
            height: imageWidth / buildingInstrumentImageRatio,
          }}
          children={displayInstrumentMeasurements(siteBuildingImages[0].id, imageWidth)}
        />
      )}
      {siteBuildingImages.length > 1 && (
        <>
          <div
            style={{
              width: imageWidth,
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
              marginBottom: 10,
            }}
          >
            <Button
              onClick={() => {
                carouselRef.current?.prev();
              }}
            >
              <Space>
                <LeftOutlined />
                Vue précédente
              </Space>
            </Button>
            <Typography.Text style={{ fontStyle: "italic", margin: 10, textAlign: "center" }}>
              {`Visualisez sous un ou plusieurs angles les températures dans votre immeuble. \n
              Passez la souris sur les mesures pour avoir des informations complémentaires.`}
            </Typography.Text>
            <Button
              onClick={() => {
                carouselRef.current?.next();
              }}
            >
              <Space>
                Vue suivante
                <RightOutlined />
              </Space>
            </Button>
          </div>

          <InteractiveImageCarousel
            imageList={siteBuildingImages}
            displayImageElements={(imageId: number) =>
              displayInstrumentMeasurements(imageId, imageWidth ? imageWidth : 0)
            }
            dimensions={{ width: imageWidth, height: imageWidth / buildingInstrumentImageRatio }}
            carouselRef={carouselRef}
          />
        </>
      )}
    </div>
  );
};

export default InstrumentsMeasurementsVisualiser;
