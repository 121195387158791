import { Radio, RadioChangeEvent, Select, Space, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import {
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { dashboardColors, graphColors } from "src/constants";
import { HeatingSeason, InstrumentGroup, InstrumentGroupMeasurement, Site } from "src/lib/api";
import CookieManager from "src/lib/cookies";
import { groupBy } from "src/lib/group-by";
import {
  useReferenceInstrumentGroupMeasurements,
  useSiteReferenceInstrumentGroups,
} from "src/lib/hooks/api";
import { DashboardNoData, RenderLabel, RenderThermometer } from "./DashboardComponents";

function dateFormatter(d: string) {
  return moment.unix(parseInt(d)).format("ll");
}

function inComfortRange(temp: number, hs: HeatingSeason) {
  return temp > hs.comfortRange.lower && temp < hs.comfortRange.upper;
}

export const DashboardTemperatureGR: React.FC<{ site: Site }> = ({ site }) => {
  const { data: referenceGroups } = useSiteReferenceInstrumentGroups(site.slug);

  const [from, setFrom] = useState<number>(moment().subtract(3, "month").unix());
  const [to, setTo] = useState<number>(moment().unix());
  const groupMeasurementsRes = useReferenceInstrumentGroupMeasurements(
    referenceGroups.map((g: InstrumentGroup) => g.id.toString()),
    from,
    to,
  );
  const allMeasurements =
    groupMeasurementsRes.status === "success" ? groupMeasurementsRes.data : [];
  const cookiesDefaultGroup = new CookieManager(`defaultGroups_${site.slug}`);
  const cookiesDefaultTimerange = new CookieManager(`defaultTimerange_${site.slug}`);

  const cookiesGroups = cookiesDefaultGroup.get() as number[];
  const cookiesIds = cookiesGroups
    ? cookiesGroups.filter((cId) => referenceGroups.some((rg) => rg.id === cId))
    : [];

  const defaultSelectedGroup = referenceGroups[0];

  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>(
    cookiesIds.length > 0 ? cookiesIds : defaultSelectedGroup ? [defaultSelectedGroup?.id] : [],
  );

  function handleSelect(selectedIds: number[]) {
    cookiesDefaultGroup.set(selectedIds);
    setSelectedGroupIds(selectedIds);
  }

  function changeTimeRange(value: RadioChangeEvent) {
    switch (value.target.value) {
      case "3months":
        cookiesDefaultTimerange.set("3months");
        setFrom(moment().subtract(3, "month").unix());
        setTo(moment().unix());
        break;
      case "heatingSeason":
        cookiesDefaultTimerange.set("heatingSeason");
        setFrom(moment(site.currentHeatingSeason.startTime).unix());
        setTo(moment(site.currentHeatingSeason.endTime).unix());
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Space
        direction="horizontal"
        style={{ marginBottom: 10, width: "100%", justifyContent: "space-between" }}
      >
        <Typography.Text style={{ paddingLeft: 20, fontSize: 16 }} strong>
          TEMPERATURES DE REFERENCE
        </Typography.Text>
        <Select
          onChange={handleSelect}
          style={{ width: 300 }}
          value={selectedGroupIds}
          mode="multiple"
          placeholder="Choix des groupes de référence"
        >
          {referenceGroups.map((g, i) => {
            return (
              <Select.Option value={g.id} s style={{ color: graphColors[i] }}>
                {g.name}
              </Select.Option>
            );
          })}
        </Select>

        <Radio.Group
          style={{ paddingRight: 20 }}
          defaultValue={cookiesDefaultTimerange.get() ?? "3months"}
          onChange={changeTimeRange}
        >
          <Radio.Button style={{ border: "1px dashed", marginRight: 5 }} value="3months">
            3 mois glissants
          </Radio.Button>
          <Radio.Button style={{ border: "1px dashed" }} value="heatingSeason">
            Saison de chauffe
          </Radio.Button>
        </Radio.Group>
      </Space>

      <DashboardTemperatureGRGraph
        site={site}
        selectedGroupIds={selectedGroupIds}
        referenceGroups={referenceGroups}
        allMeasurements={allMeasurements}
      />
    </>
  );
};

const DashboardTemperatureGRGraph: React.FC<{
  site: Site;
  selectedGroupIds: number[];
  referenceGroups: InstrumentGroup[];
  allMeasurements: InstrumentGroupMeasurement[];
}> = ({ site, selectedGroupIds, referenceGroups, allMeasurements }) => {
  const groupedData = groupBy(allMeasurements, (d) => moment(d.timestamp).format("YYYY-MM-DD"));

  const data = Object.keys(groupedData).map((timestamp) => {
    const res: any = { timestamp: moment(timestamp).unix() };
    selectedGroupIds.forEach((igid: number) => {
      const groupData = groupedData[timestamp].filter(
        (igm: InstrumentGroupMeasurement) => igm.instrumentId === igid,
      );
      res[`temp-${igid}`] =
        groupData.reduce((acc, c) => acc + c.temperatureMeasurement, 0) / groupData.length;
    });

    return res;
  });

  const firstTimestamp = data[0]?.timestamp;
  const lastTimestamp = data[data.length - 1]?.timestamp;

  const delta = lastTimestamp - firstTimestamp;

  return (
    <>
      {data.length > 0 ? (
        <ResponsiveContainer width="100%" height={350}>
          <LineChart data={data} margin={{ top: 10, right: 30, bottom: 5, left: 30 }}>
            <XAxis
              dataKey="timestamp"
              type="number"
              tickFormatter={dateFormatter}
              ticks={[firstTimestamp, lastTimestamp]}
              domain={[firstTimestamp, lastTimestamp + delta * 0.2]}
            />
            <YAxis
              yAxisId="left"
              ticks={[Math.max(...data.map((t) => t.temperature)) + 1]}
              domain={[
                Math.min(
                  ...allMeasurements.map(
                    (m: InstrumentGroupMeasurement) => m.temperatureMeasurement,
                  ),
                ) - 3,
                Math.max(
                  ...allMeasurements.map(
                    (m: InstrumentGroupMeasurement) => m.temperatureMeasurement,
                  ),
                ) + 1,
              ]}
            />
            <YAxis yAxisId="right" orientation="right" type="number">
              <Label
                value="Températures cibles (°C)"
                angle={-90}
                position="insideRight"
                offset={-15}
                fill={dashboardColors.grey}
                fontStyle="italic"
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <Tooltip
              labelFormatter={dateFormatter}
              formatter={(value: number) => [`${value.toFixed(2)}°C`, "Température"]}
            />
            <ReferenceLine x={Math.max(...data.map((t) => t.timestamp))} yAxisId="left" />
            <ReferenceLine
              y={site.currentHeatingSeason.comfortRange.target}
              stroke={dashboardColors.green}
              strokeDasharray="8 4 2 4"
              yAxisId="left"
            >
              <Label
                value={`${site.currentHeatingSeason.comfortRange.target.toFixed(1)}`}
                position="right"
                fill={dashboardColors.green}
                fontStyle="italic"
              />
            </ReferenceLine>
            <ReferenceLine
              y={site.currentHeatingSeason.comfortRange.upper}
              stroke={dashboardColors.red}
              strokeDasharray="8 10"
              yAxisId="left"
            >
              <Label
                value={`${site.currentHeatingSeason.comfortRange.upper.toFixed(1)}`}
                position="right"
                fill={dashboardColors.red}
                fontStyle="italic"
              />
            </ReferenceLine>

            <ReferenceLine
              y={site.currentHeatingSeason.comfortRange.lower}
              stroke={dashboardColors.blue}
              strokeDasharray="8 10"
              yAxisId="left"
            >
              <Label
                value={`${site.currentHeatingSeason.comfortRange.lower.toFixed(1)}`}
                position="right"
                fill={dashboardColors.blue}
                fontStyle="italic"
              />
            </ReferenceLine>
            {selectedGroupIds.map((igid) => {
              const totTemp = data.reduce((acc, v) => acc + v[`temp-${igid}`], 0);
              const avgTemp = totTemp / data.length;

              const lastTemp = data[data.length - 1]?.[`temp-${igid}`];
              const groupColor =
                graphColors[referenceGroups.map((s: InstrumentGroup) => s.id).indexOf(igid)] ??
                dashboardColors.black;

              return (
                <>
                  <ReferenceLine
                    opacity={0}
                    yAxisId="left"
                    segment={[
                      { x: firstTimestamp, y: lastTemp },
                      { x: lastTimestamp, y: lastTemp },
                    ]}
                  >
                    <Label
                      content={
                        <RenderLabel
                          labelValue={`${lastTemp?.toFixed(2)}°C`}
                          col={
                            inComfortRange(lastTemp, site.currentHeatingSeason)
                              ? dashboardColors.green
                              : dashboardColors.red
                          }
                          positionX="right"
                          borderColor={selectedGroupIds.length === 1 ? undefined : groupColor}
                        />
                      }
                    />
                    <Label content={<RenderThermometer />} />
                  </ReferenceLine>
                  <ReferenceLine
                    strokeDasharray="2 2"
                    stroke={dashboardColors.grey}
                    segment={[
                      { x: firstTimestamp, y: avgTemp },
                      { x: lastTimestamp, y: avgTemp },
                    ]}
                    yAxisId="left"
                  >
                    <Label
                      content={
                        <RenderLabel
                          labelValue={`${avgTemp.toFixed(2)}°C`}
                          labelTitle="Moyenne"
                          col={
                            inComfortRange(avgTemp, site.currentHeatingSeason)
                              ? dashboardColors.green
                              : dashboardColors.red
                          }
                          borderColor={selectedGroupIds.length === 1 ? undefined : groupColor}
                          positionX="left"
                        />
                      }
                    />
                  </ReferenceLine>
                  <Line
                    yAxisId="left"
                    type="monotone"
                    stroke={groupColor}
                    dataKey={`temp-${igid}`}
                    dot={false}
                    strokeWidth={1.5}
                  />
                </>
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <DashboardNoData />
      )}
    </>
  );
};
