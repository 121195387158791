import React, { useEffect, useState } from "react";
import { Card, Divider, Space } from "antd";
import moment from "moment";
import { Instrument, InstrumentGroup, Site } from "src/lib/api";
import { useSiteInstrumentGroups, useSiteInstruments } from "src/lib/hooks/api";
import DatePickerDownloader from "../DatePickerDownloader";
import ComfortAnalysisChart from "./ComfortAnalysisChart";
import SelectTable from "./InstrumentsSelectTable";
import withSuspenseComponent from "../HOC/withSuspenseComponent";

interface ComfortAnalysisProps {
  site: Site;
}

const ComfortAnalysis = ({ site }: ComfortAnalysisProps) => {
  const { data: igs } = useSiteInstrumentGroups(site.slug);
  const { data: is } = useSiteInstruments(site.slug);

  const [selectedIs, setSelectedIs] = useState<Instrument[]>([]);
  const [selectedIgs, setSelectedIgs] = useState<InstrumentGroup[]>([]);

  useEffect(() => {
    setSelectedIs([]);
  }, [is]);

  useEffect(() => {
    setSelectedIgs(igs);
  }, [igs]);

  return (
    <div>
      <Space direction="horizontal">
        <Card title="Groupes d'instruments">
          <SelectTable
            instruments={igs}
            selectedInstrumentsIds={selectedIgs.map((i) => i.id)}
            onSelectionChange={(selectedIds) =>
              setSelectedIgs(igs.filter((ig) => selectedIds.includes(ig.id)))
            }
          />
        </Card>
        <Divider type="vertical" />
        <Card title="Instruments">
          <SelectTable
            instruments={is}
            selectedInstrumentsIds={selectedIs.map((i) => i.id)}
            onSelectionChange={(selectedIds) =>
              setSelectedIs(is.filter((i) => selectedIds.includes(i.id)))
            }
          />
        </Card>
      </Space>
      <Divider />
      <DatePickerDownloader
        ranges={{
          "1 jour": [moment().subtract(1, "d"), moment()],
          "1 semaine": [moment().subtract(7, "d"), moment()],
          "1 mois": [moment().subtract(1, "months"), moment()],
          "3 mois": [moment().subtract(3, "months"), moment()],
        }}
      >
        {({ from, to }) => (
          <ComfortAnalysisChart site={site} is={selectedIs} igs={selectedIgs} from={from} to={to} />
        )}
      </DatePickerDownloader>
    </div>
  );
};

export default withSuspenseComponent(ComfortAnalysis);
