import * as React from "react";
import { Button, Input } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { ColumnProps } from "antd/lib/table/Column";
import { FilterConfirmProps, FilterDropdownProps } from "antd/lib/table/interface";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";

// showIf show this column only for users who have a certain permission.
// should be used with the spread operator like this: ...showIf(() => true, column)
export function showIf<T>(predicate: () => boolean, column: ColumnProps<T>): ColumnProps<T>[] | [] {
  return predicate() ? [column] : [];
}

export interface GetColumnSearchPropsState<TKey> {
  searchedColumn?: TKey;
  searchText: string;
}

function normaliseStringForSearch(s: string) {
  return s
    ?.toString()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

export function getColumnSearchProps<TKey extends string, T extends Record<TKey, any>>(
  ref: React.RefObject<Input>,
  dataIndex: TKey,
  state: GetColumnSearchPropsState<TKey>,
  setState: (s: GetColumnSearchPropsState<TKey>) => void,
  options?: {
    linkTo?: (r: T) => string;
    filterFunc?: (value: any, record: T) => boolean;
    inputTransform?: (q: string) => string;
  },
) {
  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: (_: FilterConfirmProps) => void,
    dataIndex: string,
  ) => {
    confirm({ closeDropdown: true });
    setState({
      searchedColumn: dataIndex as TKey,
      searchText: selectedKeys[0] as string,
    });
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setState({ searchText: "" });
  };

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => (ref = node as any as React.RefObject<Input>)}
          value={selectedKeys![0]}
          onChange={(e) => setSelectedKeys!(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys!, confirm!, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys!, confirm!, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        />
        <Button
          onClick={() => handleReset(clearFilters!)}
          icon={<CloseOutlined />}
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: T) => {
      const ff = options?.filterFunc;
      const q = normaliseStringForSearch(mustInputTransform(options?.inputTransform)(value));

      return ff ? ff(q, record) : onFilterString(normaliseStringForSearch(record[dataIndex]), q);
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        // To move focus inside the search box as soon as the dropdown is visible.
        setTimeout(() => (ref as any as HTMLInputElement).select());
      }
    },

    render: (text: string, record: T) => {
      const q = mustInputTransform(options?.inputTransform)(state.searchText);

      const comp =
        state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[q]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        );

      return options?.linkTo ? <Link to={options.linkTo(record)}>{comp}</Link> : comp;
    },
  };
}
export function sorter<TKey extends string, TValue extends string | number>(
  field: TKey,
  type: "date" | "string",
): (a: Record<TKey, TValue>, b: Record<TKey, TValue>) => number {
  return (a, b) => {
    switch (type) {
      case "date":
        return dateSorter(a[field], b[field]);
      case "string":
        return stringSorter(a[field] as string, b[field] as string);
    }
  };
}

export function dateSorter<TValue extends number | string>(a: TValue, b: TValue): number {
  return +new Date(a) - +new Date(b);
}

export function stringSorter(a: string, b: string): number {
  return a.localeCompare(b);
}

export function nanNumberSorter(
  a: number,
  b: number,
  type: "temperature" | "humidity" | "co2",
): number {
  const defaultValue = type === "temperature" ? -273 : 0;
  const avgTempA = isNaN(a) ? defaultValue : a;
  const avgTempB = isNaN(b) ? defaultValue : b;
  return avgTempA - avgTempB;
}

export function undefinedNumberSorter(a?: number, b?: number): number {
  const definedA = a === undefined || a === null ? 0 : a;
  const definedB = b === undefined || b === null ? 0 : b;
  return definedA - definedB;
}

function mustInputTransform(t: ((q: string) => string) | undefined): (q: string) => string {
  return t ?? ((q: string): string => q);
}

export function onFilterString(haystack: string, needle: string) {
  return haystack?.includes(needle) ?? false;
}

export function ecco2SerialNumberFromURL(url: string): string {
  const regex = /https:\/\/live.ecco2.ch\/data\?devId[=!](?<serialNumber>[0-9a-zA-Z]+)/m;
  return (regex.exec(url)?.groups?.serialNumber ?? url) as string;
}
