import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Divider, Form, InputNumber, Popover, Select, Space } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { hasPermission, Permissions } from "src/lib/access-control";
import { SchedulingMode } from "src/lib/api";
import {
  useSiteHeatingDistributionLatestSchedulingMeasurement,
  useSiteHeatingDistributionSchedulingParameters,
  useSiteHeatingDistributionMode,
  useSiteHeatingInstallationCircuitUpdateSchedulingMode,
  useSiteHeatingInstallationCircuitUpdateSchedulingParameters,
  queryKeySiteDistributionCircuitLatestSchedulingMeasurement,
  queryKeySiteDistributionCircuitMode,
} from "src/lib/hooks/api";
import { withNotify } from "src/lib/notify";
import { ReloadOutlined, MonitorOutlined } from "@ant-design/icons";

const SchedulingControls: React.FC<{
  siteSlug: string;
  heatingDistributionCircuitId: string;
}> = ({ siteSlug, heatingDistributionCircuitId }) => {
  const queryClient = useQueryClient();

  const { data: latestSchedulingMeasurement } =
    useSiteHeatingDistributionLatestSchedulingMeasurement(siteSlug, heatingDistributionCircuitId);

  const { data: schedulingParameters } = useSiteHeatingDistributionSchedulingParameters(
    siteSlug,
    heatingDistributionCircuitId,
  );

  const {
    data: { mode },
  } = useSiteHeatingDistributionMode(siteSlug, heatingDistributionCircuitId);
  const now = new Date();

  const [isLoadingUpdateMode, setIsLoadingUpdateMode] = useState<boolean>(false);

  const { mutateAsync: updateSchedulingMode } =
    useSiteHeatingInstallationCircuitUpdateSchedulingMode(
      siteSlug,
      heatingDistributionCircuitId.toString(),
    );

  const { mutateAsync: updateSchedulingParameters } =
    useSiteHeatingInstallationCircuitUpdateSchedulingParameters(
      siteSlug,
      heatingDistributionCircuitId.toString(),
    );

  const [selectedMode, setSelectedMode] = React.useState<SchedulingMode>(
    getMode(latestSchedulingMeasurement.regulationMode, latestSchedulingMeasurement.isActive),
  );

  const [form] = Form.useForm();

  const { user } = useAuth0();

  const [loading, setLoading] = useState(false);
  const [isSavingParams, setIsSavingParams] = useState(false);

  const [paramMode, setParamMode] = useState<"edit" | "consult">("consult");
  const onRefresh = async () => {
    setLoading(true);
    await Promise.all([
      queryClient.invalidateQueries(
        queryKeySiteDistributionCircuitLatestSchedulingMeasurement(
          siteSlug,
          heatingDistributionCircuitId,
        ),
      ),
      queryClient.invalidateQueries(
        queryKeySiteDistributionCircuitMode(siteSlug, heatingDistributionCircuitId),
      ),
    ]);
    setLoading(false);
  };

  function getMode(mode: string, isActive: boolean): SchedulingMode {
    if (isActive) {
      return "regulated";
    }

    switch (mode) {
      case "Auto":
        return "auto";
      case "Confort":
        return "comfort";
      case "Préconfort":
        return "precomfort";
      case "Economie":
        return "economy";
      case "Hors gel":
        return "frostfree";
      default:
        return "comfort";
    }
  }

  function getSentMode(mode: string, isActive: boolean) {
    if (isActive) {
      switch (mode) {
        case "Hors gel":
          return "Fonction de protection";
        default:
          return mode;
      }
    }
    return "Aucun (service inactif)";
  }

  async function onUpdateParams() {
    form
      .validateFields()
      .then(async ({ preHeatingMinutes, heatingRate, coolingInertia }) => {
        setIsSavingParams(true);
        await updateSchedulingParameters({ preHeatingMinutes, heatingRate, coolingInertia });
        setIsSavingParams(false);
        setParamMode("consult");
      })
      .catch((err: any) => {
        console.error("Scheduling parameter update error:", err);
      });
  }

  function onCancelEdit() {
    setParamMode("consult");
    form.resetFields();
  }

  return (
    <Card
      title={
        <Space direction="horizontal">
          <MonitorOutlined />
          Gestion de la régulation planifiée
        </Space>
      }
      extra={<Button icon={<ReloadOutlined />} onClick={onRefresh} />}
      loading={loading}
    >
      <div style={{ marginBottom: "0.5rem" }}>
        <span>
          <b>Température ambiante de la zone: </b>
          {latestSchedulingMeasurement.ambiantTemperature?.toFixed(2) ?? "-"} °C
          {` (relevé du ${moment(latestSchedulingMeasurement.timestamp).format("llll")})`}
        </span>
      </div>
      <div style={{ marginBottom: "0.5rem" }}>
        <span>
          <b>Température de consigne du mode confort du circuit: </b>
          {latestSchedulingMeasurement.comfortTemperature?.toFixed(2) ?? "-"} °C
          {` (relevé du ${moment(latestSchedulingMeasurement.timestamp).format("llll")})`}
        </span>
      </div>
      <div style={{ marginBottom: "0.5rem" }}>
        <span>
          <b>Température de consigne du mode fonction de protection du circuit: </b>
          {latestSchedulingMeasurement.protectionTemperature?.toFixed(2) ?? "-"} °C
          {` (relevé du ${moment(latestSchedulingMeasurement.timestamp).format("llll")})`}
        </span>
      </div>
      <div style={{ marginBottom: "0.5rem" }}>
        <span>
          <Popover
            title="Modification de la consigne"
            content={
              <div>
                <p>
                  Vous pouvez activer le mode "régulation planifiée" (régulation gérée par Efficap),
                  ou choisir un mode fixe. Le changement de mode devient effectif au bout d'un quart
                  d'heure.
                </p>
              </div>
            }
          >
            <div>
              <b>Modifier le mode : </b>
              <Select
                defaultValue={selectedMode}
                style={{ width: 220 }}
                placeholder="Sélectionner un mode"
                onChange={(mode: SchedulingMode) => setSelectedMode(mode)}
              >
                <Select.Option value="regulated" name="Regulated">
                  Régulation planifiée
                </Select.Option>
                <Select.Option value="auto" name="Auto">
                  Auto forcé
                </Select.Option>
                <Select.Option value="comfort" name="Confort">
                  Confort forcé
                </Select.Option>
                <Select.Option value="precomfort" name="precomfort">
                  Préconfort forcé
                </Select.Option>
                <Select.Option value="economy" name="Economie">
                  Economie forcé
                </Select.Option>
                <Select.Option value="frostfree" name="Hors gel">
                  Fonction de protection forcé
                </Select.Option>
              </Select>
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                loading={isLoadingUpdateMode}
                disabled={!hasPermission(user, Permissions.SchedulingUpdate)}
                onClick={async () => {
                  setIsLoadingUpdateMode(true);
                  await withNotify(
                    updateSchedulingMode(selectedMode),
                    "La modification du mode a bien été enregistrée",
                    "Une erreur est survenue lors de la mise à jour du mode.",
                  );
                  setIsLoadingUpdateMode(false);
                }}
              >
                Valider
              </Button>
            </div>
          </Popover>
        </span>
      </div>
      <div style={{ marginBottom: "0.5rem" }}>
        <span>
          <b>Dernier mode envoyé par Efficap en régulation planifiée: </b>
          {getSentMode(
            latestSchedulingMeasurement.regulationMode,
            latestSchedulingMeasurement.isActive,
          )}
          {` (envoyé le ${moment(latestSchedulingMeasurement.timestamp).format("llll")})`}
        </span>
      </div>
      <div style={{ marginBottom: "0.5rem" }}>
        <span>
          <b>Mode lu sur l'automate: </b>
          {mode}
          {` (${moment(now).format("llll")})`}
        </span>
      </div>
      <Divider>Paramètres</Divider>
      <Form
        form={form}
        name="scheduling-parameters"
        initialValues={{
          preHeatingMinutes: schedulingParameters.preHeatingMinutes,
          heatingRate: schedulingParameters.heatingRate,
          coolingInertia: schedulingParameters.coolingInertia,
        }}
      >
        <Form.Item
          label="Temps de préchauffage (minutes) :"
          name="preHeatingMinutes"
          hasFeedback={true}
          rules={[
            {
              type: "integer",
              min: 0,
              max: 180,
              message: "Le temps de préchauffage doit être un nombre entre 0 et 180 minutes",
            },
          ]}
        >
          <InputNumber autoFocus={true} style={{ width: 100 }} disabled={paramMode === "consult"} />
        </Form.Item>
        <Form.Item
          label="Vitesse de chauffe (°C/h) :"
          name="heatingRate"
          hasFeedback={true}
          rules={[
            {
              type: "number",
              min: 0,
              max: 20,
              message: "La vitesse de chauffe est un nombre entre 0 et 20 °/h",
            },
          ]}
        >
          <InputNumber
            autoFocus={true}
            style={{ width: 100 }}
            step={0.1}
            disabled={paramMode === "consult"}
          />
        </Form.Item>
        <Form.Item
          label="Inertie de refroidissement (minutes) :"
          name="coolingInertia"
          hasFeedback={true}
          rules={[
            {
              type: "integer",
              min: 0,
              max: 60,
              message: `L'inertie de refroidissement est un nombre entre 0 et 60 minutes`,
            },
          ]}
        >
          <InputNumber autoFocus={true} style={{ width: 100 }} disabled={paramMode === "consult"} />
        </Form.Item>
      </Form>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Button
          type="primary"
          loading={isSavingParams}
          disabled={!hasPermission(user, Permissions.SchedulingUpdate)}
          onClick={
            paramMode === "edit"
              ? async () => {
                  await withNotify(
                    onUpdateParams(),
                    "La modification des paramètres a bien été enregistrée",
                    "Une erreur est survenue lors de la mise à jour des paramètres.",
                  );
                }
              : () => setParamMode("edit")
          }
        >
          {paramMode === "edit" ? "Enregistrer" : "Modifier"}
        </Button>
        {paramMode === "edit" && (
          <Button
            type="ghost"
            style={{ color: "red", marginLeft: 10 }}
            loading={isSavingParams}
            onClick={onCancelEdit}
          >
            Abandonner
          </Button>
        )}
      </div>
      <div style={{ marginBottom: "0.5rem", marginTop: 40 }}>
        <span>
          <b>Téléchargement du calendrier : </b>
          <a href={schedulingParameters.calendarAddress ?? "--"}>lien</a>
        </span>
      </div>
    </Card>
  );
};

export default SchedulingControls;
