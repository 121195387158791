import moment from "moment";
import { DailyDHH, EnergyConsumption, HeatingPeriod, HeatingSeason } from "./api";
import { groupBy } from "./group-by";
import getHeatingPeriod, { defaultHeatingPeriod } from "./heating-period";
import { useSiteHeatingSeasons } from "./hooks/api";
export interface consumptionRef {
  timestamp: number;
  consumption?: number;
  consumptionRef?: number;
}
export interface consumptionHddRef extends consumptionRef {
  hdd?: number;
  hddRef?: number;
}

export interface consumptionOnly {
  timestamp: number;
  consumption: number;
}
export interface consumptionHdd extends consumptionOnly {
  hdd: number;
}

export function getGeneralInfos(
  hp: HeatingPeriod,
  hpRef: HeatingPeriod,
  consumptions: consumptionOnly[],
  hdd: DailyDHH[],
) {
  const hpStart = moment(hp.startTime);
  const hpRefStart = moment(hpRef.startTime);

  const deltaYear = hpStart.year() - hpRefStart.year();

  const totalConsumption = consumptions
    .filter((c) => moment.unix(c.timestamp).isAfter(hpStart))
    .reduce((acc, current) => acc + current.consumption, 0);
  const totalConsumptionRef = consumptions
    .filter(
      (c) =>
        moment.unix(c.timestamp).isAfter(hpRefStart) &&
        moment.unix(c.timestamp).isBefore(moment().subtract(deltaYear, "year")),
    )
    .reduce((acc, current) => acc + current.consumption, 0);

  const totalHdd = hdd
    .filter((h) => moment(h.date).isAfter(hpStart))
    .reduce((acc, current) => acc + current.hdd, 0);
  const totalHddRef = hdd
    .filter(
      (h) =>
        moment(h.date).isAfter(hpRefStart) &&
        moment(h.date).isBefore(moment().subtract(deltaYear, "year")),
    )
    .reduce((acc, current) => acc + current.hdd, 0);

  const consumptionPerHdd = totalHdd !== 0 ? totalConsumption / totalHdd : 0;
  const consumptionPerHddRef = totalHddRef !== 0 ? totalConsumptionRef / totalHddRef : 0;

  const consumptionVariation = totalConsumptionRef
    ? ((totalConsumption - totalConsumptionRef) / totalConsumptionRef) * 100
    : 0;

  const consumptionPerHddVariation =
    consumptionPerHddRef !== 0
      ? ((consumptionPerHdd - consumptionPerHddRef) / consumptionPerHddRef) * 100
      : 0;
  const hddVariation = totalHddRef !== 0 ? ((totalHdd - totalHddRef) / totalHddRef) * 100 : 0;

  return [consumptionVariation, consumptionPerHddVariation, hddVariation, totalHdd];
}

export function loadBoxConsumptionData(consumptions: EnergyConsumption[]): consumptionOnly[] {
  const consumptionByDay = groupBy(consumptions, (d) => moment(d.date).format("YYYY-MM-DD"));

  return Object.keys(consumptionByDay).map((item) => {
    return {
      timestamp: moment(item).unix(),
      consumption: consumptionByDay[item].reduce((acc, c) => acc + c.consumption, 0),
    };
  });
}

function getLastYearMonths(): string[] {
  return Array.from(Array(12).keys()).map((v) =>
    moment()
      .subtract(11 - v, "months")
      .format("YYYY-MM"),
  );
}

export function getMonthlyData(
  consumptions: consumptionOnly[],
  hdd: DailyDHH[],
): consumptionHddRef[] {
  const groupedConsumptions = groupBy(consumptions, (d) =>
    moment.unix(d.timestamp).format("YYYY-MM"),
  );
  const groupedHdd = groupBy(hdd, (d) => moment(d.date).format("YYYY-MM"));

  const keys = getLastYearMonths();

  return keys
    .map((k) => {
      const refKey = moment(k).subtract(1, "year").format("YYYY-MM");
      return {
        timestamp: moment(k).unix(),
        consumption: groupedConsumptions[k]
          ? groupedConsumptions[k].reduce((acc, c) => acc + c.consumption, 0)
          : undefined,
        consumptionRef: groupedConsumptions[refKey]
          ? groupedConsumptions[refKey].reduce((acc, c) => acc + c.consumption, 0)
          : undefined,
        hdd: groupedHdd[k] ? groupedHdd[k].reduce((acc, c) => acc + c.hdd, 0) : undefined,
        hddRef: groupedHdd[refKey]
          ? groupedHdd[refKey].reduce((acc, c) => acc + c.hdd, 0)
          : undefined,
      };
    })
    .filter(
      (d) =>
        d.consumption !== undefined ||
        d.consumptionRef !== undefined ||
        d.hdd !== undefined ||
        d.hddRef !== undefined,
    );
}

export function getConsumptionRepartitionData(
  consumptions: consumptionOnly[],
  hs: HeatingPeriod,
  hsRef: HeatingPeriod,
): consumptionRef[] {
  const consumptionsData = consumptions
    .filter(
      (c) =>
        moment.unix(c.timestamp).isAfter(moment(hs.startTime)) &&
        moment.unix(c.timestamp).isBefore(moment(hs.endTime)),
    )
    .map((c) => ({
      timestamp: moment.unix(c.timestamp).format("YYYY-MM-DD"),
      consumption: c.consumption,
    }));

  const consumptionsRefData = consumptions
    .filter(
      (c) =>
        moment.unix(c.timestamp).isAfter(moment(hsRef.startTime)) &&
        moment.unix(c.timestamp).isBefore(moment(hsRef.endTime)),
    )
    .map((c) => ({
      timestamp: moment.unix(c.timestamp).add(1, "year").format("YYYY-MM-DD"),
      consumption: c.consumption,
    }));

  const timestamps = Array.from(
    new Set(
      consumptionsData.map((c) => c.timestamp).concat(consumptionsRefData.map((c) => c.timestamp)),
    ),
  ).sort((a, b) => moment(a).unix() - moment(b).unix());

  let cumul = 0;
  let skippedFirst = false;
  let cumulRef = 0;
  let skippedFirstRef = false;
  const data = timestamps.map((t) => {
    const consumption = consumptionsData.find((c) => c.timestamp === t);
    const consumptionRef = consumptionsRefData.find((c) => c.timestamp === t);

    if (consumption && consumption.consumption !== 0) {
      cumul += skippedFirst ? consumption.consumption : 0;
      skippedFirst = true;
    }

    if (consumptionRef && consumptionRef.consumption !== 0) {
      cumulRef += skippedFirstRef ? consumptionRef.consumption : 0;
      skippedFirstRef = true;
    }

    return {
      timestamp: moment(t).unix(),
      consumption: consumption ? cumul : undefined,
      consumptionRef: consumptionRef ? cumulRef : undefined,
    };
  });

  return data;
}

export function GetDashboardHeatingPeriods(slug: string): [HeatingPeriod, HeatingPeriod] {
  const heatingPeriodsRes = useSiteHeatingSeasons(slug);
  const heatingPeriods = heatingPeriodsRes.status === "success" ? heatingPeriodsRes.data : [];

  const hsKey = getHeatingPeriod(moment().toDate());
  const hsRefKey = getHeatingPeriod(moment().subtract(1, "year").toDate());
  const hp =
    heatingPeriods.find((hs: HeatingSeason) => hs.key === hsKey) ?? defaultHeatingPeriod(hsKey);
  const hpRef =
    heatingPeriods.find((hs: HeatingSeason) => hs.key === hsRefKey) ??
    defaultHeatingPeriod(hsRefKey);

  return [hp, hpRef];
}

export function getConsumptionPerfoData(
  consumptions: consumptionOnly[],
  hdd: DailyDHH[],
): consumptionOnly[] {
  const from = moment().subtract(30, "days");

  const consumptionsData = consumptions
    .filter((c) => moment.unix(c.timestamp).isAfter(from))
    .map((c) => ({
      timestamp: moment.unix(c.timestamp).format("YYYY-MM-DD"),
      consumption: c.consumption,
    }));
  const hddData = hdd
    .filter((h) => moment(h.date).isAfter(from))
    .map((h) => ({ timestamp: moment(h.date).format("YYYY-MM-DD"), hdd: h.hdd }));

  return consumptionsData
    .map((c) => {
      const hdd = hddData.find((h) => h.timestamp === c.timestamp) ?? undefined;
      return {
        timestamp: moment(c.timestamp).unix(),
        consumption: hdd && hdd.hdd ? c.consumption / hdd.hdd : undefined,
      };
    })
    .filter((d) => d.consumption !== undefined) as consumptionOnly[];
}

export function getDotCloudData(
  consumptions: consumptionOnly[],
  hdd: DailyDHH[],
): consumptionHdd[] {
  const from = moment().subtract(1, "year");

  const groupedConsumptions = groupBy(
    consumptions.filter((c) => moment.unix(c.timestamp).isAfter(from)),
    (d) => moment.unix(d.timestamp).format("YYYY-MM-DD"),
  );
  const groupedHdd = groupBy(
    hdd.filter((h) => moment(h.date).isAfter(from)),
    (d) => moment(d.date).format("YYYY-MM-DD"),
  );

  const data = Object.keys(groupedConsumptions)
    .map((c) => {
      return {
        timestamp: moment(c).unix(),
        consumption: groupedConsumptions[c].reduce((acc, c) => acc + c.consumption, 0),
        hdd: groupedHdd[c] ? groupedHdd[c].reduce((acc, c) => acc + c.hdd, 0) : undefined,
      };
    })
    .filter((d) => d.consumption !== undefined && d.hdd !== undefined) as consumptionHdd[];

  const meanConsumption = data.reduce((acc, c) => acc + c.consumption, 0) / data.length;

  return data.filter((d) => d.consumption < 3 * meanConsumption);
}

export function getDotCloudLineCoordinates(
  data: consumptionHdd[],
): [number, number, number, number] {
  const ecsVariationMargin = 1; // 100%

  const maxConsumption = Math.max(...data.map((d) => d.consumption));
  const maxHdd = Math.max(...data.map((d) => d.hdd));

  const hddPercentsForHdw = [0.1, 0.15, 0.2, 0.25, 0.3];

  const coefficients = hddPercentsForHdw.map((hddPercetForHdw) => {
    // division of graph in 2 parts : data for ECS and data for Heating
    const heatingData = data.filter((d) => d.hdd > maxHdd * hddPercetForHdw);
    const ecsData = data.filter((d) => d.hdd < maxHdd * hddPercetForHdw);

    const meanEcsConsumption = ecsData.reduce((acc, c) => acc + c.consumption, 0) / ecsData.length;
    const ecsCleanData = ecsData.filter(
      (c) => c.consumption < meanEcsConsumption * (1 + ecsVariationMargin),
    );

    const maxEcsHdd = Math.max(...ecsData.map((d) => d.hdd));
    // seen as x value of last ECS data
    const meanEcsConsumptionClean =
      ecsCleanData.reduce((acc, c) => acc + c.consumption, 0) / ecsCleanData.length;
    // seen as y value of mean ECS data

    // compute linear function : y = ax + b
    const heatingDataCenterX = heatingData.reduce((acc, c) => acc + c.hdd, 0) / heatingData.length;
    const heatingDataCenterY =
      heatingData.reduce((acc, c) => acc + c.consumption, 0) / heatingData.length;

    const heatingDataA =
      (heatingDataCenterY - meanEcsConsumptionClean) / (heatingDataCenterX - maxEcsHdd);
    const heatingDataB = meanEcsConsumptionClean - heatingDataA * maxEcsHdd;

    const coef = heatingData.reduce(
      (acc, current) =>
        acc + (current.consumption - heatingDataA * current.hdd + heatingDataB) ** 2,
      0,
    );

    const maxHeatingDataX = maxHdd;
    const maxHeatingDataY = Math.min(heatingDataA * maxHeatingDataX + heatingDataB, maxConsumption);

    return [coef, maxEcsHdd, meanEcsConsumptionClean, maxHeatingDataX, maxHeatingDataY];
  });

  const result = coefficients.reduce((acc, current) => {
    if (isNaN(current[0])) {
      return acc;
    }
    if (acc.length === 0 || acc[0] > current[0]) {
      return current;
    } else {
      return acc;
    }
  }, []);

  return result.slice(-4) as [number, number, number, number];
}
