import {
  CheckSquareOutlined,
  CodeOutlined,
  DatabaseOutlined,
  HomeOutlined,
  MonitorOutlined,
  NotificationOutlined,
  QuestionOutlined,
  TeamOutlined,
  ClusterOutlined,
  HeatMapOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Affix, Layout as AntLayout, BackTop, Badge, Menu, Modal, Typography } from "antd";
import * as React from "react";
import { Suspense, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "src/assets/efficap-energie-logo.png";
import BackButton from "src/components/BackButton";
import ErrorBoundary from "src/components/ErrorBoundary";
import Loader from "src/components/Loader";
import Profile from "src/components/Profile";
import { appVersion } from "src/constants";
import { PermissionGroup, Permissions, hasPermission } from "src/lib/access-control";
import analytics from "src/lib/analytics";
import { useHealthZ, useJobAlerts, useUserRequiredActions } from "src/lib/hooks/api";
import { countJobAlerts } from "src/lib/job-alerts";
import BannerDisplay, { bannerColors } from "./BannerDisplay";
import ErrorBoundaryWithFallback from "./ErrorBoundaryWithFallback";
import SeasonStarter from "./SeasonStarter";

const { Header, Content, Footer, Sider } = AntLayout;

const MonitoringItem: React.FC<{}> = () => {
  const { data: jobAlerts } = useJobAlerts();

  return (
    <NavLink to="/monitoring">
      <MonitorOutlined />
      <span>Monitoring</span>
      <Badge offset={[6, -3]} count={countJobAlerts(jobAlerts)} />
    </NavLink>
  );
};

const RequiredActionsItem: React.FC = () => {
  const { data: requiredActions } = useUserRequiredActions();

  return (
    <>
      <CheckSquareOutlined />
      {<span>{`Actions requises ${requiredActions.heatingSeason ? "🚨" : ""}`}</span>}
    </>
  );
};

const RequiredActionModal: React.FC<{ showModal: boolean; setShowModal: (b: boolean) => void }> = ({
  showModal,
  setShowModal,
}) => {
  return (
    <Modal
      title={`Actions requises`}
      visible={showModal}
      width={"70%"}
      onOk={() => {
        setShowModal(false);
      }}
      onCancel={() => setShowModal(false)}
      okText="Me rappeler plus tard"
    >
      <SeasonStarter />
    </Modal>
  );
};

const EnvironmentBadge: React.FC = () => {
  const { data: healthz } = useHealthZ();

  if (healthz.data?.environment !== "dev") {
    return null;
  }

  return (
    <div
      style={{
        height: 50,
        backgroundColor: bannerColors.warning.color,
        color: "white",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      Vous êtes acuteuellement sur l'environnement <b>{healthz.data.environment}</b>
    </div>
  );
};

const Sidebar = React.forwardRef((_, ref) => {
  const location = useLocation();
  const { user } = useAuth0();
  const { data: requiredActions } = useUserRequiredActions();
  const [showRequirementsModal, setShowRequirementsModal] = React.useState<boolean>(false);

  useEffect(() => {
    let showRequirementsStatus = window.sessionStorage.getItem("show_requirements_status");
    if (!showRequirementsStatus && requiredActions.heatingSeason) {
      setShowRequirementsModal(true);
      window.sessionStorage.setItem("show_requirements_status", "true");
    }
  }, [setShowRequirementsModal, requiredActions]);

  const hasPermissionsUser = hasPermission(user, PermissionGroup.Users);
  const hasPermissionLiveData = hasPermission(user, Permissions.LiveDataRead);
  const hasPermissionMonitoring = hasPermission(user, Permissions.MonitorRead);
  const hasPermissionBannersCreate = hasPermission(user, Permissions.BannersCreate);
  const hasPermissionSitesCreate = hasPermission(user, Permissions.SitesCreate);

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      style={{ height: "100vh" }}
      // @ts-expect-error
      ref={ref}
    >
      <div className="logo" />
      <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
        <Menu.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
            <span>Accueil</span>
          </NavLink>
        </Menu.Item>
        {hasPermissionsUser && (
          <Menu.Item key="/users">
            <NavLink to="/users">
              <TeamOutlined />
              <span>Utilisateurs</span>
            </NavLink>
          </Menu.Item>
        )}
        {hasPermissionBannersCreate && (
          <Menu.Item key="/banners">
            <NavLink to="/banners">
              <NotificationOutlined />
              <span>Bannières</span>
            </NavLink>
          </Menu.Item>
        )}
        {hasPermissionLiveData && (
          <Menu.Item key="/live-data">
            <NavLink to="/live-data">
              <DatabaseOutlined />
              <span>Données Live</span>
            </NavLink>
          </Menu.Item>
        )}
        <Menu.Item key="/support">
          <NavLink to="/support">
            <QuestionOutlined />
            <span>Support</span>
          </NavLink>
        </Menu.Item>
        {hasPermissionMonitoring && (
          <Menu.Item key="/monitoring">
            <ErrorBoundaryWithFallback fallback={null}>
              <MonitoringItem />
            </ErrorBoundaryWithFallback>
          </Menu.Item>
        )}
        {hasPermissionMonitoring && (
          <Menu.Item key="/debug">
            <NavLink to="/debug">
              <CodeOutlined />
              <span>Debug</span>
            </NavLink>
          </Menu.Item>
        )}
        {
          <Menu.Item key="/requirements" onClick={() => setShowRequirementsModal(true)}>
            <Suspense fallback={<Loader />}>
              <ErrorBoundaryWithFallback fallback={null}>
                <RequiredActionsItem />
              </ErrorBoundaryWithFallback>
            </Suspense>
          </Menu.Item>
        }
        {hasPermissionSitesCreate && (
          <Menu.Item key="/deployment-session">
            <NavLink to="/deployment-session">
              <ClusterOutlined />
              <span>Déploiement sondes</span>
            </NavLink>
          </Menu.Item>
        )}
        {hasPermissionSitesCreate && (
          <Menu.Item key="/comfort-indicators">
            <NavLink to="/comfort-indicators">
              <HeatMapOutlined />
              <span>Indicateur de confort</span>
            </NavLink>
          </Menu.Item>
        )}
        {hasPermissionSitesCreate && (
          <Menu.Item key="/operations">
            <NavLink to="/operations">
              <SlidersOutlined />
              <span>Operations</span>
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
      <Suspense fallback={null}>
        <ErrorBoundaryWithFallback fallback={null}>
          <RequiredActionModal
            showModal={showRequirementsModal}
            setShowModal={setShowRequirementsModal}
          />
        </ErrorBoundaryWithFallback>
      </Suspense>
    </Sider>
  );
});

const Layout: React.FC = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    analytics.page({
      path: location.pathname,
      url: window.location.origin + location.pathname,
    });
  }, [location.pathname]);

  return (
    <>
      <AntLayout style={{ minHeight: "100vh" }}>
        <Affix>
          <Sidebar />
        </Affix>
        <AntLayout>
          <Header style={{ background: "#fff", padding: 0 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Link to="/">
                <img
                  src={logo}
                  alt="efficap energie logo"
                  style={{ height: "70px", margin: "0 1rem" }}
                />
              </Link>
              <Suspense fallback={null}>
                <Profile />
              </Suspense>
            </div>
          </Header>
          <Suspense fallback={null}>
            <EnvironmentBadge />
          </Suspense>
          <Suspense fallback={null}>
            <ErrorBoundaryWithFallback fallback={null}>
              <BannerDisplay />
            </ErrorBoundaryWithFallback>
          </Suspense>

          <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
            <div style={{ padding: 16, background: "#fff", minHeight: "100%" }}>
              <BackButton />
              <ErrorBoundary>
                <Suspense fallback={<Loader />}>{children}</Suspense>
              </ErrorBoundary>
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            <Typography.Text>EFFICAP-&eacute;nergie - {appVersion}</Typography.Text>
          </Footer>
        </AntLayout>
        <BackTop />
      </AntLayout>
    </>
  );
};

export default Layout;
