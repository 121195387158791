import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Typography,
  notification,
} from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { ColumnType } from "antd/lib/table";
import * as React from "react";
import { formItemLayout } from "src/constants";
import { Permissions, hasPermission } from "src/lib/access-control";
import type {
  HeatingDistributionCircuit,
  HeatingDistributionCircuitRole,
  HeatingProductionUnit,
  InstrumentGroup,
  Site,
} from "src/lib/api";
import {
  useSaveSiteHeatingDistributionCircuit,
  useSiteDistributionCircuitInstrumentGroupUpdate,
  useSiteHeatingDistributionCircuits,
  useSiteHeatingProductionUnits,
  useSiteInstrumentGroups,
  useSitePlcs,
  useUpdateSiteHeatingDistributionCircuit,
} from "src/lib/hooks/api";
import { useWindowWidth } from "src/lib/hooks/window-width";
import { withNotify } from "src/lib/notify";
import { showIf, sorter } from "src/lib/tableutils";
import { sortCircuits } from "src/lib/utils";
import DistributionDataPointConfigurationTable from "./DistributionDataPointConfiguration";
import Loader from "./Loader";
import RegulationParametersDashboardWithFallback from "./RegulationParametersDashboard";

function getHeatingDistributionCircuitRole(type: HeatingDistributionCircuitRole) {
  switch (type) {
    case "domestic_hot_water":
      return "Eau chaude sanitaire";
    case "heating":
      return "Chauffage";
    default:
      return "";
  }
}

const hdcTypes = [
  {
    value: "Eau chaude sanitaire",
    key: "domestic_hot_water",
  },
  {
    value: "Chauffage",
    key: "heating",
  },
];

const HdcMetadataForm: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  return (
    <>
      <Form layout="horizontal" form={form} size="small">
        <div
          style={{ display: "flex", justifyContent: "end", alignContent: "center", width: "100%" }}
        >
          <div style={{ fontStyle: "italic", marginRight: 20 }}>Points fixes LDC</div>
          <Form.Item
            name={["metadata", "heating_curve_point_x1"]}
            label="X1"
            style={{ fontStyle: "italic", marginRight: 10 }}
          >
            <Input placeholder={"-7"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
          <Form.Item
            name={["metadata", "heating_curve_point_y1"]}
            label="Y1"
            style={{ fontStyle: "italic" }}
          >
            <Input placeholder={"-7"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Form.Item
            name={["metadata", "heating_curve_point_x2"]}
            label="X2"
            style={{ fontStyle: "italic", marginRight: 10 }}
          >
            <Input placeholder={"0"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
          <Form.Item
            name={["metadata", "heating_curve_point_y2"]}
            label="Y2"
            style={{ fontStyle: "italic" }}
          >
            <Input placeholder={"0"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Form.Item
            name={["metadata", "heating_curve_point_x3"]}
            label="X3"
            style={{ fontStyle: "italic", marginRight: 10 }}
          >
            <Input placeholder={"10"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
          <Form.Item
            name={["metadata", "heating_curve_point_y3"]}
            label="Y3"
            style={{ fontStyle: "italic" }}
          >
            <Input placeholder={"0"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Form.Item
            name={["metadata", "heating_curve_point_x4"]}
            label="X4"
            style={{ fontStyle: "italic", marginRight: 10 }}
          >
            <Input placeholder={"20"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
          <Form.Item
            name={["metadata", "heating_curve_point_y4"]}
            label="Y4"
            style={{ fontStyle: "italic" }}
          >
            <Input placeholder={"0"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Form.Item
            name={["metadata", "water_flow_set_point_temperature"]}
            label="Consigne température (fixe)"
            style={{ fontStyle: "italic" }}
          >
            <Input placeholder={"42"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Form.Item
            name={["metadata", "v3v_regulation"]}
            label="Contrôle régulation v3v (fixe)"
            style={{ fontStyle: "italic" }}
          >
            <Input placeholder={"1"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Form.Item
            name={["metadata", "no_heating_temperature"]}
            label="Température de non chauffage (fixe)"
            style={{ fontStyle: "italic" }}
          >
            <Input placeholder={"20"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Form.Item
            name={["metadata", "heating_curve_day_correction"]}
            label="Correction jour (fixe)"
            style={{ fontStyle: "italic" }}
          >
            <Input placeholder={"0"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Form.Item
            name={["metadata", "heating_curve_night_correction"]}
            label="Correction nuit (fixe)"
            style={{ fontStyle: "italic" }}
          >
            <Input placeholder={"0"} style={{ width: 100, textAlign: "right" }} />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

const HeatingDistributionCircuitForm: React.FC<{
  form: FormInstance<any>;
  site: Site;
  siteHpus: HeatingProductionUnit[];
}> = ({ form, site, siteHpus }) => {
  const { data: plcs } = useSitePlcs(site.slug);
  const hdcId: number = form.getFieldValue("id");
  const instrumentGroupsRes = useSiteInstrumentGroups(site.slug);
  const instrumentGroups = instrumentGroupsRes.status === "success" ? instrumentGroupsRes.data : [];
  const referenceGroups = instrumentGroups.filter(
    (ig: InstrumentGroup) => ig?.category === "reference",
  );

  const hpuOptions = siteHpus.map((hpu: HeatingProductionUnit) => ({
    value: hpu.name,
    key: hpu.id,
  }));

  return (
    <Form name="hdc-form" {...formItemLayout} form={form}>
      <Form.Item name="id" hidden />
      <Form.Item name="name" label="Nom du circuit de distribution" rules={[{ required: true }]}>
        <Input placeholder={"Circuit 1 Bat. Nord..."} />
      </Form.Item>
      <Form.Item name="comments" label="Commentaires">
        <Input placeholder={"Circuit passant à l'extéireur du bât..."} />
      </Form.Item>
      <Form.Item
        name="heatingProductionUnitId"
        label="Unité de production associée"
        rules={[{ required: true }]}
      >
        <Select placeholder={"Choisir une option"}>
          {hpuOptions.map((ho) => {
            return <Select.Option value={ho.key}>{ho.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item name="role" label="Role du circuit" rules={[{ required: true }]}>
        <Select placeholder={"Choisir une option"}>
          {hdcTypes.map((ht) => {
            return <Select.Option value={ht.key}>{ht.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Groupe de référence" name="instrumentGroupId" hasFeedback={true}>
        <Select placeholder={"Associer un groupe de référence"} allowClear>
          {referenceGroups.map((ig: InstrumentGroup) => {
            return <Select.Option value={ig.id}>{ig.name}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item name="" label={<Typography.Text strong>Métadonnées</Typography.Text>}>
        <HdcMetadataForm form={form} />
      </Form.Item>
      <Space direction="vertical" style={{ width: "100%" }}>
        <DistributionDataPointConfigurationTable
          siteSlug={site.slug}
          hdcId={hdcId}
          sitePlcs={plcs}
        />
        <React.Suspense fallback={<Loader />}>
          <RegulationParametersDashboardWithFallback site={site} hdcid={hdcId} />
        </React.Suspense>
      </Space>
    </Form>
  );
};

const HeatingDistributionCircuitsTab: React.FC<{ site: Site }> = ({ site }) => {
  const { data: hpus } = useSiteHeatingProductionUnits(site.slug);
  const { data: hdcs } = useSiteHeatingDistributionCircuits(site.slug);

  const { user } = useAuth0();
  const [form] = useForm();

  const { isWideScreen } = useWindowWidth();

  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);

  const { mutateAsync: saveHeatingDistributionCircuit } = useSaveSiteHeatingDistributionCircuit(
    site.slug,
  );
  const { mutateAsync: updateHeatingDistributionCircuit } = useUpdateSiteHeatingDistributionCircuit(
    site.slug,
  );
  const { mutateAsync: saveHeatingDistributionCircuitInstrumentGroup } =
    useSiteDistributionCircuitInstrumentGroupUpdate(site.slug);

  function onAddHdc() {
    form.setFieldsValue({ id: 0, metadata: {} });
    setIsModalVisible(true);
  }

  const sortedHdcs = sortCircuits(hdcs);

  function onSaveHdc() {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(async (hdc: HeatingDistributionCircuit) => {
        if (hdc.id === 0) {
          await withNotify(
            saveHeatingDistributionCircuit(hdc),
            "Le circuit de distribution a été créé avec succès",
            "Une erreur est survenue lors de la création du circuit de distribution.",
          );
        } else {
          await withNotify(
            updateHeatingDistributionCircuit({
              ...hdc,
              id: hdc.id,
            }),
            "Le circuit de distribution a été mis à jour avec succès",
            "Une erreur est survenue lors de la mise à jour du circuit de distribution",
          );

          await withNotify(
            saveHeatingDistributionCircuitInstrumentGroup({
              hdcId: hdc.id,
              instrumentGroupId: hdc.instrumentGroupId,
            }),
            "Le groupe de référence du circuit a bien été mis à jour.",
            "Une erreur est survenue lors de la mise à jour du groupe de référence du circuit.",
          );
        }
        resetForm();
      })
      .catch(() => {
        notification.warning({
          message: `La modification ne peut pas être enregistrée car certains champs ne respectent pas les critères demandés.`,
        });
        setConfirmLoading(false);
      });
  }

  function resetForm() {
    setIsModalVisible(false);
    setConfirmLoading(false);
    form.resetFields();
  }

  const columns: ColumnType<HeatingDistributionCircuit>[] = [
    {
      title: "Nom du circuit",
      dataIndex: "name",
      key: "name",
      sorter: sorter("name", "string"),
    },
    {
      title: "Commentaires",
      dataIndex: "comments",
      key: "comments",
    },
    {
      title: "Rôle du circuit",
      dataIndex: "role",
      key: "role",
      render: (type: HeatingDistributionCircuitRole) => getHeatingDistributionCircuitRole(type),
    },
    {
      title: "Unité de production",
      dataIndex: "heatingProductionUnitId",
      key: "heatingProductionUnitId",
      render: (heatingProductionUnitId: number) => {
        const relevantHpu = hpus.find(
          (hpu: HeatingProductionUnit) => hpu.id === heatingProductionUnitId,
        );
        return relevantHpu?.name ?? "";
      },
    },
    ...showIf<HeatingDistributionCircuit>(
      () => hasPermission(user, Permissions.HeatingInstallationsUpdate),
      {
        title: "Edition",
        key: "edition",
        responsive: ["lg" as const],
        width: 120,
        render: (record: HeatingDistributionCircuit) => (
          <Button
            type="link"
            disabled={!hasPermission(user, Permissions.HeatingInstallationsUpdate)}
            icon={<EditOutlined />}
            onClick={() => {
              form.setFieldsValue(record);
              setIsModalVisible(true);
            }}
          >
            Modifier
          </Button>
        ),
      },
    ),
    // not implementing deletion yet
  ];

  return (
    <>
      <Card title="Circuits de distribution présents sur le site">
        <Table dataSource={sortedHdcs} columns={columns} pagination={false} />
        <div style={{ justifyContent: "center", display: "flex", margin: 20 }}>
          <Button icon={<PlusOutlined />} type="link" onClick={onAddHdc}>
            Ajouter un circuit de distribution
          </Button>
        </div>
      </Card>
      <Modal
        visible={isModalVisible}
        title={"Enregistrer un circuit de distribution"}
        okText={"Enregistrer"}
        onCancel={resetForm}
        onOk={onSaveHdc}
        width={isWideScreen ? 1100 : "50%"}
        forceRender={false}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !hasPermission(user, Permissions.SitesUpdate) }}
      >
        <div style={{ marginBottom: 20 }}>
          <HeatingDistributionCircuitForm form={form} site={site} siteHpus={hpus} />
        </div>
      </Modal>
    </>
  );
};

export default HeatingDistributionCircuitsTab;
