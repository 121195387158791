// exported only for testing :(.
import { ComfortRange } from "src/lib/api";
import { colors } from "src/constants";

export function temperatureMeasurementColor(
  comfortRange: ComfortRange,
  measurement: number,
): { name: string; light: string; medium: string; dark: string } | undefined {
  const { lower, upper } = comfortRange;
  let color;

  if (measurement < lower - 1) {
    color = colors.purple;
  } else if (measurement < lower && measurement >= lower - 1) {
    color = colors.blue;
  } else if (lower <= measurement && measurement <= upper) {
    color = colors.green;
  } else if (measurement <= upper + 1 && measurement >= upper) {
    color = colors.orange;
  } else if (measurement > upper + 1) {
    color = colors.red;
  }

  return color as { name: string; light: string; medium: string; dark: string };
}

export function co2MeasurementColor(
  measurement: number,
): { name: string; light: string; medium: string; dark: string } | undefined {
  let color;
  const step1 = 400;
  const step2 = 1200;
  const step3 = 1800;

  if (measurement < step1) {
    color = colors.green;
  } else if (measurement >= step1 && measurement < step2) {
    color = colors.orange;
  } else if (measurement >= step2 && measurement < step3) {
    color = colors.red;
  } else if (measurement >= step3) {
    color = colors.purple;
  }

  return color as { name: string; light: string; medium: string; dark: string };
}
