import * as React from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import moment from "moment";
import type {
  ComfortRange,
  DJUSaving,
  EnergyConsumption,
  EnergyMeter,
  HeatingDistributionCircuit,
  HeatingDistributionCircuitMeasurement,
  HeatingProductionUnitMeasurement,
  Instrument,
  InstrumentGroup,
  InstrumentGroupMeasurement,
  Measurement,
  SchedulingEvent,
  SchedulingMeasurement,
  SchedulingModeApi,
  WeatherData,
} from "src/lib/api";
import { renderUnitOfMeasure, UnitOfMeasure } from "src/lib/api";
import { Divider, Empty } from "antd";
import {
  chartLeftAxisWidth,
  colorKey,
  colorKeyList,
  colors,
  comfortRangeDefaultLower,
  comfortRangeDefaultTarget,
  comfortRangeDefaultUpper,
} from "src/constants";
import {
  interpolateData,
  isDisplayModeCO2,
  isDisplayModeConsumption,
  isDisplayModeHistoric,
  isDisplayModeHumidity,
  isDisplayModeMeasurement,
  isDisplayModeSchedulingMeasurement,
  isDisplayModeTemperature,
  isInstrumentGroup,
} from "src/components/Chart/interpolate-data";
import { LegendDisplayMode, MeasurementDisplayMode } from "../MeasurementDisplayModeSelector";
import { avg } from "src/lib/math";
import { hasPermission, Permissions } from "src/lib/access-control";
import { useAuth0 } from "@auth0/auth0-react";
import RenderIf from "../RenderIf";
import { interpolate } from "src/lib/interpolation";

// COLORS[index % COLORS.length]
const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FF8042",
  "#3b2ba0",
  "#FFBB28",
  "#912e2c",
  "#650d99",
  "#ba5349",
];

const unitCelsius = "°C";
const unitPercent = "%";
const unitPPM = "ppm";
export const unitCM = "m³ vapeur";
export const unitCMShort = "m³";
export const unitMWh = "MWh";

export enum WeatherChartTexts {
  AirTemperature = "Température de l'air",
  DirectNormalRadiation = "Radiation du soleil",
  Precipitation = "Précipitations",
}

export function datetimeFormatter(d: string): string {
  return moment(d).format("lll");
}

export function dateFormatter(d: string) {
  return moment(d).format("ll");
}

export function getLegend(
  type: string,
  instrument: Instrument | InstrumentGroup,
  legendDisplayModes?: LegendDisplayMode[],
) {
  const space = " ";
  let str = type + space + "-" + space;
  if (legendDisplayModes?.includes(LegendDisplayMode.Name)) {
    str += instrument.name + space;
  }
  if (legendDisplayModes?.includes(LegendDisplayMode.Comment)) {
    str += "[" + instrument.comments + "]";
  }
  return str;
}

export function weatherDataFormatter(value: number, name: string) {
  switch (name) {
    case WeatherChartTexts.AirTemperature:
      return value.toFixed(1);
    case WeatherChartTexts.DirectNormalRadiation:
      return value.toFixed(2);
    case WeatherChartTexts.Precipitation:
      return value.toFixed(1);
    default:
      return "";
  }
}

export enum ChartDisplayMode {
  Measurement,
  Consumption,
  Historic,
  SchedulingMeasurement,
}

function consumptionShortUnitOfMeasure(uom: UnitOfMeasure): string {
  switch (uom) {
    case UnitOfMeasure.CubicMeters:
      return unitCM;
    case UnitOfMeasure.MegaWattHour:
      return unitMWh;
    default:
      return "";
  }
}

function consumptionShorterUnitOfMeasure(uom: UnitOfMeasure): string {
  switch (uom) {
    case UnitOfMeasure.CubicMeters:
      return unitCMShort;
    case UnitOfMeasure.MegaWattHour:
      return unitMWh;
    default:
      return "";
  }
}

function avgTempForReferenceGroup(key: string, data: { timestamp: number; mwhDju: number }[]) {
  return avg(data.map((d: any) => d[key]).filter((d: any) => d !== undefined)).toFixed(2);
}

export interface ChartMeasurementsProps {
  kind: ChartDisplayMode.Measurement;
  measurementDisplayModes: MeasurementDisplayMode[];
  instruments: (Instrument | InstrumentGroup)[];
  measurements: Measurement[];
  comfortRange?: ComfortRange;
  weatherData: WeatherData;
  legendDisplayModes?: LegendDisplayMode[];
  precisionMode?: boolean;
}

export interface ChartConsumptionProps {
  kind: ChartDisplayMode.Consumption;
  distributionCircuits: HeatingDistributionCircuit[];
  djuSavings: DJUSaving[];
  energyMeters: EnergyMeter[];
  consumptions: EnergyConsumption[];
  referenceGroups: InstrumentGroup[];
  referenceGroupsMeasurements: InstrumentGroupMeasurement[];
  weatherData: WeatherData;
}

export interface ChartHistoricProps {
  kind: ChartDisplayMode.Historic;
  weatherData: WeatherData;
  hdcMeasurements: HeatingDistributionCircuitMeasurement[];
  hpuMeasurements: HeatingProductionUnitMeasurement[];
}

export interface ChartSchedulingProps {
  kind: ChartDisplayMode.SchedulingMeasurement;
  data: SchedulingMeasurement[];
  eventsData: SchedulingEvent[];
  degreeMargin: number;
  weatherData: WeatherData;
}

export type ChartProps =
  | ChartMeasurementsProps
  | ChartConsumptionProps
  | ChartHistoricProps
  | ChartSchedulingProps;

function removeDuplicates(arr: any[]) {
  return arr.filter(function (value, index, array) {
    return array.indexOf(value) === index;
  });
}

function getBasicTicks(data: { timestamp: number }[]) {
  return data.length > 2
    ? [
        data[0].timestamp,
        data[Math.floor(data.length / 2)].timestamp,
        data[data.length - 1].timestamp,
      ]
    : undefined;
}

function getDailyTicks(data: { timestamp: number }[]) {
  const dates = data.map((d) => {
    const date = new Date(d.timestamp);
    return date.setUTCHours(0, 0, 0, 0);
  });
  return removeDuplicates(dates);
}

const Chart: React.FC<ChartProps> = (props) => {
  const data = interpolateData(props).map((elt: { timestamp: string }) => ({
    ...elt,
    timestamp: moment(elt?.timestamp).valueOf(),
  }));
  const { user } = useAuth0();

  const hasDjuPermissions = hasPermission(user, Permissions.SavingsRead);

  return (
    <div style={{ height: "100%" }} id="chart">
      {isDisplayModeMeasurement(props) && (
        <MeasurementChart {...(props as unknown as ChartMeasurementsProps)} data={data} />
      )}
      {isDisplayModeConsumption(props) && (
        <>
          <RenderIf
            predicate={props.consumptions.length > 0}
            fallback={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Pas de données de consommation. Données affichées : DJU, température et météo."
              />
            }
          >
            <EnergyConsumptionChart {...props} data={data} />
          </RenderIf>

          <RenderIf predicate={hasDjuPermissions}>
            <DJUSavedChart {...props} data={data} />
          </RenderIf>

          <RenderIf predicate={props.consumptions.length > 0}>
            <DJUConsumptionChart {...props} data={data} />
          </RenderIf>

          <RenderIf
            predicate={
              props.referenceGroups.length > 0 && props.referenceGroupsMeasurements.length > 0
            }
          >
            <ReferenceGroupChart {...props} data={data} />
          </RenderIf>
        </>
      )}
      {isDisplayModeHistoric(props) && <HistoricChart {...props} data={data} />}
      {(isDisplayModeConsumption(props) ||
        isDisplayModeMeasurement(props) ||
        isDisplayModeHistoric(props)) && (
        <RenderIf predicate={props.weatherData.weather.length > 0}>
          <>
            <Divider plain={true}>Météo : {props.weatherData.weatherLocation?.name}</Divider>
            <WeatherChart {...props} data={data} />
          </>
        </RenderIf>
      )}
      {isDisplayModeSchedulingMeasurement(props) && (
        <>
          <SchedulingMeasurementChart {...(props as unknown as ChartSchedulingProps)} data={data} />
          <SchedulingModeChart {...(props as unknown as ChartSchedulingProps)} data={data} />
        </>
      )}
    </div>
  );
};

const MeasurementChart: React.FC<ChartMeasurementsProps & { data: { timestamp: number }[] }> = (
  props,
) => {
  const [lineStrokes, setLineStrokes] = React.useState<{ [k: string]: number }>({});
  return (
    <ResponsiveContainer height={400}>
      <ComposedChart
        data={props.data}
        margin={{ top: 5, right: -20, left: -20, bottom: 5 }}
        syncId="sync-charts"
      >
        <CartesianGrid strokeDasharray="10 10" />
        <XAxis
          dataKey="timestamp"
          interval="preserveStartEnd"
          tickFormatter={datetimeFormatter}
          ticks={getBasicTicks(props.data)}
          scale="time"
          type="number"
          domain={[(dataMin: number) => dataMin, (dataMax: number) => dataMax]}
        />
        <YAxis
          yAxisId="left"
          domain={["auto", "auto"]}
          allowDecimals={false}
          unit={unitCelsius}
          width={chartLeftAxisWidth}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          domain={["auto", "auto"]}
          unit={unitPercent}
          width={65}
        />
        {isDisplayModeCO2(props?.measurementDisplayModes) && (
          <YAxis
            yAxisId="co2"
            orientation="right"
            allowDecimals={false}
            unit={unitPPM}
            width={85}
          />
        )}
        <Tooltip
          labelFormatter={datetimeFormatter}
          itemSorter={(item) => (item?.value ? -item.value : -1)}
        />
        <Legend
          verticalAlign="top"
          onMouseOver={(i) => {
            if (props.instruments.length > 1) {
              const newLineStrokes = { ...lineStrokes };
              newLineStrokes[i.dataKey] = 2;
              setLineStrokes(newLineStrokes);
            }
          }}
          onMouseOut={(i) => {
            if (props.instruments.length > 1) {
              const newLineStrokes = { ...lineStrokes };
              newLineStrokes[i.dataKey] = 1;
              setLineStrokes(newLineStrokes);
            }
          }}
        />
        <ReferenceLine
          y={props.comfortRange?.lower ?? comfortRangeDefaultLower}
          label="min"
          yAxisId="left"
          ifOverflow="extendDomain"
          stroke="red"
          strokeDasharray="3 3"
        />
        <ReferenceLine
          y={props.comfortRange?.target ?? comfortRangeDefaultTarget}
          yAxisId="left"
          ifOverflow="extendDomain"
          stroke="green"
          strokeDasharray="3 3"
        />
        <ReferenceLine
          y={props.comfortRange?.upper ?? comfortRangeDefaultUpper}
          label="max"
          yAxisId="left"
          ifOverflow="extendDomain"
          stroke="red"
          strokeDasharray="3 3"
        />
        {isDisplayModeTemperature(props?.measurementDisplayModes) &&
          props.instruments.map((i, index) => (
            <Line
              key={`t-${i.name}`}
              name={getLegend("Température", i, props.legendDisplayModes)}
              type="monotone"
              yAxisId="left"
              dataKey={isInstrumentGroup(i) ? `ig-t-${i.id}` : `i-t-${i.id}`}
              stroke={COLORS[index]}
              strokeWidth={
                lineStrokes?.[isInstrumentGroup(i) ? `ig-t-${i.id}` : `i-t-${i.id}`] ?? 1
              }
              dot={false}
              connectNulls={props.precisionMode}
              unit={unitCelsius}
            />
          ))}
        {isDisplayModeHumidity(props?.measurementDisplayModes) &&
          props.instruments.map((i, index) => (
            <Line
              key={`h-${i.name}`}
              name={getLegend("Humidité", i, props.legendDisplayModes)}
              type="monotone"
              yAxisId="right"
              dataKey={isInstrumentGroup(i) ? `ig-h-${i.id}` : `i-h-${i.id}`}
              strokeWidth={
                lineStrokes?.[isInstrumentGroup(i) ? `ig-h-${i.id}` : `i-h-${i.id}`] ?? 1
              }
              stroke={COLORS[index + 4]}
              dot={false}
              connectNulls={props.precisionMode}
              unit={unitPercent}
            />
          ))}
        {isDisplayModeCO2(props?.measurementDisplayModes) &&
          props.instruments.map((i, index) => (
            <Line
              key={`co2-${i.name}`}
              name={getLegend("CO2", i, props.legendDisplayModes)}
              type="monotone"
              yAxisId="co2"
              dataKey={isInstrumentGroup(i) ? `ig-co2-${i.id}` : `i-co2-${i.id}`}
              stroke={COLORS[index + 5]}
              strokeWidth={
                lineStrokes?.[isInstrumentGroup(i) ? `ig-co2-${i.id}` : `i-co2-${i.id}`] ?? 1
              }
              dot={false}
              connectNulls={true}
              unit={unitPPM}
            />
          ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const EnergyConsumptionChart: React.FC<
  ChartConsumptionProps & { data: { timestamp: number }[] }
> = (props) => {
  const { user } = useAuth0();
  return (
    <ResponsiveContainer height={400}>
      <ComposedChart
        data={props.data}
        margin={{ top: 5, right: -20, left: -20, bottom: 5 }}
        syncId="sync-charts"
      >
        <CartesianGrid strokeDasharray="10 10" />
        <XAxis
          dataKey="timestamp"
          interval="preserveStartEnd"
          tickFormatter={dateFormatter}
          scale="time"
          type="number"
          ticks={getDailyTicks(props.data)}
          allowDuplicatedCategory={false}
          domain={[(dataMin: number) => dataMin, (dataMax: number) => dataMax]}
        />
        <YAxis
          yAxisId="left"
          domain={[0, "auto"]}
          allowDecimals={true}
          width={chartLeftAxisWidth}
          unit={consumptionShorterUnitOfMeasure(props.consumptions[0].unitOfMeasure)}
        />
        <YAxis
          yAxisId="dju"
          orientation="right"
          domain={[0, "auto"]}
          unit={unitCelsius}
          width={65}
        />
        <Tooltip labelFormatter={dateFormatter} />
        <Legend verticalAlign="top" height={65} />
        {isDisplayModeConsumption(props) &&
          props.energyMeters.map((em) => (
            <Area
              key={`c-${em.name}`}
              name={`Consommation - ${em.name}`}
              type="monotone"
              dataKey={`em-c-${em.id}`}
              stroke={colors.orange.dark}
              fill={colors.orange.medium}
              dot={true}
              connectNulls={true}
              unit={renderUnitOfMeasure(props.consumptions[0].unitOfMeasure)}
              yAxisId={props.kind === ChartDisplayMode.Consumption ? "left" : "right"}
            />
          ))}
        {isDisplayModeConsumption(props) && (
          <Line
            name="DJU 18 journalier"
            type="monotone"
            yAxisId="dju"
            connectNulls={true}
            dot={true}
            dataKey="hdd"
            stroke={colors.green.dark}
            strokeWidth={1.5}
            unit={unitCelsius}
          />
        )}
        {isDisplayModeConsumption(props) && hasPermission(user, Permissions.LiveDataRead) && (
          <Line
            name="DJU 18 horaire"
            type="monotone"
            yAxisId="dju"
            connectNulls={true}
            dot={true}
            dataKey="hourHdd"
            stroke={colors.purple.medium}
            strokeWidth={1.5}
            unit={unitCelsius}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const DJUSavedChart: React.FC<
  ChartConsumptionProps & { data: { timestamp: number; mwhDju: number }[] }
> = (props) => {
  return (
    <ResponsiveContainer height={400}>
      <ComposedChart
        data={props.data}
        margin={{ top: 5, right: -20, left: -20, bottom: 5 }}
        syncId="sync-charts"
      >
        <CartesianGrid strokeDasharray="10 10" />
        <XAxis
          dataKey="timestamp"
          interval="preserveStartEnd"
          tickFormatter={dateFormatter}
          scale="time"
          type="number"
          ticks={getDailyTicks(props.data)}
          allowDuplicatedCategory={false}
          domain={[(dataMin: number) => dataMin, (dataMax: number) => dataMax]}
        />
        <YAxis
          yAxisId="dju"
          domain={[0, "auto"]}
          allowDecimals={true}
          width={chartLeftAxisWidth}
          unit={unitCelsius}
        />
        <YAxis orientation="right" width={65} />
        <Tooltip labelFormatter={dateFormatter} />
        <Legend verticalAlign="top" height={65} />
        {isDisplayModeConsumption(props) &&
          props.distributionCircuits.map((dc, idx) => (
            <Line
              key={`djus-${dc.id}`}
              name={`DJU économisés théoriques - ${dc.name}`}
              type="monotone"
              dataKey={`djus-${dc.id}`}
              stroke={COLORS[idx]}
              dot={true}
              connectNulls={true}
              unit={unitCelsius}
              yAxisId={"dju"}
            />
          ))}
        {isDisplayModeConsumption(props) && (
          <Line
            name="DJU 18 journalier"
            type="monotone"
            yAxisId="dju"
            connectNulls={true}
            dot={true}
            dataKey="hdd"
            stroke={colors.green.dark}
            strokeWidth={1.5}
            unit={unitCelsius}
          />
        )}
        {isDisplayModeConsumption(props) && (
          <Line
            name="DJU 18 horaire"
            type="monotone"
            yAxisId="dju"
            connectNulls={true}
            dot={true}
            dataKey="hourHdd"
            stroke={colors.purple.medium}
            strokeWidth={1.5}
            unit={unitCelsius}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const DJUConsumptionChart: React.FC<
  ChartConsumptionProps & { data: { timestamp: number; mwhDju: number }[] }
> = (props) => {
  const mwhDjuAvg = avg(
    props.data
      .filter((d) => d.mwhDju !== undefined)
      .map((d: { timestamp: number; mwhDju: number }) => d.mwhDju),
  );

  return (
    <ResponsiveContainer height={400}>
      <ComposedChart
        data={props.data}
        margin={{ top: 5, right: -20, left: -20, bottom: 5 }}
        syncId="sync-charts"
      >
        <CartesianGrid strokeDasharray="10 10" />
        <XAxis
          dataKey="timestamp"
          interval="preserveStartEnd"
          tickFormatter={dateFormatter}
          ticks={getDailyTicks(props.data)}
          scale="time"
          type="number"
          domain={[(dataMin: number) => dataMin, (dataMax: number) => dataMax]}
        />
        <YAxis
          yAxisId="left"
          domain={[0, "auto"]}
          allowDecimals={true}
          width={chartLeftAxisWidth}
          unit={`${consumptionShorterUnitOfMeasure(props.consumptions[0].unitOfMeasure)}/Dju`}
        />
        <YAxis
          yAxisId="temperature"
          orientation="right"
          domain={["", "auto"]}
          unit={unitCelsius}
          width={65}
        />
        <Tooltip labelFormatter={datetimeFormatter} />
        <Legend verticalAlign="top" height={65} />
        {isDisplayModeConsumption(props) && (
          <>
            <Area
              name={`${consumptionShortUnitOfMeasure(
                props.consumptions[0].unitOfMeasure,
              )}/Dju (horaire)`}
              type="monotone"
              dataKey={"mwhDju"}
              stroke={colors.blue.dark}
              fill={colors.blue.medium}
              dot={true}
              connectNulls={true}
              unit={`${renderUnitOfMeasure(props.consumptions[0].unitOfMeasure)}/Dju`}
              yAxisId={props.kind === ChartDisplayMode.Consumption ? "left" : "right"}
            />
            <ReferenceLine
              y={mwhDjuAvg}
              yAxisId="left"
              label={
                <Label
                  value={`Valeur moyenne sur la période : ${mwhDjuAvg.toFixed(2)} 
                  ${consumptionShortUnitOfMeasure(props.consumptions[0].unitOfMeasure)}/Dju`}
                  fill={colors.blue.dark}
                  position="top"
                  style={{ fontSize: 11 }}
                />
              }
              stroke={colors.blue.dark}
              strokeDasharray="3 3"
            />
          </>
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const ReferenceGroupChart: React.FC<
  ChartConsumptionProps & { data: { timestamp: number; mwhDju: number }[] }
> = (props) => {
  const [lineStrokes, setLineStrokes] = React.useState<{ [k: string]: number }>({});
  return (
    <ResponsiveContainer height={400}>
      <ComposedChart
        data={props.data}
        margin={{ top: 5, right: -20, left: -20, bottom: 5 }}
        syncId="sync-charts"
      >
        <CartesianGrid strokeDasharray="10 10" />
        <XAxis
          dataKey="timestamp"
          interval="preserveStartEnd"
          tickFormatter={dateFormatter}
          ticks={getDailyTicks(props.data)}
          scale="time"
          type="number"
          domain={[(dataMin: number) => dataMin, (dataMax: number) => dataMax]}
        />
        <YAxis
          yAxisId="temperature"
          domain={["auto", "auto"]}
          unit={unitCelsius}
          allowDecimals={true}
          width={chartLeftAxisWidth}
        />
        <YAxis orientation="right" width={65} />
        <Tooltip labelFormatter={datetimeFormatter} />
        <Legend
          verticalAlign="top"
          height={65}
          onMouseOver={(i) => {
            if (props.data.length > 1) {
              const newLineStrokes = { ...lineStrokes };
              newLineStrokes[i.dataKey] = 2;
              setLineStrokes(newLineStrokes);
            }
          }}
          onMouseOut={(i) => {
            if (props.data.length > 1) {
              const newLineStrokes = { ...lineStrokes };
              newLineStrokes[i.dataKey] = 1;
              setLineStrokes(newLineStrokes);
            }
          }}
        />
        {isDisplayModeConsumption(props) &&
          props.referenceGroups.map((rg, index) => (
            <>
              <Line
                key={`rgm-${rg.name}`}
                name={`Température ${rg.name}`}
                dataKey={`rgm-${rg.id}`}
                type="monotone"
                yAxisId="temperature"
                connectNulls={true}
                dot={false}
                stroke={colors[colorKeyList[index] as colorKey]?.dark}
                strokeWidth={lineStrokes?.[`rgm-${rg.id}`] ?? 1}
                unit={unitCelsius}
              />
              <ReferenceLine
                y={avgTempForReferenceGroup(`rgm-${rg.id}`, props.data)}
                yAxisId="temperature"
                label={
                  <Label
                    value={`Tmoy ${rg.name}: ${avgTempForReferenceGroup(
                      `rgm-${rg.id}`,
                      props.data,
                    )} °C`}
                    fill={colors[colorKeyList[index] as colorKey]?.dark}
                    position="top"
                    style={{ fontSize: 11, fontWeight: "bold" }}
                  />
                }
                stroke={colors[colorKeyList[index] as colorKey]?.dark}
                strokeDasharray="3 3"
              />
            </>
          ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const WeatherChart: React.FC<ChartProps & { data: { timestamp: number }[] }> = ({
  data,
  weatherData: { weather },
}) => {
  const domain: [number, "auto"] = [
    weather.reduce(
      (acc, curr) =>
        curr?.airTemperature && curr.airTemperature < acc ? curr.airTemperature : acc,
      0,
    ),
    "auto",
  ];

  return (
    <ResponsiveContainer height={400}>
      <ComposedChart
        data={data}
        margin={{ top: 5, right: -20, left: -20, bottom: 5 }}
        syncId="sync-charts"
      >
        <CartesianGrid strokeDasharray="10 10" />
        <XAxis
          dataKey="timestamp"
          interval="preserveStartEnd"
          ticks={getBasicTicks(data)}
          tickFormatter={datetimeFormatter}
          scale="time"
          type="number"
          domain={[(dataMin: number) => dataMin, (dataMax: number) => dataMax]}
        />
        <YAxis yAxisId="left" allowDecimals={false} domain={domain} width={chartLeftAxisWidth} />
        <YAxis yAxisId="right" orientation="right" allowDecimals={false} width={65} />
        <Tooltip
          labelFormatter={datetimeFormatter}
          formatter={(value: number, name: string) => weatherDataFormatter(value, name)}
        />
        <Legend verticalAlign="bottom" height={36} />
        <Line
          key="temp"
          yAxisId="left"
          name={WeatherChartTexts.AirTemperature}
          type="monotone"
          dataKey="airTemperature"
          stroke={colors.green.dark}
          dot={false}
          connectNulls={true}
          unit={unitCelsius}
        />
        <Area
          name={WeatherChartTexts.DirectNormalRadiation}
          yAxisId="right"
          type="monotone"
          connectNulls={true}
          dot={false}
          dataKey="directNormalRadiation"
          stroke={colors.gold.dark}
          fill={colors.gold.medium}
          unit={" W/m2"}
        />
        <Area
          name={WeatherChartTexts.Precipitation}
          yAxisId="left"
          type="monotone"
          connectNulls={true}
          dot={false}
          dataKey="precipitation"
          stroke={colors.blue.dark}
          fill={colors.blue.dark}
          unit={" mm"}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const SchedulingMeasurementChart: React.FC<ChartSchedulingProps> = ({
  data,
  eventsData,
  degreeMargin,
}) => {
  const areaData = eventsData.map((d) => ({
    timestamp: moment(d?.timestamp).valueOf(),
    isEvent: d.isEvent ? 1 : 0,
  }));

  const lineData = data.map((d) => ({
    ...d,
    comfortTemperatureLow: d.comfortTemperature - degreeMargin,
  }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        data={areaData}
        margin={{ top: 5, right: -20, left: -20, bottom: 5 }}
        syncId="sync-charts"
      >
        <CartesianGrid strokeDasharray="10 10" />
        <XAxis
          dataKey="timestamp"
          interval="preserveStartEnd"
          tickFormatter={datetimeFormatter}
          ticks={getBasicTicks(data.map((d) => ({ timestamp: parseInt(d.timestamp) })))}
          scale="time"
          type="number"
          domain={[(dataMin: number) => dataMin, (dataMax: number) => dataMax]}
        />
        <YAxis
          yAxisId="left"
          domain={["auto", "auto"]}
          allowDecimals={false}
          unit={unitCelsius}
          width={chartLeftAxisWidth}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          ticks={[0, 1]}
          tickFormatter={(t) => (t === 0 ? "Vide" : "Occupé")}
          width={65}
        />
        <Tooltip labelFormatter={datetimeFormatter} />
        <Legend verticalAlign="top" height={65} />
        <Area
          key={`isEvent`}
          type="monotone"
          name={`Etat d'occupation`}
          dataKey={`isEvent`}
          strokeWidth={0}
          stroke={colors.orange.medium}
          fill={colors.orange.medium}
          dot={false}
          connectNulls={true}
          yAxisId={"right"}
        />
        <Line
          data={lineData}
          key={`ambiantTemperature`}
          name={`Température ambiante`}
          type="monotone"
          yAxisId="left"
          dataKey={`ambiantTemperature`}
          stroke={COLORS[0]}
          dot={false}
          connectNulls={false}
          unit={unitCelsius}
        />
        <Line
          data={lineData}
          key={`comfortTemperature`}
          name={`Température confort cible`}
          type="monotone"
          yAxisId="left"
          dataKey={`comfortTemperature`}
          dot={false}
          connectNulls={false}
          unit={unitCelsius}
          stroke="green"
          strokeDasharray="3 3"
        />
        <Line
          data={lineData}
          key={`comfortTemperatureLow`}
          name={`Limite basse température de confort`}
          type="monotone"
          yAxisId="left"
          dataKey={`comfortTemperatureLow`}
          dot={false}
          connectNulls={false}
          unit={unitCelsius}
          stroke="red"
          strokeDasharray="3 3"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

function getModeValue(mode: SchedulingModeApi) {
  switch (mode) {
    case "Auto":
      return 0;
    case "Hors gel":
      return 1;
    case "Economie":
      return 2;
    case "Confort":
      return 3;
    default:
      return 0;
  }
}

function formatModeYAxis(tick: number) {
  switch (tick) {
    case 0:
      return "Auto";
    case 1:
      return "Fonction de protection";
    case 2:
      return "Economie";
    case 3:
      return "Confort";
    default:
      return "";
  }
}

function getIsActiveValue(isActive: boolean) {
  return isActive ? 1 : 0;
}

const SchedulingModeChart: React.FC<ChartSchedulingProps> = ({ data }) => {
  const chartData = data.map((d) => ({
    timestamp: d.timestamp,
    mode: getModeValue(d.regulationMode),
    isActive: getIsActiveValue(d.isActive),
  }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        data={chartData}
        margin={{ top: 5, right: -20, left: -20, bottom: 5 }}
        syncId="sync-charts"
      >
        <CartesianGrid strokeDasharray="10 10" />
        <XAxis
          dataKey="timestamp"
          interval="preserveStartEnd"
          tickFormatter={datetimeFormatter}
          ticks={getBasicTicks(data.map((d) => ({ timestamp: parseInt(d.timestamp) })))}
          scale="time"
          type="number"
          domain={[(dataMin: number) => dataMin, (dataMax: number) => dataMax]}
        />
        <YAxis
          yAxisId="left"
          ticks={[0, 1, 2, 3]}
          tickFormatter={formatModeYAxis}
          width={chartLeftAxisWidth}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          ticks={[0, 1]}
          tickFormatter={(t) => (t === 0 ? "Inactif" : "Actif")}
          width={65}
        />
        <Tooltip labelFormatter={datetimeFormatter} />
        <Legend verticalAlign="top" height={65} />
        <Area
          key={`isActive`}
          name={`Etat de la régulation`}
          type="step"
          yAxisId="right"
          dataKey={`isActive`}
          dot={false}
          connectNulls={false}
          stroke={colors.orange.medium}
          fill={colors.orange.medium}
          strokeWidth={0}
        />
        <Line
          key={`mode`}
          name={`Mode`}
          type="monotone"
          yAxisId="left"
          dataKey={`mode`}
          stroke={COLORS[1]}
          dot={false}
          connectNulls={false}
          strokeWidth={2}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export function measurementHistoryDataFormatter(value: number, name: string) {
  switch (name) {
    case ThreeWayValveTravelPercent:
      return value.toFixed(1);
    default:
      return value.toFixed(2);
  }
}

const ThreeWayValveTravelPercent = "V3V % d'ouverture";

const HistoricChart: React.FC<ChartHistoricProps & { data: { timestamp: number }[] }> = (props) => {
  const { user } = useAuth0();

  const formattedMeasurements = React.useMemo(() => {
    const refTimestamps = props.hdcMeasurements.map((m) => moment(m.timestamp).unix());
    const formattedHpuMeasurements: Record<string, number>[] = props.hpuMeasurements.map((m) => ({
      timestamp: moment(m.timestamp).unix(),
      waterFlowTemperature: m.waterFlowTemperature,
      waterReturnTemperature: m.waterReturnTemperature,
    }));
    const hpuInterpolated = interpolate(refTimestamps, formattedHpuMeasurements, "timestamp");

    return props.hdcMeasurements.map((m: HeatingDistributionCircuitMeasurement, i) => ({
      ...m,
      isOffsetApplied:
        !m.controls.isWatchdogReleased &&
        m.controls.predictiveLocalSwitch &&
        m.controls.predictiveRemoteSwitch &&
        m.controls.v3vRegulation !== false
          ? 1
          : 0,
      heatingProductionTemperature: hpuInterpolated.find(
        (hpu) => hpu.timestamp === moment(m.timestamp).unix(),
      )?.waterFlowTemperature,
      heatingProductionReturnTemperature: hpuInterpolated.find(
        (hpu) => hpu.timestamp === moment(m.timestamp).unix(),
      )?.waterReturnTemperature,
    }));
  }, [props.hdcMeasurements, props.hpuMeasurements]);

  const [lineStrokes, setLineStrokes] = React.useState<{ [k: string]: number }>({
    waterFlowTemperature: 1,
    waterFlowTemperatureApplied: 1,
    waterFlowSetPointTemperature: 1,
    waterReturnTemperature: 1,
    compositeOutsideTemperature: 1,
    offsetFromPLC: 1,
    threeWayValveTravelPercent: 1,
    heatingProductionTemperature: 1,
    heatingProductionReturnTemperature: 1,
  });

  const [selectedLines, setSelectedLines] = React.useState<string[]>([]);

  const getStrokeColor = (dataKey: string, targetColor: string) => {
    if (selectedLines.length === 0 || selectedLines.includes(dataKey)) {
      return targetColor;
    } else {
      return colors.grey.medium;
    }
  };

  return (
    <ResponsiveContainer height={400}>
      <ComposedChart
        // Disabling for now, as it's not working as expected
        // syncId={"sync-charts"}
        data={formattedMeasurements}
        margin={{ top: 5, right: -20, left: -20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="10 10" />
        <XAxis dataKey="timestamp" interval="preserveStartEnd" tickFormatter={datetimeFormatter} />
        <YAxis
          yAxisId="temperature"
          domain={[0, "auto"]}
          allowDecimals={false}
          width={chartLeftAxisWidth}
          unit={"°C"}
        />
        <YAxis
          yAxisId="percentage"
          orientation="right"
          domain={[0, "auto"]}
          allowDecimals={false}
          width={65}
          unit={"%"}
        />
        <YAxis
          yAxisId="applied"
          orientation="right"
          domain={[0, 8]}
          allowDecimals={false}
          width={0}
          ticks={[0, 1]}
          hide
        />
        <Tooltip
          labelFormatter={datetimeFormatter}
          formatter={(value: number, name: string) => measurementHistoryDataFormatter(value, name)}
        />
        <Legend
          verticalAlign="top"
          height={65}
          onMouseOver={(i) => {
            if (selectedLines.length > 0) return;
            const newLineStrokes = { ...lineStrokes };
            newLineStrokes[i.dataKey] = 2.7;
            setLineStrokes(newLineStrokes);
          }}
          onMouseOut={(i) => {
            if (selectedLines.length > 0) return;
            const newLineStrokes = { ...lineStrokes };
            newLineStrokes[i.dataKey] = 1;
            setLineStrokes(newLineStrokes);
          }}
          onClick={(i) => {
            if (selectedLines.includes(i.dataKey)) {
              setSelectedLines(selectedLines.filter((l) => l !== i.dataKey));
              setLineStrokes({ ...lineStrokes, [i.dataKey]: 1 });
            } else {
              setSelectedLines([...selectedLines, i.dataKey]);
              setLineStrokes({ ...lineStrokes, [i.dataKey]: 2.7 });
            }
          }}
        />
        {hasPermission(user, Permissions.HeatingInstallationsUpdate) && (
          <Area
            key={`isOffsetApplied`}
            type=""
            name={`Application offset`}
            dataKey={`isOffsetApplied`}
            strokeWidth={0}
            stroke={colors.orange.medium}
            fill={colors.orange.medium}
            dot={false}
            connectNulls={true}
            yAxisId={"applied"}
          />
        )}
        <Line
          name={"T° départ"}
          type="monotone"
          yAxisId="temperature"
          connectNulls={false}
          dot={false}
          dataKey="waterFlowTemperature"
          stroke={getStrokeColor("waterFlowTemperature", colors.red.dark)}
          strokeWidth={lineStrokes.waterFlowTemperature}
          unit={"°C"}
        />
        <Line
          name={"T° appliquée régulateur"}
          type="monotone"
          yAxisId="temperature"
          connectNulls={false}
          dot={false}
          dataKey="waterFlowTemperatureApplied"
          stroke={getStrokeColor("waterFlowTemperatureApplied", colors.pink.dark)}
          strokeWidth={lineStrokes.waterFlowTemperatureApplied}
          unit={"°C"}
        />
        <Line
          name={"T° retour primaire"}
          type="monotone"
          yAxisId="temperature"
          connectNulls={false}
          dot={false}
          dataKey="heatingProductionReturnTemperature"
          stroke={getStrokeColor("heatingProductionReturnTemperature", colors.blue.medium)}
          strokeWidth={lineStrokes.heatingProductionReturnTemperature}
          unit={"°C"}
        />
        <Line
          name={"T° de consigne départ"}
          type="monotone"
          yAxisId="temperature"
          connectNulls={false}
          dot={false}
          dataKey="waterFlowSetPointTemperature"
          stroke={getStrokeColor("waterFlowSetPointTemperature", colors.green.dark)}
          strokeWidth={lineStrokes.waterFlowSetPointTemperature}
          unit={"°C"}
        />
        <Line
          name={"T° retour"}
          type="monotone"
          yAxisId="temperature"
          connectNulls={false}
          dot={false}
          dataKey="waterReturnTemperature"
          stroke={getStrokeColor("waterReturnTemperature", colors.blue.dark)}
          strokeWidth={lineStrokes.waterReturnTemperature}
          unit={"°C"}
        />
        <Line
          name={"T° extérieure mélangée"}
          type="monotone"
          yAxisId="temperature"
          connectNulls={false}
          dot={false}
          dataKey="compositeOutsideTemperature"
          stroke={getStrokeColor("compositeOutsideTemperature", colors.purple.dark)}
          strokeWidth={lineStrokes.compositeOutsideTemperature}
          unit={"°C"}
        />
        {hasPermission(user, Permissions.HeatingInstallationsUpdate) && (
          <Line
            name={"Offset (automate)"}
            type="monotone"
            yAxisId="temperature"
            connectNulls={false}
            dot={false}
            dataKey="offsetFromPLC"
            stroke={getStrokeColor("offsetFromPLC", colors.brown.dark)}
            strokeWidth={lineStrokes.offsetFromPLC}
            unit={"°C"}
          />
        )}
        <Line
          name={ThreeWayValveTravelPercent}
          type="monotone"
          yAxisId="percentage"
          connectNulls={false}
          dot={false}
          dataKey="threeWayValveTravelPercent"
          stroke={getStrokeColor("threeWayValveTravelPercent", colors.gold.dark)}
          strokeWidth={lineStrokes.threeWayValveTravelPercent}
          unit={"%"}
        />
        <Line
          name={"T° de production"}
          type="monotone"
          yAxisId="temperature"
          connectNulls={false}
          dot={false}
          dataKey="heatingProductionTemperature"
          stroke={getStrokeColor("heatingProductionTemperature", colors.black.dark)}
          strokeWidth={lineStrokes.heatingProductionTemperature}
          unit={"°C"}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Chart;
