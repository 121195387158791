import { Divider, Empty, Tabs } from "antd";
import React, { Suspense } from "react";
import { HeatingDistributionCircuit, Site } from "src/lib/api";
import { useWindowWidth } from "src/lib/hooks/window-width";
import { ellipsis } from "src/lib/utils";
import ErrorBoundaryWithFallback from "./ErrorBoundaryWithFallback";
import HeatingDistributionMeasurementsHistory from "./HeatingCircuitMeasurementsHistory";
import HeatingCurveModificationsHistory from "./HeatingCurveModificationsHistory";
import Loader from "./Loader";
import RenderIf from "./RenderIf";

const CircuitHistoryTab: React.FC<{ site: Site; hdcs: HeatingDistributionCircuit[] }> = ({
  site,
  hdcs,
}) => {
  const isWideScreen = useWindowWidth();

  return (
    <RenderIf predicate={hdcs.length > 0} fallback={<Empty />}>
      <Tabs defaultActiveKey="1" tabPosition={isWideScreen ? "left" : "top"}>
        {hdcs.map((hdc: HeatingDistributionCircuit) => (
          <Tabs.TabPane
            tab={isWideScreen ? ellipsis(hdc.name, 30) : hdc.name}
            key={`heating-distribution-circuit-${hdc.id}`}
          >
            <Suspense fallback={<Loader />}>
              <ErrorBoundaryWithFallback fallback={<Empty />}>
                <HeatingDistributionMeasurementsHistory
                  site={site}
                  heatingDistributionCircuit={hdc}
                />
              </ErrorBoundaryWithFallback>
            </Suspense>
            <Divider />
            <Suspense fallback={<Loader />}>
              <ErrorBoundaryWithFallback fallback={<Empty />}>
                <HeatingCurveModificationsHistory siteSlug={site.slug} hdcid={hdc.id} />
              </ErrorBoundaryWithFallback>
            </Suspense>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </RenderIf>
  );
};

export default CircuitHistoryTab;
