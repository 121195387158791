import moment from "moment";

interface TemperatureData {
  timestamp: string;
  temperature: number;
}

const lerp = (a: number, b: number, t: number) => {
  return a + (b - a) * t;
};

function interpolate<T extends Record<string, number>>(ref: number[], data: T[], refKey: keyof T) {
  const dataSorted = data.sort((a, b) => a[refKey] - b[refKey]);
  const refSorted = ref.sort((a, b) => a - b);
  const res = refSorted.map((e) => {
    const d = dataSorted.find((d) => d[refKey] === e);
    if (d) {
      return d;
    }
    const dBefore = dataSorted
      .slice()
      .reverse()
      .find((d) => d[refKey] < e);
    const dAfter = dataSorted.find((d) => d[refKey] > e);
    if (dBefore && dAfter) {
      const t = (e - dBefore[refKey]) / (dAfter[refKey] - dBefore[refKey]);
      return Object.keys(dBefore).reduce((acc, key) => {
        if (key === refKey) {
          return {
            ...acc,
            [key]: e,
          };
        }
        return {
          ...acc,
          [key]: lerp(dBefore[key], dAfter[key], t),
        };
      }, {}) as T;
    }
    return {} as T;
  });

  return res;
}

const interpolateWeather = (data: TemperatureData[], weather: TemperatureData[]) => {
  const res = data.map((d) => {
    const w = weather.find((w) => w.timestamp === d.timestamp);
    if (w) {
      return { x: w.temperature, y: d.temperature, timestamp: d.timestamp };
    }
    const wBefore = weather.find((w) => moment(w.timestamp).isBefore(d.timestamp));
    const wAfter = weather.find((w) => moment(w.timestamp).isAfter(d.timestamp));
    if (wBefore && wAfter) {
      const t =
        moment(d.timestamp).diff(moment(wBefore.timestamp)) /
        moment(wAfter.timestamp).diff(moment(wBefore.timestamp));
      return {
        x: lerp(wBefore.temperature, wAfter.temperature, t),
        y: d.temperature,
        timestamp: d.timestamp,
      };
    }
    return { x: 0, y: d.temperature, timestamp: d.timestamp };
  });

  return res;
};

export { interpolateWeather, interpolate };
