import { useAuth0 } from "@auth0/auth0-react";
import { Card, Empty, Space, Typography } from "antd";
import moment from "moment";
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors } from "src/constants";
import { Permissions, hasPermission } from "src/lib/access-control";
import {
  HeatingDistributionCircuitCurve,
  HeatingDistributionCircuitHeatingCurveState,
  HeatingDistributionCircuitMeasurement,
} from "src/lib/api";
import {
  useSiteHeatingDistributionCircuitMeasurements,
  useSiteHeatingInstallationCircuitHeatingCurve,
  useSiteHeatingInstallationCircuitHeatingCurveHistory,
} from "src/lib/hooks/api";
import { latestMeasurement, sorterMeasurement } from "src/lib/utils";
import HeatingCurveControls from "./HeatingCurveControls";
import RenderIf from "./RenderIf";

function PointHighLight(
  xValue: number,
  heatingCurve: HeatingDistributionCircuitCurve,
  minX: number,
  maxY: number,
  color?: string,
  strokeDasharray?: string,
  decimals?: number,
) {
  return (
    <>
      <ReferenceLine
        name={`${xValue}-axis-projection-vertical`}
        stroke={color}
        strokeDasharray={strokeDasharray}
        segment={[
          { x: xValue, y: maxY },
          {
            x: xValue,
            y: computeTempWithHeatingLaw(xValue, heatingCurve),
          },
        ]}
        label={
          <Label
            content={renderLabel}
            value={xValue.toFixed(decimals)}
            rotate={-90}
            color={color}
          />
        }
      />
      <ReferenceLine
        name={`${xValue}-axis-projection-horizontal`}
        stroke={color}
        strokeDasharray={strokeDasharray}
        segment={[
          {
            x: minX,
            y: computeTempWithHeatingLaw(xValue, heatingCurve),
          },
          {
            x: xValue,
            y: computeTempWithHeatingLaw(xValue, heatingCurve),
          },
        ]}
        label={
          <Label
            content={renderLabel}
            value={computeTempWithHeatingLaw(xValue, heatingCurve)?.toFixed(2)}
            color={color}
          />
        }
      />
    </>
  );
}

export function computeSlope(y1?: number, y2?: number, x1?: number, x2?: number) {
  if (
    y1 !== undefined &&
    y1 !== null &&
    y2 !== undefined &&
    y2 !== null &&
    x1 !== undefined &&
    x1 !== null &&
    x2 !== undefined &&
    x2 !== null &&
    x1 - x2 !== 0
  ) {
    return (y1 - y2) / (x1 - x2);
  }

  return null;
}

export function computeTempWithHeatingLaw(
  outsideTemp: number,
  heatingCurve: HeatingDistributionCircuitCurve,
) {
  if (outsideTemp <= heatingCurve.x2) {
    const linearCoeff = (heatingCurve.y1 - heatingCurve.y2) / (heatingCurve.x1 - heatingCurve.x2);
    return heatingCurve.y1 + linearCoeff * (outsideTemp - heatingCurve.x1);
  } else {
    if (heatingCurve.x3 && heatingCurve.y3) {
      if (outsideTemp > heatingCurve.x2 && outsideTemp <= heatingCurve.x3) {
        const linearCoeff =
          (heatingCurve.y2 - heatingCurve.y3) / (heatingCurve.x2 - heatingCurve.x3);
        return heatingCurve.y2 + linearCoeff * (outsideTemp - heatingCurve.x2);
      } else {
        if (heatingCurve.x4 && heatingCurve.y4) {
          const linearCoeff =
            (heatingCurve.y3 - heatingCurve.y4) / (heatingCurve.x3 - heatingCurve.x4);
          return heatingCurve.y3 + linearCoeff * (outsideTemp - heatingCurve.x3);
        } else {
          const linearCoeff =
            (heatingCurve.y2 - heatingCurve.y3) / (heatingCurve.x2 - heatingCurve.x3);
          return heatingCurve.y2 + linearCoeff * (outsideTemp - heatingCurve.x2);
        }
      }
    } else {
      const linearCoeff = (heatingCurve.y1 - heatingCurve.y2) / (heatingCurve.x1 - heatingCurve.x2);
      return heatingCurve.y1 + linearCoeff * (outsideTemp - heatingCurve.x1);
    }
  }
}

function pointFormatter(value: any, name: any, props: any) {
  return props.payload.outsideTemperatureOrigin === "dynamic"
    ? [
        `(${props.payload.outsideTemperature.toFixed(
          1,
        )}, ${props.payload.waterFlowTemperature.toFixed(2)})`,
        "Point ",
      ]
    : [
        `([${props.payload.outsideTemperature.toFixed(
          1,
        )}], ${props.payload.waterFlowTemperature.toFixed(2)})`,
        "Point ",
      ];
}

const renderLabel = (props: any) => {
  const { viewBox, value, autoReverse } = props;
  const anchor = {
    x: viewBox.x + (autoReverse ? viewBox.width : 0),
    y: viewBox.height + viewBox.y,
  };

  const stringLength = value.toString().length * 8;

  return (
    <svg>
      <g transform={`rotate(${props.rotate} ${anchor.x} ${anchor.y})`}>
        <path
          d={`
          M ${anchor.x + (autoReverse ? 2 : -2)} ${anchor.y} 
          L ${anchor.x + (autoReverse ? 2 : -2)} ${anchor.y + 10} 
          L ${anchor.x + (autoReverse ? stringLength : -stringLength)} ${anchor.y + 10}
          L ${anchor.x + (autoReverse ? stringLength : -stringLength)} ${anchor.y - 10}
          L ${anchor.x + (autoReverse ? 2 : -2)} ${anchor.y - 10}
          `}
          fill={"white"}
          opacity={0.6}
        ></path>
        <text
          x={anchor.x - 3 + (autoReverse ? stringLength + 2 : 0)}
          y={anchor.y + 4}
          fontSize={12}
          fill={props.color}
          textAnchor="end"
          style={{ fontWeight: "bold" }}
        >
          {value}
        </text>
      </g>
    </svg>
  );
};

function computeXEqualY(heatingCurve: HeatingDistributionCircuitCurve, minX: number, maxX: number) {
  const xArray = Array.from({ length: (maxX - minX + 1) * 100 }, (_, index) => index / 100 + minX);

  let minDiff = 100;
  let xEqualy;
  xArray.forEach((x) => {
    let y = computeTempWithHeatingLaw(x, heatingCurve);

    if (Math.abs(y - x) < minDiff) {
      minDiff = Math.abs(y - x);
      xEqualy = x;
    }
  });

  return xEqualy as unknown as number | undefined;
}

// Used to complete line
interface HeatingCurveLineParameters {
  minX: number;
  maxX: number;
  yMinX: number;
  yMaxX: number;
  heatingCurveMinX: number;
  heatingCurveMaxX: number;
  heatingCurveMinY: number;
  heatingCurveMaxY: number;
}

// Compute parameters to complete line on graph
function computeHeatingCurveLineParameters(
  hc: HeatingDistributionCircuitCurve | undefined,
  latestHeatingDistributionCircuitMeasurement: HeatingDistributionCircuitMeasurement | undefined,
): HeatingCurveLineParameters | undefined {
  if (!hc || !latestHeatingDistributionCircuitMeasurement) return undefined;

  const heatingCurveMinX = hc.x1;
  const heatingCurveMaxX = Math.max(hc.x2, hc?.x3 ?? hc.x1, hc?.x4 ?? hc.x1);

  const heatingCurveMinY = hc.y1;
  const heatingCurveMaxY = computeTempWithHeatingLaw(heatingCurveMaxX, hc);

  const circuitOutsideTemperature =
    latestHeatingDistributionCircuitMeasurement?.compositeOutsideTemperature ?? 0;

  const minX = Math.min(circuitOutsideTemperature - 5, heatingCurveMinX);
  const maxX = Math.max(circuitOutsideTemperature + 5, heatingCurveMaxX);
  const yMinX = computeTempWithHeatingLaw(minX, hc);
  const yMaxX = computeTempWithHeatingLaw(maxX, hc);

  return {
    minX,
    maxX,
    yMinX,
    yMaxX,
    heatingCurveMinX,
    heatingCurveMaxX,
    heatingCurveMinY,
    heatingCurveMaxY,
  };
}

const HeatingCurve: React.FC<{
  slug: string;
  heatingDistributionCircuitId: number;
  editedHeatingCurveState?: HeatingDistributionCircuitHeatingCurveState;
}> = ({ slug, heatingDistributionCircuitId, editedHeatingCurveState }) => {
  const { data: heatingCurve } = useSiteHeatingInstallationCircuitHeatingCurve(
    slug,
    heatingDistributionCircuitId,
  );
  const { user } = useAuth0();
  const latestHeatingCurve = heatingCurve.sort(sorterMeasurement)?.[0];
  const { data: measurements } = useSiteHeatingDistributionCircuitMeasurements(
    slug,
    heatingDistributionCircuitId,
    moment().startOf("day").unix(),
  );
  const latestHeatingDistributionCircuitMeasurement = latestMeasurement(measurements);

  const { data: heatingCurveModifications } = useSiteHeatingInstallationCircuitHeatingCurveHistory(
    slug,
    heatingDistributionCircuitId.toString(),
  );

  if (!latestHeatingCurve) {
    return (
      <Card title={"Loi de chauffe"}>
        <ResponsiveContainer height={400}>
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Empty />
          </div>
        </ResponsiveContainer>
      </Card>
    );
  }

  const formattedHeatingCurve = [
    {
      outsideTemperatureOrigin: latestHeatingCurve.x1_origin,
      outsideTemperature: latestHeatingCurve.x1,
      waterFlowTemperature: latestHeatingCurve.y1,
    },
    {
      outsideTemperatureOrigin: latestHeatingCurve.x2_origin,
      outsideTemperature: latestHeatingCurve.x2,
      waterFlowTemperature: latestHeatingCurve.y2,
    },
  ];

  if (latestHeatingCurve.x3 && latestHeatingCurve.y3 && latestHeatingCurve.x3_origin) {
    formattedHeatingCurve.push({
      outsideTemperatureOrigin: latestHeatingCurve.x3_origin,
      outsideTemperature: latestHeatingCurve.x3,
      waterFlowTemperature: latestHeatingCurve.y3,
    });
  }

  if (latestHeatingCurve.x4 && latestHeatingCurve.y4 && latestHeatingCurve.x4_origin) {
    formattedHeatingCurve.push({
      outsideTemperatureOrigin: latestHeatingCurve.x4_origin,
      outsideTemperature: latestHeatingCurve.x4,
      waterFlowTemperature: latestHeatingCurve.y4,
    });
  }

  const heatingCurveMinX = latestHeatingCurve.x1;
  const heatingCurveMaxX = Math.max(
    latestHeatingCurve.x2,
    latestHeatingCurve?.x3 ?? latestHeatingCurve.x1,
    latestHeatingCurve?.x4 ?? latestHeatingCurve.x1,
  );

  const heatingCurveMinY = latestHeatingCurve.y1;
  const heatingCurveMaxY = computeTempWithHeatingLaw(heatingCurveMaxX, latestHeatingCurve);

  const circuitOutsideTemperature =
    latestHeatingDistributionCircuitMeasurement?.compositeOutsideTemperature ?? 0;
  const waterFlowSetPointTemperature =
    latestHeatingDistributionCircuitMeasurement?.waterFlowSetPointTemperature ?? 0;

  const minX = Math.min(circuitOutsideTemperature - 5, heatingCurveMinX);
  const maxX = Math.max(circuitOutsideTemperature + 5, heatingCurveMaxX);
  const yMinX = computeTempWithHeatingLaw(minX, latestHeatingCurve);
  const yMaxX = computeTempWithHeatingLaw(maxX, latestHeatingCurve);

  const slope1 = computeSlope(
    latestHeatingCurve.y1,
    latestHeatingCurve.y2,
    latestHeatingCurve.x1,
    latestHeatingCurve.x2,
  );
  const slope2 = computeSlope(
    latestHeatingCurve.y2,
    latestHeatingCurve.y3,
    latestHeatingCurve.x2,
    latestHeatingCurve.x3,
  );
  const slope3 = computeSlope(
    latestHeatingCurve.y3,
    latestHeatingCurve.y4,
    latestHeatingCurve.x3,
    latestHeatingCurve.x4,
  );

  // Checking x = y over large x temperature domain (-20, 60) to be sure to find the point
  const xEqualy = computeXEqualY(latestHeatingCurve, -20, 60);

  const editedHeatingCurve: HeatingDistributionCircuitCurve | undefined = editedHeatingCurveState
    ? {
        id: 0,
        timestamp: "",
        noHeatingTemperature: 0,
        heatingDistributionId: 0,
        x1: editedHeatingCurveState.x1,
        x1_origin: editedHeatingCurveState.x1_origin,
        y1: editedHeatingCurveState.y1,
        x2: editedHeatingCurveState.x2,
        x2_origin: editedHeatingCurveState.x2_origin,
        y2: editedHeatingCurveState.y2,
        x3: editedHeatingCurveState.x3,
        x3_origin: editedHeatingCurveState.x3_origin,
        y3: editedHeatingCurveState.y3,
        x4: editedHeatingCurveState.x4,
        x4_origin: editedHeatingCurveState.x4_origin,
        y4: editedHeatingCurveState.y4,
      }
    : undefined;

  const formattedEditedHeatingCurve = [
    {
      outsideTemperatureOrigin: editedHeatingCurve?.x1_origin,
      outsideTemperature: editedHeatingCurve?.x1,
      waterFlowTemperature: editedHeatingCurve?.y1,
    },
    {
      outsideTemperatureOrigin: editedHeatingCurve?.x2_origin,
      outsideTemperature: editedHeatingCurve?.x2,
      waterFlowTemperature: editedHeatingCurve?.y2,
    },
  ];

  if (editedHeatingCurve?.x3 && editedHeatingCurve?.y3 && editedHeatingCurve?.x3_origin) {
    formattedEditedHeatingCurve.push({
      outsideTemperatureOrigin: editedHeatingCurve?.x3_origin,
      outsideTemperature: editedHeatingCurve?.x3,
      waterFlowTemperature: editedHeatingCurve?.y3,
    });
  }

  if (editedHeatingCurve?.x4 && editedHeatingCurve?.y4 && editedHeatingCurve?.x4_origin) {
    formattedEditedHeatingCurve.push({
      outsideTemperatureOrigin: editedHeatingCurve?.x4_origin,
      outsideTemperature: editedHeatingCurve?.x4,
      waterFlowTemperature: editedHeatingCurve?.y4,
    });
  }

  const editedHeatingCurveLineParameters = computeHeatingCurveLineParameters(
    editedHeatingCurve,
    latestHeatingDistributionCircuitMeasurement,
  );

  return (
    <Card
      title="Loi de chauffe"
      bodyStyle={{ padding: 0 }}
      style={{ borderRadius: 10 }}
      headStyle={{ borderBottomColor: "white" }}
    >
      <ResponsiveContainer height={400}>
        <LineChart margin={{ top: 10, right: -20, left: -20, bottom: -10 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="outsideTemperature"
            type="number"
            label={{
              value: "Température extérieure (°C)",
              style: { fill: "grey", fontStyle: "italic" },
            }}
            tickFormatter={(val) => val.toFixed(1)}
            domain={[minX, maxX]}
            height={60}
          />
          <YAxis
            dataKey="waterFlowTemperature"
            type="number"
            tickFormatter={(value) => value.toFixed(0)}
            label={{
              value: "Consigne calculée (°C)",
              angle: -90,
              style: { fill: "grey", fontStyle: "italic" },
              position: "center",
            }}
            domain={
              editedHeatingCurveLineParameters
                ? [
                    Math.min(
                      yMinX,
                      yMaxX,
                      editedHeatingCurveLineParameters.yMinX,
                      editedHeatingCurveLineParameters.yMaxX,
                      waterFlowSetPointTemperature - 5,
                    ),
                    Math.max(
                      yMinX,
                      yMaxX,
                      editedHeatingCurveLineParameters.yMinX,
                      editedHeatingCurveLineParameters.yMaxX,
                      waterFlowSetPointTemperature + 5,
                    ),
                  ]
                : [
                    Math.min(yMinX, yMaxX, waterFlowSetPointTemperature - 5),
                    Math.max(yMaxX, yMinX, waterFlowSetPointTemperature + 5),
                  ]
            }
            width={80}
          />
          <YAxis
            type="number"
            orientation="right"
            yAxisId="right"
            tickFormatter={(value) => value.toFixed(0)}
            label={{
              value: "Consigne appliquée (°C)",
              angle: -90,
              style: { fill: "grey", fontStyle: "italic" },
              position: "center",
            }}
            domain={
              yMaxX < yMinX
                ? [
                    Math.min(yMaxX, waterFlowSetPointTemperature - 5),
                    Math.max(yMinX, waterFlowSetPointTemperature + 5),
                  ]
                : [
                    Math.min(yMinX, waterFlowSetPointTemperature - 5),
                    Math.max(yMaxX, waterFlowSetPointTemperature + 5),
                  ]
            }
            width={80}
          />
          <Tooltip labelFormatter={() => ""} formatter={pointFormatter} />
          <ReferenceLine
            stroke="#8884d8"
            segment={[
              { x: heatingCurveMaxX, y: heatingCurveMaxY },
              { x: maxX, y: yMaxX },
            ]}
          />
          <ReferenceLine
            stroke="#8884d8"
            segment={[
              { x: heatingCurveMinX, y: heatingCurveMinY },
              { x: minX, y: yMinX },
            ]}
          />
          <Line
            type="linear"
            data={formattedHeatingCurve}
            dataKey="waterFlowTemperature"
            stroke="#8884d8"
          />
          {editedHeatingCurveState && editedHeatingCurveLineParameters && (
            <>
              <ReferenceLine
                stroke="#8884d8"
                strokeDasharray="3 3"
                segment={[
                  {
                    x: editedHeatingCurveLineParameters.heatingCurveMaxX,
                    y: editedHeatingCurveLineParameters.heatingCurveMaxY,
                  },
                  {
                    x: editedHeatingCurveLineParameters.maxX,
                    y: editedHeatingCurveLineParameters.yMaxX,
                  },
                ]}
              />
              <ReferenceLine
                stroke="#8884d8"
                strokeDasharray="3 3"
                segment={[
                  {
                    x: editedHeatingCurveLineParameters.heatingCurveMinX,
                    y: editedHeatingCurveLineParameters.heatingCurveMinY,
                  },
                  {
                    x: editedHeatingCurveLineParameters.minX,
                    y: editedHeatingCurveLineParameters.yMinX,
                  },
                ]}
              />
              <Line
                data={formattedEditedHeatingCurve}
                type="linear"
                dataKey="waterFlowTemperature"
                stroke="#8884d8"
                strokeDasharray="3 3"
              />
            </>
          )}
          <ReferenceDot
            name="computed-point"
            x={circuitOutsideTemperature}
            y={computeTempWithHeatingLaw(circuitOutsideTemperature, latestHeatingCurve)}
            r={6}
            stroke="green"
          />
          <ReferenceDot
            name="applied-point"
            x={circuitOutsideTemperature}
            y={waterFlowSetPointTemperature}
            r={5}
            fill="red"
          />
          {PointHighLight(
            circuitOutsideTemperature,
            latestHeatingCurve,
            minX,
            yMaxX,
            "green",
            "3 3",
            2,
          )}
          {PointHighLight(20, latestHeatingCurve, minX, yMaxX, "grey", "", 0)}
          <ReferenceLine
            name="applied-point-y-axis-projection"
            stroke="red"
            strokeDasharray="3 3"
            segment={[
              { x: maxX, y: waterFlowSetPointTemperature },
              {
                x: circuitOutsideTemperature,
                y: waterFlowSetPointTemperature,
              },
            ]}
            label={
              <Label
                content={renderLabel}
                value={waterFlowSetPointTemperature.toFixed(2)}
                color="red"
                autoReverse
              />
            }
          />
        </LineChart>
      </ResponsiveContainer>
      <RenderIf
        predicate={hasPermission(user, Permissions.HeatingInstallationsOffsetToggleUpdate)}
        fallback={null}
      >
        <HeatingCurveControls hdcId={heatingDistributionCircuitId} siteSlug={slug} />
      </RenderIf>
      <RenderIf predicate={hasPermission(user, Permissions.ExportCreate)} fallback={null}>
        <Card style={{ backgroundColor: "#dadada" }}>
          <Space direction="vertical">
            <Typography.Text strong>
              {`Informations complémentaires pour le réglage (${moment(
                heatingCurveModifications?.[heatingCurveModifications?.length - 1]?.timestamp,
              )?.format("YYYY-MM-DD")})`}
            </Typography.Text>
            <Typography.Text>{`Température x = y : ${xEqualy?.toFixed(2)} °C`}</Typography.Text>
            {slope1 && (
              <Space direction="horizontal">
                <Typography.Text>{`Pente segment 1: ${slope1.toFixed(2)} °C/°C`}</Typography.Text>
                <Typography.Text
                  style={{ color: colors.brown.dark }}
                >{`([${latestHeatingCurve?.x1}, ${latestHeatingCurve?.y1}], [${latestHeatingCurve?.x2}, ${latestHeatingCurve?.y2}])`}</Typography.Text>
              </Space>
            )}
            {slope2 && (
              <Space direction="horizontal">
                <Typography.Text>{`Pente segment 2: ${slope2.toFixed(2)} °C/°C`}</Typography.Text>
                <Typography.Text
                  style={{ color: colors.brown.dark }}
                >{`([${latestHeatingCurve?.x2}, ${latestHeatingCurve?.y2}], [${latestHeatingCurve?.x3}, ${latestHeatingCurve?.y3}])`}</Typography.Text>
              </Space>
            )}
            {slope3 && (
              <Space direction="horizontal">
                <Typography.Text>{`Pente segment 3: ${slope3.toFixed(2)} °C/°C`}</Typography.Text>
                <Typography.Text
                  style={{ color: colors.brown.dark }}
                >{`([${latestHeatingCurve?.x3}, ${latestHeatingCurve?.y3}], [${latestHeatingCurve?.x4}, ${latestHeatingCurve?.y4}])`}</Typography.Text>
              </Space>
            )}
          </Space>
        </Card>
      </RenderIf>
    </Card>
  );
};

export default HeatingCurve;
