import { Suspense } from "react";
import * as React from "react";
import { Empty, Tabs } from "antd";
import { useWindowWidth } from "src/lib/hooks/window-width";
import ErrorBoundaryWithFallback from "src/components/ErrorBoundaryWithFallback";
import Loader from "src/components/Loader";
import type { Site } from "src/lib/api";
import SiteInformationTab from "./SiteInformationTab";
import { hasPermission, Permissions } from "src/lib/access-control";
import { useAuth0 } from "@auth0/auth0-react";
import PlcsTab from "./PlcsTab";
import HeatingProductionUnitsTab from "./HeatingProductionUnitsTab";
import HeatingDistributionCircuitsTab from "./HeatingDistributionCircuitsTab";
import EnergyMetersConfigurationTab from "./EnergyMetersConfigurationTab";
import ComfortAnalysis from "./ComfortAnalysis/ComfortAnalysis";
import InstrumentPositionConfigurationTab from "./InstrumentPositionConfigurationTab";

function ellipsis(str: string, n: number): string {
  return str.length > n ? str.substr(0, n - 1) + "…" : str;
}

function adjustTitle(title: string, isWideScreen: boolean) {
  return isWideScreen ? ellipsis(title, 30) : title;
}

const SiteConfiguration: React.FC<{
  site: Site;
}> = ({ site }) => {
  const { isWideScreen } = useWindowWidth();
  const { user } = useAuth0();

  return (
    <div>
      <Tabs defaultActiveKey="1" tabPosition={isWideScreen ? "left" : "top"}>
        {hasPermission(user, Permissions.SitesCreate) && (
          <Tabs.TabPane
            tab={adjustTitle("Information du site", isWideScreen)}
            key={"site-information"}
          >
            <Suspense fallback={<Loader />}>
              <ErrorBoundaryWithFallback fallback={<Empty />}>
                <SiteInformationTab site={site}></SiteInformationTab>
              </ErrorBoundaryWithFallback>
            </Suspense>
          </Tabs.TabPane>
        )}
        {hasPermission(user, Permissions.SitesCreate) && (
          <Tabs.TabPane tab={adjustTitle("Automate(s)", isWideScreen)} key={"site-plcs"}>
            <Suspense fallback={<Loader />}>
              <ErrorBoundaryWithFallback fallback={<Empty />}>
                <PlcsTab site={site} />
              </ErrorBoundaryWithFallback>
            </Suspense>
          </Tabs.TabPane>
        )}
        {hasPermission(user, Permissions.SitesCreate) && (
          <Tabs.TabPane tab={adjustTitle("Unité(s) de production", isWideScreen)} key={"site-hpus"}>
            <Suspense fallback={<Loader />}>
              <ErrorBoundaryWithFallback fallback={<Empty />}>
                <HeatingProductionUnitsTab site={site} />
              </ErrorBoundaryWithFallback>
            </Suspense>
          </Tabs.TabPane>
        )}
        {hasPermission(user, Permissions.SitesCreate) && (
          <Tabs.TabPane
            tab={adjustTitle("Circuit(s) de distribution", isWideScreen)}
            key={"site-hdcs"}
          >
            <Suspense fallback={<Loader />}>
              <ErrorBoundaryWithFallback fallback={<Empty />}>
                <HeatingDistributionCircuitsTab site={site} />
              </ErrorBoundaryWithFallback>
            </Suspense>
          </Tabs.TabPane>
        )}
        {hasPermission(user, Permissions.SitesCreate) && (
          <Tabs.TabPane tab={adjustTitle("Compteur(s) d'énergie", isWideScreen)} key={"site-ems"}>
            <Suspense fallback={<Loader />}>
              <ErrorBoundaryWithFallback fallback={<Empty />}>
                <EnergyMetersConfigurationTab site={site} />
              </ErrorBoundaryWithFallback>
            </Suspense>
          </Tabs.TabPane>
        )}
        {hasPermission(user, Permissions.SitesCreate) && (
          <Tabs.TabPane tab={adjustTitle("Analyse du confort", isWideScreen)} key={"site-ca"}>
            <Suspense fallback={<Loader />}>
              <ErrorBoundaryWithFallback fallback={<Empty />}>
                <ComfortAnalysis site={site} />
              </ErrorBoundaryWithFallback>
            </Suspense>
          </Tabs.TabPane>
        )}
        {hasPermission(user, Permissions.SitesCreate) && (
          <Tabs.TabPane
            tab={adjustTitle("Positionnement des sondes", isWideScreen)}
            key={"site-instrument-positions"}
          >
            <Suspense fallback={<Loader />}>
              <ErrorBoundaryWithFallback fallback={<Empty />}>
                <InstrumentPositionConfigurationTab site={site} />
              </ErrorBoundaryWithFallback>
            </Suspense>
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default SiteConfiguration;
