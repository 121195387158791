import React from "react";
import Loader from "../Loader";

const withSuspenseComponent = (Component: React.ComponentType<any>) => {
  return (props: any) => {
    return (
      <React.Suspense fallback={<Loader />}>
        <Component {...props} />
      </React.Suspense>
    );
  };
};

export default withSuspenseComponent;
