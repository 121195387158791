import { useAuth0 } from "@auth0/auth0-react";
import { Button, Form, FormInstance, Input, Modal, Space, Typography } from "antd";
import * as React from "react";
import { ComfortRange, Site } from "src/lib/api";
import { useUpdateHeatingPeriod } from "src/lib/hooks/api";
import { withNotify } from "src/lib/notify";
import { isHeatingReferent } from "src/lib/utils";
import { SiteInfoConsult } from "src/pages/sites";
import TooltipButton from "./TooltipButton";

export const ConfortRangeForm: React.FC<{
  data: ComfortRange;
  form: FormInstance<any>;
  layout?: "inline" | "horizontal";
}> = ({ data, form, layout }) => {
  return (
    <Form
      form={form}
      layout={layout}
      name="comfort-range-form"
      initialValues={{ comfortRange: data }}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Form.Item label="Valeur basse (°C)" name={["comfortRange", "lower"]} style={{ margin: 5 }}>
        <Input type="number" style={{ width: 120 }} />
      </Form.Item>
      <Form.Item label="Valeur cible (°C)" name={["comfortRange", "target"]} style={{ margin: 5 }}>
        <Input type="number" style={{ width: 120 }} />
      </Form.Item>
      <Form.Item label="Valeur haute (°C)" name={["comfortRange", "upper"]} style={{ margin: 5 }}>
        <Input type="number" style={{ width: 120 }} />
      </Form.Item>
    </Form>
  );
};

const SiteComfortRange: React.FC<{ site: Site }> = ({ site }) => {
  const [form] = Form.useForm();
  const { mutateAsync: updateHeatingPeriod } = useUpdateHeatingPeriod(site.slug);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);

  const { user } = useAuth0();

  const siteHeatingReferent = site.heatingReferentUser;

  function resetForm() {
    setIsModalVisible(false);
    setConfirmLoading(false);
    form.resetFields();
  }

  function onSave() {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(async ({ comfortRange }: any) => {
        await withNotify(
          updateHeatingPeriod({
            id: site.currentHeatingSeason.id,
            key: site.currentHeatingSeason.key,
            comfortRange: {
              lower: parseFloat(comfortRange.lower),
              target: parseFloat(comfortRange.target),
              upper: parseFloat(comfortRange.upper),
            },
          }),
          "La modification de la température cible a bien été prise en compte",
          "Une erreur est survenue lors de la mise à jour de la température cible.",
        );
        resetForm();
      })
      .catch((err: any) => {
        resetForm();
        console.error("saving instruments error", err);
      });
  }

  return (
    <div>
      <SiteInfoConsult
        updateAction={() => setIsModalVisible(true)}
        textContent={`${site.currentHeatingSeason.comfortRange.lower}°C (mini) - ${site.currentHeatingSeason.comfortRange.target}°C (cible) - ${site.currentHeatingSeason.comfortRange.upper}°C (maxi)`}
      />
      <Modal
        visible={isModalVisible}
        title={"Température cible"}
        okText="Enregistrer"
        cancelText="Annuler"
        onCancel={resetForm}
        confirmLoading={confirmLoading}
        forceRender={true}
        destroyOnClose={true}
        footer={[
          <Space>
            <Button key="cancel" onClick={resetForm}>
              Annuler
            </Button>
            <TooltipButton
              onClick={onSave}
              disableMessage={`Pour modifier ces données veuillez contacter le référent chauffage de ce site: ${siteHeatingReferent?.name}`}
              disabled={!isHeatingReferent(user, siteHeatingReferent)}
            />
          </Space>,
        ]}
      >
        <div style={{ marginBottom: 20 }}>
          <Typography.Text>
            Renseignez les températures cibles de votre site lors de la saison de chauffe.
          </Typography.Text>
        </div>
        <ConfortRangeForm
          data={site.currentHeatingSeason.comfortRange}
          form={form}
          layout={"inline"}
        ></ConfortRangeForm>
      </Modal>
    </div>
  );
};

export default SiteComfortRange;
