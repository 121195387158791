export const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FF8042",
  "#3b2ba0",
  "#FFBB28",
  "#912e2c",
  "#650d99",
  "#ba5349",
];
