import { groupBy } from "src/lib/group-by";
import { sum } from "src/lib/math";

import type { InstrumentGroup, InstrumentGroupAlert } from "src/lib/api";

export function filterAlertsForInstrumentGroups(
  alerts: InstrumentGroupAlert[],
  instrumentGroups: InstrumentGroup[],
) {
  const instrumentGroupsLookup = new Set(instrumentGroups.map((ig) => ig.id));
  return alerts
    .filter((a) => a.isActive)
    .filter((a) => instrumentGroupsLookup.has(a.instrumentGroupId));
}

export function filterOpenAlerts(alerts: InstrumentGroupAlert[]) {
  return alerts.filter((a) => a.isActive && !a.isDefault);
}

export function instrumentGroupHasOpenAlert(
  alerts: InstrumentGroupAlert[],
  instrumentGroup: InstrumentGroup,
): number {
  return filterOpenAlerts(alerts).filter((a) => a.instrumentGroupId === instrumentGroup.id).length;
}

export function openAlertsPerSite(
  alerts: InstrumentGroupAlert[],
  instrumentGroups: InstrumentGroup[],
): { [sId: string]: number } {
  const alertsPerInstrumentGroup = groupBy(alerts, (a) => a.instrumentGroupId);
  const nAlertsPerInstrumentGroupEntries = Object.entries(alertsPerInstrumentGroup).map(
    ([igId, as]) => [igId, filterOpenAlerts(as).length],
  );
  const nAlertsPerInstrumentGroup = Object.fromEntries(nAlertsPerInstrumentGroupEntries);
  const instrumentGroupsPerSite = groupBy(instrumentGroups, (ig) => ig.siteId);

  return Object.entries(instrumentGroupsPerSite).reduce(
    (acc, [sId, igs]) => ({
      ...acc,
      [sId]: sum(igs.map((ig) => ig.id).map((id) => nAlertsPerInstrumentGroup[id])),
    }),
    {} as { [sId: number]: number },
  );
}
