import { notification } from "antd";

/****
 * withNotify will await the received promise notifying the user of it's result.
 *
 * @param promise The user will be notified of the completion of this promise.
 * @param onSuccess The message to display upon the success of the function.
 * @param onFailure The message to display upon the failure of the function.
 */
async function withNotify(promise: Promise<any>, onSuccess: string, onFailure: string) {
  try {
    await promise;
    notification.success({ message: onSuccess });
  } catch {
    notification.error({
      message: "Une erreur s'est produite",
      description: onFailure,
    });
  }
}

export { withNotify };
