import moment from "moment";
import { HeatingPeriod, HeatingSeason } from "./api";

export const defaultHeatingPeriod = (key: string) => {
  const dhp: HeatingPeriod = {
    key,
    startTime: heatingPeriodDefaultStart(key).format("YYYY-MM-DD"),
    endTime: heatingPeriodDefaultEnd(key).format("YYYY-MM-DD"),
    isDefaultPeriod: true,
  };

  return dhp;
};

export const heatingPeriodDefaultStart = (key: string) => {
  const startYear = key.split("-")[0];
  return moment(`${startYear}-10-01`, "YYYY-MM-DD");
};

export const heatingPeriodDefaultEnd = (key: string) => {
  const endYear = key.split("-")[1];
  return moment(`${endYear}-05-31`, "YYYY-MM-DD");
};

export const heatingPeriodOptions = () => {
  const startYear = 2017;
  const currentYear = new Date().getFullYear();
  const years = Array.from(Array(currentYear - startYear + 1), (_, x) => startYear + x);
  return years.map((y: number) => getHeatingPeriodKey(y));
};

export function formatHeatingPeriodTitle(heatingPeriod?: Partial<HeatingSeason>) {
  const currentKey = getHeatingPeriod(new Date());
  return `${moment(heatingPeriod?.startTime ?? heatingPeriodDefaultStart(currentKey)).format(
    "DD/MM/YYYY",
  )}
    - ${moment(heatingPeriod?.endTime ?? heatingPeriodDefaultEnd(currentKey)).format(
      "DD/MM/YYYY",
    )}`;
}

export function getHeatingPeriodReferenceMoment(
  date: moment.Moment,
  heatingPeriodReference?: HeatingSeason,
) {
  if (!heatingPeriodReference) {
    // if no reference is defined, we return the same month of the previous year
    return date.clone().subtract(1, "year");
  } else {
    const key = heatingPeriodReference.key;
    const startYear = moment(key.slice(0, 4));
    const endYear = moment(key.slice(5, 9));

    if (date.month() <= 6) {
      // from january to june
      return date.clone().year(endYear.year());
    } else {
      // from july to december
      return date.clone().year(startYear.year());
    }
  }
}

function getHeatingPeriodKey(startYear: number) {
  return `${startYear}-${startYear + 1}`;
}

function getHeatingPeriod(date: Date) {
  const dateYear = date.getFullYear();
  const dateMonth = date.getMonth();

  if (dateMonth >= 0 && dateMonth < 7) {
    return getHeatingPeriodKey(dateYear - 1);
  } else if (dateMonth >= 7 && dateMonth < 13) {
    return getHeatingPeriodKey(dateYear);
  } else {
    return "";
  }
}

export default getHeatingPeriod;
